import { Box, Divider, FormHelperText, Grid, Typography } from "@mui/material";
import FormInputStaticDatePicker from "components/forms/FormInputStaticDatePicker";
import FormTimeSelect from "components/forms/FormTimeSelect";
import PlanActionButton from "components/PlanEventActionButtons/PlanActionButton";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import { normalizeDate } from "utilities";
import { ampm, hours, min } from "./data/list/detailData";

function DateForm({
  errors,
  register,
  setValue,
  control,
  trigger,
  setFormStep,
  setError,
  getValues,
}) {
  const handleClickContinue = async () => {
    const result = await trigger(["startTime", "endTime"]);
    const { startTime, endTime } = getValues();
    const normalizeStartDate = normalizeDate(startTime);
    const normalizeEndDate = normalizeDate(endTime);
    const startDateTime = dayjs(startTime.date)
      .hour(normalizeStartDate.split(":")[0])
      .minute(normalizeStartDate.split(":")[1])
      .second(0)
      .millisecond(0);
    const endDateTime = dayjs(endTime.date)
      .hour(normalizeEndDate.split(":")[0])
      .minute(normalizeEndDate.split(":")[0])
      .second(0)
      .millisecond(0);
    if (new Date(startDateTime) < new Date()) {
      toast.error("past date is not allowed!");
      return;
    }
    if (new Date(endDateTime) < new Date(startDateTime)) {
      toast.error("end date can't be before start date");
      return;
    }
    result && setFormStep((prev) => prev + 1);

    document.querySelector("#stepper").scrollIntoView({
      behavior: "smooth",
    });
  };

  const handleClickBack = async () => {
    setFormStep((prev) => prev - 1);
  };
  return (
    <Grid
      container
      rowSpacing={3}
      sx={{ padding: { xxs: "0.7rem", xs: "1.5rem", sm: "2rem" } }}
      justifyContent="center"
    >
      <Grid item xxs={12}>
        <FirstSection />
      </Grid>
      <Grid item xxs={12}>
        <Divider />
      </Grid>
      <Grid item xxs={12}>
        <SecondSection
          control={control}
          register={register}
          setValue={setValue}
          errors={errors}
          getValues={getValues}
        />
      </Grid>
      <Grid
        item
        container
        xxs={12}
        justifyContent="center"
        style={{
          textAlign: "center",
          paddingTop: "4rem",
        }}
      >
        <PlanActionButton
          primaryTextValue="Save and Continue"
          handlePrimaryClick={handleClickContinue}
          secondaryTextValue="Back"
          handleSecondaryClick={handleClickBack}
        />
      </Grid>
    </Grid>
  );
}

export default DateForm;

function FirstSection() {
  return (
    <Grid
      container
      rowSpacing={2}
      justifyContent="center"
      sx={{ textAlign: "center", pb: "1rem" }}
    >
      <Grid item xxs={12}>
        <Typography variant="h4">Date of Event</Typography>
      </Grid>
      <Grid item xxs={12}>
        <Typography variant="p3">
          When are you celebrating? Please tell us your date of the event
        </Typography>
      </Grid>
    </Grid>
  );
}

function SecondSection({ control, setValue, register, errors, getValues }) {
  return (
    <Grid container justifyContent="center" rowSpacing={2}>
      <Grid container item xxs={12} lg={6}>
        <Grid item xxs={12} sx={{ textAlign: "center" }}>
          <Typography variant="p600" color="textPrimary">
            Start Time
          </Typography>
        </Grid>
        <Grid item xxs={12}>
          <FormInputStaticDatePicker
            control={control}
            name="startTime.date"
            errors={errors?.startTime?.date?.message}
            minDate={dayjs(new Date())}
            defaultValue={
              getValues("startTime.date")
                ? dayjs(new Date(getValues("startTime.date")))
                : dayjs(new Date())
            }
          />
        </Grid>
        <Grid
          justifyContent="center"
          sx={{ marginTop: "1rem" }}
          item
          container
          xxs={12}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              maxWidth: "340px",
            }}
          >
            <Time
              errors={errors}
              register={register}
              setValue={setValue}
              getValues={getValues}
            />
          </Box>
        </Grid>
      </Grid>
      <Grid container item xxs={12} lg={6} justifyContent="center">
        <Grid item xxs={12} sx={{ textAlign: "center" }}>
          <Typography variant="p600" color="textPrimary">
            End Time
          </Typography>
        </Grid>
        <Grid item xxs={12}>
          <FormInputStaticDatePicker
            control={control}
            name="endTime.date"
            errors={errors?.endTime?.date?.message}
            minDate={dayjs(new Date())}
            defaultValue={
              getValues("endTime.date")
                ? dayjs(new Date(getValues("endTime.date")))
                : dayjs(new Date())
            }
          />
        </Grid>
        <Grid
          item
          container
          xxs={12}
          justifyContent="center"
          sx={{
            marginTop: "1rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flex: 1,
              maxWidth: "340px",
            }}
          >
            <Time
              register={register}
              setValue={setValue}
              getValues={getValues}
              time="endTime"
              errors={errors}
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
}

function Time({ errors, register, setValue, getValues, time = "startTime" }) {
  const timeError = errors?.[time]?.hours?.message;

  return (
    <Grid justifyContent="center" container>
      <Grid item xxs>
        <FormTimeSelect
          register={register}
          name={`${time}.hours`}
          label="Hour"
          setValue={setValue}
          getValues={getValues}
          options={hours}
        />
      </Grid>

      <Grid item xxs>
        <FormTimeSelect
          register={register}
          name={`${time}.min`}
          label="Minute"
          setValue={setValue}
          getValues={getValues}
          options={min}
        />
      </Grid>
      <Grid item xxs>
        <FormTimeSelect
          register={register}
          name={`${time}.ampm`}
          label="AM/PM"
          setValue={setValue}
          getValues={getValues}
          options={ampm}
        />
      </Grid>
      <Grid item xxs={12}>
        {timeError && <FormHelperText error>{timeError}</FormHelperText>}
      </Grid>
    </Grid>
  );
}
