import { Grid, Typography } from "@mui/material";
import FormInputDatePicker from "components/forms/FormInputDatePicker";
import FormInputTextSecondary from "components/forms/FormInputTextSecondary";
import FormNumberInput from "components/forms/FormNumberInput";
import dayjs from "dayjs";
import React from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import FormRadioButton from "components/forms/FormRadioButton";
import FormCheckBox from "components/forms/FormCheckBox";
import { gender } from "constants";
import { language } from "constants";
import Wrapper from "../../../comp/Wrapper";
import FormIconInputText from "components/forms/FormIconInputText";
import {
  facebookIcon,
  instagramIcon,
  twitterIcon,
} from "views/pages/Login/SignUpPage/Vendor/data/icons/businessIcon";
import { useWatch } from "react-hook-form";
function PersonalForm({ control, errors, getValues, date }) {
  useWatch({
    control,
    name: "languages",
  });

  const [showPass, setShowPass] = React.useState(false);
  const [showExistingPassword, setShowExistingPassword] = React.useState(false);

  const handleClickShowPass = () => {
    setShowPass(!showPass);
  };
  const { birthDate, languages, existingPassword } = getValues();

  return (
    <Wrapper label="Personal Information">
      <Grid container justifyContent="center" rowSpacing={2}>
        <Grid item container xxs={12} rowSpacing={2} columnSpacing={2}>
          <Grid item xxs={12} sm>
            <FormInputTextSecondary
              control={control}
              label="Full Name"
              name="fullName"
              placeholder="Enter your name"
              errors={errors?.fullName?.message}
            />
          </Grid>

          <Grid item xxs={12} sm>
            <FormInputDatePicker
              control={control}
              name="birthDate"
              label="Birth Date"
              errors={errors?.birthDate?.message}
              defaultValue={
                birthDate ??
                dayjs(new Date(date.getFullYear() - 18, date.getMonth(), 1))
              }
              minDate={dayjs(new Date(1900, 0, 1))}
              maxDate={dayjs(
                new Date(
                  date.getFullYear() - 18,
                  date.getMonth(),
                  date.getDate()
                )
              )}
              placeholder="Enter your birth date"
            />
          </Grid>
        </Grid>
        <Grid item container xxs={12} rowSpacing={2} columnSpacing={2}>
          <Grid item xxs={12} sm>
            <FormInputTextSecondary
              control={control}
              label="Old Password"
              name="existingPassword"
              icon={VisibilityOff}
              iconToggle={Visibility}
              toggle={showExistingPassword}
              handleClick={() => setShowExistingPassword(!showExistingPassword)}
              errors={errors?.password?.message}
            />
          </Grid>
          <Grid item xxs={12} sm>
            <FormInputTextSecondary
              control={control}
              label="New Password"
              name="password"
              icon={VisibilityOff}
              iconToggle={Visibility}
              toggle={showPass}
              handleClick={handleClickShowPass}
              errors={errors?.password?.message}
              disabled={!Boolean(existingPassword)}
            />
          </Grid>
        </Grid>
        <Grid
          item
          container
          xxs={12}
          rowSpacing={2}
          columnSpacing={2}
          justifyContent="flex-start"
        >
          <Grid item xxs={12} sm={6}>
            <FormNumberInput
              control={control}
              label="Phone Number"
              name="telephoneNumber"
              errors={errors?.telephoneNumber?.message}
            />
          </Grid>
          <Grid item xxs={12} sm={6}>
            <FormInputTextSecondary
              control={control}
              label="Website Url"
              name="websiteUrl"
              placeholder="Enter your website url."
              errors={errors?.websiteUrl?.message}
            />
          </Grid>
        </Grid>
        <Grid item container justifyContent="flex-start" rowSpacing={2}>
          <Grid item xxs={12} md={11}>
            <FormRadioButton
              control={control}
              name="gender"
              label="Select your gender"
              options={gender}
              row={true}
              sx={{ justifyContent: "space-between" }}
              errors={errors?.gender?.message}
            />
          </Grid>
          <Grid item xxs={12}>
            <FormCheckBox
              control={control}
              name="languages"
              label="Select your working languages"
              options={language}
              row={true}
              sx={{ mr: "3.5rem" }}
              errors={errors?.languages?.message}
            />
          </Grid>
          {languages.includes("other") && (
            <Grid item xxs={12}>
              <FormInputTextSecondary
                control={control}
                label="Other Languages"
                name="otherLanguages"
                placeholder="Please enter your languages(seperate with comma's)"
                sx={{ "& .MuiOutlinedInput-root": { borderRadius: "12px" } }}
                errors={errors?.otherLanguages?.message}
              />
            </Grid>
          )}
        </Grid>
        <Grid item container rowSpacing={3}>
          <Grid item>
            <Typography variant="h5">Social Media URLs</Typography>
          </Grid>
          <Grid item xxs={12}>
            <FormIconInputText
              control={control}
              name="facebookUrl"
              icon={facebookIcon}
              placeholder="Enter your facebook profile link"
              errors={errors?.facebookUrl?.message}
            />
          </Grid>
          <Grid item xxs={12}>
            <FormIconInputText
              control={control}
              name="instagramUrl"
              icon={instagramIcon}
              placeholder="Enter your Instagram profile link"
              errors={errors?.instagramUrl?.message}
            />
          </Grid>
          <Grid item xxs={12}>
            <FormIconInputText
              control={control}
              name="twitterUrl"
              icon={twitterIcon}
              placeholder="Enter your Twitter profile link"
              errors={errors?.twitterUrl?.message}
            />
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default PersonalForm;
