import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import banner from "assets/dashboard/myEvent/banner.png";
import classes from "./EventDetail.module.css";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import circleTick from "assets/icon/circle-tick.svg";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import profile from "assets/dashboard/myEvent/profile.webp";
import VendorDetailCard from "components/VendorDetailCard/VendorDetailCard";
import EventBanner from "components/EventBanner/EventBanner";
import VendorQuoteCard from "components/VendorQuoteCard/VendorQuoteCard";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getOneEvents } from "api/v1/event";
import { getEventBookings } from "api/v1/booking";
import formatDate from "utils/formatDate";
import convertFirstLetterCapital from "utils/convertFirstLetterCapital";
import dayjs from "dayjs";
import { useUserContext } from "context/UserContext";
import CreateQuote from "views/pages/Dashboard/Quotes/CreateQuotePage/CreateQuote";
import SubscribedServices from "./comp/SubscribedServices";
import useImage from "Hooks/useImage";

const EventDetail = () => {
  const theme = useTheme();
  const { id } = useParams();
  const {
    isVendor,
    isPaypalConnected,
    isStripeConnected,
    user: { businessName, rating, skills },
  } = useUserContext();
  const {
    data,
    isLoading: isEventLoading,
    error,
    refetch,
  } = useQuery(
    ["event", id, "detail"],
    async () => {
      const eventDetail = await getOneEvents(id);
      return eventDetail.data;
    },
    {
      enabled: Boolean(id),
    }
  );
  const { data: bannerImage, isLoading: isImageLoading } = useImage({
    key: data?.type?.eventTypeId?.images[0],
  });
  const { data: bookings } = useQuery(
    ["event", id, "bookings"],
    async () => {
      const bookings = await getEventBookings({ id });
      return bookings.data;
    },
    {
      enabled: Boolean(id),
    }
  );

  const [openAddQuoteDialog, setOpenAddQuoteDialog] = React.useState(false);

  const filteredServices = () => {
    return data?.services
      .filter(({ serviceId }) => skills.includes(serviceId._id))
      .map(({ serviceId }) => ({
        label: serviceId.name,
        value: serviceId._id,
      }));
  };
  const isBooking = bookings && bookings.length > 0;
  const isBookingPaid = () => {
    if (isBooking) {
      return bookings.some((booking) => booking.paymentStatus === "paid");
    } else {
      return false;
    }
  };
  return (
    <Box>
      <EventBanner
        status={data?.status}
        btnLink={data ? `/dashboard/events/${data?._id}/edit` : "#"}
        bannerImage={bannerImage}
        isImageLoading={isImageLoading}
        isVendor={isVendor}
        isQuote={data?.quotes ? data?.quotes.length > 0 : false}
        isEventLoading={isEventLoading}
        setOpenAddQuoteDialog={setOpenAddQuoteDialog}
        isPaypalConnected={isPaypalConnected}
        isStripeConnected={isStripeConnected}
        eventId={id}
        refetchEvent={refetch}
        isBookingPaid={isBookingPaid}
        isBooking={isBooking}
      />
      {isEventLoading ? (
        <div className="center">
          <CircularProgress size="3rem" />
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            px: 4,
            pb: 3,
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: "1500px",
              width: "100%",
              gap: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ display: "flex" }}>
                {" "}
                <Typography
                  variant="body6"
                  sx={{ hyphens: "auto", wordBreak: "break-all" }}
                >
                  {data?.title}
                </Typography>
                <Typography variant="body6" color={theme.palette.primary.main}>
                  (
                  {data?.venueType &&
                    convertFirstLetterCapital(data?.venueType)}
                  )
                </Typography>
              </Box>
              <Typography
                component="p"
                variant="p"
                className={classes.paragraph}
              >
                {data?.description}
              </Typography>
            </Box>
            <hr />
            <Box className={classes.iconContainer} sx={{ gap: 2 }}>
              <Box
                className={classes.iconGroup}
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                <Box className={classes.icon}>
                  <CalendarMonthIcon />
                  <Typography
                    variant="h5"
                    fontWeight="400"
                    style={{ whiteSpace: "pre-line" }}
                  >
                    {`${formatDate(data?.startTime)} - ${formatDate(
                      data?.endTime
                    )}`}
                  </Typography>
                </Box>
                <Box className={classes.icon}>
                  <LocationOnIcon />
                  <Typography variant="h5" fontWeight="400">
                    {data?.location?.address}
                  </Typography>
                </Box>
                <Box className={classes.icon}>
                  <GroupAddIcon />
                  <Typography variant="h5" fontWeight="400">
                    {data?.numberOfPeople} Number of Guests
                  </Typography>
                </Box>
              </Box>
              <Box
                className={classes.iconGroup}
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                {/* <Box className={classes.icon}>
                  <WatchLaterIcon />
                  {data?.startTime && data?.endTime && (
                    <Typography variant="h5" fontWeight="400">
                      {dayjs(data?.startTime).format("LT")} -{" "}
                      {dayjs(data?.endTime).format("LT")}
                    </Typography>
                  )}
                </Box> */}
                {/* <Box className={classes.icon}>
                  <GroupAddIcon />
                  <Typography variant="h5" fontWeight="400">
                    {data?.numberOfPeople} Number of Guests
                  </Typography>
                </Box> */}
              </Box>
            </Box>
            <Typography component="p" variant="body6" mt={3}>
              {isVendor ? "Services Details" : "Other Details"}
            </Typography>
            {isVendor ? (
              <SubscribedServices services={data?.services} />
            ) : (
              <Grid
                container
                columnSpacing={1}
                rowSpacing={1}
                mt={2}
                mb={2}
                alignItems="flex-start"
              >
                {data?.services?.map(({ info: { name } }, index) => (
                  <Grid item lg md={6} sm={12} xxs={12} key={index}>
                    <Box className={classes.icon}>
                      <img src={circleTick} alt="tick" />
                      <Typography variant="h5" fontWeight="400">
                        {name}
                      </Typography>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            )}

            <hr />
            {isVendor ? (
              <>
                {data?.quotes.length > 0 ? (
                  <Box>
                    <VendorQuote
                      businessName={businessName}
                      rating={rating}
                      quote={data?.quotes[0]}
                      eventName={data?.title}
                      location={data?.location?.address}
                      eventDate={data?.startTime}
                    />
                  </Box>
                ) : null}
              </>
            ) : (
              <>
                {bookings?.length > 0 ? (
                  <>
                    <Box className={classes.vendor}>
                      <Typography component="p" variant="body6" mt={3}>
                        Vendor Details
                      </Typography>
                    </Box>
                    <Box
                      mt={3}
                      sx={{ display: "flex", flexDirection: "column", gap: 2 }}
                    >
                      {bookings?.map(
                        ({ _id, quoteId, amount, vendorId, status }) => (
                          <VendorDetailCard
                            key={_id}
                            userName={vendorId?.fullName}
                            numberOfReview={vendorId?.rating}
                            priceOffered={amount}
                            rating={vendorId?.rating}
                            profileImage={vendorId?.profilePhoto}
                            profileLink={`/dashboard/vendors/${_id}`}
                            seeFullQuoteLink={`/dashboard/quotes/${quoteId}`}
                            quoteId={quoteId}
                            status={status}
                          />
                        )
                      )}
                    </Box>
                  </>
                ) : null}
              </>
            )}
          </Box>
        </Box>
      )}

      {isVendor ? (
        <CreateQuote
          open={openAddQuoteDialog}
          setOpen={setOpenAddQuoteDialog}
          id={id}
          filteredServices={filteredServices}
          refetchEvent={refetch}
        />
      ) : null}
    </Box>
  );
};

export default EventDetail;

function VendorQuote({
  businessName,
  rating,
  quote,
  eventName,
  location,
  eventDate,
}) {
  return (
    <Box>
      <Box className={classes.vendor}>
        <Typography component="p" variant="body6" mt={3}>
          Quote Details
        </Typography>
      </Box>
      <Box className={classes.quoteCardContainer}>
        <VendorQuoteCard
          profileImage={profile}
          userName={businessName}
          event={eventName}
          location={location}
          date={eventDate}
          price={quote?.price}
          rating={rating}
          showText={quote?.description}
          hiddenText="psum dolor sit amet, consectetur adipiscing elit. Nisi, sed orci et eros, vitae porttitor sit. Leo ac dolor eros praesent amet, porttitor nisi, felis"
          messageLink="#"
          seeDetailLink={`/dashboard/quotes/${quote?._id}`}
          pending={quote?.status}
        />
      </Box>
    </Box>
  );
}
