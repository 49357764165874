export const minortyOptions = [
  { label: "Black-Owned Business", value: "blackOwned" },
  { label: "Latin-Owned Business", value: "latinOwned" },
  { label: "Asian-American Owned Business", value: "asianOwned" },
  { label: "Indigenous-Owned Business", value: "indigenousOwned" },
  { label: "Woman-Owned Business", value: "womenOwned" },
  { label: "LGBTQ-Owned Business", value: "lgbtqOwned" },
  { label: "NMSDC Certified", value: "nsmdcOwned" },
  { label: "MBE Certified", value: "mbeOwned" },
  { label: "Active Duty Military/Veteran", value: "militaryOwned" },
];
