import * as React from "react";
import { Box, Button, Container, Rating, Typography } from "@mui/material";
import bannerPlaceholderImage from "assets/dashboard/myEvent/banner.png";
import profilePlaceholerImage from "assets/dashboard/myEvent/profile.webp";
import classes from "./ServiceDetail.module.css";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CustomTabs from "components/navigation/Tabs/CustomTabs";
import { calendarIcon, detailIcon, partyIcon } from "./icon/themeIcons";
import AboutVendor from "./AboutVendor/AboutVendor";
import PastEvents from "./PastEvents/PastEvents";
import CancelationPolicy from "./CancelationPolicy/CancelationPolicy";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { getVendorDetails } from "api/v1/public/vendors";
import useImage from "Hooks/useImage";
import QuickFreeModal from "components/QuickFreeModal/QuickFreeModal";
import { useNavStyleContext } from "context/NavStyleContext";
import useLoaded from "Hooks/useLoaded";
import LoadingImage from "components/LoadingImage/LoadingImage";

const ServiceDetail = ({ isDashboard = false }) => {
  const { setTextColorPrimary } = useNavStyleContext();
  const params = useParams();
  const [formStep, setFormStep] = React.useState(0);
  const [open, setOpen] = React.useState(false);
  const { data } = useQuery("VendorDetail", async () => {
    const res = await getVendorDetails(params.id);
    return res.data;
  });

  React.useEffect(() => {
    setTextColorPrimary(true);

    return () => setTextColorPrimary(false);
  }, []);
  const setValue = (index) => {
    setFormStep(index);
  };
  const { data: profilePhoto, isLoading: isProfileLinkLoading } = useImage({
    key: data?.profilePhoto,
  });
  const { data: coverPhoto, isLoading: isCoverLinkLoading } = useImage({
    key: data?.coverPhoto,
  });

  const isProfilePhoto = useLoaded({ src: profilePhoto });
  const isCoverPhoto = useLoaded({ src: coverPhoto });
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          marginTop: isDashboard ? "0" : "170px",
          paddingBottom: "1rem",
        }}
      >
        <Container maxWidth="xl" disableGutters={true}>
          <Box sx={{ width: "100%", height: "427px" }}>
            <LoadingImage
              isImageLinkLoading={isCoverLinkLoading}
              isImageLoaded={isCoverPhoto}
              image={coverPhoto}
              placeholderImage={bannerPlaceholderImage}
              alt="bannerImage"
            />
          </Box>
          <Box className={classes.container}>
            <Box className={classes.profile}>
              <Box
                sx={{
                  width: { xxs: "170px", sm: "211px" },
                  height: { xxs: "170px", sm: "206px" },
                  borderRadius: "50%",
                  marginTop: "-110px",
                  marginLeft: "30px",
                  overflow: "hidden",
                }}
              >
                <LoadingImage
                  isImageLinkLoading={isProfileLinkLoading}
                  isImageLoaded={isProfilePhoto}
                  image={profilePhoto}
                  placeholderImage={profilePlaceholerImage}
                  alt="profileImage"
                  imageStyles={{ borderRadius: "50%" }}
                />
              </Box>
              <Box>
                <Typography variant="h2" className={classes.name}>
                  {data?.businessName}
                  <CheckCircleIcon
                    sx={{ color: "#0d99ff", marginLeft: "15px" }}
                  />
                </Typography>
                {data?.rating ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "10px",
                    }}
                  >
                    <Rating
                      name="vendor-rating"
                      value={data?.rating}
                      precision={0.5}
                      readOnly
                    />
                    <Typography variant="pBold">{`${data?.rating} Reviews`}</Typography>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      paddingTop: "10px",
                    }}
                  >
                    <Rating
                      name="vendor-rating"
                      value={0}
                      precision={0.5}
                      readOnly
                    />
                    <Typography variant="pBold">{`${data?.rating} Reviews`}</Typography>
                  </Box>
                )}
              </Box>
            </Box>
            {/* <Box>
              {!localStorage.getItem("token") && (
                <Button
                  variant="primary"
                  className={classes.btn}
                  onClick={handleOpen}
                >
                  Quick Free Quote
                </Button>
              )}
            </Box> */}
          </Box>
          <Box sx={{ px: 2 }}>
            <Box>
              <CustomTabs
                step={formStep}
                values={[
                  { label: "About Vendor", icon: calendarIcon },
                  { label: "Past Events", icon: partyIcon },
                  { label: "Cancelation Policy", icon: detailIcon },
                ]}
                onSelect={setValue}
              />
            </Box>
            <Box sx={{ minHeight: "400px" }}>
              {formStep === 0 ? (
                <Box>
                  <AboutVendor data={data} />
                </Box>
              ) : formStep === 1 ? (
                <Box>
                  <PastEvents
                    vendorId={data?._id}
                    vendorSkills={data?.skills}
                  />
                </Box>
              ) : (
                <Box>
                  <CancelationPolicy policy={data?.cancellationPolicy} />
                </Box>
              )}
            </Box>
          </Box>
        </Container>
      </Box>
      {/* <Box>
        <QuickFreeModal open={open} setOpen={setOpen} paramId={params.id} />
      </Box> */}
    </>
  );
};

export default ServiceDetail;
