import React from "react";
import {
  Box,
  Typography,
  Button,
  Rating,
  useTheme,
  Grid,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import classes from "./QuotesDetails.module.css";
import large from "assets/icon/large.svg";
import profileImage from "assets/dashboard/myEvent/profile.webp";
import StarIcon from "@mui/icons-material/Star";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import moment from "moment";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import { useQuery } from "react-query";
import { useMutation } from "react-query";
import { createThread } from "api/v1/chat";
import { getQuotesById, declineQuote } from "api/v1/quotes";
import Tags from "components/Tags";
import useImage from "Hooks/useImage";
import { toast } from "react-toastify";
import { LoadingButton } from "@mui/lab";
import { useUserContext } from "context/UserContext";
import { objectKeyBasedOnUserType } from "utilities";
import CreateQuote from "../CreateQuotePage/CreateQuote";

function QuotesDetails() {
  const theme = useTheme();
  const { id } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();
  const {
    isVendor,
    user: { skills },
  } = useUserContext();
  const objectKey = objectKeyBasedOnUserType(isVendor);
  const [message, setMessage] = React.useState("");
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [declineModal, setDeclineModal] = React.useState(false);
  const [openAddQuoteDialog, setOpenAddQuoteDialog] = React.useState(false);
  const {
    data: quote,
    isLoading,
    isFetching,
    refetch,
  } = useQuery(
    ["quote", id],
    async () => {
      const res = await getQuotesById(id);
      return res.data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );
  const data = isLoading ? (state ? state : quote) : quote;

  const isDisabledActions = ["declined", "booked"].includes(data?.status);
  const { mutate: quoteDecline, isLoading: declineLoading } = useMutation(
    declineQuote,
    {
      onSuccess() {
        toast.success("Quote Declined Successfully");
        navigate("/dashboard/quotes");
      },
      onError(error) {
        toast.error(error.errMsg);
      },
    }
  );
  const { data: profilePhoto } = useImage({
    key: data?.[objectKey]?.profilePhoto,
  });
  const { mutate, isLoading: isThreadLoading } = useMutation(
    async (payload) => {
      const data = await createThread({ data: payload });
      return data;
    },
    {
      onSuccess(successData) {
        navigate(`/dashboard/messages/${successData.data._id}`);
      },
    }
  );
  function createMessage() {
    mutate({
      userId: data?.[objectKey]?._id,
      content: message,
    });
  }
  function decline() {
    quoteDecline(data._id);
  }
  function onMessageClick() {
    if (data?.[objectKey]?.threads?._id)
      navigate(`/dashboard/messages/${data?.[objectKey]?.threads._id}`);
    else setIsModalOpen(true);
  }

  function onBookingClick() {
    navigate(`/dashboard/vendors/${data?.[objectKey]?._id}/bookings`);
  }

  function handleClose() {
    setDeclineModal(false);
  }

  const filteredServices = () => {
    return quote?.eventId?.services
      .filter(({ serviceId }) => skills?.includes(serviceId))
      .map(({ serviceId, info: { name } }) => ({
        label: name,
        value: serviceId,
      }));
  };
  const selectedServices = () =>
    quote?.servicesId?.map((service) => service._id);

  return (
    <Box>
      <Typography variant="h2" px={2}>
        Quote Details:{" "}
      </Typography>
      <Box className={classes.root}>
        {isLoading ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "absolute",
              left: "50%",
              height: "100%",
            }}
          >
            <CircularProgress size="4rem" />
          </Box>
        ) : (
          <>
            <Box className={classes.detail}>
              {isVendor ? (
                <Typography
                  variant="body6"
                  mr={1}
                  fontWeight={600}
                  sx={{ hyphens: "auto", wordBreak: "break-all" }}
                >
                  {data?.eventId?.title}
                </Typography>
              ) : (
                <Box className={classes.profileDetail}>
                  <Box>
                    <img
                      src={profilePhoto ?? profileImage}
                      alt="profile"
                      className={classes.profile}
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="body6"
                      mr={1}
                      fontWeight={600}
                      sx={{ hyphens: "auto", wordBreak: "break-all" }}
                    >
                      {data?.eventId?.title}
                    </Typography>
                    <Link to={`/dashboard/vendors/${data?.vendorId?._id}`}>
                      <img
                        src={large}
                        alt="large"
                        style={{ cursor: "pointer" }}
                      />
                    </Link>
                    <Box className={classes.rating}>
                      <Rating
                        name="half-rating-read"
                        defaultValue={data?.[objectKey]?.rating}
                        precision={0.5}
                        readOnly
                      />
                      <Typography component="p" variant="pBold">
                        {`${data?.[objectKey]?.rating} Reviews`}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              )}
              <Box className={classes.price}>
                <Typography
                  variant="mediumText"
                  color={theme.palette.primary.main}
                >
                  Price Offered
                </Typography>
                <Typography variant="body6" component="h3">
                  {`$ ${data?.price}`}
                </Typography>
              </Box>
            </Box>
            <hr />
            {isVendor ? (
              <Box sx={{ pt: 1 }}>
                <Typography
                  variant="body3"
                  className={classes.skills}
                  color="#01214D"
                >
                  Customer:
                  <Box component="span" sx={{ pl: 0.5, fontWeight: 400 }}>
                    {data?.[objectKey]?.fullName}
                  </Box>
                </Typography>
              </Box>
            ) : null}
            <Box className={classes.quote}>
              <Typography variant="body4" color={theme.palette.primary.main}>
                Quote:
              </Typography>
              <Typography
                variant="pSmall"
                color={theme.palette.primary.black}
                sx={{ lineHeight: "24px" }}
                mt={2}
              >
                {data?.description}
              </Typography>
            </Box>
            <Box mt={3}>
              <Typography
                variant="body3"
                className={classes.skills}
                color="#01214D"
              >
                Desired Services
              </Typography>
              <Box className={classes.tags}>
                {data?.servicesId?.map(({ name }, index) => {
                  return <Tags text={name} key={index} />;
                })}
              </Box>
            </Box>
            <Grid
              mt={2}
              container
              rowSpacing={2}
              sx={{
                gap: {
                  xl: "0px",
                  lg: "10px",
                  md: "15",
                  sm: "25px",
                  xxs: "25px",
                },
              }}
            >
              <Grid item xl={3} lg={5} md={5} sm={6} xxs={12}>
                <Box className={classes.box}>
                  <Box color={theme.palette.primary.main}>
                    <LocationOnIcon />
                  </Box>
                  <Box sx={{ marginTop: "-4px" }}>
                    <Typography variant="mediumText">Location</Typography>
                    <Typography
                      component="p"
                      variant="smallp"
                      color={theme.palette.primary.black}
                    >
                      {data?.eventId?.location?.address}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              {isVendor ? null : (
                <Grid item xl={3} lg={5} md={5} sm={6} xxs={12}>
                  <Box className={classes.box}>
                    <Box color={theme.palette.primary.main}>
                      <EmojiEventsOutlinedIcon />
                    </Box>
                    <Box sx={{ marginTop: "-4px" }}>
                      <Typography variant="mediumText">Past Events</Typography>
                      <Typography
                        component="p"
                        variant="smallp"
                        color={theme.palette.primary.black}
                      >
                        {data?.vendorPastEventsCount}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
              )}
              <Grid item xl={3} lg={5} md={5} sm={6} xxs={12}>
                <Box className={classes.box}>
                  <Box color={theme.palette.primary.main}>
                    <CalendarMonthIcon />
                  </Box>
                  <Box sx={{ marginTop: "-4px" }}>
                    <Typography variant="mediumText">Event Date</Typography>
                    <Typography
                      component="p"
                      variant="smallp"
                      color={theme.palette.primary.black}
                    >
                      {moment(data?.eventId?.startTime).format(
                        "dddd, MMMM Do YYYY"
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xl={3} lg={5} md={5} sm={6} xxs={12}>
                <Box className={classes.box}>
                  <Box color={theme.palette.primary.main}>
                    <LocationOnIcon />
                  </Box>
                  <Box sx={{ marginTop: "-4px" }}>
                    <Typography variant="mediumText">Event Time</Typography>
                    <Typography
                      component="p"
                      variant="smallp"
                      color={theme.palette.primary.black}
                    >
                      {`
                    ${moment(data?.eventId?.startTime).format("h:mm A")} -
                    ${moment(data?.eventId?.endTime).format("h:mm A")}
                    `}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            {isVendor ? (
              <Box
                sx={{
                  display: "flex",

                  justifyContent: "flex-end",
                  py: 1,
                }}
              >
                {" "}
                <Button
                  onClick={() => setOpenAddQuoteDialog(true)}
                  disabled={isFetching}
                  variant="primary"
                >
                  Edit
                </Button>
              </Box>
            ) : (
              <Box className={classes.btnContainer}>
                <Button
                  disabled={declineLoading || isDisabledActions}
                  onClick={() => setDeclineModal(true)}
                  variant="primary"
                  className={classes.btn}
                >
                  Decline
                </Button>
                <Button onClick={onMessageClick} variant="secondary">
                  {isThreadLoading && (
                    <CircularProgress
                      style={{ color: "#fff", marginRight: "10px" }}
                      size={15}
                    />
                  )}
                  Message
                </Button>
                <Button
                  onClick={onBookingClick}
                  disabled={isDisabledActions}
                  variant="primary"
                >
                  Book Now
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
      {isVendor ? (
        openAddQuoteDialog &&
        selectedServices() && (
          <CreateQuote
            open={openAddQuoteDialog}
            setOpen={setOpenAddQuoteDialog}
            id={id}
            filteredServices={filteredServices}
            refetchEvent={refetch}
            isEdit={true}
            eventData={{
              quote: quote?.description,
              price: quote?.price,
              services: selectedServices(),
            }}
          />
        )
      ) : (
        <>
          <Dialog maxWidth open={declineModal} onClose={handleClose}>
            <DialogTitle textAlign="center">
              <Typography variant="h4" component="h4">
                Decline
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography variant="h6" component="h6">
                Are you sure you want to decline quote?
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>cancel</Button>
              <LoadingButton
                variant="primary"
                loading={declineLoading}
                onClick={decline}
                autoFocus
              >
                ok
              </LoadingButton>
            </DialogActions>
          </Dialog>
          <Dialog
            fullWidth
            open={isModalOpen}
            onClose={() => setIsModalOpen(false)}
          >
            <DialogTitle>Message</DialogTitle>
            <DialogContent>
              <input
                className="input"
                placeholder="Please insert your message"
                onChange={(e) => setMessage(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={createMessage} disabled={!message}>
                Send Message
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </Box>
  );
}

export default QuotesDetails;
