import React from "react";
import { Box, Container, Grid } from "@mui/material";

import TopSection from "./comp/TopSection";
import MiddleSection from "./comp/MiddleSection";
import BottomSection from "./comp/BottomSection";
import { useUserContext } from "context/UserContext";
import { useQuery } from "react-query";
import { getUpcomingEvents } from "api/v1/event";
import { DAY, MONTH, YEAR } from "constants";
import useStats from "Hooks/useStats";
import useTimeStats from "Hooks/useTimeStats";

function Dashboard() {
  const { isVendor } = useUserContext();
  const { data: total, isLoading: totalLoading } = useStats(isVendor);
  const { data: daily, isLoading: dailyLoading } = useTimeStats(DAY, isVendor);
  const { data: monthly, isLoading: monthlyLoading } = useTimeStats(
    MONTH,
    isVendor
  );
  const { data: yearly, isLoading: yearlyLoading } = useTimeStats(
    YEAR,
    isVendor
  );
  const stats = {
    total: { total, totalLoading },
    daily: { daily, dailyLoading },
    monthly: { monthly, monthlyLoading },
    yearly: { yearly, yearlyLoading },
  };
  const upComingEvent = useQuery("upcomingEvent", async () => {
    const { data } = await getUpcomingEvents({
      queryParams: { limit: 1, sortBy: "startTime" },
    });
    return data;
  });

  return (
    <Container maxWidth="xl">
      <Box
        sx={{
          width: "100%",
          height: "100%",
          px: "1rem",
          py: "2rem",
        }}
      >
        <Grid container columnSpacing={2} rowSpacing={6}>
          <Grid item xxs={12}>
            {isVendor ? null : <TopSection />}
          </Grid>
          <Grid item xxs={12}>
            <MiddleSection
              upComingEvent={upComingEvent}
              isVendor={isVendor}
              stats={stats}
            />
          </Grid>
          <Grid item xxs={12}>
            <BottomSection />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default Dashboard;
