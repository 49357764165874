import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/system";
import { Controller } from "react-hook-form";
import { RadioGroup } from "@mui/material";

let StyledFormControlLabel = styled(FormControlLabel)({
  background: "#FFF4FD",
  borderRadius: "4px",
  width: "100%",
  minHeight: "49px",
  display: "flex",
  margin: "0",
  justifyContent: "space-between",
  paddingLeft: "1rem",
  paddingRight: "0.7rem",
  "& .MuiTypography-root": {
    fontWeight: "700",
    fontSize: "0.875rem",
  },
});

function FormCustomRadioButton({ value, label }) {
  return (
    <StyledFormControlLabel
      labelPlacement="start"
      value={value}
      control={<Radio />}
      label={label}
    />
  );
}

export default FormCustomRadioButton;
