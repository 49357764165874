import { Grid } from "@mui/material";
import GooglePlacesAutoComplete from "components/GooglePlacesAutoComplete";
import React from "react";
import Wrapper from "../../../comp/Wrapper";

function AddressForm({ control, errors, getValues, setValue }) {
  const { location } = getValues();

  return (
    <Wrapper label="Address Information">
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item container xxs={12} rowSpacing={2} columnSpacing={2}>
          <Grid item xxs={12} sm>
            {location && (
              <GooglePlacesAutoComplete
                isSecondarySelect={true}
                setFormValue={setValue}
                defaultValue={location}
                control={control}
                errors={errors}
                name="location"
                label="Address"
              />
            )}
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default AddressForm;
