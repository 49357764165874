import { CircularProgress } from "@mui/material";
import useImage from "Hooks/useImage";
import * as React from "react";

const Image = ({ src, style = {} }) => {
  const { data, isLoading } = useImage({
    key: src,
  });
  return (
    <>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size="3rem" />
        </div>
      ) : (
        <img style={style} src={data} alt="" />
      )}
    </>
  );
};
export default Image;
