import client from "../axiosConfig";

import formatUrl from "utils/formatUrl";

export const createAccount = async ({ body }) => {
  return client.post("/api/v1/stripe/create-account", body);
};

export const onboardingLink = async () => {
  return client.get("/api/v1/stripe/onboarding-link");
};

export const paymentIntent = async ({ body }) => {
  return client.post("/api/v1/stripe/payment-intent", body);
};

export const getTransactions = async (queryParams = {}) => {
  return client.get(formatUrl("/api/v1/stripe/transactions", queryParams));
};

export const getBalance = async () => {
  return client.get("/api/v1/stripe/balance");
};

export const deauthorizeAccount = async () => {
  return client.post("/api/v1/stripe/deauthorize-account");
};
