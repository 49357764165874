import { Box, Button, Typography } from "@mui/material";
import * as React from "react";
import classes from "./ServiceCheckList.module.css";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { idOfServices } from "utils/idOfServices";
import { useIsOverflow } from "Hooks/useIsOverflow";
import useWindowSize from "Hooks/useWindowSize";

const ServiceCheckList = ({
  data,
  serviceData,
  image,
  isSelected,
  selectedState,
  handleServiceChange,
  handleRemoveService,
}) => {
  const { width } = useWindowSize();
  const [show, setShow] = React.useState(false);
  const [desc, setDesc] = React.useState(selectedState?.description ?? "");
  const [localState, setLocalState] = React.useState(selectedState ?? "");
  const ref = React.useRef();
  const isOverflow = useIsOverflow(ref, null, width);
  const serviceName = React.useRef(data?.name);

  React.useEffect(() => {
    setLocalState(selectedState ?? "");
    setDesc(selectedState?.description ?? "");
  }, [selectedState]);

  const serviceId = idOfServices(serviceData, data?.name);
  if (isOverflow) {
    serviceName.current = serviceName.current?.includes("/")
      ? serviceName.current.split("/").join("/ ")
      : serviceName.current;
  } else {
    if (width > 710) {
      serviceName.current = serviceName.current?.includes("/")
        ? serviceName.current.split("/ ").join("/")
        : serviceName.current;
    }
  }

  const handleSave = () => {
    if (localState) {
      const temp = {
        name: data?.name,

        id: serviceId._id,
        ...localState,
        description: desc,
      };
      handleServiceChange(temp);
    }
    setShow(false);
  };

  const handleRemove = () => {
    handleRemoveService(serviceId._id);
    setShow(false);
  };

  const handleTextChange = (e) => {
    setDesc(e.target.value);
  };
  const handleInputChange = (e, services, isChecked) => {
    let tempServiceName = services?.name;
    if (services.type === "radio") {
      setLocalState((prev) => {
        return {
          ...prev,
          [tempServiceName]: e.target.value,
        };
      });
    } else {
      if (isChecked) {
        let filterArray = localState[tempServiceName].filter(
          ({ name }) => name !== e.target.value
        );
        if (filterArray?.length < 1) {
          setLocalState((prev) => ({
            ...prev,
            [tempServiceName]: null,
          }));
        } else {
          setLocalState((prev) => ({
            ...prev,
            [tempServiceName]: filterArray,
          }));
        }
      }
      if (localState[tempServiceName]) {
        if (
          !localState[tempServiceName].some(
            ({ name }) => e.target.value === name
          )
        ) {
          setLocalState((prev) => ({
            ...prev,
            [tempServiceName]: [
              ...prev[tempServiceName],
              { name: e.target.value },
            ],
          }));
        }
      } else {
        setLocalState((prev) => ({
          ...prev,
          [tempServiceName]: [{ name: e.target.value }],
        }));
      }
    }
  };

  return (
    <>
      {data?.show && (
        <Box>
          <Box
            className={`${classes.root} ${isSelected ? classes.selected : ""}`}
            ref={ref}
          >
            <Box className={classes.text}>
              <Box>
                <img
                  src={image}
                  alt="icon"
                  style={{
                    width: "35px",
                    height: "auto",
                  }}
                />
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  className={classes.heading}
                  sx={{
                    "-ms-hyphens": "auto",
                    "-moz-hyphens": "auto",
                    "-webkit-hyphens": "auto",
                    hyphens: "auto",
                    wordBreak: isOverflow ? "break-word" : "normal",
                    overflowWrap: isOverflow ? "break-word" : "normal",
                  }}
                >
                  {serviceName.current}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.icon}>
              {!show ? (
                <AddIcon
                  sx={{
                    fontSize: {
                      xl: "40px",
                      lg: "40px",
                      md: "30px",
                      sm: "30px",
                      xs: "30px",
                      xxs: "30px",
                    },
                  }}
                  onClick={() => {
                    setShow(!show);
                  }}
                />
              ) : (
                <RemoveIcon
                  sx={{
                    fontSize: {
                      xl: "40px",
                      lg: "40px",
                      md: "30px",
                      sm: "30px",
                      xs: "30px",
                      xxs: "30px",
                    },
                  }}
                  onClick={() => {
                    setShow(!show);
                  }}
                />
              )}
            </Box>
          </Box>
          <Box
            className={show ? ` ${classes.mainContainer}` : `${classes.hidden}`}
          >
            <Box className={classes.container}>
              {data?.subServices.map((services, index) => {
                const selectedServiceValues =
                  localState?.[services.name] &&
                  Array.isArray(localState?.[services.name])
                    ? localState?.[services.name]?.map((x) => x.name)
                    : localState?.[services.name];
                return (
                  <Box key={index}>
                    <Typography
                      component="p"
                      variant="body5"
                      fontWeight="bold"
                      mt={2}
                    >
                      {services?.name}
                    </Typography>

                    {services?.categories?.map((categories, index) => {
                      const isChecked = services?.name
                        ? selectedServiceValues
                          ? Array.isArray(selectedServiceValues)
                            ? selectedServiceValues.includes(categories.text)
                            : selectedServiceValues === categories.text
                          : false
                        : false;

                      return (
                        <Box className={classes.inputContainer} key={index}>
                          <input
                            type={services.type}
                            name={categories.name}
                            value={categories?.text}
                            checked={isChecked}
                            onChange={(e) =>
                              handleInputChange(e, services, isChecked)
                            }
                          />
                          <Typography variant="body5" fontWeight={400}>
                            {categories.text}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                );
              })}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "10px",
                pt: 1,
              }}
            >
              <Typography variant="p">
                Please include any additional details.
              </Typography>
              <textarea
                className={classes.textarea}
                rows={4}
                value={desc}
                onChange={handleTextChange}
              />
            </Box>
            <Box
              className={classes.btn}
              sx={{ justifyContent: { xxs: "center", sm: "space-between" } }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: "10px",
                  flexWrap: "wrap",
                  justifyContent: { xxs: "center", sm: "space-between" },
                }}
              >
                <Box
                  sx={{
                    width: {
                      xxs: "100%",
                      sm: "auto",
                      display: "flex",
                      justifyContent: "center",
                    },
                  }}
                >
                  <Button
                    variant="secondary"
                    sx={{ maxWidth: "90px" }}
                    onClick={handleSave}
                  >
                    Close
                  </Button>
                </Box>
                <Box
                  sx={{
                    width: {
                      xxs: "100%",
                      sm: "auto",
                      display: "flex",
                      justifyContent: "center",
                    },
                  }}
                >
                  <Button
                    variant="primary"
                    sx={{ maxWidth: "90px" }}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </Box>
              </Box>
              {selectedState && isSelected && (
                <Box
                  sx={{
                    width: {
                      xxs: "100%",
                      sm: "auto",
                      display: "flex",
                      justifyContent: "center",
                    },
                  }}
                >
                  <Button variant="primary" onClick={handleRemove}>
                    Remove
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ServiceCheckList;
