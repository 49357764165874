import { Divider, Grid, Typography } from "@mui/material";
import EventCheckList from "components/EventCheckList";
import PlanActionButton from "components/PlanEventActionButtons/PlanActionButton";
function ServicesForm({
  isSubmitButtonLoading,
  setFormStep,
  errors,
  setValue,
  getValues,
}) {
  const handleClickBack = async () => {
    setFormStep((prev) => prev - 1);
  };

  return (
    <Grid
      container
      rowSpacing={4}
      sx={{ padding: { xxs: "0.7rem", xs: "1.5rem", sm: "2rem" } }}
    >
      <Grid item xxs={12}>
        <FirstSection />
      </Grid>
      <Grid item xxs={12}>
        <Divider />
      </Grid>
      <Grid item xxs={12}>
        <EventCheckList
          errors={errors}
          setValue={setValue}
          prevServiceData={getValues("servicesId")}
        />
      </Grid>
      <Grid
        item
        container
        xxs={12}
        columnSpacing={2}
        rowSpacing={2}
        justifyContent="center"
        style={{ textAlign: "center", paddingTop: "4rem" }}
      >
        <PlanActionButton
          isSubmitButtonLoading={isSubmitButtonLoading}
          isSubmit={true}
          secondaryTextValue="Back"
          handleSecondaryClick={handleClickBack}
        />
      </Grid>
    </Grid>
  );
}

export default ServicesForm;

function FirstSection() {
  return (
    <Grid
      container
      rowSpacing={2}
      justifyContent="center"
      sx={{ textAlign: "center", pb: "1rem" }}
    >
      <Grid item xxs={12}>
        <Typography variant="h4">Event Checklist</Typography>
      </Grid>
      <Grid item xxs={12}>
        <Typography variant="p3">
          Please provide more information about your event
        </Typography>
      </Grid>
    </Grid>
  );
}
