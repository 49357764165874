import React from "react";
import { Paper, Grid, Typography, Box, Button } from "@mui/material";
import { ReactComponent as QuoteIcon } from "assets/dashboard/quote.svg";
import { ReactComponent as MessageIcon } from "assets/dashboard/message.svg";
import { ReactComponent as MeetingIcon } from "assets/dashboard/meeting.svg";
import { Link } from "react-router-dom";

const data = [
  {
    icon: <QuoteIcon />,
    title: "Vendor Quotes",
    description:
      "See the nearby Vendor Quotes submitted on your events and connect with them directly.",
    buttonText: "See All Quotes",
    btnLink: "/dashboard/quotes",
  },
  {
    icon: <MessageIcon />,
    title: "Messages",
    description:
      "Checkout your latest messages with vendors. Plan and discuss your requirements as you like.",
    buttonText: "See All Chats",
    btnLink: "/dashboard/messages",
  },
  {
    icon: <MeetingIcon />,
    title: "Meetings",
    description:
      "See your upcoming meetings with vendors or checkout the bookings you paid for.",
    buttonText: "See All Schedule Meetings",
    btnLink: "/dashboard/meetings",
  },
];

function BottomSection() {
  return (
    <Grid container columnSpacing={2} rowSpacing={2}>
      {data.map((x, i) => (
        <Grid key={i + 10} item xxs={12} sm>
          <BottomSectionCard
            icon={x.icon}
            title={x.title}
            description={x.description}
            buttonText={x.buttonText}
            btnLink={x.btnLink}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default BottomSection;

function BottomSectionCard({ icon, title, description, buttonText, btnLink }) {
  return (
    <Paper
      sx={{
        background: " #FFFFFF",
        boxShadow: " 0px -2.96848px 26.7163px 9.89492px rgba(0, 0, 0, 0.05)",
        borderRadius: " 7.91594px",
        display: "flex",
        flexDirection: "column",
        padding: "2rem",
        width: "100%",
        height: "100%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", pb: "0.7rem" }}>
        {icon}
        <Typography
          variant="p600"
          sx={{ fontWeight: "700", color: "text.primary", pl: "0.6rem" }}
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ pb: "0.7rem" }}>
        <Typography variant="p" sx={{ color: "text.primary" }}>
          {description}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-end",

          flex: 1,
        }}
      >
        <Link to={btnLink}>
          <Button variant="primary">{buttonText}</Button>
        </Link>
      </Box>
    </Paper>
  );
}
