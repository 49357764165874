import * as React from "react";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { createMessage, getMessages, getThreads } from "api/v1/chat";
import { SOCKET_EVENT } from "../constants";
import { useSocketContext } from "context/SocketContext";
import { useUserContext } from "context/UserContext";
import { addMessageInCachedThread, updateLastMessage } from "Helpers/chat";
import useIntersectionObserver from "./useIntersectionObserver";
import { getImage } from "api/v1/image";
import { getUploadSignedUrl } from "api/v1/image";
import axios from "axios";
export function useChat({ messageInputRef, activeChat, activeChatId }) {
  const threadLoadMoreButtonRef = React.useRef();
  const messageLoadMoreButtonRef = React.useRef();
  const queryClient = useQueryClient();
  const { user: userMetaData } = useUserContext();
  const { socketRef, activeChatIdRef } = useSocketContext();
  const { mutateAsync: getUploadSignedUrlData } =
    useMutation(getUploadSignedUrl);
  // THREADS
  const {
    data: threads,
    isLoading: areThreadsLoading,
    refetch: refetchThreads,
    fetchNextPage: fetchNextThreadsPage,
    hasNextPage: threadHasNextPage,
  } = useInfiniteQuery(
    "threads",
    async ({ pageParam = 0 }) => {
      const res = await getThreads({
        queryParams: {
          limit: 10,
          cursor: pageParam === 0 ? undefined : pageParam,
        },
      });
      return res.data;
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return allPages.length
          ? allPages?.[allPages?.length - 1]?.length === 10 // same as the limit
            ? allPages?.[allPages?.length - 1][
                allPages?.[allPages?.length - 1].length - 1
              ]._id
            : undefined // To stop it from calling the api again
          : 0;
      },
      refetchOnWindowFocus: false,
    }
  );
  const {
    data: messages,
    isLoading: areMessagesLoading,
    fetchNextPage: fetchNextThreadsMessagesPage,
    hasNextPage: messageHasNextPage,
  } = useInfiniteQuery(
    ["threads", activeChatId],
    async ({ pageParam = 0 }) => {
      const res = await getMessages({
        id: activeChatId,
        queryParams: {
          limit: 20,
          cursor: pageParam === 0 ? undefined : pageParam,
        },
      });
      return res.data;
    },
    {
      enabled: Boolean(activeChatId),
      cacheTime: Boolean(activeChatId) ? 30000 : 0,
      getNextPageParam: (lastPage, allPages) => {
        // console.log(
        //   allPages.length
        //     ? allPages?.[allPages?.length - 1]?.length === 20
        //       ? allPages?.[allPages?.length - 1][
        //           allPages?.[allPages?.length - 1].length - 1
        //         ]._id
        //       : undefined // To stop it from calling the api again
        //     : 0
        // );
        return allPages.length
          ? allPages?.[allPages?.length - 1]?.length === 20
            ? allPages?.[allPages?.length - 1][
                allPages?.[allPages?.length - 1].length - 1
              ]._id
            : undefined // To stop it from calling the api again
          : 0;
      },
    }
  );

  // Intersection Observer for Threads
  useIntersectionObserver({
    target: threadLoadMoreButtonRef,
    onIntersect: () => {
      fetchNextThreadsPage();
    },
    enabled: threads?.pages?.flat()?.[0]?._id ? threadHasNextPage : false,
  });

  useIntersectionObserver({
    target: messageLoadMoreButtonRef,
    onIntersect: () => {
      // console.log("fetchNextThreadsMessagesPage intersect called.");
      fetchNextThreadsMessagesPage();
    },
    enabled: messages?.pages?.flat()?.[0]?._id ? messageHasNextPage : false,
  });

  async function sendMessage() {
    if (!messageInputRef.current?.value?.trim?.()) {
      return;
    }
    const message = {
      sender: userMetaData._id,
      content: messageInputRef.current.value,
      thread: activeChat._id,
      receiver: activeChat.users.find((user) => user._id !== userMetaData._id)
        ._id,
      createdAt: new Date(),
    };
    socketRef.current?.emit(SOCKET_EVENT.MESSAGE, message);
    addMessageInCachedThread({
      queryKey: ["threads", activeChat._id],
      queryClient,
      message,
    });
    updateLastMessage(
      "threads",
      queryClient,
      message,
      activeChatIdRef.current,
      userMetaData._id
    );
    messageInputRef.current.value = "";
    await createMessage({
      id: activeChatIdRef.current,
      data: { content: message.content },
    });
  }
  async function sendAttachments(images) {
    const uploadSignedUrls = images.map((item) =>
      getUploadSignedUrlData({
        key: "messages",
      })
    );
    const signedUrls = await Promise.all(uploadSignedUrls);
    signedUrls.forEach((signedUrl) => {
      queryClient.prefetchQuery(
        ["image", signedUrl?.data?.key],
        async () => {
          const res = await getImage({ key: signedUrl?.data?.key });
          return res.data;
        },
        {
          staleTime: Infinity,
        }
      );
    });
    const message = {
      sender: userMetaData._id,
      thread: activeChat._id,
      receiver: activeChat.users.find((user) => user._id !== userMetaData._id)
        ._id,
      createdAt: new Date(),
      attachments: signedUrls.map((item) => item.data.key),
    };
    const uploadImages = [];
    for (var i = 0; i < signedUrls.length; i++) {
      uploadImages[i] = axios.put(signedUrls[i]?.data?.url, images[i], {
        headers: {
          "Content-Type": images[i].type,
        },
      });
    }
    await Promise.all(uploadImages);
    socketRef.current?.emit(SOCKET_EVENT.MESSAGE, message);
    addMessageInCachedThread({
      queryKey: ["threads", activeChat._id],
      queryClient,
      message,
    });

    await createMessage({
      id: activeChatIdRef.current,
      data: {
        attachments: signedUrls.map((item) => item.data.key),
      },
    });
  }
  return {
    sendMessage,
    threads,
    areThreadsLoading,
    threadHasNextPage,
    messages,
    areMessagesLoading,
    messageHasNextPage,
    threadLoadMoreButtonRef,
    messageLoadMoreButtonRef,
    sendAttachments,
  };
}
