import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as MeetingIcon } from "assets/dashboard/meeting/meeting.svg";
import FormCheckBox from "components/forms/FormCheckBox";
import FormInputTextSecondary from "components/forms/FormInputTextSecondary";
import FormRadioButton from "components/forms/FormRadioButton";
import { useQuery } from "react-query";

function TopSection({ control, errors, vendorId }) {
  const { data: upcomingEvents } = useQuery(["upcoming-events", vendorId]);
  return (
    <Grid container rowSpacing={3}>
      <Grid item xxs={12}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <MeetingIcon />
          </Box>
          <Box sx={{ pl: "0.5rem" }}>
            <Typography variant="h5">Booking Details</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xxs={12}>
        <FormRadioButton
          name="eventId"
          row
          control={control}
          errors={errors?.eventId?.message}
          label="Select event"
          options={upcomingEvents?.data.map((item) => ({
            label: item.title,
            value: item._id,
          }))}
        />
      </Grid>
      <Grid item xxs={12}>
        <FormInputTextSecondary
          name="amount"
          control={control}
          label="Amount"
          type="number"
          errors={errors?.amount?.message}
          placeholder="Enter amount here"
          sx={{ width: { xxs: "100%", sm: "80%", md: "60%" } }}
        />
      </Grid>
      <Grid item xxs={12}>
        <FormCheckBox
          name="acceptVendorPolicy"
          errors={errors?.acceptVendorPolicy?.message}
          control={control}
          options={[
            {
              label: "I agree to the vendor's cancellation policy.",
              value: "accept",
            },
          ]}
          checkBoxProps={{ size: "small" }}
          componentsProps={{
            typography: {
              fontSize: "0.85rem",
              color: "primary.main",
            },
          }}
        />

        <FormCheckBox
          name="acceptPlatformPolicy"
          errors={errors?.acceptPlatformPolicy?.message}
          control={control}
          options={[
            {
              label:
                "I agree to the Terms of Service, Privacy Policy, and Terms & Conditions.",
              value: "accept",
            },
          ]}
          componentsProps={{
            typography: {
              fontSize: "0.85rem",
              component: "a",
              href: `${process.env.REACT_APP_LANDING_PAGE_URL}/assets/pdfs/terms-services.pdf`,
              target: "_blank",
              color: "primary.main",
            },
          }}
          checkBoxProps={{ size: "small" }}
        />
      </Grid>
    </Grid>
  );
}

export default TopSection;
