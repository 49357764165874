import { Divider, Grid, Typography } from "@mui/material";
import React from "react";

function Wrapper({ label, children }) {
  return (
    <Grid container rowSpacing={5}>
      <Grid item xxs={12}>
        <Typography variant="h4" color="primary" sx={{ pb: "1rem" }}>
          {label}
        </Typography>
        <Divider />
      </Grid>
      <Grid item xxs={12}>
        {children}
      </Grid>
    </Grid>
  );
}

export default Wrapper;
