import {
  Box,
  Card,
  CardContent,
  CardMedia,
  CardActions,
  Chip,
  CircularProgress,
  Collapse,
  Container,
  Stack,
  Typography,
  useTheme,
  Rating,
} from "@mui/material";
import * as React from "react";
import classes from "./PastEvents.module.css";
import { getCompletedEventsByVendorId } from "api/v1/event";
import { useQuery } from "react-query";
import Image from "components/Image";
import useVendorReviews from "Hooks/useVendorReviews";
import useImage from "Hooks/useImage";
import cardPlaceholderImage from "assets/dashboard/myEvent/banner.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import noData from "assets/not-found/no-data.svg";

const PastEvents = ({ vendorId, vendorSkills }) => {
  const theme = useTheme();
  const { data, isLoading } = useQuery(
    "pastEvents",
    async () => {
      const pastEvents = await getCompletedEventsByVendorId({ id: vendorId });
      return pastEvents.data;
    },
    {
      enabled: !!vendorId,
      refetchOnWindowFocus: false,
    }
  );

  const skillsIds = vendorSkills.map((skill) => skill._id);

  if (isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          minHeight: "450px",
          minWidth: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );
  return (
    <Container maxWidth="xl">
      <Box sx={{ marginTop: "25px" }}>
        <Typography
          component="h2"
          variant="h3"
          className={classes.heading}
          color={theme.palette.primary.main}
        >
          Past Events
        </Typography>
      </Box>
      {data.length < 1 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            gap: "1rem",
            padding: "2rem",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography variant="h5">This vendor has no past events.</Typography>
          <Box sx={{ maxWidth: "500px", maxHeight: "500px" }}>
            <img
              src={noData}
              alt="no data"
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            padding: { xxs: "2rem 0", sm: "2rem 2rem" },
            display: "flex",
            flexDirection: "column",
            gap: "2rem",
          }}
        >
          {data.map((event) => (
            <PastEventsCard
              vendorId={vendorId}
              event={event}
              skillsIds={skillsIds}
            />
          ))}
        </Box>
      )}
    </Container>
  );
};

export default PastEvents;

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
}));

function PastEventsCard({ event, vendorId, skillsIds }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const { data: reviews, isLoading: isReviewsLoading } = useVendorReviews(
    vendorId,
    { limit: 5, eventId: event._id }
  );

  const { data: image, isLoading: isImageLoading } = useImage({
    key: event?.type?.eventTypeId?.images?.[0],
  });

  const filteredServices =
    event &&
    event?.services
      .filter(({ serviceId, info }) => skillsIds.includes(serviceId))
      .map(({ info }) => info.name);

  return (
    <Card sx={{ maxWidth: "100%" }}>
      <CardMedia
        component="img"
        height="140"
        image={isImageLoading ? cardPlaceholderImage : image}
        alt="event img"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {event?.type?.name}
        </Typography>

        <Typography variant="body2" color="text.secondary">
          <Box sx={{ display: "flex", gap: "0.7rem", flexWrap: "wrap" }}>
            <b>Services Provided:</b>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {filteredServices &&
                filteredServices.length > 0 &&
                filteredServices.map((service) => (
                  <Chip label={service} size="small" />
                ))}
            </Box>
          </Box>
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Typography>{expanded ? "Hide Reviews" : "View Reviews"}</Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          {reviews && reviews.length > 0 ? (
            reviews.map((review, index) => (
              <Box sx={{ display: "flex", gap: 1 }}>
                <Typography
                  paragraph
                  sx={{
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {review?.customerId?.fullName}:
                </Typography>
                <Rating
                  name={`vendor-rating ${index}`}
                  value={review?.rating}
                  readOnly
                  precision={0.5}
                />
              </Box>
            ))
          ) : (
            <Typography paragraph textAlign="center">
              No reviews{" "}
            </Typography>
          )}
        </CardContent>
      </Collapse>
    </Card>
  );
}
