import { Box, Button, Container, Grid, Typography } from "@mui/material";
import EventCheckList from "components/EventCheckList";
import React from "react";
export default function CheckList() {
  return (
    <Box sx={{ pt: "5rem", pb: "2rem" }}>
      <Container maxWidth="lg">
        <Grid container rowSpacing={6}>
          <Grid item xxs={12}>
            <SectionOne />
          </Grid>
          <Grid item xxs={12}>
            <EventCheckList />
          </Grid>
          <Grid item xxs={7} xs={5} sm={4} md={3}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Button variant="primary" fullWidth sx={{ maxWidth: "385px" }}>
                Request Quotes
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

function SectionOne() {
  return (
    <Grid container justifyContent="center">
      <Grid item xxs={12}>
        <Typography variant="h1" style={{ textAlign: "center" }}>
          Your Event Checklist
        </Typography>
      </Grid>
      <Grid item xxs={12}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "500",
            color: "text.secondary",
            textAlign: "center",
          }}
        >
          Select which categories you need to plan your dream event. Once you
          select your options in each category, click the Request Quotes to
          receive free instant quotes from local or virtual vendors.
        </Typography>
      </Grid>
    </Grid>
  );
}
