import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Box } from "@mui/system";
import { Button, CircularProgress, Skeleton, Typography } from "@mui/material";
import { ReactComponent as NoImages } from "assets/not-found/noImages.svg";
import axios from "axios";

function Gallery({
  gallery,
  galleryImages,
  getUploadSignedUrlData,
  mutatePhoto,
  isPhotoSubmitting,
  isEdit = false,
}) {
  const [isRemove, setIsRemove] = React.useState(false);
  const [removeItems, setRemoveItems] = React.useState([]);
  const disableInputWhileOnChange = React.useRef(false);
  const onChangeImage = async (e) => {
    disableInputWhileOnChange.current = true;
    const file = e.target.files[0];

    const uploadSignedUrl = await getUploadSignedUrlData({
      key: "gallery",
    });

    await axios.put(uploadSignedUrl?.data?.url, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
    const body = {
      gallery: [...gallery, uploadSignedUrl?.data?.key],
    };
    mutatePhoto(
      { body },
      {
        onSuccess: () => {
          disableInputWhileOnChange.current = false;
        },

        onError: () => {
          disableInputWhileOnChange.current = false;
        },
      }
    );
  };

  const handleImageRemove = () => {
    if (removeItems.length > 0 && gallery.length > 0) {
      disableInputWhileOnChange.current = true;
      const filterdList = gallery.filter((x, i) => !removeItems.includes(i));
      const body = {
        gallery: filterdList,
      };
      mutatePhoto(
        { body },
        {
          onSuccess: () => {
            disableInputWhileOnChange.current = false;
            setRemoveItems([]);
            setIsRemove(false);
          },

          onError: () => {
            disableInputWhileOnChange.current = false;
            setRemoveItems([]);
            setIsRemove(false);
          },
        }
      );
    }
  };

  const handleCancelClick = () => {
    setRemoveItems([]);
    setIsRemove(false);
  };

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          rowGap: 1,
        }}
      >
        <Typography variant="body6" color="primary">
          Gallery
        </Typography>
        {isEdit ? (
          <>
            {isRemove ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.6rem",
                  flexWrap: "wrap",
                }}
              >
                <Button
                  variant="primary"
                  onClick={handleImageRemove}
                  sx={{ backgroundColor: "#d32f2f" }}
                  disabled={removeItems?.length < 1}
                >
                  Delete
                </Button>

                <Button variant="primary" onClick={handleCancelClick}>
                  Cancel
                </Button>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "0.6rem",
                  flexWrap: "wrap",
                }}
              >
                <Box>
                  <label htmlFor="galleryPhoto">
                    <Button component="div" variant="primary">
                      Add Photos
                    </Button>
                  </label>
                  <input
                    type="file"
                    id="galleryPhoto"
                    accept="image/png, image/gif, image/jpeg"
                    style={{ display: "none" }}
                    disabled={
                      disableInputWhileOnChange.current || isPhotoSubmitting
                    }
                    onChange={(e) => onChangeImage(e)}
                  />
                </Box>

                <Button variant="primary" onClick={() => setIsRemove(true)}>
                  Remove Photos
                </Button>
              </Box>
            )}
          </>
        ) : null}
      </Box>
      {isRemove && (
        <Box sx={{ paddingTop: "1rem" }}>
          <Typography variant="pBold">
            Select photos and click "Delete" to delete selected photos.{" "}
          </Typography>
        </Box>
      )}
      <Box>
        {galleryImages && galleryImages.length > 0 ? (
          <GalleryImageList
            galleryImages={galleryImages}
            isRemove={isRemove}
            removeItems={removeItems}
            setRemoveItems={setRemoveItems}
            isPhotoSubmitting={isPhotoSubmitting}
          />
        ) : (
          <NoGalleryImages />
        )}
      </Box>
    </Box>
  );
}

export default Gallery;

function NoGalleryImages() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "13px",
        py: 1,
      }}
    >
      <Box>
        <Typography variant="h6">
          You don't have any images in your gallery
        </Typography>
      </Box>
      <Box sx={{ width: "350px", height: "300px" }}>
        <NoImages width="inherit" height="inherit" />
      </Box>
    </Box>
  );
}

function GalleryImageList({
  galleryImages,
  isRemove,
  removeItems,
  setRemoveItems,
  isPhotoSubmitting,
}) {
  const handleClickImage = (index) => {
    if (removeItems.includes(index)) {
      setRemoveItems((prev) => prev.filter((val) => val !== index));
    } else {
      setRemoveItems((prev) => [...prev, index]);
    }
  };
  return (
    <Box sx={{ display: "flex", width: "100%", height: 650 }}>
      {isPhotoSubmitting ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <ImageList variant="masonry" cols={4} gap={8}>
          {galleryImages.map(({ data, isLoading }, i) => {
            return (
              <ImageListItem
                key={i}
                sx={{
                  border: removeItems.includes(i)
                    ? "4px solid #cf0072"
                    : "none",
                  cursor: isRemove ? "pointer" : "default",
                }}
                onClick={() => handleClickImage(i)}
              >
                {isLoading ? (
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: "100%", height: "100%" }}
                  />
                ) : (
                  <img
                    src={`${data}`}
                    srcSet={`${data}`}
                    alt={`gallery-image-${i}`}
                    loading="lazy"
                    style={{ objectFit: "cover", objectPosition: "center" }}
                  />
                )}
              </ImageListItem>
            );
          })}
        </ImageList>
      )}
    </Box>
  );
}
