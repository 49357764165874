import { LoadingButton } from "@mui/lab";
import { Button } from "@mui/material";
import { Box } from "@mui/system";

export default function PlanActionButton({
  handleSecondaryClick,
  handlePrimaryClick,
  primaryTextValue,
  secondaryTextValue,
  isSubmit = false,
  isSubmitButtonLoading,
}) {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        gap: 2,
        flexWrap: "wrap",
      }}
    >
      <Box sx={{ maxWidth: "160px", flex: 1 }}>
        <Button fullWidth variant="secondary" onClick={handleSecondaryClick}>
          {secondaryTextValue}
        </Button>
      </Box>

      <Box sx={{ flex: 2, maxWidth: "250px" }}>
        {isSubmit ? (
          <LoadingButton
            loading={isSubmitButtonLoading}
            fullWidth
            variant="primary"
            type="submit"
          >
            Submit Event Details
          </LoadingButton>
        ) : (
          <Button fullWidth variant="primary" onClick={handlePrimaryClick}>
            {primaryTextValue}
          </Button>
        )}
      </Box>
    </Box>
  );
}
