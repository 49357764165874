import { Box, Typography } from "@mui/material";
import React from "react";
import CustomList from "./comp/CustomList";

function CustomListWrapper({ list, label }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Box>
        <Typography variant="body6" color="primary">
          {label}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            width: "90%",
            rowGap: "15px",
            display: "flex",
            flexDirection: "column",
            border: " 1px solid #E1E0E3",
            boxShadow: "0px -3px 27px rgba(0, 0, 0, 0.05)",
            borderRadius: "8px",
            padding: 4,
            "&:hover": {
              boxShadow:
                " 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
            },
          }}
        >
          {list && <CustomList list={list} />}
        </Box>
      </Box>
    </Box>
  );
}

export default CustomListWrapper;
