import React from "react";
import { markRead } from "api/v1/chat";
import { updateCache } from "utilities";
import { useMutation } from "react-query";

export default function useMarkAsReadMutation(queryClient) {
  const { mutate: markAsReadMutation } = useMutation(
    async (id) => {
      const res = await markRead(id);
      return res.data;
    },
    {
      onSuccess: (data, id) => {
        updateCache("threads", queryClient, function update(threads) {
          let pageIndex = 0;
          let threadIndex = 0;
          let found = false;
          for (let i = 0; i < threads.pages.length; ++i) {
            for (let j = 0; j < threads.pages[i].length; ++j) {
              if (threads.pages[i][j]._id === id) {
                pageIndex = i;
                threadIndex = j;
                found = true;
                break;
              }
            }
            if (found) break;
          }
          updateCache(
            "newMessagesCount",
            queryClient,
            function update(newMessagesCount) {
              newMessagesCount -=
                threads.pages[pageIndex][threadIndex].metaData.unreadCount;
              queryClient.setQueryData("newMessagesCount", newMessagesCount);
            }
          );
          threads.pages[pageIndex][threadIndex].metaData.unreadCount = 0;
          queryClient.setQueryData("threads", threads);
        });
      },
    }
  );

  return { markAsReadMutation };
}
