import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Rating,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { createThread } from "api/v1/chat";
import { concludeBookings, addReviews, disputeBookings } from "api/v1/booking";
import profilePhoto from "assets/dashboard/myEvent/profile.webp";
import useImage from "Hooks/useImage";
import moment from "moment";
import React from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DetailItem from "components/DetailCard/DetailItem";
import { useUserContext } from "context/UserContext";

const vendorDisableStatus = [
  "disputed",
  "completedByVendor",
  "cancelled",
  "completed",
];
const customerDisableStatus = [
  "disputed",
  "completedByCustomer",
  "cancelled",
  "completed",
];

function BookingDetailCard({
  item,
  objectKey,
  bookingRefetch,
  bookingIsFetching,
}) {
  const navigate = useNavigate();
  const { isVendor } = useUserContext();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [reviewModal, setReviewModal] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const completedStatuses = [
    "completed",
    "completedByCustomer",
    "completedByVendor",
    "disputed",
  ];

  function bookingStatus(itemStatus) {
    let status = "pending";

    switch (itemStatus) {
      case "pending":
        status = "pending";
        break;
      case "inProgress":
        status = "in progress";
        break;
      case "disputed":
        status = "disputed";
        break;
      case "completedByCustomer":
        status = "completed by customer";
        break;
      case "completedByVendor":
        status = "completed by vendor";
        break;
      case "cancelled":
        status = "cancelled";
        break;
      case "completed":
        status = "completed";
        break;
      default:
        status = "pending";
    }
    return status;
  }

  const { mutate, isLoading } = useMutation(
    async (payload) => {
      const data = await createThread({ data: payload });
      return data;
    },
    {
      onSuccess(successData) {
        navigate(`/dashboard/messages/${successData._id}`);
      },
    }
  );
  const { mutate: conclude, isLoading: concludeBookingLoading } = useMutation(
    (id) => concludeBookings(id),
    {
      onSuccess() {
        bookingRefetch();
        if (!isVendor) setReviewModal(true);
      },
      onError(error) {
        toast.error(error.errMsg);
      },
    }
  );
  const { mutate: dispute, isLoading: disputeBookingLoading } = useMutation(
    (id) => disputeBookings(id),
    {
      onSuccess() {
        bookingRefetch();
      },
      onError(error) {
        toast.error(error.errMsg);
      },
    }
  );
  const { mutate: addReview } = useMutation(addReviews, {
    onSuccess() {
      toast.success("Thanks for your feedback");
    },
    onError(error) {
      toast.error(error.errMsg);
    },
  });

  const { data: profileImage } = useImage({
    key: item[objectKey].profilePhoto,
  });
  function createMessage() {
    mutate({
      userId: item[objectKey]._id,
      content: message,
    });
  }
  function onMessageClick() {
    if (item[objectKey].threads?._id)
      navigate(`/dashboard/messages/${item[objectKey].threads._id}`);
    else setIsModalOpen(true);
  }
  function completeBooking() {
    conclude(item._id);
  }

  return (
    <Grid
      container
      xxs={12}
      justifyContent="center"
      spacing={2}
      sx={{
        py: "1rem",
        backgroundColor: "#FFF6FB",
        pl: "1rem",
        m: "0",
      }}
    >
      <Grid
        item
        container
        xxs={12}
        sx={{ color: "#000" }}
        justifyContent="center"
        alignItems="center"
        columnSpacing={1}
      >
        <Grid item xxs={3}>
          <DetailItem
            rating={item[objectKey].rating}
            profileImage={profileImage ?? profilePhoto}
            value={item[objectKey].fullName}
            isVendor={isVendor}
          />
        </Grid>
        <Grid item xxs={1.8}>
          {item?.eventId.title}
        </Grid>
        <Grid item xxs={1.8}>
          {item?.eventId?.location?.address}
        </Grid>
        <Grid item xxs={1.8}>
          {moment(item?.eventId?.startTime).format("MM/DD/YYYY")}
        </Grid>
        <Grid item xxs={1.8}>
          {`$ ${item.amount}`}
        </Grid>
        <Grid item xxs={1.8}>
          {item && bookingStatus(item?.status)}
        </Grid>
      </Grid>
      <Grid item xxs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            px: 1,
            gap: "5px",
          }}
        >
          {isVendor ? (
            <VendorBookingButtons
              conclude={completeBooking}
              concludeBookingLoading={concludeBookingLoading}
              dispute={dispute}
              disputeBookingLoading={disputeBookingLoading}
              completedStatuses={completedStatuses}
              item={item}
              bookingIsFetching={bookingIsFetching}
            />
          ) : (
            <CustomerBookingButtons
              onMessageClick={onMessageClick}
              isLoading={isLoading}
              completeBooking={completeBooking}
              concludeBookingLoading={concludeBookingLoading}
              completedStatuses={completedStatuses}
              item={item}
              navigate={navigate}
              bookingIsFetching={bookingIsFetching}
            />
          )}
          <Button
            variant="secondary"
            onClick={() => {
              navigate(`/dashboard/bookings/${item._id}`, { state: item });
            }}
            sx={{ maxWidth: "94.61px", maxHeight: "31.08px" }}
          >
            See Details
          </Button>
        </Box>
      </Grid>
      <Dialog
        fullWidth
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <DialogTitle>Message</DialogTitle>
        <DialogContent>
          <input
            className="input"
            placeholder="Please insert your message"
            onChange={(e) => setMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={createMessage} disabled={!message}>
            Send Message
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        fullWidth
        open={reviewModal}
        onClose={() => setReviewModal(false)}
      >
        <DialogTitle>Review</DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Rating
            onChange={(_, newValue) => {
              setReviewModal(false);
              addReview({ id: item._id, payload: { rating: newValue } });
            }}
            size="large"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setReviewModal(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
}

export default BookingDetailCard;

function VendorBookingButtons({
  conclude,
  concludeBookingLoading,
  dispute,
  disputeBookingLoading,
  completedStatuses,
  item,
  bookingIsFetching,
}) {
  return (
    <>
      {vendorDisableStatus.includes(item?.status) ? null : (
        <>
          {" "}
          <LoadingButton
            variant="primary"
            sx={{ maxHeight: "31.08px" }}
            onClick={conclude}
            loading={concludeBookingLoading}
            disabled={bookingIsFetching}
          >
            Conclude
          </LoadingButton>
          <LoadingButton
            variant="primary"
            sx={{ maxHeight: "31.08px" }}
            onClick={() => dispute(item._id)}
            loading={disputeBookingLoading}
            disabled={bookingIsFetching}
          >
            Dispute
          </LoadingButton>
        </>
      )}
    </>
  );
}

function CustomerBookingButtons({
  onMessageClick,
  isLoading,
  completeBooking,
  concludeBookingLoading,
  completedStatuses,
  item,
  navigate,
  bookingIsFetching,
}) {
  return (
    <>
      {customerDisableStatus.includes(item.status) ? null : (
        <>
          <Button
            variant="primary"
            sx={{ maxWidth: "94.61px", maxHeight: "31.08px" }}
            onClick={onMessageClick}
          >
            {isLoading && (
              <CircularProgress
                style={{ color: "#fff", marginRight: "10px" }}
                size={15}
              />
            )}
            Message
          </Button>
          <>
            {item.paymentStatus === "pending" ||
            item.paymentStatus === "partiallyPaid" ? (
              <Button
                variant="primary"
                sx={{ maxWidth: "94.61px", maxHeight: "31.08px" }}
                onClick={() => navigate(`${item._id}/payment`)}
                disabled={bookingIsFetching}
              >
                Pay
              </Button>
            ) : (
              <LoadingButton
                variant="primary"
                sx={{ maxHeight: "31.08px" }}
                onClick={completeBooking}
                loading={concludeBookingLoading}
                disabled={bookingIsFetching}
              >
                Complete Booking
              </LoadingButton>
            )}
          </>
        </>
      )}
    </>
  );
}
