import React from "react";
import { styled, Box } from "@mui/system";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";

function CustomSelect({ value, setValue, label, data, height, minWidth }) {
  const [valLabel, setValLabel] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuWidth, setMenuWidth] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setMenuWidth(event.currentTarget.offsetWidth);
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  const handleClose = (event) => {
    setValue(event.target.value);
    setValLabel(event.target.attributes.label.value);
    setAnchorEl(null);
  };

  return (
    <>
      <Box
        sx={{
          color: "text.primary",
          background: "#FFFFFF",
          /* Black */
          width: "100%",
          minWidth: minWidth || "auto",
          height: height || "auto",
          border: "1px solid #01214D",
          borderRadius: " 123px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          px: "1rem",
          cursor: "pointer",
          fontWeight: "600",
        }}
        onClick={handleClick}
      >
        {valLabel ? valLabel : label}
        <Box component="span" sx={{ pl: "0.5rem" }}>
          {open ? <ExpandLessRoundedIcon /> : <ExpandMoreRoundedIcon />}
        </Box>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          "& .MuiMenu-paper": {
            minWidth: menuWidth,
          },
        }}
      >
        {data?.map(({ label, value }) => (
          <MenuItem
            key={value}
            component="option"
            value={value}
            label={label}
            onClick={handleClose}
          >
            {label}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default CustomSelect;
