import { Box, Typography } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";

const StyledBox = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  borderRadius: "8px",
  boxShadow: "0px -3px 27px rgba(0, 0, 0, 0.05)",
  width: "100%",
  height: "120px",
  filter: "blur(0.1px)",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "120px",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    height: "100px",
  },
}));

function StatsCard1({
  label,
  selectedButton,
  dailyValue,
  monthlyValue,
  yearlyValue,
  totalValue,
}) {
  let currentValue =
    selectedButton === "Total"
      ? totalValue
      : selectedButton === "Day"
      ? dailyValue
      : selectedButton === "Month"
      ? monthlyValue
      : selectedButton === "Year"
      ? yearlyValue
      : totalValue;
  return (
    <StyledBox>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          height: "100%",
          px: 3,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontFamily: "Be Vietnam Pro",
            fontWeight: "500",
            lineHeight: "39px",
            color: "text.secondary",
          }}
        >
          {label}
        </Typography>
        <Typography
          variant="h5"
          sx={{
            fontFamily: "Be Vietnam Pro",
            fontWeight: "700",
            lineHeight: "39px",
            color: "text.primary",
          }}
        >
          {currentValue}
        </Typography>
      </Box>
    </StyledBox>
  );
}

export default StatsCard1;
