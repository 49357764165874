import { Avatar, Box, Link, Typography } from "@mui/material";
import useImage from "Hooks/useImage";
import React from "react";
import classes from "./index.module.css";
const Tabs = ({
  thread,
  onBlockClick,
  profile,
  activeChat,
  date,
  lastMessage,
}) => {
  const { data: profileImage } = useImage({
    key: profile.profilePhoto,
  });
  return (
    <Box
      className={`${classes.inActiveTab} ${
        activeChat?._id === thread?._id && classes.tab
      }`}
      onClick={() => onBlockClick({ profile })}
    >
      <Box className={classes.icon}>
        <Link href={`/dashboard/vendors/${profile?._id}`}>
          <Avatar src={profileImage} />
        </Link>
      </Box>
      <Box className={`${classes.rootChat}`}>
        <Typography style={{ cursor: "default" }}>
          {profile.firstName
            ? `${profile.firstName} ${profile.lastName}`
            : profile.fullName}
        </Typography>
        <Typography>{lastMessage}</Typography>
      </Box>
      <Box className={classes.status}>
        <Typography>{date}</Typography>
        {thread.metaData.unreadCount ? (
          <Box className={classes.badge}>
            <Typography>{thread.metaData.unreadCount}</Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
};

export default Tabs;
