import { yupResolver } from "@hookform/resolvers/yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Divider, Link, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { login } from "api/v1/auth";
import CustomCard from "components/forms/CustomCard";
import FormInputCheckBox from "components/forms/FormInputCheckBox";
import FormInputText from "components/forms/FormInputText";
import CustomTabs from "components/navigation/Tabs/CustomTabs";
import { USER_TYPE } from "constants";
import { useEventContext } from "context/EventContext";
import { useUserContext } from "context/UserContext";
import messages from "messages";
import queryString from "query-string";
import * as React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ROUTES from "routes";
import * as yup from "yup";
import classes from "./LoginForm.module.css";

export default function LoginForm() {
  const location = useLocation();
  const { event } = useEventContext();
  const [loginAs, setLoginAs] = React.useState(
    queryString.parse(location.search)?.loginAs ?? USER_TYPE.CUSTOMER
  );
  const isVendor = loginAs === "vendors";
  const { setToken, isAuthenticated } = useUserContext();
  const [currentTab, setCurrentTab] = React.useState(isVendor ? 1 : 0);

  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    resolver: yupResolver(schema),
  });
  const { mutateAsync, isLoading, isError, isSuccess, error } = useMutation(
    login,
    {
      onSuccess: () => {
        toast.success(messages.loggedIn);
      },
      onError: (error) => {
        toast.error(error.errMsg);
      },
    }
  );

  const [showPass, setShowPass] = React.useState(false);

  async function onSubmit(data) {
    try {
      const res = await mutateAsync({ userType: loginAs, body: data });
      setToken(res.data.token);
    } catch (err) {
      console.error(err);
    }
  }
  React.useEffect(() => {
    if (isAuthenticated) {
      if (loginAs === USER_TYPE.CUSTOMER) {
        if (event && Boolean(Object.values(event))) {
          navigate("/dashboard/events/plan");
        } else {
          navigate(ROUTES.DASHBOARD);
        }
      } else if (loginAs === USER_TYPE.VENDOR) {
        navigate(ROUTES.DASHBOARD);
      }
    }
  }, [isAuthenticated]);

  const handleClickShowPass = () => {
    setShowPass(!showPass);
  };

  React.useEffect(() => {
    const tempVal =
      queryString.parse(location.search)?.loginAs ?? USER_TYPE.CUSTOMER;

    setLoginAs(tempVal);
    setCurrentTab(tempVal === "vendors" ? 1 : 0);
  }, [location.search]);

  const handleOnSelectTab = (index, type) => {
    reset();
    navigate(`/?loginAs=${type}`);
  };

  return (
    <CustomCard>
      <Box sx={{ pt: 1 }}>
        <CustomTabs
          step={currentTab}
          values={[
            { type: USER_TYPE.CUSTOMER, label: "Customer" },
            { type: USER_TYPE.VENDOR, label: "Vendor" },
          ]}
          onSelect={handleOnSelectTab}
          mobileTabFontSize="0.9rem"
        />
      </Box>
      <Box className={classes.root} sx={{ px: 3.5, py: 3 }}>
        <Box>
          <Box sx={{ pb: "5px" }}>
            <Typography
              variant="pbold"
              sx={{
                fontSize: { xxs: "1.7rem", md: "2rem" },
                lineHeight: { xxs: "37px", md: "47px" },
                fontWeight: "700",
              }}
            >
              {`${isVendor ? "Vendor" : "Customer"} Login`}
            </Typography>
          </Box>
          <Box sx={{ pb: "7px" }}>
            <Typography
              variant="p"
              sx={{
                fontSize: { xxs: "1rem", md: "1.25" },
                lineHeight: { xxs: "24px", md: "34px" },
              }}
            >
              If you are already a user you can login with your email address
              and password.
            </Typography>
          </Box>
        </Box>
        <Divider />
        <Box>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                flexWrap: "wrap",
              }}
            >
              <Box
                sx={{
                  width: "90%",
                }}
              >
                <Box
                  sx={{
                    mb: "30px",
                    mt: "30px",
                  }}
                >
                  <FormInputText
                    label="Email Address"
                    name="email"
                    placeholder="sample@user.com"
                    control={control}
                    errors={errors?.email?.message}
                  />
                </Box>
                <Box>
                  <FormInputText
                    label="Password"
                    name="password"
                    icon={VisibilityOff}
                    iconToggle={Visibility}
                    toggle={showPass}
                    handleClick={handleClickShowPass}
                    position="start"
                    positionEdge="start"
                    adornment="endAdornment"
                    control={control}
                    errors={errors?.password?.message}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: "30px",
                    mt: "5px",
                  }}
                >
                  <FormInputCheckBox
                    name="rememberMe"
                    control={control}
                    label="Remember Me"
                  />
                  <StyleLink
                    component={RouterLink}
                    to={ROUTES.FORGOT_PASSWORD}
                    sx={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      lineHeight: "23px",
                    }}
                  >
                    Forgot Password?
                  </StyleLink>
                </Box>
                <Box sx={{ mb: "20px" }}>
                  <LoadingButton
                    type="submit"
                    variant="primary"
                    fullWidth
                    sx={{ height: "50px" }}
                    loading={isLoading}
                  >
                    Login to your account
                  </LoadingButton>
                </Box>
                <Box>
                  <StyleLink
                    sx={{
                      color: (theme) => theme.palette.text.secondary,
                      fontSize: "1.25rem",
                      fontWeight: "500",
                      lineHeight: "29px",
                    }}
                    component={RouterLink}
                    to={isVendor ? ROUTES.SIGNUP.VENDOR : ROUTES.SIGNUP.USER}
                  >
                    Don't have an account?{" "}
                    <Box component="span" sx={{ color: "primary.main" }}>
                      Sign up here
                    </Box>
                  </StyleLink>
                </Box>
              </Box>
            </Box>
          </form>
        </Box>
      </Box>
    </CustomCard>
  );
}

const schema = yup
  .object({
    email: yup.string().email(messages.invalidEmail).required(),
    password: yup.string().required(messages.requiredField),
  })
  .required();

const StyleLink = styled(Link)({
  fontFamily: "Be Vietnam Pro",
  fontStyle: "normal",
  textDecoration: "none",
});
