const messages = {
  passwordLength: "Password must be at least 8 characters long",
  invalidPassword:
    "Password must include at least one upper case letter, one lower case letter, and one numeric digit",
  passwordNotMatch: "Passwords do not match",
  invalidEmail: "Must be a valid email address",
  invalidName: "Invalid Name, only alphabets are allowed",
  usernameNotAvailable: "Username not available",
  emailNotAvailable: "Email address is already registered",
  signedUp: "Successfully signed up!",
  loggedIn: "Successfully logged in!",
  acceptAllInformationIsTrue:
    "You must accept that the given information is true",
  invalidDate: "Invalid date",
  invalidTime: "Invalid time",
  invalidURL: "Invalid URL example(https://www.example.com/)",
  requiredField: "This field is required",
  invalidValue: "Invalid value",
  noDecimalValue: "Decimal values are not allowed",
  positiveNumber: "Negative values are not allowed",
  validateLanguageString:
    "Value must be an alphabet seperated with commas and no trailling comma allowed",
  sessionExpiry: "Session has been expired!",
  saveSelection: "Reminder: Save your selections",
  validLocation: "must be a valid location",
  invalidToken: "Invalid token",
  malformedToken: "invalid signature",
  onlyNumber: "Only numbers are allowed for this field",
  invalidImageSize: "Image size is too large! select a image below 3MB",
  invalidImageType: "Only JPEG and PNG are allowed",
  paymentGatewayNotConnected: "Payment gateway not connected",
  invalidFBUrl: "Invalid Url. example(www.facebook.com/abcd)",
  invalidTwitterUrl: "Invalid Url. example(www.twitter.com/abcd)",
  invalidInstaUrl: "Invalid Url. example(www.instagram.com/abcd)",
  serviceRequired: "You must select a service",
  created: (name) => {
    return `${name} has been created successfully!`;
  },
  required: (name) => {
    return `${name} is Required!`;
  },
};

export default messages;
