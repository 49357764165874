import * as React from "react";
import createSocketConnection from "../socket";
import { useUserContext } from "./UserContext";
const SocketContext = React.createContext();

export function SocketProvider({ children }) {
  const { isAuthenticated } = useUserContext();
  const socketRef = React.useRef();
  const activeChatIdRef = React.useRef(null); // for socket events

  // To create socket connection only when the login status changes
  socketRef.current = React.useMemo(
    () =>
      isAuthenticated
        ? createSocketConnection({
            token: window.localStorage.getItem("token"),
          })
        : undefined,
    [isAuthenticated]
  );

  return (
    <SocketContext.Provider value={{ socketRef, activeChatIdRef }}>
      {children}
    </SocketContext.Provider>
  );
}

export function useSocketContext() {
  const context = React.useContext(SocketContext);
  if (context === undefined) {
    throw new Error("useSocketContext can only be used inside SocketProvider");
  }
  return context;
}
