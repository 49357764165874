import {
  Grid,
  Box,
  Typography,
  Divider,
  Avatar,
  AvatarGroup,
} from "@mui/material";

import React from "react";
import { styled } from "@mui/system";
import AvatarImage from "assets/dashboard/avatar/avatar4.jpg";
import dayjs from "dayjs";
import { DATE_FORMAT_THREE } from "constants";

const StyledBox = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  boxShadow: "0px -3.50209px 31.5188px 11.6736px rgba(0, 0, 0, 0.05)",
  borderRadius: "9.3389px",
  minWidth: "300px",
  width: "100%",
  height: "290px",
  cursor: "pointer",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    height: "auto",
    minWidth: "200px",
  },
}));

const StyledTextLabel = styled(Typography)(({ theme }) => ({
  fontFamily: "Be Vietnam Pro",
  fontWeight: "500",
  lineHeight: "39px",
}));
const StyledTextValue = styled(Typography)(({ theme }) => ({
  fontFamily: "Be Vietnam Pro",

  fontWeight: "700",
  lineHeight: "39px",
  color: theme.palette.text.primary,
}));
function MeetingCard({ data, subTitle = "", ...rest }) {
  return (
    <StyledBox {...rest}>
      <Box sx={{ p: "1.5rem" }}>
        <TopSection title={data?.title} subTitle={subTitle} />
      </Box>
      <Divider />
      <Box sx={{ p: "1.5rem" }}>
        <BottomSection
          date={dayjs(data?.startTime).format(DATE_FORMAT_THREE)}
          time={`${dayjs(data?.startTime).format("LT")} (${data?.timeZone})`}
        />
      </Box>
    </StyledBox>
  );
}

export default MeetingCard;

function TopSection({ title }) {
  return (
    <Grid container>
      <Grid item container rowSpacing={1.4} xxs={12}>
        <Grid item xxs={12}>
          <Typography variant="h5">{title}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

function BottomSection({ date, time }) {
  return (
    <Grid container rowSpacing={3}>
      <Grid item xxs={6}>
        <StyledTextLabel variant="p600">Meeting Date </StyledTextLabel>
      </Grid>
      <Grid item xxs={6}>
        <StyledTextValue>{date || "13/2/2021"}</StyledTextValue>
      </Grid>
      <Grid item xxs={6}>
        <StyledTextLabel variant="p600">Meeting Start Time</StyledTextLabel>
      </Grid>
      <Grid item xxs={6}>
        <StyledTextValue>{time || "3:00 PM - 4:00 PM"}</StyledTextValue>
      </Grid>
      {/* <Grid item container xxs={12} rowSpacing={1} alignItems="center">
        <Grid item xxs={12}>
          <Typography variant="p600" sx={{ color: "primary.main" }}>
            Participants
          </Typography>
        </Grid>
        <Grid item xxs={12} xs={6}>
          <Box sx={{ display: "flex" }}>
            <AvatarGroup
              max={4}
              sx={{
                "& .MuiAvatarGroup-avatar": {
                  backgroundColor: "#5542F6",
                },
              }}
            >
              {[1, 2, 3, 4, 5].map((x) => (
                <Avatar key={x} alt="Remy Sharp" src={AvatarImage} />
              ))}
            </AvatarGroup>
          </Box>
        </Grid>
        <Grid item xxs={12} xs={6}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                width: "10.37px",
                height: "10.37px",
                backgroundColor: "#14B13B",
                borderRadius: "50px",
              }}
            />
            <Box>
              <Typography variant="p" sx={{ pl: "0.4rem" }}>
                Burger House (Vendor)
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid> */}
    </Grid>
  );
}
