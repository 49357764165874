import { Box } from "@mui/material";
import BasicSkeleton from "components/Skeleton/BasicSkeleton";
import useImage from "Hooks/useImage";

function LeftMiddleSubSection1({ data }) {
  const { data: image, isLoading } = useImage({
    key: data.type.eventTypeId.images[0],
  });
  return (
    <Box sx={{ borderRadius: "8px", width: "100%", height: "100%" }}>
      {isLoading ? (
        <BasicSkeleton
          variant="rectangle"
          style={{ width: "100%", height: "100%", borderRadius: "8px" }}
        />
      ) : (
        <img
          src={image}
          alt="cake"
          style={{
            borderRadius: "8px",
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
      )}
    </Box>
  );
}

export default LeftMiddleSubSection1;
