import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Button, Divider, Grid, Typography } from "@mui/material";
import CustomFileInput from "components/forms/CustomFileInput";
import FormCheckBox from "components/forms/FormCheckBox";
import FormInputDatePicker from "components/forms/FormInputDatePicker";
import FormInputTextSecondary from "components/forms/FormInputTextSecondary";
import FormNumberInput from "components/forms/FormNumberInput";
import FormRadioButton from "components/forms/FormRadioButton";
import dayjs from "dayjs";
import React from "react";
import {
  businessTypeOptions,
  gender,
  language,
} from "../data/form/personalInfoData";

const date = new Date();

function PersonalInformation({
  control,
  getValues,
  setActiveStep,
  trigger,
  errors,
  forProfile = true,
}) {
  const { birthDate, dateOfRegisteration } = getValues();
  const handleClickContinue = async () => {
    const result = await trigger([
      "businessCriteria",
      "dateOfRegisteration",
      "image",
      "fullName",
      "birthDate",
      "telephoneNumber",
      "email",
      "password",
      "confirmPassword",
      "profilePhoto",
      "gender",
      "languages",
    ]);

    if (result) setActiveStep(1);
  };
  return (
    <Grid container rowSpacing={5}>
      {forProfile && (
        <Grid item xxs={12}>
          <SectionOne
            control={control}
            errors={errors}
            dateOfRegisteration={dateOfRegisteration}
          />
        </Grid>
      )}
      <Grid item xxs={12}>
        <Typography variant="h4" sx={{ pb: "1rem" }}>
          Personal Information
        </Typography>
        <Divider />
      </Grid>
      <Grid item xxs={12}>
        <SectionTwo
          control={control}
          getValues={getValues}
          errors={errors}
          forProfile={forProfile}
          birthDate={birthDate}
        />
      </Grid>
      <Grid item xxs={12}>
        {forProfile && (
          <Button variant="primary" onClick={handleClickContinue}>
            Continue
          </Button>
        )}
      </Grid>
    </Grid>
  );
}

export default PersonalInformation;

function SectionOne({ control, errors, dateOfRegisteration }) {
  return (
    <Grid container rowSpacing={4} columnSpacing={3}>
      <Grid item container xxs={12} sm={7} rowSpacing={2}>
        <SectionOneSub1
          control={control}
          errors={errors}
          dateOfRegisteration={dateOfRegisteration}
        />
      </Grid>
      <Grid
        item
        container
        xxs={12}
        sm={5}
        rowSpacing={2}
        justifyContent={{ xxs: "center", sm: "flex-end" }}
      >
        <SectionOneSub2 control={control} errors={errors} />
      </Grid>
    </Grid>
  );
}

function SectionOneSub1({ control, errors, dateOfRegisteration }) {
  return (
    <>
      <Grid item xxs={12}>
        <FormCheckBox
          control={control}
          name="businessCriteria"
          label="Is Your Business?"
          options={businessTypeOptions}
          row={true}
          sx={{ mr: "3.5rem" }}
          errors={errors?.businessCriteria?.message}
        />
      </Grid>

      <Grid item xxs={12}>
        <FormInputDatePicker
          control={control}
          name="dateOfRegisteration"
          label="Date of Registration"
          errors={errors?.dateOfRegisteration?.message}
          defaultValue={dateOfRegisteration ? dateOfRegisteration : null}
          maxDate={dayjs(date)}
          minDate={dayjs(new Date(1900, 0, 1))}
          placeholder="Enter your date of registration"
        />
      </Grid>
    </>
  );
}
function SectionOneSub2({ control, errors }) {
  return (
    <CustomFileInput
      error={errors?.image?.message}
      control={control}
      name="image"
    />
  );
}

function SectionTwo({ control, getValues, errors, forProfile, birthDate }) {
  return (
    <Grid container rowSpacing={2}>
      <Grid item xxs={12}>
        <SectionTwoSub1
          control={control}
          errors={errors}
          getValues={getValues}
          forProfile={forProfile}
          birthDate={birthDate}
        />
      </Grid>
      <Grid item xxs={12}>
        <SectionTwoSub2
          control={control}
          getValues={getValues}
          errors={errors}
          forProfile={forProfile}
        />
      </Grid>
    </Grid>
  );
}

function SectionTwoSub1({ control, errors, forProfile, birthDate }) {
  const [showPass, setShowPass] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const handleClickShowPass = (isConfirm) => {
    if (isConfirm) setShowConfirmPassword(!showConfirmPassword);
    else setShowPass(!showPass);
  };

  return (
    <Grid container justifyContent="center" rowSpacing={2}>
      <Grid item container xxs={12} rowSpacing={2} columnSpacing={2}>
        {!forProfile ? (
          <>
            <Grid item xxs={12} sm>
              <FormInputTextSecondary
                control={control}
                label="First Name"
                name="firstName"
                placeholder="Enter your first name"
                errors={errors?.firstName?.message}
              />
            </Grid>
            <Grid item xxs={12} sm>
              <FormInputTextSecondary
                control={control}
                label="last Name"
                name="lastName"
                placeholder="Enter your last name"
                errors={errors?.lastName?.message}
              />
            </Grid>
          </>
        ) : (
          <Grid item xxs={12} sm>
            <FormInputTextSecondary
              control={control}
              label="Full Name"
              name="fullName"
              placeholder="Enter your first name"
              errors={errors?.fullName?.message}
            />
          </Grid>
        )}

        <Grid item xxs={12} sm>
          <FormInputDatePicker
            control={control}
            name="birthDate"
            label="Birth Date"
            errors={errors?.birthDate?.message}
            defaultValue={birthDate ?? null}
            minDate={dayjs(new Date(1900, 0, 1))}
            maxDate={dayjs(
              new Date(date.getFullYear() - 18, date.getMonth(), date.getDate())
            )}
            placeholder="Enter your birth date"
          />
        </Grid>
      </Grid>
      <Grid item container xxs={12} rowSpacing={2} columnSpacing={2}>
        <Grid item xxs={12} sm>
          <FormInputTextSecondary
            control={control}
            label="Email"
            name="email"
            placeholder="Enter your email"
            errors={errors?.email?.message}
          />
        </Grid>
        <Grid item xxs={12} sm>
          <FormInputTextSecondary
            control={control}
            label={"Password"}
            name="password"
            icon={VisibilityOff}
            iconToggle={Visibility}
            toggle={showPass}
            handleClick={() => handleClickShowPass(false)}
            errors={errors?.password?.message}
          />
        </Grid>
        <Grid item xxs={12} sm>
          <FormInputTextSecondary
            control={control}
            label={"Confirm Password"}
            name="confirmPassword"
            icon={VisibilityOff}
            iconToggle={Visibility}
            toggle={showConfirmPassword}
            handleClick={() => handleClickShowPass(true)}
            errors={errors?.confirmPassword?.message}
          />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xxs={12}
        rowSpacing={2}
        columnSpacing={2}
        justifyContent="flex-start"
      >
        <Grid item xxs={12} sm={6}>
          {forProfile && (
            <FormNumberInput
              control={control}
              label="Phone Number"
              name="telephoneNumber"
              errors={errors?.telephoneNumber?.message}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}
function SectionTwoSub2({ control, getValues, errors, forProfile }) {
  const [disableInput, setDisableInput] = React.useState(false);
  const { languages } = getValues();
  return (
    <Grid container justifyContent="flex-start" rowSpacing={2}>
      <Grid item xxs={12} md={11}>
        <FormRadioButton
          control={control}
          name="gender"
          label="Select your gender"
          options={gender}
          row={true}
          sx={{ justifyContent: "space-between" }}
          errors={errors?.gender?.message}
        />
      </Grid>
      {forProfile && (
        <>
          <Grid item xxs={12}>
            <FormCheckBox
              control={control}
              name="languages"
              label="Select your working languages"
              options={language}
              row={true}
              handleSwitch={setDisableInput}
              sx={{ mr: "3.5rem" }}
              errors={errors?.languages?.message}
            />
          </Grid>
          {languages.includes("other") && (
            <Grid item xxs={12}>
              <FormInputTextSecondary
                control={control}
                label="Other Languages"
                name="otherLanguages"
                placeholder="Please enter your languages(seperate with comma's)"
                disabled={!disableInput}
                sx={{ "& .MuiOutlinedInput-root": { borderRadius: "12px" } }}
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}
