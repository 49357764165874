import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import { createThread } from "api/v1/chat";
import profilePhoto from "assets/dashboard/myEvent/profile.webp";
import Tags from "components/Tags";
import { useUserContext } from "context/UserContext";
import useImage from "Hooks/useImage";
import moment from "moment";
import React from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { objectKeyBasedOnUserType } from "utilities";
import DetailItem from "./DetailItem";
import classes from "./index.module.css";

function DetailCard({ Icon, quote }) {
  const navigate = useNavigate();
  const { isVendor } = useUserContext();
  const objectKey = objectKeyBasedOnUserType(isVendor);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const { mutate, isLoading } = useMutation(
    async (payload) => {
      const data = await createThread({ data: payload });
      return data;
    },
    {
      onSuccess(successData) {
        navigate(`/dashboard/messages/${successData._id}`);
      },
    }
  );
  const { data: profileImage } = useImage({
    key: quote?.[objectKey]?.profilePhoto,
  });
  function createMessage() {
    mutate({
      userId: quote.vendorId._id,
      content: message,
    });
  }
  function onMessageClick() {
    if (quote?.vendorId?.threads?._id)
      navigate(`/dashboard/messages/${quote?.vendorId?.threads?._id}`);
    else setIsModalOpen(true);
  }
  return (
    <Grid
      container
      xxs={12}
      justifyContent="center"
      spacing={2}
      sx={{
        py: "1rem",
        backgroundColor: "#FFF6FB",
        pl: "1rem",
        m: "0",
      }}
    >
      <Grid
        item
        container
        xxs={12}
        sx={{ color: "#000" }}
        justifyContent="center"
        alignItems="center"
        columnSpacing={isVendor ? 0 : 1}
      >
        <Grid item xxs={3}>
          <DetailItem
            rating={quote?.vendorId?.rating}
            profileImage={profileImage ?? profilePhoto}
            value={quote?.[objectKey]?.fullName}
            isVendor={isVendor}
          />
        </Grid>
        <Grid item xxs={1.8}>
          <Box
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {quote?.eventId?.title}
          </Box>
        </Grid>
        <Grid item xxs={1.8}>
          {quote?.eventId?.location?.address}
        </Grid>
        <Grid item xxs={1.8}>
          {moment(quote?.eventId?.startTime).format("MM/DD/YYYY")}
        </Grid>
        <Grid item xxs={1.8}>
          {`$ ${quote?.price}`}
        </Grid>
        <Grid item xxs={1.8}>
          {`${quote?.status}`}
        </Grid>
      </Grid>
      <Grid
        item
        container
        xxs={12}
        justifyContent="space-between"
        alignItems="center"
        columnSpacing={4}
      >
        <Grid item xxs={3}>
          <Box
            sx={{
              display: "-webkit-box",
              "-webkit-line-clamp": "3",
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
              fontSize: "1.1rem",
              fontFamily: "Be Vietnam Pro",
              fontWeight: "600",
            }}
          >
            <Box
              component="span"
              sx={{ color: "primary.main", fontSize: "18px" }}
            >
              Quote:
            </Box>
            <Box
              component="span"
              sx={{
                color: "#000",
                fontSize: "14px",
                pl: 1,
              }}
            >
              {quote?.description}
            </Box>
          </Box>
        </Grid>
        <Grid item xxs={4}>
          <Box className={classes.tags}>
            {quote?.servicesId?.map(({ name }, index) => {
              return <Tags isShowIcon={false} text={name} key={index} />;
            })}
          </Box>
        </Grid>
        <Grid item container xxs={4} columnSpacing={1} rowSpacing={1}>
          <Grid item xxs={12} xs>
            {isVendor ? null : (
              <Button
                variant="primary"
                sx={{ maxWidth: "94.61px", maxHeight: "31.08px" }}
                onClick={onMessageClick}
              >
                {isLoading && (
                  <CircularProgress
                    style={{ color: "#fff", marginRight: "10px" }}
                    size={15}
                  />
                )}
                Message
              </Button>
            )}
          </Grid>
          <Grid item xxs={12} xs>
            <Button
              variant="secondary"
              onClick={() => {
                navigate(`/dashboard/quotes/${quote._id}`, { state: quote });
              }}
              sx={{ maxWidth: "94.61px", maxHeight: "31.08px" }}
            >
              See Details
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {isVendor ? null : (
        <Dialog
          fullWidth
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        >
          <DialogTitle>Message</DialogTitle>
          <DialogContent>
            <input
              className="input"
              placeholder="Please insert your message"
              onChange={(e) => setMessage(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={createMessage} disabled={!message}>
              Send Message
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Grid>
  );
}

export default DetailCard;
