export const breakpoints = {
  values: {
    xxs: 0,
    xs: 450,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
  },
};
