import { Box, Grid, Typography } from "@mui/material";
import React from "react";

function CustomList({ list }) {
  return (
    <Grid container alignItems="flex-start" rowSpacing={1} columnSpacing={1.4}>
      {list &&
        list.map(({ label, value, seperatedBy }) => (
          <>
            {" "}
            {value && value?.length > 0 && (
              <Grid item xxs={12} sm={6} md={4} container>
                <Grid item xxs={12}>
                  <Typography variant="pBold">{label}</Typography>
                </Grid>

                {Array.isArray(value) ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: "3px",
                      pr: 1,
                    }}
                  >
                    {value.map(
                      (val, i) =>
                        val && (
                          <Typography
                            key={i}
                            variant="p"
                            sx={{
                              hyphens: "auto",
                              wordBreak: "break-word",
                            }}
                          >
                            {seperatedBy && i !== value.length - 1
                              ? `${val}${seperatedBy}`
                              : val}
                          </Typography>
                        )
                    )}
                  </Box>
                ) : (
                  <Grid item>
                    <Typography
                      variant="p"
                      sx={{ hyphens: "auto", wordBreak: "break-word" }}
                    >
                      {value}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            )}
          </>
        ))}
    </Grid>
  );
}

export default CustomList;
