import React from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Rating,
  Typography,
  useTheme,
} from "@mui/material";
import classes from "./BookingDetails.module.css";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import Status from "components/Accordian/dashboard/Status/Status";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import large from "assets/icon/large.svg";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import profileImage from "assets/dashboard/myEvent/profile.webp";
import { getBooking } from "api/v1/booking";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import { useUserContext } from "context/UserContext";
import DetailItem from "components/DetailCard/DetailItem";
import useImage from "Hooks/useImage";
import { getQuotesById } from "api/v1/quotes";
import formatDate from "utils/formatDate";

function BookingDetails() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isVendor } = useUserContext();
  const params = useParams();
  const { data: booking, isLoading: isBookingLoading } = useQuery(
    ["bookings", params.bookingId],
    async () => {
      const res = await getBooking({ id: params.bookingId });
      return res.data;
    }
  );

  const data = booking;

  const { data: quote } = useQuery(
    ["quote", data?.quoteId],
    async () => {
      const res = await getQuotesById(data?.quoteId);
      return res.data;
    },
    { enabled: isVendor && Boolean(data) }
  );

  const { data: userImage } = useImage({
    key: data?.customerId?.profilePhoto,
  });

  if (isBookingLoading) {
    return (
      <div className="center">
        <CircularProgress size="3rem" />
      </div>
    );
  }

  return (
    <Box>
      <Typography variant="h2" p={2}>
        Booking Details
      </Typography>
      <Box className={classes.root}>
        <CardHeaderForVendor
          status={data?.status}
          color={theme.palette.primary.main}
          eventTitle={data?.eventId?.title}
        />

        <hr />
        <Grid container rowSpacing={2} mt={3}>
          <Grid item xxs={12}>
            <Box className={classes.iconContainer}>
              <Box sx={{ color: theme.palette.primary.main }}>
                <CalendarMonthOutlinedIcon fontSize="large" />
              </Box>
              <Typography
                variant="h5"
                fontWeight="400"
                style={{ whiteSpace: "pre-line" }}
              >
                {`${formatDate(data?.eventId?.startTime)} - ${formatDate(
                  data?.eventId?.endTime
                )}`}
              </Typography>
            </Box>
            <Box className={classes.iconContainer}>
              <Box sx={{ color: theme.palette.primary.main }}>
                <LocationOnIcon fontSize="large" />
              </Box>
              <Typography variant="body4" fontWeight="400">
                {data?.eventId?.location?.address}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Typography variant="body6" component="h3" mt={3}>
          {isVendor ? "Customer Details" : "Vendor Details"}
        </Typography>

        {isVendor ? (
          <Box sx={{ pt: 2 }}>
            <UserDetailCard
              name={data?.customerId?.fullName}
              profilePhoto={userImage}
              rating={null}
              isVendor={isVendor}
              primaryTextValue="Price Offered"
              secondaryTextValue={`$ ${data?.amount}`}
              primaryTextVariant="mediumText"
              secondaryTextVariant="body6"
              primaryColor={theme.palette.primary.main}
            />
          </Box>
        ) : (
          <Grid container rowSpacing={2} mt={1} sx={{ marginBottom: "40px" }}>
            <Grid item xl={4} lg={12} md={12} sm={12} xxs={12}>
              <Box className={classes.profileDetail}>
                <Box>
                  <img
                    src={profileImage}
                    alt="profile"
                    className={classes.profile}
                  />
                </Box>
                <Box>
                  <Typography variant="body6" mr={1} fontWeight={600}>
                    {data?.vendorId?.fullName}
                  </Typography>
                  <Link to={`/dashboard/vendors/${data?.vendorId?._id}`}>
                    <img
                      src={large}
                      alt="large"
                      style={{ cursor: "pointer" }}
                    />
                  </Link>
                  <Box className={classes.rating}>
                    <Rating
                      name="half-rating-read"
                      defaultValue={data?.vendorId?.rating}
                      precision={0.5}
                      readOnly
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xl={4} lg={3} md={6} sm={6} xxs={12}>
              <Box className={classes.price}>
                <Typography
                  variant="mediumText"
                  color={theme.palette.primary.main}
                >
                  Price Offered
                </Typography>
                <Typography variant="body6" component="h3">
                  $ {data?.amount}
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={4} lg={3} md={6} sm={6} xxs={12}>
              <Box className={classes.price}>
                <Typography
                  variant="mediumText"
                  color={theme.palette.primary.main}
                >
                  Price Paid
                </Typography>
                <Typography variant="body6" component="h3">
                  $ {data?.amountPaid}
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={4} lg={3} md={6} sm={6} xxs={12}>
              <Box className={classes.btn}>
                <Link to="/dashboard/quotes">
                  <Button variant="secondary">See All Quote</Button>
                </Link>
              </Box>
            </Grid>
          </Grid>
        )}
        <Box className={classes.btnContainer}>
          <Button
            component="button"
            variant="secondary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default BookingDetails;

function CardHeaderForVendor({ eventTitle, status, color }) {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      {" "}
      <Typography variant="h5" component="h3" color={color} mt={1}>
        Event:{" "}
        <Box component="span" sx={{ color: "text.primary", fontWeight: 600 }}>
          {eventTitle}
        </Box>
      </Typography>
      <Status
        sx={{
          height: {
            xxs: "30px !important",
            sm: "35px !important",
            lg: "37px !important",
          },
        }}
        textVariant="p"
        status={status === "pending" ? "scheduled" : status}
      />
    </Box>
  );
}

function UserDetailCard({
  name,
  rating,
  profilePhoto,
  isVendor,
  primaryTextValue,
  secondaryTextValue,
  primaryTextVariant,
  secondaryTextVariant,
  primaryColor,
  secondaryColor,
}) {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Box>
        <DetailItem
          profileImage={profilePhoto}
          value={name}
          rating={rating}
          isVendor={isVendor}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant={primaryTextVariant} color={primaryColor}>
          {primaryTextValue}
        </Typography>
        <Typography variant={secondaryTextVariant} color={secondaryColor}>
          {secondaryTextValue}
        </Typography>
      </Box>
    </Box>
  );
}
