import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import StatsCard1 from "components/Cards/StatsCard1";

export default function StatsCardSkeleton() {
  return (
    <Skeleton
      variant="rectangular"
      sx={{ borderRadius: "9.3389px", width: "100%", height: "120px" }}
    />
  );
}
