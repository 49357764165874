import { Grid, Link, Typography, Stack, Box } from "@mui/material";
import { Link as NextLink, useNavigate } from "react-router-dom";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import classes from "./FooterList.module.css";
import * as React from "react";

const localLinks = ["/event/plan", "/vendor/signup", "/services"];

function filterLink(z) {
  return z === "Plan My Event"
    ? `/event/plan`
    : z === "Become a Vendor"
    ? `/vendor/signup`
    : z === "All Services"
    ? `/services`
    : z === "Faqs"
    ? `/faqs`
    : z === "Blogs"
    ? `/blogs`
    : "";
}
function navigateTo(link, navigate) {
  if (localLinks.includes(link)) {
    navigate(link);
  } else {
    window.location.href = `${process.env.REACT_APP_LANDING_URL}${link}`;
  }
}

const iconStyle = { color: "#fff", fontSize: { xxs: "18px", sm: "22px" } };

const data = [
  ["SERVICES", "Follow Us"],
  ["Plan My Event", ["Facebook", <FacebookIcon key="face" sx={iconStyle} />]],
  [
    "Become a Vendor",
    ["Instagram", <InstagramIcon key="insta" sx={iconStyle} />],
  ],
  ["All Services", ""],
  ["Faqs", ""],
  ["Blogs", ""],
];

export default function FooterList() {
  const navigate = useNavigate();
  return (
    <>
      {data.map((x, index) => (
        <Grid
          key={index}
          item
          xxs={12}
          sm={12}
          md={10}
          lg={9}
          xl={8}
          container
          justifyContent="flex-end"
          sx={{
            alignItems: "center",
            justifyContent: {
              sm: "flex-end",
              xs: "flex-start",
            },
            gap: { xxs: "20px", sm: "20px", lg: "0px", xl: "0px" },
          }}
        >
          {x?.map((z, i) => {
            let display =
              z === "hello"
                ? { display: "none", color: "#fff", fontSize: "inherit" }
                : { color: "#fff", fontSize: "inherit" };
            return (
              <>
                <Grid
                  key={i + 20}
                  item
                  xxs={3}
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    paddingBottom: "20px",
                    pr: { xxs: "0", sm: "3px" },
                    fontSize: { xxs: "0.8rem", sm: "0.9rem" },
                  }}
                >
                  {Array.isArray(z) ? (
                    <>{IconText(z[0], z[1])}</>
                  ) : index === 0 ? (
                    <Typography
                      variant="p2Bold"
                      style={{
                        color: "#fff",
                        fontSize: {
                          xxs: "inherit",

                          sm: "1.1rem",
                        },
                      }}
                    >
                      {z}
                    </Typography>
                  ) : (
                    <Box
                      style={{ color: "white !important", cursor: "pointer" }}
                      onClick={() => {
                        navigateTo(filterLink(z), navigate);
                      }}
                    >
                      <Typography
                        variant="p2"
                        className={classes.links}
                        sx={{
                          color: "#fff",
                          fontSize: "inherit",
                          textDecoration: "none",
                        }}
                      >
                        {z}
                      </Typography>
                    </Box>
                  )}
                </Grid>
              </>
            );
          }, index)}
        </Grid>
      ))}
    </>
  );
}

function IconText(text, Ticon) {
  return (
    <Link
      href={
        text === "Facebook"
          ? "https://www.facebook.com/Thepartystarter-100947045830836/"
          : "https://www.instagram.com/thepartystarter.official/"
      }
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        gap={1}
      >
        {Ticon}

        <Typography
          variant="p2"
          className={classes.links}
          sx={{
            color: "#fff",
            fontSize: "inherit",
            textDecoration: "none",
          }}
        >
          {text}
        </Typography>
      </Stack>
    </Link>
  );
}
