import { getVendorReviews } from "api/v1/vendor";
import { useQuery } from "react-query";

export default function useVendorReviews(id, queryParams) {
  return useQuery(
    ["reviews", id],
    async () => {
      const res = await getVendorReviews(id, queryParams);
      return res.data;
    },
    { refetchOnWindowFocus: false }
  );
}
