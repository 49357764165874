import client from "../axiosConfig";
import { formatUrl } from "../../utilities";

export const getThreads = async ({ queryParams = {} }) => {
  return client.get(formatUrl(`/api/v1/chats`, queryParams));
};
export const getMessages = async ({ id, queryParams = {} }) => {
  return client.get(formatUrl(`/api/v1/chats/${id}`, queryParams));
};
export const createThread = async ({ data }) => {
  return client.post(`/api/v1/chats`, data);
};
export const createMessage = async ({ id, data }) => {
  return client.post(`/api/v1/chats/${id}`, data);
};
export const getNewMessages = async () => {
  return client.get("/api/v1/chats/new");
};
export const markRead = async (id) => {
  return client.patch(`/api/v1/chats/${id}/markAsRead`);
};
export const getOnlineStatus = async (id) => {
  return client.get(`/api/v1/chats/status/${id}`);
};
