import { Box, CircularProgress } from "@mui/material";
import classes from "./LoadMore.module.css";

export default function LoadMore({ loadMoreRef, children, hasNextPage }) {
  return (
    <Box
      ref={loadMoreRef}
      className={classes.loader}
      style={{
        display: hasNextPage ? "flex" : "none",
        flexDirection: "column",
      }}
    >
      {children}
      <CircularProgress disableShrink style={{marginTop:"10px"}}/>
    </Box>
  );
}
