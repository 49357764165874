import * as React from "react";

// To temporarily hold event data if the vendor is not logged in
const EventContext = React.createContext();

export function EventProvider({ children }) {
  const [event, setEvent] = React.useState(null);

  return (
    <EventContext.Provider
      value={{
        event,
        setEvent,
      }}
    >
      {children}
    </EventContext.Provider>
  );
}

export function useEventContext() {
  const context = React.useContext(EventContext);
  if (context === undefined) {
    throw new Error("useEventContext can only be used inside EventProvider");
  }
  return context;
}
