import AddIcon from "@mui/icons-material/Add";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import SendIcon from "@mui/icons-material/Send";
import {
  Box,
  Button,
  CircularProgress,
  ClickAwayListener,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Popper,
  Skeleton,
  Typography,
} from "@mui/material";
import { getUpcomingEvents } from "api/v1/event";
import ChatHead from "components/ChatHead";
import MainCard from "components/MainCard";
import Picker, { SKIN_TONE_MEDIUM_DARK } from "emoji-picker-react";
import moment from "moment";
import * as React from "react";
import { useDropzone } from "react-dropzone";
import { useQuery } from "react-query";
import { getOnlineStatus } from "api/v1/chat";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import classes from "./index.module.css";
import { useUserContext } from "context/UserContext";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useMenu from "Hooks/useMenu";

const ChatSection = ({
  profile,
  messageInputRef,
  areMessagesLoading,
  sendMessage,
  sendAttachments,
  user,
  messages,
  activeChat,
  messageLoadMoreButtonRef,
  messageHasNextPage,
}) => {
  const { isVendor } = useUserContext();
  const { data: status } = useQuery(
    "onlineStatus",
    async () => {
      return getOnlineStatus(profile._id).then((onlineStatus) => {
        return onlineStatus.data;
      });
    },
    {
      refetchInterval: 300000,
      enabled: !!profile._id,
    }
  );
  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "image/*": [],
    },
    onDrop: (acceptedFiles) => sendAttachments(acceptedFiles),
    multiple: true,
    noKeyboard: true,
  });
  const navigate = useNavigate();
  const { data: upcomingEvents } = useQuery(
    "upcoming-events",
    getUpcomingEvents
  );
  // handle emoji
  const onEmojiClick = (event, emojiObject) => {
    messageInputRef.current.value =
      messageInputRef.current.value + emojiObject.emoji;
  };

  const [anchorElEmoji, setAnchorElEmoji] = React.useState();
  const handleOnEmojiButtonClick = (event) => {
    setAnchorElEmoji(anchorElEmoji ? null : event?.currentTarget);
  };

  const emojiOpen = Boolean(anchorElEmoji);
  const emojiId = emojiOpen ? "simple-popper" : undefined;
  const handleCloseEmoji = () => {
    setAnchorElEmoji(null);
  };

  function onScheduleMeetingClick() {
    navigate(`/dashboard/vendors/${profile._id}/meetings`);
  }
  function onBookNowClick() {
    navigate(`/dashboard/vendors/${profile._id}/bookings`);
  }
  return (
    <Box className={classes.root}>
      <Box className={classes.profile}>
        <Box className={classes.titleSection}>
          <Box
            sx={{
              maxWidth: "300px",
            }}
          >
            <Link href={`/dashboard/vendors/${profile?._id}`}>
              <Typography
                sx={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                }}
              >
                {" "}
                {profile?.firstName
                  ? `${profile?.firstName} ${profile.lastName}`
                  : profile.fullName}
              </Typography>
            </Link>
          </Box>
          <Box className={classes.statusSection}>
            <Box
              className={`${classes.status} ${
                !status && classes.offlineStatus
              }`}
            ></Box>
            <Typography>{status ? "Online" : "Offline"}</Typography>
          </Box>
        </Box>
        {isVendor ? null : (
          <>
            <Box
              sx={{
                display: { xxs: "none", xl: "flex" },
                flexWrap: "wrap",
                width: "100%",
                justifyContent: "flex-end",
                gap: 2,
              }}
            >
              <Button
                onClick={onScheduleMeetingClick}
                className={classes.meetingButton}
                variant="primary"
              >
                Schedule a meeting
              </Button>
              <Button variant="primary" onClick={onBookNowClick}>
                Book Now
              </Button>
            </Box>
            <Box sx={{ display: { xxs: "flex", xl: "none" } }}>
              <MobileMenu profileId={profile._id} />
            </Box>
          </>
        )}
      </Box>
      <Box className={`${classes.inbox} chat`}>
        {areMessagesLoading
          ? [1, 2, 3].map((i) => (
              <Skeleton
                variant="rect"
                height={40}
                width={`${50 / i}%`}
                style={{ margin: "30px 0" }}
                key={i}
              />
            ))
          : messages?.pages
              ?.flat()
              .map((message) => (
                <ChatHead
                  key={message._id}
                  message={message.content}
                  messageSendingTime={moment(message.createdAt).fromNow()}
                  userId={message.sender}
                  userMetaData={user}
                  attachments={message.attachments}
                />
              ))}
        <div
          ref={messageLoadMoreButtonRef}
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress
            style={{
              display: messageHasNextPage ? "block" : "none",
            }}
            size="3rem"
          />
        </div>
      </Box>
      <Box className={classes.sendMessage}>
        <Box className={classes.iconEmojie}>
          <EmojiEmotionsIcon
            ref={anchorElEmoji}
            aria-describedby={emojiId}
            onClick={handleOnEmojiButtonClick}
            style={{
              cursor: "pointer",
            }}
          />
        </Box>
        <Popper
          id={emojiId}
          open={emojiOpen}
          anchorEl={anchorElEmoji}
          disablePortal
          popperOptions={{
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [-20, 20],
                },
              },
            ],
          }}
        >
          <ClickAwayListener onClickAway={handleCloseEmoji}>
            <MainCard elevation={8} content={false}>
              <Picker
                onEmojiClick={onEmojiClick}
                skinTone={SKIN_TONE_MEDIUM_DARK}
                disableAutoFocus
              />
            </MainCard>
          </ClickAwayListener>
        </Popper>
        <Box className={classes.input}>
          <input
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                sendMessage();
              } else {
                return null;
              }
            }}
            ref={messageInputRef}
            type="text"
            placeholder="Send your message…"
          />
        </Box>
        <Box className={classes.sendMessageSection}>
          <Box style={{ cursor: "pointer" }} className={classes.addIcon}>
            <Box {...getRootProps({ className: `${classes.dropzone}` })}>
              <input {...getInputProps()} />
              <AddIcon />
            </Box>
          </Box>
          <Box className={classes.sendMessageIconSection}>
            <Box
              className={classes.sendMessageIcon}
              onClick={() => sendMessage()}
            >
              <SendIcon />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default ChatSection;

function MobileMenu({ textColorPrimary = true, profileId }) {
  const responsiveLink = [
    {
      title: "Schedule a meeting",
      url: `/dashboard/vendors/${profileId}/meetings`,
    },
    {
      title: "Book now",
      url: `/dashboard/vendors/${profileId}/bookings`,
    },
  ];

  const { open, anchorEl, handleClick, handleClose } = useMenu();

  return (
    <div>
      <IconButton
        id="menu-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreVertIcon
          sx={
            textColorPrimary === true ? { color: "#01214d" } : { color: "#fff" }
          }
        />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "menu-button",
        }}
      >
        {responsiveLink.map((x) => (
          <MenuItem dense key={x.title} onClick={handleClose}>
            <Link
              noWrap
              variant="p"
              to={{ pathname: x.url }}
              component={RouterLink}
              sx={{ p: 1, textDecoration: "none", color: "primary.light" }}
            >
              {x.title}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
