import { Box, Typography } from "@mui/material";
import * as React from "react";
import classes from "./Status.module.css";

const Status = ({ status, sx, textVariant = "body5" }) => {
  return (
    <>
      {status && (
        <Box
          className={classes.status}
          sx={{
            backgroundColor:
              status === "open"
                ? "#3888FF"
                : status === "completed"
                ? "#4FC27E"
                : status === "scheduled"
                ? "#eeedff"
                : status === "cancelled"
                ? "#FF345B"
                : "#3888FF",
            ...sx,
          }}
        >
          {status === "open" ? (
            <Typography variant={textVariant} color="#fff" fontWeight="600">
              Upcoming
            </Typography>
          ) : status === "completed" ? (
            <Typography variant={textVariant} color="#fff" fontWeight="600">
              Completed
            </Typography>
          ) : status === "scheduled" ? (
            <Typography variant={textVariant} color="#5542F6" fontWeight="600">
              Scheduled
            </Typography>
          ) : status === "cancelled" ? (
            <Typography variant={textVariant} color="#fff" fontWeight="600">
              Canceled
            </Typography>
          ) : (
            <Typography variant={textVariant} color="#fff" fontWeight="600">
              {status}
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default Status;
