import { Grid, Box, Typography } from "@mui/material";

import React from "react";
import { styled } from "@mui/system";

import dayjs from "dayjs";

const StyledBox = styled(Box)(({ theme }) => ({
  background: "#FFFFFF",
  boxShadow: "0px -3.50209px 31.5188px 11.6736px rgba(0, 0, 0, 0.05)",
  borderRadius: "9.3389px",
  width: "100%",
  height: "auto",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "auto",
  },
  [theme.breakpoints.down("xs")]: {
    width: "100%",
    height: "auto",
  },
}));

const StyledChip = styled(Box)(({ theme }) => ({
  borderRadius: "4px",
  padding: "0.5rem 0.5rem",
  color: "white !important",
  backgroundColor: theme.palette.primary.main,
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  maxWidth: "100%",
}));

const StyledTextLabel = styled(Typography)(({ theme }) => ({
  fontFamily: "Be Vietnam Pro",
  fontWeight: "500",
  lineHeight: "39px",
}));
const StyledTextValue = styled(Typography)(({ theme }) => ({
  fontFamily: "Be Vietnam Pro",
  fontWeight: "700",
  lineHeight: "30px",
  color: "#fff",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  overflow: "hidden",
  maxWidth: "100%",
}));
function BasicCard({
  data,
  title,
  subTitle,
  status,
  date,
  time,
  participant,
  serivceObject,
  ...rest
}) {
  return (
    <StyledBox {...rest}>
      <Box
        sx={{
          p: "1.5rem",
          borderRadius: "9.3389px 9.3389px 0px 0px",
          background:
            "linear-gradient(123.73deg,rgba(211, 18, 176, 1) 13%,rgba(125, 70, 224, 1) 70%,rgba(91, 0, 255, 1) 98%)",
        }}
      >
        <TopSection title={title} subTitle={subTitle} />
      </Box>

      <Box sx={{ p: "1.5rem" }}>
        <BottomSection
          date={dayjs(data?.startTime).format("DD/MM/YYYY")}
          time={dayjs(data?.startTime).format("LT")}
          participant={participant}
          serivceObject={serivceObject}
        />
      </Box>
    </StyledBox>
  );
}

export default BasicCard;

function TopSection({ title }) {
  return (
    <Grid container sx={{ minHeight: "64px" }}>
      <Grid item container rowSpacing={1.4} xxs={12}>
        <Grid item xxs={12}>
          <Typography variant="h5" color="#fff">
            {title}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

function BottomSection({ date, time, serivceObject }) {
  return (
    <Grid container rowSpacing={3}>
      {dynamicList(serivceObject)}
    </Grid>
  );
}

function dynamicList(obj) {
  const keys = Object.keys(obj);
  return keys.map((key) => {
    const check = typeof obj[key];

    if (check === "string") {
      return (
        <>
          {key === "description" ? null : (
            <>
              {" "}
              <Grid item xxs={6}>
                <StyledTextLabel variant="p600">{`${key} `}</StyledTextLabel>
              </Grid>
              <Grid item xxs={6}>
                {obj[key] && (
                  <Box sx={{ display: "flex", flex: "0 0 auto" }}>
                    <StyledChip>
                      {" "}
                      <StyledTextValue>{key}</StyledTextValue>
                    </StyledChip>
                  </Box>
                )}
              </Grid>
            </>
          )}
        </>
      );
    }
    if (check === "object") {
      const checkArray = Array.isArray(obj[key]);
      const arr = obj[key];

      if (checkArray) {
        return (
          <>
            <Grid item xxs={6}>
              <StyledTextLabel variant="p600">{key}</StyledTextLabel>
            </Grid>
            <Grid item xxs={6}>
              <Box
                sx={{
                  display: "flex",

                  flex: "0 0 auto",
                  gap: "5px",
                  width: "100%",
                  maxWidth: "100%",
                }}
              >
                {arr.map((y, i) => {
                  if (typeof y === "string") {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flex: "0 0 auto",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                        key={i + 100}
                      >
                        <StyledChip>
                          <StyledTextValue>{y}</StyledTextValue>
                        </StyledChip>
                      </Box>
                    );
                  }
                  if (typeof y === "object") {
                    const nestedValues = Object.values(y);

                    return (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                        }}
                      >
                        {nestedValues.map(
                          (z) =>
                            z && (
                              <StyledChip>
                                {" "}
                                <StyledTextValue>{z}</StyledTextValue>
                              </StyledChip>
                            )
                        )}
                      </Box>
                    );
                  }

                  return "error";
                })}
              </Box>
            </Grid>
          </>
        );
      }
    }
    return key;
  });
}
