import { Box, Typography } from "@mui/material";
import React from "react";

function Banner({ bannerImage, title, opacity = 1 }) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
      }}
    >
      <Box
        style={{
          position: "absolute",
          backgroundColor: "black",
          width: "100%",
          height: "100%",
        }}
      >
        <img
          src={bannerImage}
          alt="back"
          style={{
            objectFit: "cover",
            objectPosition: "center",
            width: "100%",
            height: "100%",
            position: "absolute",
            opacity: opacity,
          }}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexGrow: "1",
          textAlign: "center",
          color: "white",
          width: "100%",
          height: "100%",
        }}
      >
        <Typography
          variant="h1"
          sx={{ fontSize: { lg: "92px" }, color: "white" }}
        >
          {title}
        </Typography>
      </Box>
    </Box>
  );
}

export default Banner;
