import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import DashboardCard from "components/styled/DashboardCard";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import * as React from "react";
import { createMeeting, updateMeeting } from "api/v1/meeting";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import BottomSection from "./comp/BottomSection";
import TopSection from "./comp/TopSection";
import messages from "messages";
import dayjs from "dayjs";
import { URL_REGEX } from "constraints";
import { getMeeting } from "api/v1/meeting";
import { denormalizeDate, normalizeDate } from "utilities";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

function MeetingCreate() {
  const { vendorId, id } = useParams();

  const { data: meetingDetail } = useQuery(
    ["meetings", id],
    async () => {
      const res = await getMeeting({ id });
      return res.data;
    },
    {
      enabled: Boolean(id),
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    }
  );

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    watch,
    register,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: "",
      description: "",
      people: "",
      meetingType: "",
      timeZone: "",
      startTime: {
        date: null,
        hours: "00",
        min: "00",
        ampm: "AM",
      },
      link: "",
      venue: "",
    },
    resolver: yupResolver(schema),
  });

  const { mutateAsync, isLoading: isSubmitting } = useMutation(
    async ({ body }) => {
      if (id) return await updateMeeting({ body, id });
      else return await createMeeting({ body });
    },
    {
      onError: (err) => {
        toast.error(err.errMsg);
      },
    }
  );

  const navigate = useNavigate();

  React.useEffect(() => {
    if (meetingDetail && Object.keys(meetingDetail)?.length > 0) {
      const meetingType = meetingDetail?.link ? "virtual" : "inPerson";

      reset({
        meetingType,
        title: meetingDetail?.title,
        people: meetingDetail?.people.join(),
        description: meetingDetail?.description,
        timeZone: meetingDetail?.timeZone,
        startTime: denormalizeDate(meetingDetail?.startTime),
        link: meetingDetail?.link ?? "",
        venue: meetingDetail?.venue ?? "",
      });
    }
  }, [meetingDetail]);

  async function onSubmit(data) {
    const normalizeStartDate = normalizeDate(data.startTime);
    const startTime = dayjs(data.startTime.date)
      .hour(normalizeStartDate.split(":")[0])
      .minute(normalizeStartDate.split(":")[1])
      .second(0)
      .millisecond(0);
    const body = {
      ...data,
      people: data?.people?.split(",")?.map((p) => p?.trim()),
      startTime,
      vendorId,
      link: data?.meetingType === "inPerson" ? "" : data?.link,
      venue: data?.meetingType === "virtual" ? "" : data?.venue,
    };

    const res = await mutateAsync({
      body,
    });
    navigate(`/dashboard/meetings/${res.data._id}`);
  }

  return (
    <Container sx={{ py: "1rem" }}>
      <Box sx={{ py: "1.4rem" }}>
        <Typography variant="h4" sx={{ fontFamily: "Playfair Display" }}>
          {id ? "Edit Meeting" : "Create Meeting"}
        </Typography>
      </Box>
      <Box>
        <DashboardCard sx={{ p: "2rem" }}>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <Grid container rowSpacing={6}>
              <Grid item xxs={12}>
                <TopSection control={control} errors={errors} watch={watch} />
              </Grid>
              <Grid item xxs={12}>
                <BottomSection
                  control={control}
                  register={register}
                  getValues={getValues}
                  setValue={setValue}
                  errors={errors}
                />
              </Grid>
              <Grid item xxs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Box mr={"24px"}>
                    <Button
                      variant="secondary"
                      onClick={() => navigate("/dashboard/meetings")}
                    >
                      Cancel
                    </Button>
                  </Box>
                  <Box>
                    <LoadingButton
                      loading={isSubmitting}
                      variant="primary"
                      type="submit"
                    >
                      {id ? "Update Meeting" : "Create Meeting"}
                    </LoadingButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </DashboardCard>
      </Box>
    </Container>
  );
}

export default MeetingCreate;

const schema = yup
  .object({
    title: yup.string().required(messages.requiredField),
    description: yup.string().required(messages.requiredField),
    timeZone: yup.string().required(messages.requiredField),
    venue: yup.string().when("meetingType", {
      is: (val) => val === "inPerson",
      then: yup.string().required(messages.requiredField),
    }),
    link: yup.string().when("meetingType", {
      is: (val) => val === "virtual",
      then: yup
        .string()
        .matches(URL_REGEX, messages.invalidURL)
        .required(messages.requiredField),
    }),
    startTime: yup.object({
      date: yup
        .date()
        .required(messages.requiredField)
        .typeError(messages.invalidValue)
        .nullable(),
      hours: yup
        .string()
        .test("notZero", messages.invalidTime, (v) => v !== "00")
        .required(messages.requiredField),
    }),
  })
  .required();
