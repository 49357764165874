import { Box, Typography, Menu, MenuItem } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";

const ITEM_HEIGHT = 35;

const StyledDiv = styled("div")(({ theme }) => ({
  border: "1.2619px solid #504E4E",
  borderRadius: "5.04762px",
  maxWidth: "75.71px",
  maxHeight: "64.36px",
  minWidth: "75px",
  minHeight: "64px",
  backgroundColor: "#fff",
  textAlign: "center",
  padding: "1rem",
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "28px",
  cursor: "pointer",
}));

function FormTimeSelect({
  name,
  label,
  options,
  register,
  setValue,
  getValues,
  errors,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  let val = getValues(name);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setValue(name, event.target.dataset.value);
    setAnchorEl(null);
  };
  const handleCloseMenu = (event) => {
    setAnchorEl(null);
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        jutifyContent: "center",
      }}
    >
      <Box onClick={handleClick}>
        <StyledDiv>{val}</StyledDiv>
        <input
          style={{ cursor: "pointer" }}
          type="hidden"
          value={val}
          {...register(name)}
        />
      </Box>
      <Box>
        {" "}
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: "auto",
            },
          }}
        >
          {options.map((x, i) => (
            <MenuItem key={i} data-value={x} onClick={handleClose}>
              {x}
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          maxWidth: "75.71px",
          mt: "0.5rem",
        }}
      >
        <Typography
          variant="p"
          color="textPrimary"
          style={{ fontWeight: "600", fontSize: "0.875rem" }}
        >
          {label}
        </Typography>
      </Box>
    </Box>
  );
}

export default FormTimeSelect;
