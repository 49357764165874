import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Grid } from "@mui/material";
import { checkUsernameAvailability, signup } from "api/v1/auth";
import FormDialog from "components/Dialog/FormDialog";
import FormInputText from "components/forms/FormInputText";
import FormNumberInput from "components/forms/FormNumberInput";
import GooglePlacesAutoComplete from "components/GooglePlacesAutoComplete";
import { PASSWORD_MIN_LENGTH, PASSWORD_REGEX } from "constraints";
import messages from "messages";
import React from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useMutation } from "react-query";
import { createEvent } from "api/v1/event";
import { useUserContext } from "context/UserContext";
import { USER_TYPE } from "constants";
import { useNavigate } from "react-router-dom";

export default function SignUpDialog({ open, setOpen, formData }) {
  const navigate = useNavigate();
  const { setToken, isAuthenticated } = useUserContext();
  const [showPass, setShowPass] = React.useState(false);
  const [showPassConfirm, setShowPassConfirm] = React.useState(false);
  const submitOnce = React.useRef(false);
  const [loadingForm, setLoadingForm] = React.useState(false);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      passwordConfirm: "",
      firstName: "",
      lastName: "",
      telephoneNumber: "",
      email: "",
      location: "",
    },
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    if (isAuthenticated) {
      if (!submitOnce.current) {
        submitOnce.current = true;
        submitEvent({ body: formData });
      }
    }
  }, [isAuthenticated]);

  const { mutate: submitEvent, isLoading: eventSubmitting } = useMutation(
    createEvent,
    {
      onSuccess: () => {
        setLoadingForm(false);
        setOpen(false);
        navigate("/dashboard/events", { replace: true });
      },
    }
  );

  const { mutateAsync: signUpUserAsync, isLoading: signingUpUser } =
    useMutation(signup);

  const handleClickShowPass = () => {
    setShowPass(!showPass);
  };
  const handleClickShowPassConfirm = () => {
    setShowPassConfirm(!showPassConfirm);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const onSubmit = async (data) => {
    try {
      setLoadingForm(true);
      const res = await signUpUserAsync({
        userType: USER_TYPE.CUSTOMER,
        body: { ...data },
      });
      setToken(res.data.token);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <FormDialog
      open={open}
      handleClose={handleClose}
      title="Customer Sign Up"
      btnText="Submit"
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      loadingForm={loadingForm}
    >
      <Box>
        <Grid container columnSpacing={10} rowSpacing={4}>
          <Grid item xxs={12} sm={6}>
            <FormInputText
              label="First Name"
              name="firstName"
              placeholder="John"
              control={control}
              errors={errors?.firstName?.message}
            />
          </Grid>
          <Grid item xxs={12} sm={6}>
            <FormInputText
              label="Last Name"
              name="lastName"
              placeholder="Doe"
              control={control}
              errors={errors?.lastName?.message}
            />
          </Grid>
          <Grid item xxs={12} sm={6}>
            <FormInputText
              label="Username"
              name="username"
              placeholder="John Doe"
              control={control}
              errors={errors?.username?.message}
            />
          </Grid>
          <Grid item xxs={12} sm={6}>
            <FormInputText
              label="Email"
              name="email"
              placeholder="sample@user.com"
              control={control}
              errors={errors?.email?.message}
            />
          </Grid>
          <Grid item xxs={12} sm={6}>
            <FormInputText
              label="Add Password"
              name="password"
              icon={VisibilityOff}
              iconToggle={Visibility}
              toggle={showPassConfirm}
              handleClick={handleClickShowPassConfirm}
              position="start"
              positionEdge="start"
              adornment="endAdornment"
              control={control}
              errors={errors?.password?.message}
            />
          </Grid>
          <Grid item xxs={12} sm={6}>
            <FormInputText
              label="Retype Password"
              name="passwordConfirm"
              icon={VisibilityOff}
              iconToggle={Visibility}
              toggle={showPass}
              handleClick={handleClickShowPass}
              position="start"
              positionEdge="start"
              adornment="endAdornment"
              control={control}
              errors={errors?.passwordConfirm?.message}
            />
          </Grid>

          <Grid item xxs={12} sm={6}>
            <FormNumberInput
              control={control}
              name="telephoneNumber"
              label="Phone Number"
              errors={errors?.telephoneNumber?.message}
              primary={true}
            />
          </Grid>

          <Grid item xxs={12} sm={6}>
            <GooglePlacesAutoComplete
              control={control}
              errors={errors}
              name="location"
              setFormValue={setValue}
              label="Address"
              customHeight="56px"
              style={{
                borderRadius: "123px",
                boxShadow: "0px 4px 10px 3px rgba(0, 0, 0, 0.11)",
                "& .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #01214D",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "primary.light",
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </FormDialog>
  );
}

const schema = yup
  .object({
    username: yup
      .string()
      .min(4)
      .required()
      .test(
        "username_async_validation",
        messages.usernameNotAvailable,
        async function (username) {
          const res = await checkUsernameAvailability({ username });
          if (res.data.isAvailable) {
            return this.resolve(res.data.isAvailable);
          } else {
            return this.createError({
              message: messages.usernameNotAvailable,
            });
          }
        }
      ),
    password: yup
      .string()
      .matches(PASSWORD_REGEX, messages.invalidPassword)
      .min(PASSWORD_MIN_LENGTH, messages.passwordLength)
      .required(messages.requiredField),
    passwordConfirm: yup
      .string()
      .required(messages.requiredField)
      .oneOf([yup.ref("password")], "Your passwords do not match."),
    firstName: yup
      .string()
      .required(messages.requiredField)
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    lastName: yup
      .string()
      .required(messages.requiredField)
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    telephoneNumber: yup
      .string()
      .min(7, "Enter a valid Phone number")
      .max(15, "Enter a valid Phone number")
      .required(messages.requiredField),
    email: yup.string().email().required(messages.requiredField),
    location: yup
      .object()
      .shape({
        type: yup.string().required(),
        address: yup.string().required(),
        state: yup.string().required(),
        country: yup.string().required(),
        coordinates: yup.array().of(yup.number()).required(),
      })
      .nullable()
      .required(messages.requiredField),
  })
  .required();
