import React from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";

function CustomInput({
  borderColor,
  iconProps,
  focusedBorderColor,
  height,
  adornment,
  position,
  Icon,
  textInputStyle = {},
  ...rest
}) {
  return (
    <TextField
      variant="outlined"
      sx={{
        width: "100%",
        "& .MuiOutlinedInput-root": {
          width: "100%",
          height: height || "auto",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: borderColor || "#DEDEDE",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: focusedBorderColor || "primary.light",
        },
        ...textInputStyle,
      }}
      InputProps={{
        [adornment]: (
          <InputAdornment position={position}>
            {<Icon {...iconProps} />}
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
}

export default CustomInput;
