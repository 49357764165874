import * as React from "react";
const NavStyleContext = React.createContext();

export function NavStyleProvider({ children }) {
  const [textColorPrimary, setTextColorPrimary] = React.useState(false);

  return (
    <NavStyleContext.Provider
      value={{
        textColorPrimary,
        setTextColorPrimary,
      }}
    >
      {children}
    </NavStyleContext.Provider>
  );
}

export function useNavStyleContext() {
  const context = React.useContext(NavStyleContext);
  if (context === undefined) {
    throw new Error(
      "useNavStyleContext can only be used inside NavStylesProvider"
    );
  }
  return context;
}
