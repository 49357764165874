import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useMutation } from "react-query";

export default function useStripePay({
  fn,
  setIsLoading = () => {},
  setIsCompleted = () => {},
  customerName = "Jenny Rosen",
}) {
  const stripe = useStripe();
  const elements = useElements();

  return useMutation(async (params) => {
    if (!stripe || !elements) {
      return;
    }

    // Call payment intent API
    setIsLoading(true);
    try {
      var paymentIntentRes = await fn(params);
    } catch (err) {
      setIsLoading(false);
    }

    if (!paymentIntentRes) {
      return;
    }

    const result = await stripe.confirmCardPayment(
      paymentIntentRes.data.client_secret,
      {
        payment_method: {
          card: elements.getElement(CardElement),
          billing_details: {
            name: customerName,
          },
        },
      }
    );

    setIsLoading(false);
    if (result.error) {
      // Show error to your customer (for example, insufficient funds)
      console.log(result.error.message);
    } else {
      // The payment has been processed!
      if (result.paymentIntent.status === "succeeded") {
        setIsCompleted(true);
        // Show a success message to your customer
        // There's a risk of the customer closing the window before callback
        // execution. Set up a webhook or plugin to listen for the
        // payment_intent.succeeded event that handles any business critical
        // post-payment actions.
      }
    }
  });
}
