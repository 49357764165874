import React from "react";
import { Typography, Box, Grid, FormHelperText } from "@mui/material";
import FormTimeSelect from "components/forms/FormTimeSelect";
import { ReactComponent as TimeIcon } from "assets/dashboard/meeting/time.svg";

import {
  ampm,
  hours,
  min,
} from "views/pages/Event/EventPage/PlanPage/forms/data/list/detailData";
import FormInputSelect from "components/forms/FormInputSelect";
import FormInputDatePicker from "components/forms/FormInputDatePicker";
import dayjs from "dayjs";
import Delayed from "components/Delayed/Delayed";
function BottomSection({ register, setValue, getValues, control, errors }) {
  const options = [
    {
      label: "AST",
      value: "AST",
    },
    {
      label: "EST",
      value: "EST",
    },
    {
      label: "PST",
      value: "PST",
    },
    {
      label: "CST",
      value: "CST",
    },
  ];

  const dateDefaultValue = getValues("startTime.date");
  return (
    <Grid container rowSpacing={3}>
      <Grid item xxs={12}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <TimeIcon />
          </Box>
          <Box sx={{ pl: "0.5rem" }}>
            <Typography variant="h5">Time of the Meeting</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item container rowSpacing={2} xxs={6}>
        <Grid item xxs={12} sx={{ textAlign: "left" }}>
          <Typography variant="p600" color="textPrimary">
            Start Time
          </Typography>
        </Grid>
        <Grid item xxs={12} sm md={3}>
          <FormTimeSelect
            register={register}
            name="startTime.hours"
            label="Hours"
            setValue={setValue}
            getValues={getValues}
            options={hours}
          />
        </Grid>

        <Grid item xxs={12} sm md={3}>
          <FormTimeSelect
            register={register}
            name="startTime.min"
            label="Minutes"
            setValue={setValue}
            getValues={getValues}
            options={min}
          />
        </Grid>
        <Grid item xxs={12} sm md={3}>
          <FormTimeSelect
            register={register}
            name="startTime.ampm"
            label="AM/PM"
            setValue={setValue}
            getValues={getValues}
            options={ampm}
          />
        </Grid>
      </Grid>
      <Grid item xxs={12}>
        {errors?.startTime?.hours?.message && (
          <FormHelperText error>
            {errors?.startTime?.hours?.message}
          </FormHelperText>
        )}
      </Grid>
      <Grid item xxs={12}>
        <FormInputSelect
          name="timeZone"
          label="Time Zone"
          options={options}
          control={control}
          placeholder="Eastern Time (US and Canada)"
          width={{ xxs: "100%", sm: "80%", md: "60%" }}
        />
        {errors?.timeZone?.message && (
          <FormHelperText error>{errors?.timeZone?.message}</FormHelperText>
        )}
      </Grid>
      <Grid item xxs={12}>
        <Box sx={{ width: { xxs: "100%", sm: "80%", md: "60%" } }}>
          <Delayed>

            <FormInputDatePicker
              name="startTime.date"
              label="Add Date"
              defaultValue={dateDefaultValue ? dateDefaultValue : null}
              minDate={dayjs(new Date())}
              control={control}
              errors={errors?.startTime?.date?.message}
            />
          </Delayed>
        </Box>
      </Grid>
    </Grid>
  );
}

export default BottomSection;
