import * as React from "react";

// To temporarily hold event data if the vendor is not logged in
const LoginContext = React.createContext();

export function LoginProvider({ children }) {
  const [loginModal, setLoginModal] = React.useState(false);

  return (
    <LoginContext.Provider
      value={{
        loginModal,
        setLoginModal,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
}

export function useLoginContext() {
  const context = React.useContext(LoginContext);
  if (context === undefined) {
    throw new Error("useLoginContext can only be used inside LogoutProvider");
  }
  return context;
}
