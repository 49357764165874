import { Box, Container, Grid, Typography } from "@mui/material";
import backgroundImage from "assets/login/login.svg";
import React from "react";
import LoginForm from "./LoginForm";

function LoginPage() {
  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        backgroundColor: "black",
        zIndex: "1",
        flexGrow: "1",
      }}
    >
      <img
        src={backgroundImage}
        alt="sign-up-page"
        style={{
          objectFit: "cover",
          objectPosition: "center",
          position: "absolute",
          width: "100%",
          height: "100%",
          opacity: "0.5",
          zIndex: "-2",
        }}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#000",
          opacity: "0.3",
          display: "flex",
          position: "absolute",
          zIndex: "-1",
        }}
      />
      <Box sx={{ pt: "7rem" }}>
        <Container
          sx={{
            mt: 7,
            mb: 3,
          }}
          maxWidth="xl"
        >
          <Grid container>
            <Grid item sm={12} md={6}>
              <Box
                sx={{
                  pt: 5,
                  pl: 4,
                  pr: 2,
                  pb: 1,
                  textAlign: { xxs: "center", md: "left" },
                }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: {
                      xxs: "2.2rem",
                      md: "3rem",
                      lg: "3.5rem",
                    },
                    pb: 1,
                    lineHeight: { xxs: "50px", md: "70px" },
                    fontWeight: "800",
                  }}
                  variant="h1"
                >
                  Your Dream Event Starts Here
                </Typography>
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: { xxs: "1rem", md: "1.5rem" },
                    lineHeight: "40px",
                    fontWeight: "500",
                  }}
                  variant="p"
                >
                  ThePartyStarter helps you organize, create, and manage
                  important events and preserve your happiness. It's easy to get
                  started.
                </Typography>
              </Box>
            </Grid>
            <Grid item container sm={12} md={6} justifyContent="center">
              <Grid sx={{ maxWidth: "600px" }}>
                <LoginForm />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

export default LoginPage;
