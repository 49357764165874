// Custom hook to fetch images
import { useQuery } from "react-query";
import { getImage } from "api/v1/image";

export default function useImage({
  key,
  id = key,
  enabled = Boolean(key),
  ...queryConfig
}) {
  return useQuery(
    ["image", id],
    async () => {
      const res = await getImage({ key });
      return res.data;
    },
    {
      staleTime: Infinity,
      refetchInterval: 840000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      enabled,
      ...queryConfig,
    }
  );
}
