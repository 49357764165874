import * as React from "react";
import classes from "./VendorDetailCard.module.css";
import { Box, Button, Rating, Typography } from "@mui/material";
import large from "assets/icon/large.svg";
import { Link } from "react-router-dom";
import profile from "assets/dashboard/myEvent/profile.webp";
import useImage from "Hooks/useImage";

const VendorDetailCard = ({
  profileImage,
  userName,
  profileLink = "#",
  rating,
  priceOffered,
  quoteId,
  hiredLink = "#",
  seeFullQuoteLink = "$",
  status,
}) => {
  const { data: profilePhoto } = useImage({
    key: profileImage,
  });

  const buttonStatus = {
    label: "",
    color: "",
  };

  switch (status) {
    case "pending":
      buttonStatus.label = "Pending";
      buttonStatus.color = "warning.light";
      break;
    case "disputed":
      buttonStatus.label = "Disputed";
      buttonStatus.color = "error.light";
      break;
    case "compeleted":
      buttonStatus.label = "Hired";
      buttonStatus.color = "success.light";
      break;
    default:
      buttonStatus.label = "Pending";
      buttonStatus.color = "warning.light";
  }
  return (
    <Box className={classes.root} sx={{ rowGap: 1 }}>
      <Box className={classes.profileDetail}>
        <Box>
          <img
            src={profilePhoto ?? profile}
            alt="profile"
            className={classes.profile}
          />
        </Box>
        <Box>
          <Typography variant="body6" mr={1} fontWeight={600}>
            {userName}
          </Typography>
          <Link to={profileLink}>
            <img src={large} alt="large" style={{ cursor: "pointer" }} />
          </Link>
          <Box className={classes.rating}>
            <Rating
              name="half-rating-read"
              value={rating}
              precision={0.5}
              readOnly
            />
          </Box>
        </Box>
      </Box>
      <Box>
        <Typography variant="smallText" color="#E801BE">
          Price Offered
        </Typography>
        <Typography variant="body6" component="h4">
          $ {priceOffered}
        </Typography>
      </Box>
      <Box className={classes.btnContainer}>
        <Box>
          <Box
            component="div"
            sx={{
              cursor: "default",
              backgroundColor: buttonStatus.color,
              fontFamily: "Poppins",
              fontWeight: 600,
              fontSize: { xxs: "1rem", sm: "1.2rem" },
              padding: { xxs: "6px 25px", sm: "6px 30px" },
              borderRadius: "6.6px",
              color: "#ffffff",
            }}
          >
            {buttonStatus.label}
          </Box>
        </Box>
        {/* {quoteId && (
          <Box>
            <Link to={seeFullQuoteLink}>
              <Button variant="primary">See Full Quote</Button>
            </Link>
          </Box>
        )} */}
      </Box>
    </Box>
  );
};

export default VendorDetailCard;
