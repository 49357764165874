import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import {
  destroySDKScript,
  getScriptID,
  PayPalScriptProvider,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { ReactComponent as TimeIcon } from "assets/dashboard/meeting/time.svg";
import { useUserContext } from "context/UserContext";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import PaypalButton from "./PaypalButton";
import StripeButton from "./StripeButton";

export default function PaymentForm({
  bookingId,
  partiallyPaid,
  paymentServicesStatus,
}) {
  const [percentage, setPercentage] = React.useState(100);
  const [loaded, setLoaded] = React.useState(false);
  const [isCompleted, setIsCompleted] = React.useState();
  const navigate = useNavigate();

  const {
    user: { fullName },
  } = useUserContext();

  function onViewDetailsClick() {
    navigate(`/dashboard/bookings/${bookingId}`);
  }

  return (
    <Grid container rowSpacing={3}>
      <Grid item xxs={12}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <TimeIcon />
          </Box>
          <Box sx={{ pl: "0.5rem" }}>
            <Typography variant="h5">Payment</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xxs={12}>
        {isCompleted ? (
          <div>
            <Typography variant="h5" sx={{ my: "20px" }}>
              Thank you for your payment!
            </Typography>
            <Button variant="primary" onClick={onViewDetailsClick}>
              View booking details
            </Button>
          </div>
        ) : (
          <Box>
            {!partiallyPaid && (
              <Box>
                <Typography variant="h6" sx={{ mb: "20px" }}>
                  Select Deposit amount
                </Typography>
                <Box display="flex">
                  {[25, 50, 75, 100].map((item) => (
                    <Button
                      onClick={() => setPercentage(item)}
                      variant={percentage === item ? "contained" : "outlined"}
                    >
                      {item}%
                    </Button>
                  ))}
                </Box>
              </Box>
            )}
            {paymentServicesStatus?.stripe ? (
              <Box>
                <Typography variant="h6" sx={{ mb: "20px" }}>
                  Pay with Card
                </Typography>
                <StripeButton
                  bookingId={bookingId}
                  amount={50}
                  showSpinner={false}
                  setIsCompleted={setIsCompleted}
                  percentage={percentage}
                  customerName={fullName}
                />
              </Box>
            ) : null}
            {paymentServicesStatus?.stripe && paymentServicesStatus?.paypal ? (
              <Divider>OR</Divider>
            ) : null}
            {paymentServicesStatus?.paypal ? (
              <Box>
                <Typography variant="h6" sx={{ my: "20px" }}>
                  Pay with Paypal
                </Typography>
                <PayPalScriptProvider
                  options={{
                    "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                    components: "buttons",
                    currency: "USD",
                    "disable-funding": "venmo,paylater",
                  }}
                >
                  <LoadScriptButton loaded={loaded} setLoaded={setLoaded} />
                  {loaded && (
                    <PaypalButton
                      bookingId={bookingId}
                      amount={50}
                      showSpinner={false}
                      setIsCompleted={setIsCompleted}
                      percentage={percentage}
                    />
                  )}
                </PayPalScriptProvider>
              </Box>
            ) : null}
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

const LoadScriptButton = ({ loaded, setLoaded }) => {
  const [{ isResolved }, dispatch] = usePayPalScriptReducer();

  React.useEffect(() => {
    if (isResolved) {
      if (!loaded) setLoaded(true);
    }
  }, [isResolved]);

  React.useEffect(() => {
    if (isResolved) return;
    dispatch({
      type: "setLoadingStatus",
      value: "pending",
    });
    return () => {
      destroySDKScript(
        getScriptID({
          "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
          components: "buttons",
          currency: "USD",
          "disable-funding": "venmo,paylater",
        })
      );
      dispatch({
        type: "setLoadingStatus",
        value: "initial",
      });
    };
  }, []);

  return <></>;
};
