import React from "react";
import {
  Box,
  Button,
  ButtonGroup,
  CircularProgress,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getUpcomingEvents } from "api/v1/event";
import { getVendors } from "api/v1/vendor";
import LeftMiddleSubSection1 from "./sub/middle/LeftMiddleSubSection1";
import LeftMiddleSubSection2 from "./sub/middle/LeftMiddleSubSection2";
import RightMiddleSubSection from "./sub/middle/RightMiddleSubSection";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import useImage from "Hooks/useImage";
import StatsCard1 from "components/Cards/StatsCard1";
import StatsCardSkeleton from "components/Skeleton/StatsCardSkeleton";

function MiddleSection({
  upComingEvent,
  isVendor,

  stats = null,
}) {
  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      <Grid item xxs={12} lg={7.5} xl={8}>
        <LeftMiddleSection upComingEvent={upComingEvent} />
      </Grid>
      <Grid item xxs={12} lg={4.5} xl={4}>
        {isVendor ? (
          <VendorRightMiddleSection stats={stats} />
        ) : (
          <RightMiddleSection isVendor={isVendor} stats={stats} />
        )}
      </Grid>
    </Grid>
  );
}

export default MiddleSection;

function LeftMiddleSection({ upComingEvent: { data, isLoading } }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          pb: "1rem",
        }}
      >
        <Typography variant="h4" sx={{ fontFamily: "Playfair Display" }}>
          Upcoming Event
        </Typography>

        {data && data.length > 0 && (
          <Link to="/dashboard/events">
            <Button variant="text"> View All</Button>
          </Link>
        )}
      </Box>
      {isLoading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress size="3rem" />
        </div>
      ) : data && data?.length > 0 ? (
        <Paper
          sx={{ p: "1rem", boxShadow: " 0px -3px 27px rgba(0, 0, 0, 0.05)" }}
        >
          <Grid container rowSpacing={1} columnSpacing={1.5}>
            <Grid item xxs={12} sm>
              <LeftMiddleSubSection1
                data={Array.isArray(data) ? data[0] : data}
              />
            </Grid>
            <Grid item xxs={12} sm>
              <LeftMiddleSubSection2
                data={Array.isArray(data) ? data[0] : data}
              />
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6" sx={{ fontFamily: "Playfair Display" }}>
            No Upcoming Event
          </Typography>
        </Box>
      )}
    </Box>
  );
}

function RightMiddleSection() {
  const { data: topVendors, isLoading } = useQuery("topVendors", async () => {
    const { data } = await getVendors({ limit: 4, sortBy: "rating" });
    return data;
  });

  return (
    <Box>
      <Box
        sx={{
          pb: "1rem",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          columnGap: 1,
          rowGap: 1,
          justifyContent: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Playfair Display",
            }}
          >
            Top Vendors
          </Typography>
        </Box>
      </Box>
      <Box>
        {isLoading ? (
          <div
            style={{
              display: "flex",
              width: "100%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgress size="3rem" />
          </div>
        ) : topVendors?.length > 0 ? (
          <Grid container rowSpacing={3}>
            {topVendors.map((item, index) => (
              <Grid item xxs={12} key={index}>
                <RightMiddleSubSection {...item} />
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography
            variant="h6"
            sx={{ textAlign: "center", fontFamily: "Playfair Display" }}
          >
            No Vendors
          </Typography>
        )}
      </Box>
    </Box>
  );
}

function VendorRightMiddleSection({ stats }) {
  const [selectedButton, setSelectedButton] = React.useState("Total");
  const areStatsLoaded =
    stats?.total?.totalLoading ||
    stats?.daily?.dailyLoading ||
    stats?.monthly?.monthlyLoading ||
    stats?.yearly?.yearlyLoading;
  return (
    <Box>
      <Box
        sx={{
          pb: "1rem",
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
          columnGap: 1,
          rowGap: 1,
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              fontFamily: "Playfair Display",
              textAlign: "center",
            }}
          >
            Statistics
          </Typography>
        </Box>

        <Box sx={{ display: "flex", columnGap: 1, justifyContent: "center" }}>
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
            sx={{
              fontSize: "1rem",
              backgroundColor: "#fff",
              color: "primary.main",
            }}
          >
            {["Total", "Day", "Month", "Year"].map((x) => (
              <StatsButton
                key={x}
                label={x}
                selectedButton={selectedButton}
                onClick={() => setSelectedButton(x)}
              />
            ))}
          </ButtonGroup>
        </Box>
      </Box>
      <Box>
        {areStatsLoaded ? (
          <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
            {[1, 2, 3, 4, 5].map((x) => (
              <StatsCardSkeleton key={x} />
            ))}
          </Box>
        ) : (
          <StatsCardsWrapper
            selectedButton={selectedButton}
            totalStats={stats?.total?.total}
            dayStats={stats?.daily?.daily}
            monthStats={stats?.monthly?.monthly}
            yearStats={stats?.yearly?.yearly}
          />
        )}
      </Box>
    </Box>
  );
}

function StatsButton({ label, selectedButton, onClick }) {
  const theme = useTheme();
  const matchesUp = useMediaQuery(theme.breakpoints.up("lg"));
  const matchesDown = useMediaQuery(theme.breakpoints.down("xl"));
  const styleCondition = selectedButton === label;
  return (
    <Button
      sx={{
        minWidth: matchesUp && matchesDown ? "50px" : "40px",
        color: styleCondition ? "#fff" : "inherit",
        backgroundColor: styleCondition ? "primary.main" : "#fff",
        "&:hover": {
          color: "#fff",
        },
      }}
      onClick={onClick}
    >
      {label}
    </Button>
  );
}

function StatsCardsWrapper({
  selectedButton,
  totalStats,
  dayStats,
  monthStats,
  yearStats,
}) {
  const cardHeaders = [
    { label: "Events", value: "events" },
    { label: "Upcoming Events", value: "upcomingEvents" },
    { label: "Quotes", value: "quotes" },
    { label: "Bookings", value: "bookings" },
  ];
  return (
    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
      {cardHeaders.map(({ label, value }) => (
        <StatsCard1
          label={label}
          selectedButton={selectedButton}
          totalValue={totalStats[value]}
          dailyValue={dayStats[value]}
          monthlyValue={monthStats[value]}
          yearlyValue={yearStats[value]}
        />
      ))}
    </Box>
  );
}
