import { Box, Typography } from "@mui/material";
import React from "react";

function Policy({ cancellationPolicy }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
      <Box>
        <Typography variant="body6" color="primary">
          Cancellation Policy
        </Typography>
      </Box>
      <Box sx={{}}>
        {cancellationPolicy ? (
          <Typography
            variant="smallP"
            sx={{
              fontWeight: "500",
              fontFamily: "Be Vietnam Pro",
              lineHeight: "28px",
            }}
          >
            {cancellationPolicy}
          </Typography>
        ) : (
          <Typography variant="h5">You have no cancellation policy.</Typography>
        )}
      </Box>
    </Box>
  );
}

export default Policy;
