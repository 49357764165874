import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./FormNumberInput.css";
import { FormControl, FormLabel, FormHelperText } from "@mui/material";
import { Controller } from "react-hook-form";

const MyField = ({ value, onChange }, primary) => {
  return (
    <PhoneInput
      country={"us"}
      value={value}
      onChange={onChange}
      containerStyle={{
        width: "100%",
      }}
      inputStyle={{
        background: "#FFFFFF",
        border: primary ? "1px solid #01214D" : "",
        boxSizing: "border-box",
        boxShadow: primary ? "0px 4px 10px 3px rgba(0, 0, 0, 0.11)" : "",
        borderRadius: "123px",
        fontFamily: "Be Vietnam pro",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "16px",
        lineHeight: "20px",
        width: "100%",
        height: primary ? "56px" : "45px",
      }}
      dropdownStyle={{
        textAlign: "left",
      }}
      buttonStyle={{
        backgroundColor: "transparent",
        border: "none",
        position: "absolute",
        left: "5%",
        boxSizing: "border-box",
        borderRadius: "123px",
      }}
    />
  );
};

function FormNumberInput({ name, control, label, errors, primary }) {
  return (
    <FormControl sx={{ display: "flex", maxWidth: "100%" }}>
      <FormLabel
        id="input-label"
        sx={{
          fontWeight: "600",
          fontSize: "18px",
          lineHeight: "26px",
          color: "#01214D",
          textAlign: "left",
          marginBottom: "5px",
        }}
      >
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        render={({ field }) => MyField(field, primary)}
      />
      <FormHelperText error>{errors ? errors : null}</FormHelperText>
    </FormControl>
  );
}

export default FormNumberInput;
