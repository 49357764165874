import { Box } from "@mui/system";
import Banner from "components/banner/Banner";
import React from "react";
import bannerImage from "assets/login/login.svg";
import { Outlet } from "react-router-dom";
import Footer from "components/navigation/footer/Footer";

export default function Event() {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Box sx={{ height: "600px" }}>
        <Banner
          bannerImage={bannerImage}
          title="Let’s Plan Your Dream Event"
          opacity={0.5}
        />
      </Box>
      <Box>
        <Outlet />
      </Box>
      <Box>
        <Footer />
      </Box>
    </Box>
  );
}
