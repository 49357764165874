import { Grid } from "@material-ui/core";
import { getServices } from "api/v1/service";
import ServiceCheckList from "components/ServiceCheckList/ServiceCheckList";
import { cloneDeep } from "lodash";
import * as React from "react";
import { useQuery } from "react-query";
import { CircularProgress } from "@material-ui/core";
import { finalServicesData } from "utils/finalServicesData";
import { findService } from "utils/findService";
import { servicesData } from "../ServiceCheckList/serviceData";
import florist from "../../assets/services/florist.png";
import entertainment from "../../assets/services/entertainment.png";
import venue from "../../assets/services/venue.png";
import security from "../../assets/services/security.png";
import transportation from "../../assets/services/transportation.png";
import photo from "../../assets/services/photo.png";
import decorator from "../../assets/services/decorator.png";
import cake from "../../assets/services/cake.png";
import dj from "../../assets/services/dj.png";
import caterer from "../../assets/services/dj.png";
import stationary from "../../assets/services/stationary.png";
import glam from "../../assets/services/glam.png";
import speaker from "../../assets/services/speaker.png";
import setup from "../../assets/services/setup.png";
import assistantce from "../../assets/services/assistance.png";

import { convertIntoSmall } from "utils/convertIntoSmall";

const EventCheckList = ({ setValue, prevServiceData }) => {
  const [state, setState] = React.useState(prevServiceData ?? []);

  const { data: serviceData, isLoading } = useQuery(
    "services",
    async () => {
      const data = await getServices({ queryParams: { limit: 15 } });
      return data.data;
    },
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );
  const handleRemoveService = (serviceId) => {
    let oldData = state?.findIndex((data, index) => {
      return data.id === serviceId;
    });

    if (oldData >= 0) {
      const clone = cloneDeep(state);
      clone.splice(oldData, 1);
      setState(clone);
      setValue("servicesId", clone);
    }
  };

  const handleServiceChange = (service) => {
    let oldData = state?.findIndex((data, index) => {
      return data.id === service.id;
    });

    const clone = cloneDeep(state);

    let data = null;
    if (oldData < 0) {
      data = [...clone, service];
    } else {
      clone[oldData] = service;
      data = clone;
    }
    setState(data);
    setValue("servicesId", data);
  };

  return (
    <Grid container spacing={2}>
      {isLoading ? (
        <div className="center">
          <CircularProgress size="3rem" />
        </div>
      ) : (
        servicesData.map((data, index) => {
          const name = convertIntoSmall(data?.name);
          const selectedState = state.find((item) => item.name === data?.name);

          let isSelected = false;
          if (selectedState) {
            Object.keys(selectedState).forEach((key) => {
              if (
                key !== "name" &&
                key !== "id" &&
                key !== "description" &&
                selectedState[key]?.length > 0
              ) {
                isSelected = true;
              }
            });
          }

          let image;
          if (name === "florist") {
            image = florist;
          }
          if (name === "entertainment and rentals") {
            image = entertainment;
          }
          if (name === "venue") {
            image = venue;
          }
          if (name === "security") {
            image = security;
          }
          if (name === "transportation") {
            image = transportation;
          }
          if (name === "officiant/speaker") {
            image = speaker;
          }
          if (name === "dj/band") {
            image = dj;
          }
          if (name === "pastry chef/cake decorator") {
            image = cake;
          }
          if (name === "decorator") {
            image = decorator;
          }
          if (name === "photography/videography") {
            image = photo;
          }
          if (name === "glam services") {
            image = glam;
          }
          if (name === "caterer") {
            image = caterer;
          }
          if (name === "stationery, invitations, favors, gifts") {
            image = stationary;
          }
          if (name === "set-up, tear down & clean up") {
            image = setup;
          }
          if (name === "assistant/coordinator/planner") {
            image = assistantce;
          }
          return (
            findService(serviceData, data?.name) && (
              <Grid item xxs={12} xs={12} key={index}>
                <ServiceCheckList
                  setValue={setValue}
                  data={data}
                  serviceData={serviceData}
                  image={image}
                  isSelected={isSelected}
                  selectedState={selectedState}
                  handleServiceChange={handleServiceChange}
                  handleRemoveService={handleRemoveService}
                />
              </Grid>
            )
          );
        })
      )}
    </Grid>
  );
};
export default EventCheckList;
