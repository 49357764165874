import { useSocket } from "Hooks/useSocket";
import MainLayoutRoutes from "layouts/routes/MainLayoutRoutes";
import SecondaryLayoutRoutes from "layouts/routes/SecondaryLayoutRoutes";
import LogoutModal from "components/LogoutModal";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import NotLogInModal from "components/NotLogInModal";

function App() {
  useSocket();
  return (
    <div className="App-header">
      <Routes>
        <Route path="/dashboard/*" element={<SecondaryLayoutRoutes />} />
        <Route path="*" element={<MainLayoutRoutes />} />
      </Routes>
      <LogoutModal />
      <NotLogInModal />
    </div>
  );
}

export default App;
