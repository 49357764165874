import { Box } from "@mui/material";
import React from "react";
import ProfileBanner from "../comp/ProfileBanner";
import Profile from "../comp/Profile";
import Gallery from "../comp/Gallery";
import Policy from "../comp/Policy";
import { formatDate, formatPhoneNumber } from "utilities";
import {
  profileIcon,
  descriptionIcon,
  galleryIcon,
} from "../icon/profilePageIcons";
import { useLocation, useOutletContext } from "react-router-dom";
import ProfileBody from "../comp/ProfileBody";
import ActiveTab from "../comp/ActiveTab";
import { minortyOptions } from "constants";

const tabs = [
  { type: 0, label: "Profile", icon: profileIcon },
  { type: 1, label: "Gallery", icon: galleryIcon },
  { type: 2, label: "Cancellation Policy", icon: descriptionIcon },
];

function VendorProfile() {
  const {
    user,
    coverImage,
    isCoverImageLoaded,
    profileImage,
    isProfileImageLoaded,
    galleryImages,
    services,
    servicesLoading,
  } = useOutletContext();
  const { state } = useLocation();
  const [currentTab, setCurrentTab] = React.useState(state ?? 0);

  const filteredMinority = user?.minorityEligibility
    ? minortyOptions
        .filter(({ value }) => user?.minorityEligibility.includes(value))
        .map(({ label }) => label)
    : null;

  const filteredSkills =
    !servicesLoading && user?.skills
      ? services
          .filter(({ _id }) => user?.skills.includes(_id))
          .map(({ name }) => name)
      : null;

  const personalList = [
    { label: "Name", value: user?.fullName },
    { label: "Gender", value: user?.gender },
    { label: "Birth Date", value: formatDate(user?.birthDate) },
    { label: "Email", value: user?.email },
    { label: "Languages", value: user?.languages, seperatedBy: ", " },
    { label: "Website URL", value: user?.websiteUrl },
    { label: "Facebook", value: user?.facebookUrl },
    { label: "Instagram", value: user?.instagramUrl },
    { label: "Twitter", value: user?.twitterUrl },
  ];

  const businessList = [
    { label: "Name", value: user?.businessName },
    { label: "Criteria", value: user?.businessCriteria },
    { label: "Virtual Events", value: user?.virtualEvents ? "Yes" : "No" },
    { label: "Contact Email", value: user?.contactEmail },
    { label: "Description", value: user?.serviceDescription },
    { label: "Phone Number", value: formatPhoneNumber(user?.telephoneNumber) },
    {
      label: "Minority Eligibility",
      value: filteredMinority,
      seperatedBy: ", ",
    },
    {
      label: "Skills",
      value: filteredSkills,
      seperatedBy: ", ",
    },
  ];

  const addressList = [
    { label: "Business Address", value: user?.location?.address },
    { label: "City", value: user?.location?.city },
    { label: "State", value: user?.location?.state },
    { label: "Country", value: user?.location?.country },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        backgroundColor: "#fafafa",
        boxShadow: "0px -3px 27px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box>
        <ProfileBanner
          profileImage={profileImage}
          coverImage={coverImage}
          businessName={user?.businessName}
          rating={user?.rating}
          isEdit={true}
          isCoverImageLoaded={isCoverImageLoaded}
          isProfileImageLoaded={isProfileImageLoaded}
        />
      </Box>
      <Box sx={{ width: "100%", mt: { xxs: 15, sm: 18 }, py: 2 }}>
        <ProfileBody
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          tabs={tabs}
        >
          <ActiveTab currentTab={currentTab}>
            <Profile
              value={0}
              personalList={personalList}
              addressList={addressList}
              businessList={businessList}
            />
            <Gallery value={1} galleryImages={galleryImages} />
            <Policy value={2} cancellationPolicy={user?.cancellationPolicy} />
          </ActiveTab>
        </ProfileBody>
      </Box>
    </Box>
  );
}

export default VendorProfile;
