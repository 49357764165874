import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CircularProgress } from "@material-ui/core";

export default function AlertDialog({
  open,
  handleClose,
  title,
  description,
  handleSubmit = null,
  isLoading,
  agreeText = "Yes",
  disagreeText = "No",
}) {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{disagreeText}</Button>
          {handleSubmit && (
            <Button
              onClick={handleSubmit}
              autoFocus
              endIcon={
                <CircularProgress
                  style={{
                    width: "15px",
                    height: "15px",
                    display: isLoading ? "block" : "none",
                  }}
                />
              }
            >
              {agreeText}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
