export const ImageUploadIcon = (
  <svg
    width="inherit"
    height="inherit"
    viewBox="0 0 98 98"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M98 49C98 76.062 76.062 98 49 98C21.938 98 0 76.062 0 49C0 21.938 21.938 0 49 0C76.062 0 98 21.938 98 49ZM63.7 34.3C63.7 42.4186 57.1186 49 49 49C40.8814 49 34.3 42.4186 34.3 34.3C34.3 26.1814 40.8814 19.6 49 19.6C57.1186 19.6 63.7 26.1814 63.7 34.3ZM49 88.2C42.4284 88.2099 36.0002 86.5613 30.2917 83.4565C26.4224 81.3521 24.5 77.0381 24.5 72.6336V63.7C24.5 58.2855 28.8463 53.9 34.3 53.9H63.7C69.1145 53.9 73.5 58.2365 73.5 63.7V72.6246C73.5 77.0343 71.5733 81.3523 67.6995 83.4592C62.1415 86.4822 55.7721 88.2 49 88.2Z"
      fill="url(#paint0_linear_194_381)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_194_381"
        x1="8.84722"
        y1="7.5625"
        x2="78.3841"
        y2="53.9879"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.0771073" stopColor="#D312B0" />
        <stop offset="1" stopColor="#7D46E0" />
        <stop offset="1" stopColor="#5B00FF" />
      </linearGradient>
    </defs>
  </svg>
);
