import * as React from "react";

export const useIsOverflow = (ref, callback, width) => {
  const [isOverflow, setIsOverflow] = React.useState(undefined);

  React.useLayoutEffect(() => {
    const { current } = ref;

    const trigger = () => {
      const hasOverflow = current.scrollWidth > current.clientWidth;

      setIsOverflow(hasOverflow);

      if (callback) callback(hasOverflow);
    };

    trigger();
  }, [callback, ref, width]);

  return isOverflow;
};
