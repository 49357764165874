import { Backdrop, Box, Button, CircularProgress } from "@mui/material";
import { CardElement, useStripe } from "@stripe/react-stripe-js";
import { createOrderStripe } from "api/v1/booking";
import useStripePay from "Hooks/useStripePay";
import * as React from "react";

export default function StripeButton({
  bookingId,
  setIsCompleted,
  percentage,
  customerName,
}) {
  const [isLoading, setIsLoading] = React.useState(false);
  const stripe = useStripe();
  const { mutate } = useStripePay({
    fn: createOrderStripe,
    setIsLoading,
    setIsCompleted,
    customerName,
  });

  function onSubmitHandler(event) {
    event.preventDefault();
    mutate({
      id: bookingId,
      body: {
        percentage,
      },
    });
  }

  return (
    <Box component="form" onSubmit={onSubmitHandler}>
      <CardElement options={CARD_ELEMENT_OPTIONS} />
      <Button
        type="submit"
        variant="primary"
        disabled={!stripe}
        sx={{ mt: "20px" }}
      >
        Confirm order
      </Button>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "16px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
