import { Box, Button, Typography, Container } from "@mui/material";
import { getAllEvents, getEventType, getUpcomingEvents } from "api/v1/event";
import searchIcon from "assets/icon/search.svg";
import canceledLottie from "assets/lottie/canceledlottie.json";
import bookingLottie from "assets/lottie/bookinglottie.json";
import MyEventCard from "components/Accordian/dashboard/MyEventCard/MyEventCard";
import LoadMore from "components/LoadMore/LoadMore";
import CustomTabs from "components/navigation/Tabs/CustomTabs";
import { useUserContext } from "context/UserContext";
import useInfiniteListQuery from "Hooks/useInfiniteListQuery";
import Lottie from "lottie-react";
import * as React from "react";
import { useQuery } from "react-query";
import classes from "./Events.module.css";
import EventCardSkeleton from "components/Skeleton/EventCardSkeleton";
import CustomSelect from "components/InputField/CustomSelect";
import { ReactComponent as SearchIcon } from "assets/dashboard/quotes/search.svg";
import CustomInput from "components/InputField/CustomInput";

const Events = () => {
  const { isVendor } = useUserContext();
  const [formStep, setFormStep] = React.useState(0);
  const [sort, setSort] = React.useState("");
  const [type, setType] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [search, setSearch] = React.useState();
  const setValue = (index, type) => {
    setFormStep(index);
    if (type) setStatus(type);
    else setStatus("");
  };

  const noData = {
    open: {
      label: "No Events",
      lottie: bookingLottie,
    },
    completed: { label: "No Completed Events.", lottie: bookingLottie },
    cancelled: {
      label: "No Canceled Events",
      lottie: canceledLottie,
    },
  };

  let currentText = noData.open.label;

  switch (status) {
    case "open":
      currentText = noData.open.label;
      break;
    case "completed":
      currentText = noData.completed.label;
      break;
    case "cancelled":
      currentText = noData.cancelled.label;
      break;
    default:
      currentText = isVendor ? "No New Events Near You" : "No Events";
  }

  let currentLottie = status === "cancelled" ? canceledLottie : bookingLottie;

  const queryObject =
    isVendor && status === "open"
      ? {
          queryKey: "upComingEvents",
          queryFn: getUpcomingEvents,
          queryParams: {
            sortDirection: sort,
            sortBy: "startTime",
            type,
            text: search,
          },
        }
      : {
          queryKey: "allEvents",
          queryFn: getAllEvents,
          queryParams: {
            sortDirection: sort,
            status,
            type,
            text: search,
          },
        };

  const { data, isLoading, loadMoreRef, refetch, hasNextPage } =
    useInfiniteListQuery(queryObject);

  const { data: eventTypes, isLoading: isEventTypeLoading } = useQuery(
    ["eventTypes"],
    async () => {
      const eventTypes = await getEventType();
      return eventTypes.data;
    }
  );

  const eventTypeList = !isEventTypeLoading
    ? eventTypes.map(({ name }) => ({ value: name, label: name }))
    : [];

  React.useEffect(() => {
    refetch();
  }, [sort, type, status]);

  return (
    <Container maxWidth="xl" sx={{ py: "3rem" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
        }}
      >
        <Typography variant="h3">My Event</Typography>
        <Box sx={{ display: "flex", gap: "5px" }}>
          <Box sx={{ flex: 1 }}>
            <CustomInput
              height="45px"
              adornment="endAdornment"
              position="end"
              Icon={SearchIcon}
              onChange={(e) => setSearch(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  refetch();
                } else {
                  return null;
                }
              }}
              placeholder="Search here"
              textInputStyle={{ maxWidth: "700px" }}
            />
          </Box>
          <Box>
            <CustomSelect
              label="Sort By"
              value={sort}
              setValue={setSort}
              data={[
                { value: "", label: "Sort By" },
                { value: "1", label: "Ascending" },
                { value: "-1", label: "Descending" },
              ]}
              height="45px"
            />
          </Box>
          <Box>
            <CustomSelect
              label="Type"
              value={type}
              setValue={setType}
              data={[{ value: "", label: "Select Type" }, ...eventTypeList]}
              height="45px"
            />
          </Box>
        </Box>
        <Box className={classes.root}>
          <Box>
            <CustomTabs
              step={formStep}
              values={[
                {
                  label: isVendor ? "New Event Requests" : "All Events",
                },
                {
                  type: "open",
                  label: isVendor ? "My Events" : "Upcoming Events",
                },
                { type: "completed", label: "Completed Events" },
                { type: "cancelled", label: "Canceled Events" },
              ]}
              onSelect={setValue}
            />
          </Box>
          <Box
            className={classes.container}
            sx={{
              px: 3,

              justifyContent: {
                xxs: "center",
                md: "flex-start",
              },
            }}
          >
            {isLoading ? (
              <>
                {[1, 2, 3, 4].map((x, i) => (
                  <EventCardSkeleton key={i} isVendor={isVendor} />
                ))}
              </>
            ) : data?.pages?.flat()?.length > 0 ? (
              data?.pages?.flat()?.map((event, index) => {
                return <MyEventCard key={event._id} {...event} />;
              })
            ) : (
              <Box
                sx={{
                  marginTop: "60px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <Box>
                  <Typography variant="h4" sx={{ textAlign: "center" }}>
                    {currentText}
                  </Typography>
                </Box>
                <Box
                  style={{
                    maxWidth: "500px",
                    width: "100%",
                    marginTop: "-2.5rem",
                  }}
                >
                  <Lottie animationData={currentLottie} loop={true} />
                </Box>
              </Box>
            )}
            <LoadMore More loadMoreRef={loadMoreRef} hasNextPage={hasNextPage}>
              <Box className={classes.container}>
                {[1, 2, 3, 4].map((x, i) => (
                  <EventCardSkeleton key={i} isVendor={isVendor} />
                ))}
              </Box>
            </LoadMore>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Events;
