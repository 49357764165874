import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import CustomCard from "components/forms/CustomCard";
import FormApiResponse from "components/forms/FormApiResponse";
import FormInputText from "components/forms/FormInputText";
import FormNumberInput from "components/forms/FormNumberInput";
import GooglePlacesAutoComplete from "components/GooglePlacesAutoComplete";
import messages from "messages";
import * as React from "react";
import classes from "./FormTwo.module.css";
function FormTwo({
  control,
  errors,
  handleClick,
  isLoading,
  isError,
  isSuccess,
  setValue,
  error,
}) {
  return (
    <Box sx={{ maxWidth: "1000px" }}>
      <CustomCard>
        <Box className={classes.root} sx={{ p: 6 }}>
          <Box>
            <FormHead />
          </Box>
          <Box sx={{ pb: 3 }}>
            <Divider />
          </Box>
          <Box>
            <FormBody
              control={control}
              errors={errors}
              handleClick={handleClick}
              isLoading={isLoading}
              setValue={setValue}
            />
          </Box>
          <Box sx={{ mt: "1rem" }}>
            <FormApiResponse
              isError={isError}
              isSuccess={isSuccess}
              error={error}
              successMessage={messages.signedUp}
            />
          </Box>
        </Box>
      </CustomCard>
    </Box>
  );
}

export default FormTwo;

function FormHead() {
  return (
    <>
      <Box sx={{ pb: 2 }}>
        <Typography
          variant="pbold"
          style={{ fontSize: "2rem", lineHeight: "47px", fontWeight: 700 }}
        >
          More About Yourself
        </Typography>
      </Box>
      <Box sx={{ pb: 3 }}>
        <Typography
          variant="p"
          style={{ lineHeight: "34px", fontSize: "1.25rem" }}
        >
          Please tell us little about yourself by provinding the information
          below.
        </Typography>
      </Box>
    </>
  );
}

function FormBody({
  setValue,
  control,
  errors,
  handleClick,
  isLoading,
  error,
}) {
  return (
    <>
      <Grid container columnSpacing={10} rowSpacing={4}>
        <Grid item xxs={12} sm={6}>
          <FormInputText
            label="First Name"
            name="firstName"
            placeholder="John"
            control={control}
            errors={errors?.firstName?.message}
          />
        </Grid>
        <Grid item xxs={12} sm={6}>
          <FormInputText
            label="Last Name"
            name="lastName"
            placeholder="Doe"
            control={control}
            errors={errors?.lastName?.message}
          />
        </Grid>

        <Grid item xxs={12} sm={6}>
          <FormNumberInput
            control={control}
            name="telephoneNumber"
            label="Phone Number"
            errors={errors?.telephoneNumber?.message}
            primary={true}
          />
        </Grid>
        <Grid item xxs={12} sm={6}>
          <FormInputText
            label="Email"
            name="email"
            placeholder="sample@user.com"
            control={control}
            errors={errors?.email?.message}
          />
        </Grid>
        <Grid item xxs={12} sm={6}>
          <GooglePlacesAutoComplete
            control={control}
            errors={errors}
            name="location"
            setFormValue={setValue}
            label="Address"
            customHeight="56px"
            style={{
              borderRadius: "123px",
              boxShadow: "0px 4px 10px 3px rgba(0, 0, 0, 0.11)",
              "& .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #01214D",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "primary.light",
              },
            }}
          />
        </Grid>
      </Grid>
      <Box sx={{ mt: 3 }}>
        <Button
          onClick={handleClick}
          variant="secondary"
          sx={{
            borderColor: "#000",
            color: "#01214D",
            mr: { xxs: 0, sm: 3 },
            mb: { xxs: 1, sm: 0 },
          }}
        >
          Back
        </Button>
        <LoadingButton
          type="submit"
          variant="primary"
          sx={{ width: { xxs: "auto", sm: "40%" } }}
          loading={isLoading}
        >
          Submit
        </LoadingButton>
      </Box>
    </>
  );
}
