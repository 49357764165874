import { Box, Button, Grid, Rating, Typography, useTheme } from "@mui/material";
import * as React from "react";
import profile from "assets/dashboard/myEvent/profile.webp";
import classes from "./VendorQuoteCard.module.css";
import { Link } from "react-router-dom";
import { formatDate } from "utilities";

const VendorQuoteCard = ({
  profileImage,
  userName,
  rating,
  event,
  price,
  location,
  date,
  showText,
  hiddenText,
  messageLink = "#",
  seeDetailLink = "#",
  pending = false,
}) => {
  const theme = useTheme();
  const [clamped, setClamped] = React.useState(true);

  const handleClick = () => {
    setClamped(!clamped);
  };

  return (
    <Box
      className={classes.root}
      sx={{ backgroundColor: pending ? "#F8FAFC" : "#FFF6FB" }}
    >
      <Grid container sx={{ gap: "25px" }}>
        <Grid
          item
          xl={2}
          lg={4}
          md={4}
          sm={4}
          xs={4}
          className={classes.profileContainer}
        >
          <Box>
            <img src={profileImage} alt="profile" className={classes.profile} />
          </Box>
          <Box>
            <Typography
              component="h4"
              variant="mediumText"
              sx={{ whiteSpace: "nowrap" }}
            >
              {userName}
            </Typography>
            <Rating
              name="half-rating-read"
              size="small"
              value={rating}
              precision={0.5}
              readOnly
            />
          </Box>
        </Grid>
        <Grid item xl={2} lg={4} md={4} sm={4} xs={4}>
          <Box
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              color: "primary.main",
            }}
          >
            <Typography variant="smallText" color={theme.palette.primary.main}>
              {event}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={2} lg={4} md={4} sm={4} xs={4}>
          <Typography variant="smallText" fontWeight="500">
            {location}
          </Typography>
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
          <Typography variant="smallText" fontWeight="600">
            {formatDate(date)}
          </Typography>
        </Grid>
        <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
          <Typography variant="smallText" fontWeight="600">
            $ {price}
          </Typography>
        </Grid>
      </Grid>
      <Box className={classes.detailContainer}>
        <Box
          className={classes.text}
          sx={{
            display: "-webkit-box",
            "-webkit-line-clamp": "3",
            "-webkit-box-orient": "vertical",
            overflow: "hidden",
          }}
        >
          <Typography variant="mediumText" color={theme.palette.primary.main}>
            Quote:
          </Typography>
          <Typography variant="p" ml={2}>
            {showText}
          </Typography>
        </Box>
        <Box className={classes.btnContainer}>
          <Box>
            <Link to={seeDetailLink}>
              <Button variant="secondary">See Details</Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default VendorQuoteCard;
