import * as React from "react";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export default function BasicSkeleton({ variant, skeletonStyles, ...rest }) {
  return (
    <Skeleton
      variant={variant}
      {...rest}
      sx={{ width: "100%", height: "100%", ...skeletonStyles }}
    />
  );
}
