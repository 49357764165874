const ROUTES = {
  LOGIN: "/",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  VERIFICATIONCODE: {
    USER: "/user/verification-code",
  },
  EVENT: {
    PLAN: "/event/plan",
  },
  SERVICIES: {
    MAIN: "/services",
    VENDOR: "/services/:id/vendors",
    DETAIL: "/vendors/:id",
  },
  SIGNUP: {
    USER: "/user/signup",
    VENDOR: "/vendor/signup",
  },
  SIGNUP_CONFIRMATION: {
    USER: "/user/signup/confirm",
    VENDOR: "/vendor/signup/confirm",
  },
  PROFILE: "/profile/edit-profile",
  DASHBOARD: "/dashboard",
};

export default ROUTES;
