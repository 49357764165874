import { useTheme } from "@emotion/react";
import SearchIcon from "@mui/icons-material/Search";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import { getVendorByServiceId } from "api/v1/public/vendors";
import notFound from "assets/not-found/notFound.jpg";
import vendorImage from "assets/services/vendor-card.svg";
import GooglePlacesAutoComplete from "components/GooglePlacesAutoComplete";
import CustomInput from "components/InputField/CustomInput";
import CustomSelect from "components/InputField/CustomSelect";
import LoadMore from "components/LoadMore/LoadMore";
import ServicesCard from "components/servicesCard/servicesCard";
import { useUserContext } from "context/UserContext";
import useInfiniteListQuery from "Hooks/useInfiniteListQuery";
import useSearch from "Hooks/useSearch";
import * as React from "react";
import { useParams } from "react-router-dom";
import { minortyOptions } from "../../constants";
import classes from "./index.module.css";
const Vendors = ({ isDashboard }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState("");
  const { user } = useUserContext();
  const [minority, setMinority] = React.useState("");
  const [address, setAddress] = React.useState(null);
  const params = useParams();
  const { id } = params;
  const { onSearchChange, search } = useSearch();
  const { data, isLoading, isRefetching, loadMoreRef, refetch, hasNextPage } =
    useInfiniteListQuery({
      queryKey: "VendorByServiceId",
      queryFn: getVendorByServiceId,
      otherParams: { id },
      queryParams: {
        minorityEligibility: minority,
        sortDirection: value,
        text: search,
        ...(isDashboard
          ? user
            ? {
                coordinates: JSON.stringify(user?.location.coordinates),
              }
            : {}
          : address?.coordinates
          ? {
              coordinates: JSON.stringify([...address?.coordinates]),
            }
          : {}),
      },
      queryConfig: {
        refetchOnWindowFocus: false,
      },
    });
  const customAddressValueSetter = (key, val) => {
    if (key === "location") {
      if (val === "null") setAddress(null);
      else {
        setAddress(val);
      }
    }
  };

  React.useEffect(() => {
    refetch();
  }, [search, value, minority, address, user]);
  return (
    <Container>
      <Box sx={{ textAlign: "center" }}>
        <Typography
          component="h1"
          variant="h1"
          color={theme.palette.primary.black}
          sx={{ marginTop: "120px" }}
        >
          Vendors
        </Typography>
        <Typography
          component="p"
          variant="p"
          sx={{ marginBottom: "58px", marginTop: "22px" }}
        >
          Select a category below to view premier local and virtual vendors.
        </Typography>
      </Box>
      <Box className={classes.container}>
        <Box sx={{ maxWidth: "250px" }}>
          <CustomInput
            key="keysishere"
            height="45px"
            adornment="endAdornment"
            position="end"
            Icon={isRefetching ? CircularProgress : SearchIcon}
            iconProps={{ size: "1rem" }}
            onChange={onSearchChange}
            placeholder="Search here"
          />
        </Box>
        {!isDashboard && (
          <Box
            sx={{
              width: "300px",
            }}
          >
            <GooglePlacesAutoComplete setFormValue={customAddressValueSetter} />
          </Box>
        )}
        <Box>
          <CustomSelect
            label="Sort By Rating"
            data={[
              { value: "", label: "Select" },
              { value: "1", label: "Ascending" },
              { value: "-1", label: " Descending" },
            ]}
            height="45px"
            value={value}
            setValue={setValue}
          />
        </Box>
        <Box>
          <CustomSelect
            label="Minority-owned
            Business"
            data={[
              { label: "Select Minority-owned Business", value: "" },
              ...minortyOptions,
            ]}
            height="45px"
            value={minority}
            setValue={setMinority}
          />
        </Box>
      </Box>
      <Box className={classes.cardContainer}>
        {isLoading ? (
          <div className="center">
            <CircularProgress size="3rem" />
          </div>
        ) : data?.pages?.flat().length > 0 ? (
          <>
            {data?.pages
              ?.flat()
              .map(
                (
                  {
                    businessName,
                    coverPhoto,
                    id,
                    profilePhoto,
                    serviceDescription,
                  },
                  index
                ) => {
                  return (
                    <ServicesCard
                      key={index}
                      heading={businessName}
                      text={serviceDescription}
                      image={coverPhoto ? coverPhoto : vendorImage}
                      vendor={false}
                      vendorDetailUrl={
                        isDashboard
                          ? `/dashboard/vendors/${id}`
                          : `/vendors/${id}`
                      }
                      imageKey={profilePhoto}
                    />
                  );
                }
              )}
          </>
        ) : (
          <div className="center">
            <img src={notFound} />
          </div>
        )}
      </Box>
      <LoadMore loadMoreRef={loadMoreRef} hasNextPage={hasNextPage} />
    </Container>
  );
};
export default Vendors;
