import qs from "query-string";
import { cloneDeep } from "lodash";
import { VALID_LOCATION_KEYS } from "./constraints";
import dayjs from "dayjs";

export function formatUrl(url, queryParam) {
  if (Object.keys(queryParam).length !== 0) {
    queryParam = qs.stringify(queryParam);
    return `${url}?${queryParam}`;
  }
  return url;
}
export function updateCache(queryKey, queryClient, update) {
  queryClient.cancelQueries(queryKey, { exact: true });
  // Snapshot of the previous value
  const previousData = queryClient.getQueryData(queryKey);
  // To avoid mutating previousData
  let updatedData = cloneDeep(previousData);
  update(updatedData, previousData);
  return previousData;
}

export function validLocation(location) {
  const keys = Object.keys(location);
  if (keys.length <= 0 || !location) {
    return false;
  }
  for (var i = 0; i < VALID_LOCATION_KEYS.length; i++) {
    if (!keys.includes(VALID_LOCATION_KEYS[i])) {
      return false;
    }
  }
  return true;
}
export function normalizeDate(_time) {
  const time = { ..._time };
  const is12 = Number(time.hours) === 12;

  if (is12) {
    time.hours = "00";
  }
  return (
    (time.ampm === "PM" ? Number(time.hours) + 12 : time.hours) + ":" + time.min
  );
}

export function objectKeyBasedOnUserType(isVendor) {
  return isVendor ? "customerId" : "vendorId";
}

export function formatDate(date) {
  return new Intl.DateTimeFormat("en-US").format(new Date(date));
}

export function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
}

export function filterNullArrayData(arr) {
  if (arr?.length > 0) {
    return arr.filter(
      (val) => val != undefined && val !== "other" && val !== ""
    );
  }
  return [];
}

export function YesNoToBoolean(val) {
  if (val == null) return null;
  return val === "yes" ? true : false;
}

export function formatCurrency(amount) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",

    // These options are needed to round to whole numbers if that's what you want.
    // minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    // maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });
  return formatter.format(amount);
}

export function denormalizeDate(timeDate) {
  const date = dayjs(timeDate);
  const time = dayjs(timeDate).format("HH:mm");
  let [timeHour, timeMinute] = time.split(":");
  const is00 = timeHour === "00";
  if (is00) {
    timeHour = "12";
  }
  return {
    date,
    hours: timeHour > 12 ? String(timeHour - 12) : String(timeHour),
    min: timeMinute,
    ampm: timeHour >= 12 ? (is00 ? "AM" : "PM") : "AM",
  };
}

export const dirtyValues = (dirtyFields, allValues) => {
  // NOTE: Recursive function.

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [
      key,
      dirtyValues(dirtyFields[key], allValues[key]),
    ])
  );
};
const filterObj = (obj, predicate) => 
Object.fromEntries(Object.entries(obj).filter(predicate));

export const filterObjectEmptyValues = (data )=>( filterObj(data, ([name, val]) => Array.isArray(val) ? val.length > 0:val))
