import {
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  RadioGroup,
  Typography,
} from "@mui/material";
import FormCustomRadioButton from "components/forms/FormCustomRadioButton";
import FormInputTextSecondary from "components/forms/FormInputTextSecondary";
import React from "react";
import { Controller } from "react-hook-form";

function ThemeForm({
  watch,
  eventTypes,
  control,
  trigger,
  errors,
  setFormStep,
  setValue,
  isEventTypeLoading,
}) {
  let watchType = watch("type", "");
  const handleClickContinue = async () => {
    let result = await trigger(["type", "title", "description", "customType"]);
    result && setFormStep((prev) => prev + 1);
    document.querySelector("#stepper").scrollIntoView({
      behavior: "smooth",
    });
  };
  return (
    <Grid
      container
      rowSpacing={3}
      sx={{ padding: { xxs: "0.7rem", xs: "1.5rem", sm: "2rem" } }}
    >
      <Grid item xxs={12}>
        <FirstSection />
      </Grid>
      <Grid item xxs={12}>
        <Divider />
      </Grid>
      <Grid item xxs={12}>
        <SecondSection
          eventTypes={eventTypes}
          control={control}
          watchType={watchType}
          isLoading={isEventTypeLoading}
          errors={errors}
          setValue={setValue}
        />
      </Grid>
      <Grid item xxs={12}>
        <ThirdSection control={control} errors={errors} />
      </Grid>
      <Grid item xxs={12} style={{ textAlign: "center" }}>
        <Button variant="primary" onClick={handleClickContinue}>
          Save and Continue
        </Button>
      </Grid>
    </Grid>
  );
}

export default ThemeForm;

function FirstSection() {
  return (
    <Grid
      container
      rowSpacing={2}
      justifyContent="center"
      sx={{ textAlign: "center", pb: "1rem" }}
    >
      <Grid item xxs={12}>
        <Typography variant="h4">Event Occasion And Theme</Typography>
      </Grid>
      <Grid item xxs={12}>
        <Typography variant="p3">
          What are you celebrating? Is there a specific theme?
        </Typography>
      </Grid>
    </Grid>
  );
}

function SecondSection({
  isLoading,
  eventTypes,
  control,
  watchType,
  errors,
  setValue,
}) {
  const inputActive = watchType?.toLowerCase() === "other" ? false : true;

  const RadioButtonList = (
    <>
      <Controller
        name="type"
        control={control}
        render={({ field: { value, onChange } }) => (
          <>
            <RadioGroup value={value} onChange={onChange}>
              <Grid container rowSpacing={2} columnSpacing={3}>
                {isLoading ? (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      height: "100%",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress size="3rem" />
                  </div>
                ) : eventTypes?.length > 0 ? (
                  <>
                    {eventTypes?.map((eventType, i) => (
                      <Grid key={i} item xxs={12} sm={6} md={4}>
                        <FormCustomRadioButton
                          label={eventType.name}
                          value={eventType?.name
                            ?.trim()
                            ?.toLowerCase()
                            ?.replace(" ", "_")}
                        />
                      </Grid>
                    ))}
                    <Grid item xxs>
                      {!inputActive && (
                        <FormInputTextSecondary
                          name="customType"
                          control={control}
                          placeholder="Write down your event type here"
                          disabled={inputActive}
                          customHeight="49px"
                          errors={errors?.customType?.message}
                        />
                      )}
                    </Grid>
                  </>
                ) : null}
              </Grid>
            </RadioGroup>
            <FormHelperText error>
              {errors && errors.type?.message}
            </FormHelperText>
          </>
        )}
      />
    </>
  );

  return (
    <Grid container>
      <Grid item xxs={12}>
        <FormControl>{RadioButtonList}</FormControl>
      </Grid>
    </Grid>
  );
}
function ThirdSection({ control, errors }) {
  return (
    <Grid container rowSpacing={3}>
      <Grid item xxs={12}>
        <FormInputTextSecondary
          name="title"
          control={control}
          label="Event title"
          placeholder="Enter Title of your event"
          errors={errors?.title?.message}
          customHeight="49px"
        />
      </Grid>
      <Grid item xxs={12}>
        <FormInputTextSecondary
          name="description"
          control={control}
          errors={errors?.description?.message}
          label="Tell us more about your event"
          placeholder="Please explain your theme and details about your dream event"
          multiline
          minRows={4}
          maxRows={7}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              maxHeight: "none",
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
