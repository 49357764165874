import { Navigate, Outlet } from "react-router-dom";
import { useUserContext } from "context/UserContext";

const PrivateRoutes = ({ allowedUsers, redirectPath = "/", children }) => {
  const { user } = useUserContext();

  if (allowedUsers.includes(user?.model)) {
    return children;
  }
  return <Navigate to={redirectPath} replace />;
};

export default PrivateRoutes;
