import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Container, Typography } from "@mui/material";
import { createEvent, getEventType, getOneEvents, update } from "api/v1/event";
import CustomCard from "components/forms/CustomCard";
import FormApiResponse from "components/forms/FormApiResponse";
import CustomStepper2 from "components/navigation/Stepper/CustomStepper2";
import { useEventContext } from "context/EventContext";
import { useLoginContext } from "context/LoginContext";
import { useUserContext } from "context/UserContext";
import dayjs from "dayjs";
import { cloneDeep } from "lodash";
import messages from "messages";
import qs from "query-string";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { denormalizeDate, normalizeDate, validLocation } from "utilities";
import * as yup from "yup";
import checklistIcon from "../../../../../assets/event/checklist.png";
import SignUpDialog from "views/pages/Login/SignUpPage/User/SignUpDialog";
import {
  calendarIcon,
  detailIcon,
  partyIcon,
} from "./forms/data/icon/themeIcons";
import DateForm from "./forms/DateForm";
import DetailForm from "./forms/DetailForm";
import ServicesForm from "./forms/ServicesForm";
import ThemeForm from "./forms/ThemeForm";
import { getServices } from "api/v1/service";
function Plan() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { isAuthenticated } = useUserContext();

  const { data: eventDetail } = useQuery(
    ["event", id, "detail"],
    async () => {
      const event = await getOneEvents(id);
      return event.data;
    },
    {
      enabled: Boolean(id),
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );
  const {
    mutate,
    isLoading: isSubmitButtonLoading,
    isError,
    isSuccess,
    error,
  } = useMutation(id ? update : createEvent, {
    onSuccess({ message }) {
      toast.success(message);
      navigate("/dashboard/events");
    },
    onError(error) {
      toast.error(error.errMsg);
    },
  });
  const [formStep, setFormStep] = useState(0);
  const [formData, setFormData] = React.useState(null);
  const [openDialog, setOpenDialog] = React.useState(false);
  const formRef = React.useRef(null);
  const Forms = [ThemeForm, DateForm, DetailForm, ServicesForm];
  const CurrentForm = Forms[formStep];
  const location = qs.parse(useLocation().search);
  const { data: eventTypes, isLoading } = useQuery(
    "eventType",
    async () => {
      const data = await getEventType();
      return data.data?.sort((a) =>
        a?.name?.toLowerCase() === "other" ? 1 : -1
      );
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
    }
  );

  const {
    control,
    handleSubmit,
    watch,
    trigger,
    getValues,
    setValue,
    reset,
    setError,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      type: location.selected?.trim()?.toLowerCase()?.replace(" ", "_") ?? "",
      customType: "",
      servicesId: [],
      title: "",
      description: "",
      venueType: "",
      venueName: "",
      location: null,
      numberOfPeople: null,
      startTime: {
        date: new Date(),
        hours: "00",
        min: "00",
        ampm: "AM",
      },
      endTime: {
        date: new Date(),
        hours: "00",
        min: "00",
        ampm: "AM",
      },
    },
    resolver: yupResolver(schema),
  });
  React.useEffect(() => {
    if (eventDetail && Object.keys(eventDetail)?.length > 0) {
      const servicesId = eventDetail?.services.map((service) => {
        return { id: service.serviceId._id, ...service.info };
      });

      reset({
        type: eventDetail?.type?.eventTypeId?.name
          .trim()
          ?.toLowerCase()
          ?.replace(" ", "_"),
        title: eventDetail?.title,
        customType: eventTypes
          ?.map((eventType) => eventType.name)
          .includes(eventDetail?.type?.name)
          ? ""
          : eventDetail?.type?.name,
        description: eventDetail?.description,
        venueType: eventDetail?.venueType,
        venueName: eventDetail?.venueName,
        numberOfPeople: eventDetail?.numberOfPeople,
        startTime: denormalizeDate(eventDetail?.startTime),
        endTime: denormalizeDate(eventDetail?.endTime),
        location: eventDetail?.location,
        servicesId: servicesId,
      });
    }
  }, [eventDetail]);

  React.useEffect(() => {
    if (document)
      document.querySelector("#form").scrollIntoView({
        behavior: "smooth",
      });
  }, [document]);

  function onSubmit(data) {
    data.type = eventTypes?.find(
      (event) =>
        event?.name?.trim()?.toLowerCase()?.replace(" ", "_") === data.type
    )?.name;

    data.services = data.servicesId
      .map((item) => {
        const val = cloneDeep(item);
        delete val.id;

        let check = false;
        Object.keys(val).forEach((key) => {
          if (
            key !== "name" &&
            key !== "description" &&
            val[key] &&
            val[key]?.length > 0
          ) {
            check = true;
          }
        });

        if (check) {
          return {
            serviceId: item.id,
            info: val,
          };
        }
        return null;
      })
      .filter((x) => x);

    if (data.services.length <= 0 || !data.services) {
      toast.error(messages.saveSelection);
      return;
    }
    const normalizeStartDate = normalizeDate(data.startTime);
    const normalizeEndDate = normalizeDate(data.endTime);

    const startTime = dayjs(data.startTime.date)
      .hour(normalizeStartDate.split(":")[0])
      .minute(normalizeStartDate.split(":")[1])
      .second(0)
      .millisecond(0);
    const endTime = dayjs(data.endTime.date)
      .hour(normalizeEndDate.split(":")[0])
      .minute(normalizeEndDate.split(":")[0])
      .second(0)
      .millisecond(0);

    const eventType = eventTypes?.findIndex((val) => val.name === data.type);
    const body = {
      ...data,
      startTime: startTime,
      endTime: endTime,
      type: {
        eventTypeId: eventTypes?.[eventType]._id,
        name: data.customType ? data.customType : data.type,
      },
    };

    if (isAuthenticated) {
      if (id)
        mutate({
          body,
          id,
        });
      else
        mutate({
          body,
        });
    } else {
      setFormData(body);
      setOpenDialog(true);
    }
  }
  return (
    <Box>
      <Container maxWidth="lg">
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            pt: "5rem",
            pb: "5rem",
          }}
        >
          <Box sx={{ pb: "2rem" }}>
            <Box sx={{ pb: "1rem" }}>
              <Typography variant="h1">
                {formStep === 3
                  ? `Select the categories you need to
                  plan your dream event.`
                  : "Tell us more about your event."}
              </Typography>
            </Box>
            <Box
              id="stepper"
              sx={{
                pb: "1rem",
                // m: {
                //   xxs: "0 1rem",
                //   sm: "0 4rem",
                //   md: "0 14rem",
                //   lg: "0 13rem",
                // },
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  fontWeight: "500",
                  color: "text.secondary",
                }}
              >
                {formStep === 3
                  ? `Once you
                  select your options, click the
                  "SAVE" button to add your
                  selections to your account.`
                  : `Let's start with the basics so we can build your event checklist
                and find your vendors.`}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: "100%", pb: "2.5rem" }}>
            <CustomStepper2
              step={formStep}
              values={[
                {
                  label: "Event Occasion And Theme",
                  icon: partyIcon,
                  stepValue: 0,
                },
                { label: "Date Of Event", icon: calendarIcon, stepValue: 1 },
                { label: "Event Details", icon: detailIcon, stepValue: 2 },
                {
                  label: "Event Checklist",
                  icon: (
                    <img
                      src={checklistIcon}
                      alt="checklist"
                      width="100%"
                      height="100%"
                    />
                  ),
                  stepValue: 3,
                },
              ]}
            />
          </Box>
          <Box
            sx={{
              background:
                "linear-gradient(123.73deg,rgba(211, 18, 176, 1) 13%,rgba(125, 70, 224, 1) 70%,rgba(91, 0, 255, 1) 98%)",
              width: "100%",
              height: "100%",
              borderRadius: "19px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              pt: "3.5rem",
              pb: "3.5rem",
            }}
          >
            <Box
              sx={{
                width: "84%",
                height: "100%",
                position: "relative",
              }}
            >
              <CustomCard elevation={0}>
                <form ref={formRef} id="form" onSubmit={handleSubmit(onSubmit)}>
                  <CurrentForm
                    watch={watch}
                    trigger={trigger}
                    getValues={getValues}
                    setValue={setValue}
                    errors={errors}
                    control={control}
                    setError={setError}
                    register={register}
                    eventTypes={eventTypes}
                    isEventTypeLoading={isLoading}
                    setFormStep={setFormStep}
                    isSubmitButtonLoading={isSubmitButtonLoading}
                  />
                </form>
              </CustomCard>
            </Box>
          </Box>
        </Box>
      </Container>

      {openDialog && (
        <SignUpDialog
          open={openDialog}
          setOpen={setOpenDialog}
          formData={formData}
        />
      )}
    </Box>
  );
}

export default Plan;

const schema = yup
  .object({
    title: yup.string().required(messages.requiredField),
    description: yup.string().required(messages.requiredField),
    type: yup.string().required(messages.requiredField),
    customType: yup.string().when("type", {
      is: (val) => val === "other",
      then: yup.string().required(messages.requiredField),
    }),
    venueType: yup.string().required(messages.requiredField),
    venueName: yup.string().required(messages.requiredField),
    location: yup
      .object()
      .shape({
        type: yup.string().required(),
        address: yup.string().required(),
        state: yup.string().required(),
        country: yup.string().required(),
        coordinates: yup.array().of(yup.number()).required(),
      })
      .nullable()
      .required(messages.requiredField),
    numberOfPeople: yup
      .number()
      .positive(messages.positiveNumber)
      .typeError(messages.invalidValue)
      .test(
        "Is decimal?",
        messages.noDecimalValue,
        (value) => value.toString().indexOf(".") === -1
      )
      .required(messages.requiredField),
    startTime: yup.object({
      date: yup.date().required(),
      hours: yup
        .string()
        .test("notZero", "Please select a valid date hour", (v) => v !== "00")
        .required(),
    }),
    endTime: yup.object({
      date: yup.date().required(),

      hours: yup
        .string()
        .test("notZero", "Please select a valid date hour", (v) => v !== "00")
        .required(),
    }),
  })
  .required();
