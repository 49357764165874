import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { getBookings } from "api/v1/booking";
import { ReactComponent } from "assets/dashboard/quotes/quoteAvatar.svg";
import bookingLottie from "assets/lottie/bookinglottie.json";
import BookingDetailCard from "components/BookingDetailCard/BookingDetailCard";
import ListHeader from "components/ListHeader/ListHeader";
import LoadMore from "components/LoadMore/LoadMore";
import { listMessages } from "constants";
import { useUserContext } from "context/UserContext";
import useInfiniteListQuery from "Hooks/useInfiniteListQuery";
import Lottie from "lottie-react";
import React from "react";
import { objectKeyBasedOnUserType } from "utilities";
import BookingsFilters from "./BookingsFilters";

const headers = [
  { id: "name", label: "Name", col: 3 },
  { id: "event-title", label: "Event Title", col: 1.8 },
  { id: "location", label: "Location", col: 1.8 },
  { id: "event-date", label: "Event Date", col: 1.8 },
  { id: "price-offered", label: "Price Offered", col: 1.8 },
  { id: "status", label: "Status", col: 1.8 },
];

function BookingsList() {
  const { isVendor } = useUserContext();
  const objectKey = objectKeyBasedOnUserType(isVendor);
  if (isVendor) headers[0] = { id: "customer", label: "Customer", col: 3 };

  const [sortBy, setSortBy] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [search, setSearch] = React.useState("");
  const { data, loadMoreRef, refetch, hasNextPage, isLoading, isFetching } =
    useInfiniteListQuery({
      queryKey: "bookings",
      queryFn: getBookings,
      queryParams: {
        text: search,
        sortDirection: sortBy,
        status,
      },
    });
  const isData = data?.pages[0]?.length > 0;

  React.useEffect(() => {
    refetch();
  }, [sortBy, status, refetch, search]);

  if (isLoading)
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          minHeight: "100%",
          minWidth: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Container maxWidth="xl" sx={{ py: "3rem" }}>
      <Box sx={{ mb: "2rem" }}>
        <BookingsFilters
          sortBy={sortBy}
          status={status}
          setSortBy={setSortBy}
          setStatus={setStatus}
          setSearch={setSearch}
        />
      </Box>
      <Box sx={{ overflowX: "auto" }}>
        <Box
          sx={{
            borderRadius: "8px",
            background: "#FFFFFF",
            boxShadow: "0px -3px 27px 10px #0000000d",
            minWidth: isData ? "900px" : "0",
            py: "1rem",
          }}
        >
          <Grid
            container
            justifyContent="center"
            sx={{
              color: "#000",
              py: "1rem",
              minWidth: isData ? "900px" : "0",
            }}
          >
            <Grid item xxs={12} sx={{ borderBottom: "1.4px solid #EBEAED" }}>
              {isData && <ListHeader headers={headers} spacing={true} />}
            </Grid>
            <Grid
              item
              container
              xxs={12}
              justifyContent="center"
              rowSpacing={4}
              sx={{ backgroundColor: "#fffff" }}
            >
              {data?.pages?.flat()?.length ? (
                data?.pages?.flat()?.map((item) => (
                  <Grid item xxs={12} key={item._id}>
                    <BookingDetailCard
                      item={item}
                      Icon={ReactComponent}
                      objectKey={objectKey}
                      bookingRefetch={refetch}
                      bookingIsFetching={isFetching}
                      detailUrl={`/dashboard/bookings/${item?._id}`}
                    />
                  </Grid>
                ))
              ) : (
                <Box
                  sx={{
                    marginTop: "60px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box sx={{ width: "100%", flex: 1 }}>
                    <Typography variant="h4" sx={{ textAlign: "center" }}>
                      {isVendor
                        ? listMessages.booking.vendor
                        : listMessages.booking.customer}
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      maxWidth: "500px",
                      width: "100%",
                      marginTop: "-2.5rem",
                    }}
                  >
                    <Lottie animationData={bookingLottie} loop={true} />
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>
          <LoadMore loadMoreRef={loadMoreRef} hasNextPage={hasNextPage} />
        </Box>
      </Box>
    </Container>
  );
}

export default BookingsList;
