import { Box, Container } from "@mui/system";
import CustomTabs from "components/navigation/Tabs/CustomTabs";
import React from "react";

function ProfileBody({ children, tabs, currentTab, setCurrentTab }) {
  const onSelectHandler = (index, type) => {
    setCurrentTab(type);
  };

  return (
    <Container maxWidth="lg">
      <Box>
        <CustomTabs
          step={currentTab}
          values={tabs}
          onSelect={onSelectHandler}
          iconPosition="start"
        />
      </Box>
      <Container maxWidth={currentTab === 0 ? "md" : "lg"}>
        <Box sx={{ py: 4 }}>{children}</Box>
      </Container>
    </Container>
  );
}

export default ProfileBody;
