import { Grid } from "@mui/material";
import FormCheckBox from "components/forms/FormCheckBox";
import FormInputDatePicker from "components/forms/FormInputDatePicker";
import FormInputSelect from "components/forms/FormInputSelect";
import FormInputTextSecondary from "components/forms/FormInputTextSecondary";
import FormRadioButton from "components/forms/FormRadioButton";
import { businessTypeOptions } from "constants";
import { minortyOptions } from "constants";
import dayjs from "dayjs";
import React from "react";
import Wrapper from "../../../comp/Wrapper";

function BusinessForm({
  control,
  errors,
  services,
  servicesLoading,
  getValues,
  date,
}) {
  const { dateOfRegisteration } = getValues();

  return (
    <Wrapper label="Business Information">
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xxs={12}>
          <FormCheckBox
            control={control}
            name="businessCriteria"
            label="Is Your Business?"
            options={businessTypeOptions}
            row={true}
            sx={{ mr: "3.5rem" }}
            errors={errors?.businessCriteria?.message}
          />
        </Grid>
        <Grid item xxs={12} sm={6}>
          <FormInputTextSecondary
            control={control}
            label="Business Name"
            name="businessName"
            placeholder="Enter your business name"
            errors={errors?.businessName?.message}
          />
        </Grid>
        <Grid item xxs={12} sm={6}>
          <FormInputDatePicker
            control={control}
            name="dateOfRegisteration"
            label="Date of Registration"
            defaultValue={dateOfRegisteration ? dateOfRegisteration : null}
            errors={errors?.dateOfRegisteration?.message}
            maxDate={dayjs(date)}
            minDate={dayjs(new Date(1900, 0, 1))}
            placeholder="Enter your date of registration"
          />
        </Grid>
        <Grid item xxs={12} sm={6}>
          <FormRadioButton
            name="virtualEvents"
            control={control}
            label="Does your business offer virtual events?"
            options={[
              { label: "Yes", value: "yes" },
              { label: "No", value: "no" },
            ]}
            row={true}
            sx={{ mr: "3.5rem" }}
            errors={errors?.virtualEvents?.message}
          />
        </Grid>
        <Grid item xxs={12}>
          <FormCheckBox
            control={control}
            name="minorityEligibility"
            errors={errors?.minority?.message}
            label="Minority-Owned Business Eligibility:"
            options={minortyOptions}
            row={true}
          />
        </Grid>
        <Grid item xxs={12}>
          <FormInputTextSecondary
            name="serviceDescription"
            errors={errors?.serviceDescription?.message}
            control={control}
            label="Description of Talent or Service"
            placeholder="Enter description here"
            multiline
            minRows={4}
            maxRows={7}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "12px",
                maxHeight: "none",
              },
            }}
          />
        </Grid>

        <Grid item xxs={12}>
          <Grid>
            <Grid item xxs={12} sm={6}>
              <FormInputSelect
                name="skills"
                label="Services"
                width={{ xxs: "300px", sm: "400px", md: "600px" }}
                multiple={true}
                control={control}
                options={
                  servicesLoading || services?.length <= 0 || !services
                    ? []
                    : services?.map((service) => ({
                        label: service.name,
                        value: service._id,
                      }))
                }
                placeholder="Enter your skills"
                errors={errors?.skills?.message}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default BusinessForm;
