import * as React from "react";
import { Box } from "@mui/system";
import Drawer from "@mui/material/Drawer";

import DrawerList from "./comp/DrawerList";
import NavBar2 from "../AppBar/NavBar2";
import { useUserContext } from "context/UserContext";

const drawerWidth = 240;

function CustomDrawer({ window }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { isVendor } = useUserContext();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <>
      <NavBar2
        drawerWidth={drawerWidth}
        setMobileOpen={setMobileOpen}
        mobileOpen={mobileOpen}
      />

      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xxs: "block", md: "none" },

            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: isVendor ? "#0E0026" : "#280071",
              color: "#ffffff",
            },
          }}
        >
          <DrawerList />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xxs: "none", md: "block" },

            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: isVendor ? "#0E0026" : "#280071",
              color: "#ffffff",
            },
          }}
          open
        >
          <DrawerList />
        </Drawer>
      </Box>
    </>
  );
}

export default CustomDrawer;
