import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import { getBooking } from "api/v1/booking";
import { getPaymentServicesStatus } from "api/v1/vendor";
import DashboardCard from "components/styled/DashboardCard";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import PaymentForm from "./comp/PaymentForm";
function BookingsPayment() {
  const { bookingId } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(["booking", bookingId], async () => {
    const res = await getBooking({ id: bookingId });
    return res.data;
  });
  const { data: paymentServicesStatus } = useQuery(
    ["payment-services-status"],
    async () => {
      const res = await getPaymentServicesStatus({
        id: data?.vendorId?._id,
      });
      return res.data;
    },
    {
      enabled: Boolean(data?.vendorId?._id),
    }
  );

  if (isLoading) {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else if (data?.paymentStatus === "paid") {
    navigate("/dashboard/bookings/" + bookingId);
    return;
  } else {
    return (
      <Container sx={{ py: "1rem" }}>
        <Box sx={{ py: "1.4rem" }}>
          <Typography variant="h4" sx={{ fontFamily: "Playfair Display" }}>
            Create Booking
          </Typography>
        </Box>
        <Box>
          <DashboardCard sx={{ p: "2rem" }}>
            <PaymentForm
              bookingId={bookingId}
              partiallyPaid={data.paymentStatus === "partiallyPaid"}
              paymentServicesStatus={paymentServicesStatus}
            />
          </DashboardCard>
        </Box>
      </Container>
    );
  }
}

export default BookingsPayment;
