import * as React from "react";
import bannerImage from "assets/services/vendor.svg";
import { Box } from "@mui/material";
import Banner from "components/banner/Banner";
import Footer from "components/navigation/footer/Footer";
import VendorList from "components/Vendors";

const Vendors = ({ isFavVendors = false, isDashboard = false }) => {
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {!isDashboard && (
        <Box sx={{ height: "600px" }}>
          <Banner
            bannerImage={bannerImage}
            title="Let’s Plan Your
          Dream Event"
            opacity={0.5}
          />
        </Box>
      )}
      <VendorList isDashboard={isDashboard} />
      {!isDashboard && (
        <Box>
          <Footer />
        </Box>
      )}
    </Box>
  );
};

export default Vendors;
