import LocationOnIcon from "@mui/icons-material/LocationOn";
import { FormControl, FormHelperText } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";
import * as React from "react";
import { geocodeByAddress, getLatLng } from "react-places-autocomplete";
// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = "AIzaSyC2B_uOINR8DeqETvvYEkf-FTkD07_oet8";

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };

export default function GoogleMaps({
  label,
  errors,
  defaultValue = null,
  setFormValue = null,
  customHeight = "45px",
  name = "location",
  color,
  style,
}) {
  const [value, setValue] = React.useState(defaultValue?.address ?? null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (!newValue) {
          setFormValue?.("location", "null");
        }
        if (newValue && Object.keys(newValue)?.length > 0) {
          const addressObj = {
            address: newValue.description,
          };
          geocodeByAddress(newValue.description)
            .then((results) => {
              for (let address_component of results[0]["address_components"]) {
                if (
                  address_component["types"][0] ===
                  "administrative_area_level_2"
                ) {
                  addressObj.city = address_component["long_name"];
                }
                if (
                  address_component["types"][0] ===
                  "administrative_area_level_1"
                ) {
                  addressObj.state = address_component["long_name"];
                }
                if (address_component["types"][0] === "country") {
                  addressObj.country = address_component["long_name"];
                }
                if (address_component["types"][0] === "postal_code") {
                  addressObj.postalCode = address_component["long_name"];
                }
              }
              return getLatLng(results[0]);
            })
            .then(({ lat, lng }) => {
              addressObj.coordinates = [lng, lat];
              addressObj.type = "Point";
              setFormValue?.("location", addressObj);
            })
            .catch((error) => console.error("Error", error));
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <FormControl sx={{ display: "flex", maxWidth: "100%" }}>
          {label && (
            <Typography
              id="input-label"
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "26px",
                color: color ? color : "#01214D",
                textAlign: "left",
                marginBottom: "5px",
              }}
              focused={false}
            >
              {label}
            </Typography>
          )}
          <StyledTextField
            style={style}
            customHeight={customHeight}
            placeholder="Add a location"
            {...params}
            InputLabelProps={{ shrink: false }}
            fullWidth
          />
          {errors && (
            <FormHelperText error>{errors?.[name]?.message}</FormHelperText>
          )}
        </FormControl>
      )}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings;
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match) => [match.offset, match.offset + match.length])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: "text.secondary", mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}

const StyledTextField = styled(TextField)(({ theme, customHeight, style }) => ({
  "& .MuiOutlinedInput-root": {
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    maxHeight: customHeight,
  },
  borderRadius: "123px",

  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "primary.light",
  },
  ...style,
}));
