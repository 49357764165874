import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Box } from "@mui/system";
import NavList from "./comp/NavList";
import { onBoardPaypal } from "api/v1/paypal";
import { useMutation } from "react-query";

function NavBar2({ drawerWidth, setMobileOpen, mobileOpen }) {
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const { mutate: payPalClick, isLoading: payPalLinkLoading } = useMutation(
    async () => {
      const response = await onBoardPaypal();
      return response.data;
    },
    {
      onSuccess(data) {
        window.location.href = data.links[1].href;
      },
    }
  );

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          backgroundColor: "#ffffff",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" }, color: "#280071" }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              alignItems: "center",
            }}
          >
            <NavList
              payPalClick={payPalClick}
              payPalLinkLoading={payPalLinkLoading}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

export default NavBar2;
