import { Box, Container, Typography } from "@mui/material";
import CustomCard from "components/forms/CustomCard";
import backgroundImage from "assets/login/vendor-signup.png";
import partyPopperImage from "assets/login/party-popper.png";
import React from "react";
import ConfettiGenerator from "confetti-js";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ROUTES from "routes";
import { useUserContext } from "context/UserContext";

function SignUpConfirmation() {
  const [time, setTime] = React.useState(5);
  const { isAuthenticated } = useUserContext();
  const navigate = useNavigate();
  React.useEffect(() => {
    const confettiSettings = {
      target: "my-canvas",
    };
    const confetti = new ConfettiGenerator(confettiSettings);
    confetti.render();

    return () => confetti.clear();
  }, []);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTime((prev) => prev - 1);
    }, 1000);
    const timer = setTimeout(() => {
      if (isAuthenticated) navigate("/dashboard");
      navigate("/");
    }, 5000);

    return () => {
      clearTimeout(timer);
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <Box
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          flexGrow: "1",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            maxWidth: "100%",
            maxHeight: "100%",
            overflow: "hidden",
          }}
        >
          <canvas id="my-canvas"></canvas>
        </Box>
        <img
          src={backgroundImage}
          alt="sign-up-page"
          style={{
            objectFit: "cover",
            objectPosition: "center",
            position: "absolute",
            width: "100%",
            height: "100%",
            zIndex: "-2",
          }}
        />
        <div
          style={{
            width: "100%",
            height: "100%",
            backgroundColor: "#000",
            opacity: "0.3",
            position: "absolute",
            zIndex: "-1",
          }}
        />

        <Box sx={{ pt: "7rem" }}>
          <Container
            sx={{
              marginTop: "30px",
              pb: "20px",
            }}
            maxWidth="sm"
          >
            <Box
              sx={{
                textAlign: "center",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                mt: 2,
              }}
            >
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CustomCard sx={{ p: "2rem" }}>
                  <Box>
                    <Typography variant="pBold">Redirect in: {time}</Typography>
                  </Box>
                  <Box
                    sx={{
                      mb: "2rem",
                      display: "flex",
                      flexDirection: "column",
                      gap: 3,
                    }}
                  >
                    <Typography variant="h4" sx={{ zIndex: "9" }}>
                      Welcome to ThePartyStarter
                    </Typography>
                    <Box>
                      <Typography variant="h6">
                        Thank you for registering with us.
                      </Typography>
                      <Typography variant="h6">
                        ThePartyStarter will help you to organize, create and
                        manage important events
                      </Typography>
                      <img src={partyPopperImage} alt="confetti" style={{}} />
                    </Box>
                  </Box>
                  <Box sx={{ bottom: 0 }}>
                    <Typography variant="pBold">
                      PS: We hope you like to party!
                    </Typography>
                  </Box>
                </CustomCard>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </>
  );
}

export default SignUpConfirmation;
