import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Container, Divider, Typography } from "@mui/material";
import { checkUsernameAvailability, signup } from "api/v1/auth";
import backgroundImage2 from "assets/login/sign-up-ab.svg";
import backgroundImage from "assets/login/sign-up.svg";
import { USER_TYPE } from "constants";
import { PASSWORD_MIN_LENGTH, PASSWORD_REGEX } from "constraints";
import { useUserContext } from "context/UserContext";
import messages from "messages";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import classes from "./VerificationCode.module.css";
import { toast } from "react-toastify";
import * as yup from "yup";
import FormOne from "../SignUpPage/User/FormOne";
import FormTwo from "../SignUpPage/User/FormTwo";
import { useNavigate } from "react-router-dom";
import { validLocation } from "utilities";
import CustomCard from "components/forms/CustomCard";
function VerificationCode() {
  const pin1 = React.useRef();
  const pin2 = React.useRef();
  const pin3 = React.useRef();
  const pin4 = React.useRef();

  const [value1, setValue1] = React.useState("");
  const [value2, setValue2] = React.useState("");
  const [value3, setValue3] = React.useState("");
  const [value4, setValue4] = React.useState("");
  const emailVerify = /^[0-9].{1}$/gi;
  const handleChange1 = (e) => {
    if (e.target.value === "") {
      setValue1("");
      return;
    }
    setValue1(e.target.value.replace(emailVerify, value1));
    pin2.current.focus();
  };
  const handleChange2 = (e) => {
    if (e.target.value === "") {
      setValue2("");
      pin1.current.focus();
    } else {
      setValue2(e.target.value.replace(emailVerify, value2));
      pin3.current.focus();
    }
  };
  const handleChange3 = (e) => {
    if (e.target.value === "") {
      setValue3("");
      pin2.current.focus();
    } else {
      setValue3(e.target.value.replace(emailVerify, value3));
      pin4.current.focus();
    }
  };
  const handleChange4 = (e) => {
    if (e.target.value === "") {
      setValue4("");
      pin3.current.focus();
    } else {
      setValue4(e.target.value.replace(emailVerify, value4));
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  };
  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: "1",
        position: "relative",
      }}
    >
      <img
        src={false ? backgroundImage2 : backgroundImage}
        alt="sign-up-page"
        style={{
          objectFit: "cover",
          objectPosition: "center",
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: "-2",
        }}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#000",
          opacity: "0.3",
          position: "absolute",
          zIndex: "-1",
        }}
      />
      <Box sx={{ pt: "7rem" }}>
        <Container
          sx={{
            marginTop: "30px",
            marginBottom: "20px",
          }}
          maxWidth="md"
        >
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              mt: 2,
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "3.5rem",
                  lineHeight: "70px",
                  fontWeight: "800",
                  pb: 2,
                  pt: 2,
                }}
                variant="h1"
              >
                Your Dream Event Starts Here
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box
                sx={{
                  maxWidth: "600px",
                }}
              >
                <CustomCard>
                  <Box sx={{ display: "flex", flexDirection: "column", p: 5 }}>
                    <Box>
                      <FormHead />
                    </Box>
                    <Divider />
                    <Box component="form" onSubmit={handleSubmit}>
                      <Box className={classes.container}>
                        <input
                          type="text"
                          maxLength={1}
                          className={classes.input}
                          ref={pin1}
                          value={value1}
                          onChange={handleChange1}
                        />
                        <input
                          type="text"
                          maxLength={1}
                          className={classes.input}
                          ref={pin2}
                          value={value2}
                          onChange={handleChange2}
                        />
                        <input
                          type="text"
                          maxLength={1}
                          className={classes.input}
                          ref={pin3}
                          value={value3}
                          onChange={handleChange3}
                        />
                        <input
                          type="text"
                          maxLength={1}
                          className={classes.input}
                          ref={pin4}
                          value={value4}
                          onChange={handleChange4}
                        />
                      </Box>
                      <Button
                        sx={{ marginTop: "20px" }}
                        variant="primary"
                        type="submit"
                      >
                        Submit
                      </Button>
                    </Box>
                  </Box>
                </CustomCard>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default VerificationCode;

const schema = yup
  .object({
    username: yup
      .string()
      .min(4)
      .required()
      .test(
        "username_async_validation",
        messages.usernameNotAvailable,
        async function (username) {
          const res = await checkUsernameAvailability({ username });
          if (res.data.isAvailable) {
            return this.resolve(res.data.isAvailable);
          } else {
            return this.createError({
              message: messages.usernameNotAvailable,
            });
          }
        }
      ),
    password: yup
      .string()
      .matches(PASSWORD_REGEX, messages.invalidPassword)
      .min(PASSWORD_MIN_LENGTH, messages.passwordLength)
      .required(messages.requiredField),
    passwordConfirm: yup
      .string()
      .required(messages.requiredField)
      .oneOf([yup.ref("password")], "Your passwords do not match."),
    firstName: yup.string().required(messages.requiredField),
    lastName: yup.string().required(messages.requiredField),
    telephoneNumber: yup
      .string()
      .min(7, "Enter a valid Phone number")
      .max(15, "Enter a valid Phone number")
      .required(messages.requiredField),
    email: yup.string().email().required(messages.requiredField),
  })
  .required();

function FormHead() {
  return (
    <>
      <Box sx={{ pb: "5px" }}>
        <Typography
          variant="pbold"
          style={{ fontSize: "2rem", lineHeight: "47px" }}
        >
          Sign Up
        </Typography>
      </Box>
      <Box sx={{ pb: "7px" }}>
        <Typography
          variant="p"
          style={{ lineHeight: "34px", fontSize: "1.25rem" }}
        >
          If you are already a user you can login with your email address and
          password.
        </Typography>
      </Box>
    </>
  );
}
