import React from "react";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";

import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/system";
import { Controller } from "react-hook-form";
import InputWrapper from "../Wrapper/InputWrapper";
import IconWrapper from "components/Wrapper/IconWrapper";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    minHeight: "64px",
  },
  borderRadius: "123px",

  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "primary.light",
  },
}));

function FormIconInputText({ name, label, control, errors, icon, ...rest }) {
  return (
    <InputWrapper label={label} errors={errors}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <StyledTextField
            {...field}
            InputProps={{
              startAdornment: (
                <InputAdornment position="end">
                  <IconWrapper
                    svgIcon={icon}
                    sx={{ width: "30px", height: "30px", mr: "0.85rem" }}
                  />
                </InputAdornment>
              ),
            }}
            {...rest}
          />
        )}
      />
    </InputWrapper>
  );
}

export default FormIconInputText;
