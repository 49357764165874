import * as React from "react";
import { Logout } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { ReactComponent as MessageIcon } from "assets/navbar/message-icon.svg";
import { useLogoutContext } from "context/LogoutContext";
import { useUserContext } from "context/UserContext";
import useImage from "Hooks/useImage";
import useStripeOnboardingLink from "Hooks/useStripeOnboardingLink";
import { Link, useNavigate } from "react-router-dom";
import stripe from "assets/stripe/stripe.svg";
import useMenu from "Hooks/useMenu";
import StripeIcon from "assets/logo/stripe.svg";
import PaypalIcon from "assets/logo/paypal.svg";
import AccountIcon from "assets/navbar/account.svg";
import ImageWrapper from "components/Wrapper/ImageWrapper";
import { useMutation } from "react-query";
import { deauthorizeAccount } from "api/v1/stripe";
import CloseIcon from "@mui/icons-material/Close";
import AlertDialog from "components/Dialog/AlertDialog";
import { toast } from "react-toastify";

const NavList = ({ payPalClick, payPalLinkLoading }) => {
  const theme = useTheme();
  const { getStripeOnboardingLink, isLoading: isStripeLoading } =
    useStripeOnboardingLink();
  const {
    user,
    isVendor,
    isPaypalConnected,
    isStripeConnected,
    setPaymentStatusRefetch,
    paymentStatusRefetch,
  } = useUserContext();
  const { setLogoutModal } = useLogoutContext();
  const navigate = useNavigate();
  const [stripeDialog, setStripeDialog] = React.useState(false);
  const [paypalDialog, setPaypalDialog] = React.useState(false);

  const { data: profilePhoto } = useImage({
    key: user?.profilePhoto,
  });

  const { mutate, isLoading } = useMutation(
    async () => await deauthorizeAccount(),
    {
      onSuccess: () => {
        setPaymentStatusRefetch(!paymentStatusRefetch);
        setStripeDialog(false);
        toast.success("Your stripe account has been delinked");
      },
    }
  );

  const handleDialogOpen = (setDialog) => {
    setDialog(true);
  };
  const handleDialogClose = (setDialog) => {
    setDialog(false);
  };

  return (
    <>
      <Box
        sx={{
          display: { xxs: "none", md: "flex" },
          alignItems: "center",
          justifyContent: "flex-end",
        }}
      >
        {isVendor ? (
          <Box sx={{ ml: "0.5rem", display: "flex", gap: 1 }}>
            {isPaypalConnected ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  border: `1px solid ${theme.palette.primary.main}`,
                  borderRadius: "8px",
                  p: 0,
                  m: 0,
                }}
              >
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    px: 1,
                    alignItems: "center",
                    gap: "5px",
                    py: 1,
                  }}
                >
                  <Typography variant="p600">PayPal</Typography>
                  <Tooltip title="connected" enterTouchDelay={0}>
                    <Box
                      sx={{
                        borderRadius: "50%",
                        backgroundColor: "success.main",
                        width: "10px",
                        height: "10px",
                      }}
                    />
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    borderLeft: `1px solid ${theme.palette.primary.main} `,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    "-webkit-transition":
                      "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    transition:
                      "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    "&: hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                    p: 1,
                  }}
                  onClick={() => handleDialogOpen(setPaypalDialog)}
                >
                  <CloseIcon color="error" />
                </Box>
              </Box>
            ) : (
              <Button
                startIcon={
                  <img
                    src="https://www.paypalobjects.com/webstatic/en_US/i/buttons/PP_logo_h_150x38.png"
                    alt="PayPal"
                    width="100px"
                    height="23px"
                  />
                }
                endIcon={
                  payPalLinkLoading ? (
                    <CircularProgress size={15} sx={{ color: "#383837" }} />
                  ) : null
                }
                onClick={payPalClick}
                sx={{
                  backgroundColor: "#EEEEEE",
                  color: "#383837",
                  fontSize: "0.9rem",
                  fontFamily: "Sans-serif",
                  py: 1,
                  px: 2,
                  fontWeight: "600",
                  display: "inline-flex",
                  alignItems: "center",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#EEEEEE",
                    opacity: "0.9",
                  },
                }}
              >
                Connect
              </Button>
            )}
            {isStripeConnected ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  border: `1px solid ${theme.palette.primary.main}`,
                  borderRadius: "8px",
                  p: 0,
                  m: 0,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    px: 1,
                    alignItems: "center",
                    gap: "5px",
                    py: 1,
                  }}
                >
                  <Typography variant="p600">Stripe</Typography>
                  <Tooltip title="connected" enterTouchDelay={0}>
                    <Box
                      sx={{
                        borderRadius: "50%",
                        backgroundColor: "success.main",
                        width: "10px",
                        height: "10px",
                      }}
                    />
                  </Tooltip>
                </Box>
                <Box
                  sx={{
                    borderLeft: `1px solid ${theme.palette.primary.main} `,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                    "-webkit-transition":
                      "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    transition:
                      "background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    "&: hover": {
                      backgroundColor: "rgba(0, 0, 0, 0.04)",
                    },
                    p: 1,
                  }}
                  onClick={() => handleDialogOpen(setStripeDialog)}
                >
                  <CloseIcon color="error" />
                </Box>
              </Box>
            ) : (
              <Button
                startIcon={
                  <Box display="flex" alignItems="center">
                    <img src={stripe} alt="PayPal" width="60px" height="30px" />
                    {isStripeLoading ? (
                      <CircularProgress size={15} sx={{ color: "#383837" }} />
                    ) : null}
                  </Box>
                }
                onClick={getStripeOnboardingLink}
                sx={{
                  backgroundColor: "#EEEEEE",
                  color: "#383837",
                  fontSize: "0.9rem",
                  fontFamily: "Sans-serif",
                  py: 1,
                  px: 1,
                  ml: 1,
                  fontWeight: "600",
                  display: "inline-flex",
                  alignItems: "center",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#EEEEEE",
                    opacity: "0.9",
                  },
                }}
              >
                Connect
              </Button>
            )}
          </Box>
        ) : null}
        <Box sx={{ ml: "0.5rem" }}>
          <IconButton onClick={() => navigate("/dashboard/messages")}>
            <MessageIcon />
          </IconButton>
        </Box>
        <Box sx={{ ml: "0.5rem" }}>
          <Link to={isVendor ? "profile" : "/profile/edit-profile"}>
            <Button startIcon={<Avatar src={profilePhoto} />}>
              {user?.firstName
                ? `${user?.firstName} ${user?.lastName}`
                : user?.fullName}
            </Button>
          </Link>
        </Box>
        <Box sx={{ ml: "0.5rem" }}>
          <Button
            onClick={() => setLogoutModal(true)}
            startIcon={<Logout />}
          ></Button>
        </Box>
      </Box>
      <Box sx={{ display: { xxs: "flex", md: "none" } }}>
        <MobileMenu
          firstName={user?.firstName}
          lastName={user?.lastName}
          fullName={user?.fullName}
          isVendor={isVendor}
          profilePhoto={profilePhoto}
          navigate={navigate}
          payPalClick={payPalClick}
          getStripeOnboardingLink={getStripeOnboardingLink}
          setLogoutModal={setLogoutModal}
          isStripeConnected={isStripeConnected}
          isPaypalConnected={isPaypalConnected}
          handleDialogOpen={handleDialogOpen}
          setStripeDialog={setStripeDialog}
          setPaypalDialog={setPaypalDialog}
        />
      </Box>
      <AlertDialog
        title="Stripe Disconnect"
        description="Do you wish to disconnect your account from ThePartyStarter connect platform?"
        open={stripeDialog}
        handleSubmit={mutate}
        isLoading={isLoading}
        handleClose={() => handleDialogClose(setStripeDialog)}
      />
      <AlertDialog
        title="Paypal Disconnect"
        description="If you wish to disconnect your account from ThePartyStarter connect platform visit your paypal dashboard to disconnect your account."
        open={paypalDialog}
        handleClose={() => handleDialogClose(setPaypalDialog)}
        disagreeText="Close"
      />
    </>
  );
};

export default NavList;

function MobileMenu({
  firstName,
  lastName,
  fullName,
  isVendor,
  profilePhoto,
  navigate,
  payPalClick,
  getStripeOnboardingLink,
  setLogoutModal,
  isPaypalConnected,
  isStripeConnected,
  handleDialogOpen,
  setStripeDialog,
  setPaypalDialog,
}) {
  const menuItems = [
    {
      label: "Profile",
      icon: <ImageWrapper svgIcon={AccountIcon} alt="profile" />,
      func: () => navigate(isVendor ? "profile" : "/profile/edit-profile"),
      style: {},
      active: true,
    },
    {
      label: "Messages",
      icon: <MessageIcon />,
      func: () => navigate("/dashboard/messages"),
      style: {},
      active: true,
    },
    {
      label: isStripeConnected ? "Stripe Connected" : "Stripe Connect",
      icon: <ImageWrapper svgIcon={StripeIcon} alt="Stripe" />,
      func: () => getStripeOnboardingLink(),
      style: isStripeConnected
        ? {
            color: "#689f38",
          }
        : {},
      active: !isStripeConnected,
      secondFunc: isStripeConnected ? (
        <IconButton
          size="small"
          edge="end"
          aria-label="close-stripe"
          onClick={() => handleDialogOpen(setStripeDialog)}
        >
          <CloseIcon color="error" />
        </IconButton>
      ) : null,
    },
    {
      label: isPaypalConnected ? "PayPal Connected" : "PayPal Connect",
      icon: <ImageWrapper svgIcon={PaypalIcon} alt="paypal" />,
      func: () => payPalClick(),
      style: isPaypalConnected
        ? {
            color: "#689f38",
          }
        : {},
      active: !isPaypalConnected,
      secondFunc: isPaypalConnected ? (
        <IconButton
          size="small"
          edge="end"
          aria-label="close-paypal"
          onClick={() => handleDialogOpen(setPaypalDialog)}
        >
          <CloseIcon color="error" />
        </IconButton>
      ) : null,
    },
    {
      label: "Logout",
      icon: <Logout />,
      func: () => setLogoutModal(true),
      style: {},
      active: true,
    },
  ];

  const { open, anchorEl, handleClick, handleClose } = useMenu();

  return (
    <div>
      <Box sx={{ ml: "0.5rem" }}>
        <Button startIcon={<Avatar src={profilePhoto} />} onClick={handleClick}>
          {isVendor ? fullName : `${firstName} ${lastName}`}
        </Button>
      </Box>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "menu-button",
        }}
      >
        {menuItems.map(
          ({ label, icon, func, style, active, secondFunc = null }) => (
            <>
              {" "}
              {!isVendor &&
              (label === "Stripe Connect" ||
                label === "Paypal Connect") ? null : (
                <MenuItem
                  key={label}
                  onClick={() => {
                    if (active) {
                      func();
                    }
                    handleClose();
                  }}
                  dense
                  sx={{ display: "flex", alignItems: "center" }}
                >
                  <ListItem
                    dense
                    sx={{
                      columnGap: "7px",
                      px: 0,
                      py: 1,
                      alignItems: "center",
                      display: "flex",
                      margin: 0,
                      justifyContent: "space-between",
                    }}
                  >
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText sx={{ ...style }}>{label}</ListItemText>
                    {secondFunc && <ListItemIcon>{secondFunc}</ListItemIcon>}
                  </ListItem>
                </MenuItem>
              )}
            </>
          )
        )}
      </Menu>
    </div>
  );
}
