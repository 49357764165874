import React from "react";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/system";

let StyledPaper = styled(Paper)({
  background: "#FFFFFF",
  border: "3px solid #FFFFFF",
  boxSizing: "border-box",
  borderRadius: "19px",
  width: "100%",
});

function CustomCard({ style, children, ...rest }) {
  return (
    <StyledPaper style={style} {...rest}>
      {children}
    </StyledPaper>
  );
}

export default CustomCard;
