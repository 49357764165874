import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Container, Typography } from "@mui/material";
import { checkUsernameAvailability, signup } from "api/v1/auth";
import backgroundImage2 from "assets/login/sign-up-ab.svg";
import backgroundImage from "assets/login/sign-up.svg";
import { USER_TYPE } from "constants";
import { PASSWORD_MIN_LENGTH, PASSWORD_REGEX } from "constraints";
import { useUserContext } from "context/UserContext";
import messages from "messages";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import FormOne from "./FormOne";
import FormTwo from "./FormTwo";
import { useNavigate } from "react-router-dom";
import ROUTES from "routes";

function SignUpPage() {
  const { setToken } = useUserContext();
  const { mutateAsync, isLoading, isError, isSuccess, error } =
    useMutation(signup);
  const [toggleForm, setToggleForm] = useState(false);

  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    trigger,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: "",
      password: "",
      passwordConfirm: "",
      firstName: "",
      lastName: "",
      telephoneNumber: "",
      email: "",
      location: "",
    },
    resolver: yupResolver(schema),
  });

  async function onSubmit(data) {
    try {
      const res = await mutateAsync({
        userType: USER_TYPE.CUSTOMER,
        body: { ...data },
      });
      setToken(res.data.token);
      navigate(ROUTES.SIGNUP_CONFIRMATION.USER, {
        state: ROUTES.SIGNUP.USER,
        replace: true,
      });
    } catch (err) {}
  }

  const handleClickContinue = async () => {
    const result = await trigger(["username", "password", "passwordConfirm"]);
    setToggleForm(result);
  };
  const handleClickBack = async () => {
    setToggleForm(false);
  };

  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: "1",
        position: "relative",
      }}
    >
      <img
        src={toggleForm ? backgroundImage2 : backgroundImage}
        alt="sign-up-page"
        style={{
          objectFit: "cover",
          objectPosition: "center",
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: "-2",
        }}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#000",
          opacity: "0.3",
          position: "absolute",
          zIndex: "-1",
        }}
      />
      <Box sx={{ pt: "7rem" }}>
        <Container
          sx={{
            marginTop: "30px",
            marginBottom: "20px",
          }}
          maxWidth="md"
        >
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              mt: 2,
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "3.5rem",
                  lineHeight: "70px",
                  fontWeight: "800",
                  pb: 2,
                  pt: 2,
                }}
                variant="h1"
              >
                Your Dream Event Starts Here
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                {!toggleForm ? (
                  <FormOne
                    control={control}
                    handleClick={handleClickContinue}
                    errors={errors}
                    trigger={trigger}
                  />
                ) : (
                  <FormTwo
                    control={control}
                    errors={errors}
                    handleClick={handleClickBack}
                    isLoading={isLoading}
                    isError={isError}
                    isSuccess={isSuccess}
                    error={error}
                    setValue={setValue}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default SignUpPage;

const schema = yup
  .object({
    username: yup
      .string()
      .min(4)
      .required()
      .test(
        "username_async_validation",
        messages.usernameNotAvailable,
        async function (username) {
          const res = await checkUsernameAvailability({ username });
          if (res.data.isAvailable) {
            return this.resolve(res.data.isAvailable);
          } else {
            return this.createError({
              message: messages.usernameNotAvailable,
            });
          }
        }
      ),
    password: yup
      .string()
      .matches(PASSWORD_REGEX, messages.invalidPassword)
      .min(PASSWORD_MIN_LENGTH, messages.passwordLength)
      .required(messages.requiredField),
    passwordConfirm: yup
      .string()
      .required(messages.requiredField)
      .oneOf([yup.ref("password")], "Your passwords do not match."),
    firstName: yup
      .string()
      .required(messages.requiredField)
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    lastName: yup
      .string()
      .required(messages.requiredField)
      .matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    telephoneNumber: yup
      .string()
      .min(7, "Enter a valid Phone number")
      .max(15, "Enter a valid Phone number")
      .required(messages.requiredField),
    email: yup.string().email().required(messages.requiredField),
    location: yup
      .object()
      .shape({
        type: yup.string().required(),
        address: yup.string().required(),
        state: yup.string().required(),
        country: yup.string().required(),
        coordinates: yup.array().of(yup.number()).required(),
      })
      .nullable()
      .required(messages.requiredField),
  })
  .required();
