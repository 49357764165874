import { Box, CircularProgress, Grid, Typography } from "@mui/material";
import React from "react";

import { ReactComponent } from "assets/dashboard/quotes/quoteAvatar.svg";
import DetailCard from "components/DetailCard/DetailCard";
import Lottie from "lottie-react";
import quotesLottie from "assets/lottie/quoteslottie.json";
import LoadMore from "components/LoadMore/LoadMore";
import ListHeader from "components/ListHeader/ListHeader";
import { listMessages } from "constants";

const headers = [
  { id: "name", label: "Name", col: 3 },
  { id: "event-title", label: "Event Title", col: 1.8 },
  { id: "location", label: "Location", col: 1.8 },
  { id: "event-date", label: "Event Date", col: 1.8 },
  { id: "price-offered", label: "Price Offered", col: 1.8 },
  { id: "status", label: "Status", col: 1.8 },
];

function BottomSectionTable({
  data,
  isLoading,
  loadMoreRef,
  hasNextPage,
  isVendor = false,
  isData,
}) {
  
  if (isVendor) headers[0] = { id: "customer", label: "Customer", col: 3 };
  return (
    <Grid
      container
      justifyContent="center"
      sx={{
        color: "#000",
        py: "1rem",
        minWidth: isData ? "680px" : "0",
      }}
    >
      <Grid item xxs={12} sx={{ borderBottom: "1.4px solid #EBEAED" }}>
       {isData && <ListHeader headers={headers} spacing={true} />}
      </Grid>
      <Grid
        item
        container
        xxs={12}
        justifyContent="center"
        rowSpacing={4}
        sx={{ backgroundColor: "#fffff" }}
      >
        {isLoading ? (
          <div
            className="center"
            style={{
              marginTop: "2rem",
            }}
          >
            <CircularProgress size="3rem" />
          </div>
        ) : data?.pages?.flat().length > 0 ? (
          data?.pages?.flat().map((quote, index) => (
            <Grid key={index} item xxs={12}>
              <DetailCard
                quote={quote}
                Icon={ReactComponent}
                detailUrl="/dashboard/quotes/details"
              />
            </Grid>
          ))
        ) : (
          <Box
            sx={{
              marginTop: "60px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography variant="h4" sx={{ textAlign: "center" }}>
                {isVendor
                  ? listMessages.quote.vendor
                  : listMessages.quote.customer}
              </Typography>
            </Box>
            <Box style={{ maxWidth: "630px" }}>
              <Lottie animationData={quotesLottie} loop={true} />
            </Box>
          </Box>
        )}
        <LoadMore loadMoreRef={loadMoreRef} hasNextPage={hasNextPage} />
      </Grid>
    </Grid>
  );
}

export default BottomSectionTable;
