import { yupResolver } from "@hookform/resolvers/yup";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import DashboardCard from "components/styled/DashboardCard";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { createBooking } from "api/v1/booking";
import messages from "messages";
import { useMutation, useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import TopSection from "./comp/TopSection";
import { getUpcomingEvents } from "api/v1/event";
import { toast } from "react-toastify";

function BookingsCreatePage() {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      amount: null,
      eventId: null,
      acceptVendorPolicy: "",
      acceptPlatformPolicy: "",
    },
    resolver: yupResolver(schema),
  });
  const { vendorId } = useParams();
  const { data: upcomingEvents, isLoading } = useQuery(
    ["upcoming-events", vendorId],
    async () =>
      await getUpcomingEvents({ queryParams: { vendorId, limit: 10000 } })
  );
  const { mutateAsync } = useMutation(createBooking);

  const navigate = useNavigate();

  async function onSubmit(data) {
    const body = {
      ...data,
      acceptPlatformPolicy: Boolean(data.acceptPlatformPolicy),
      acceptVendorPolicy: Boolean(data.acceptVendorPolicy),
      vendorId,
    };

    try {
      const res = await mutateAsync({
        body,
      });
      navigate(`/dashboard/bookings/${res.data._id}/payment`);
    } catch (err) {
      toast.error(err.errMsg);
    }
  }

  function onCreateEventClick() {
    navigate("/dashboard/events/plan");
  }

  return (
    <Container sx={{ py: "1rem" }}>
      <Box sx={{ py: "1.4rem" }}>
        <Typography variant="h4" sx={{ fontFamily: "Playfair Display" }}>
          Create Booking
        </Typography>
      </Box>
      <Box>
        <DashboardCard sx={{ p: "2rem" }}>
          {upcomingEvents?.data?.length ? (
            <Box
              width="100%"
              component="form"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Grid container rowSpacing={6}>
                <Grid item xxs={12}>
                  <TopSection
                    control={control}
                    errors={errors}
                    watch={watch}
                    vendorId={vendorId}
                  />
                </Grid>
                <Grid item xxs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Box mr={"24px"}>
                      <Button variant="secondary">Cancel</Button>
                    </Box>
                    <Button type="submit" variant="primary">
                      Book Now
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ) : isLoading ? (
            <CircularProgress />
          ) : (
            <div>
              <Typography variant="h5" sx={{ my: "20px" }}>
                You currently do not have any upcoming event
              </Typography>
              <Button variant="primary" onClick={onCreateEventClick}>
                Create new event
              </Button>
            </div>
          )}
        </DashboardCard>
      </Box>
    </Container>
  );
}

export default BookingsCreatePage;

const schema = yup
  .object({
    amount: yup
      .number()
      .typeError(messages.invalidValue)
      .required(messages.requiredField),
    eventId: yup
      .string()
      .typeError(messages.invalidValue)
      .required(messages.requiredField),
    acceptVendorPolicy: yup
      .string()
      .typeError(messages.invalidValue)
      .required(messages.requiredField),
    acceptPlatformPolicy: yup
      .string()
      .typeError(messages.invalidValue)
      .required(messages.requiredField),
  })
  .required();
