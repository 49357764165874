import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconWrapper from "components/Wrapper/IconWrapper";

export default function CustomStepper2({ step, values }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      sx={{
        bgcolor: "transparent",
        maxWidth: { sm: "100%" },
      }}
    >
      <Tabs
        value={step}
        variant={"fullWidth"}
        aria-label="tabs"
        sx={{
          "& .Mui-selected": {
            color: "#000000 !important",
          },
          "& .MuiTabs-indicator": {
            borderBottom: "4px solid #000000",
          },
          cursor: "default",
        }}
      >
        {values.map((x) => (
          <Tab
            key={x.label}
            label={matches ? null : x.label}
            icon={
              <IconWrapper
                sx={{ width: "28px", height: "28px" }}
                svgIcon={x.icon}
              />
            }
            disableRipple
            iconPosition="start"
            sx={{
              textTransform: "none",
              fontSize: { xxs: "0.6rem", sm: "0.9rem", md: "1.25rem" },
              borderBottom: "4px solid #E7E4E4",
              cursor: "default",
              opacity: x.stepValue !== step && ".5",
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
