import React from "react";
import { Box } from "@mui/system";
function ActiveTab({ children, currentTab }) {
  return (
    <Box>
      {children && Array.isArray(children)
        ? children.filter((child) => child.props.value === currentTab)
        : children}
    </Box>
  );
}

export default ActiveTab;
