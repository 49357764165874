import { Box, Typography } from "@mui/material";
import Image from "components/Image";
import React from "react";
import Linkify from "react-linkify";
import classes from "./index.module.css";
const InboxChatHead = ({
  userMetaData,
  message,
  attachments,
  messageSendingTime,
  userId,
}) => {
  return (
    <Box
      className={`${
        userId !== userMetaData._id
          ? classes.otherMessageAlignment
          : classes.myMessageAlignment
      }`}
    >
      {message && (
        <div
          className={`${classes.Chat_Head_Message} ${
            userId !== userMetaData._id ? classes.User_Chat_Head_Message : ""
          }`}
        >
          <Typography sx={{ wordBreak: "break-all" }}>
            <Linkify>{message}</Linkify>
          </Typography>
        </div>
      )}
      {attachments?.map((item) => (
        <Image
          src={item}
          style={{
            height: "20rem",
            objectFit: "contain",
            marginTop: "1rem",
            width: "100%",
          }}
        />
      ))}
      <small
        className={`${
          userId !== userMetaData._id
            ? classes.userSendingTime
            : classes.sendingTime
        }`}
      >
        {messageSendingTime}
      </small>
    </Box>
  );
};

export default InboxChatHead;
