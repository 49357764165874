import { Box } from "@mui/system";
import CustomDrawer from "components/navigation/Drawer/CustomDrawer";
import ProtectedRoute from "components/ProtectedRoute/ProtectedRoute";
import React from "react";
import { Route, Routes } from "react-router-dom";
import NotFound from "views/pages/404/NotFound";
import Chat from "views/pages/Dashboard/Chat";
import DashboardPage from "views/pages/Dashboard/DashboardPage";
import Events from "views/pages/Dashboard/Events/AllEvents/Events";
import EventDetail from "views/pages/Dashboard/Events/EventDetail/EventDetail";
import MeetingsCreatePage from "views/pages/Dashboard/Meetings/MeetingsCreatePage";
import MeetingDetailsPage from "views/pages/Dashboard/Meetings/MeetingsDetailsPage";
import MeetingsListPage from "views/pages/Dashboard/Meetings/MeetingsListPage";
import BookingsListPage from "views/pages/Dashboard/Bookings/BookingsListPage";
import BookingDetailsPage from "views/pages/Dashboard/Bookings/BookingsDetailsPage";
import BookingsCreatePage from "views/pages/Dashboard/Bookings/BookingsCreatePage";
import BookingsPaymentPage from "views/pages/Dashboard/Bookings/BookingsCreatePage/payment";
import QuotesDetailsPage from "views/pages/Dashboard/Quotes/QuotesDetailsPage";
import QuotesListPage from "views/pages/Dashboard/Quotes/QuotesListPage";
import EditProfile from "views/pages/EditProfile/EditProfile";
import PlanPage from "views/pages/Event/EventPage/PlanPage";
import AllServices from "views/pages/Services/AllServices/AllServices";
import ServiceDetail from "views/pages/Services/ServiceDetail/ServiceDetail";
import Vendors from "views/pages/Services/Vendors/Vendors";
import PrivateRoutes from "components/ProtectedRoute/PrivateRoutes";
import VendorProfilePage from "views/pages/Profile/VendorProfilePage";
import VendorProfile from "views/pages/Profile/VendorProfilePage/VendorProfileDetail/VendorProfileDetail";
import VendorProfileEdit from "views/pages/Profile/VendorProfilePage/VendorProfileEdit/VendorProfileEdit";
import Onboard from "views/pages/Stripe/Onboard";

function SecondaryLayoutRoutes() {
  return (
    <>
      <Box sx={{ display: "flex", width: "100%", flex: 1 }}>
        <Box>
          <CustomDrawer />
        </Box>
        <Box sx={{ mt: { xxs: "56px", sm: "64px" }, width: "100%" }}>
          <Routes>
            <Route path="/" element={<ProtectedRoute />}>
              <Route
                path="/"
                element={
                  <PrivateRoutes allowedUsers={["Vendors", "Customers"]}>
                    <DashboardPage />
                  </PrivateRoutes>
                }
              />
              <Route path="/quotes" element={<QuotesListPage />} />
              <Route
                path="/vendors/:id"
                element={<ServiceDetail isDashboard={true} />}
              />
              <Route path="/event/:id/quotes" element={<QuotesListPage />} />
              <Route path="/quotes/:id" element={<QuotesDetailsPage />} />
              {/* MEETINGS */}
              <Route path="/meetings" element={<MeetingsListPage />} />
              <Route
                path="/meetings/:meetingId"
                element={<MeetingDetailsPage />}
              />
              <Route
                path="/meetings/:id/edit"
                element={<MeetingsCreatePage />}
              />
              <Route
                path="/vendors/:vendorId/meetings"
                element={<MeetingsCreatePage />}
              />
              {/* BOOKINGS */}
              <Route path="/bookings" element={<BookingsListPage />} />
              <Route
                path="/bookings/:bookingId"
                element={<BookingDetailsPage />}
              />
              <Route
                path="/vendors/:vendorId/bookings"
                element={<BookingsCreatePage />}
              />
              <Route
                path="/bookings/:bookingId/payment"
                element={<BookingsPaymentPage />}
              />
              <Route path="/events" element={<Events />} />
              <Route path="/events/:id" element={<EventDetail />} />
              <Route
                path="/profile"
                element={
                  <PrivateRoutes allowedUsers={["Vendors"]}>
                    <VendorProfilePage />
                  </PrivateRoutes>
                }
              >
                <Route path="" element={<VendorProfile />} />
                <Route path="edit" element={<VendorProfileEdit />} />
              </Route>
              <Route path="/profile/edit-profile" element={<EditProfile />} />
              <Route path="/messages" element={<Chat />} />
              <Route path="/messages/:id" element={<Chat />} />
              <Route
                path="/explore/vendors"
                element={<AllServices isDashboard={true} />}
              />
              <Route
                path="/services/:id/explore-vendors"
                element={<Vendors isDashboard={true} />}
              />
              <Route path="/events/plan" element={<PlanPage />} />
              <Route path="/events/:id/edit" element={<PlanPage />} />
              <Route path="/stripe/onboard" element={<Onboard />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          </Routes>
        </Box>
      </Box>
    </>
  );
}

export default SecondaryLayoutRoutes;
