import { Box, Typography } from "@mui/material";
import { ReactComponent as SearchIcon } from "assets/dashboard/quotes/search.svg";
import CustomInput from "components/InputField/CustomInput";
import CustomSelect from "components/InputField/CustomSelect";

export default function BookingsFilters({
  sortBy,
  status,
  setSortBy,
  setStatus,
  setSearch,
}) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box>
        <Typography
          variant="h4"
          style={{ fontFamily: "Playfair Display", paddingBottom: "1rem" }}
        >
          Bookings
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xxs: "column", sm: "row" },
          width: "100%",
        }}
      >
        <Box sx={{ flexGrow: 1, pb: { xxs: "0.5rem", md: "0" } }}>
          <CustomInput
            height="45px"
            adornment="endAdornment"
            position="end"
            Icon={SearchIcon}
            placeholder="Search here"
            onChange={(e) => setSearch(e.target.value)}
            textInputStyle={{ maxWidth: "700px" }}
          />
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ pl: "0.5rem" }}>
            <CustomSelect
              label="Sort By"
              data={[
                { value: "1", label: "Ascending" },
                { value: "-1", label: " Descending" },
              ]}
              height="45px"
              value={sortBy}
              setValue={setSortBy}
            />
          </Box>
          <Box sx={{ pl: "0.5rem" }}>
            <CustomSelect
              label="Status"
              data={[
                { value: "pending", label: "Pending" },
                { value: "disputed", label: "Disputed" },
                {
                  value: "completedByCustomer",
                  label: "Completed By Customer",
                },
                { value: "completedByVendor", label: "Completed By Vendor" },
                { value: "completed", label: "Completed" },
                { value: "cancelled", label: "Canceled" },
              ]}
              height="45px"
              value={status}
              setValue={setStatus}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
