import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  CircularProgress,
  Container,
  Typography,
  useTheme,
} from "@mui/material";
import { getAllServices } from "api/v1/public/services";
import bannerImage from "assets/services/banner.svg";
import Banner from "components/banner/Banner";
import CustomInput from "components/InputField/CustomInput";
import Footer from "components/navigation/footer/Footer";
import ServicesCard from "components/servicesCard/servicesCard";
import * as React from "react";
import classes from "./AllServices.module.css";
// import services from dummy data if you want to use it
import notFound from "assets/not-found/notFound.jpg";
import useInfiniteListQuery from "Hooks/useInfiniteListQuery";
import LoadMore from "components/LoadMore/LoadMore";
import useSearch from "Hooks/useSearch";

const AllServices = ({ isSavedVendor = false, isDashboard = false }) => {
  const [limit, setLimit] = React.useState(6);
  const theme = useTheme();
  const { onSearchChange, search } = useSearch();

  // const isSavedVendor = location.pathname.includes("/saved/vendors");
  const { data, isLoading, isRefetching, loadMoreRef, refetch, hasNextPage } =
    useInfiniteListQuery({
      queryKey: "allServices",
      queryFn: getAllServices,
      queryParams: {
        text: search,
        limit,
      },
      queryConfig: {
        refetchOnWindowFocus: false,
      },
    });
  React.useEffect(() => {
    refetch();
  }, [search, limit, refetch]);
  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {!isDashboard && (
        <Box sx={{ height: "600px" }}>
          <Banner
            bannerImage={bannerImage}
            title="Let’s Plan Your
          Dream Event"
            opacity="0.5"
          />
        </Box>
      )}
      <Container>
        <Box sx={{ textAlign: "center" }}>
          <Typography
            component="h1"
            variant="h1"
            color={theme.palette.primary.black}
            sx={{ marginTop: "120px" }}
          >
            All Services
          </Typography>
          <Typography
            component="p"
            variant="p"
            sx={{ marginBottom: "58px", marginTop: "22px" }}
          >
            Select a category below to view premier local and virtual vendors
          </Typography>
        </Box>
        <Box className={classes.container}>
          <form className={classes.form}>
            <Box className={classes.iconContainer}>
              <Box
                sx={{
                  dispplay: "flex",
                  flexDirection: "row",
                  maxWidth: "250px",
                  pb: { xxs: "0.5rem", md: "0" },
                }}
              >
                <CustomInput
                  key="keysishere"
                  height="45px"
                  adornment="endAdornment"
                  position="end"
                  Icon={isRefetching ? CircularProgress : SearchIcon}
                  iconProps={{ size: "1rem" }}
                  onChange={onSearchChange}
                  placeholder="Search here"
                />
              </Box>
            </Box>
          </form>
        </Box>
        <Box className={classes.cardContainer}>
          {isLoading ? (
            <div className="center">
              <CircularProgress size="3rem" />
            </div>
          ) : data?.pages?.flat()?.length > 0 ? (
            data?.pages?.flat()?.map((data) => {
              return (
                <ServicesCard
                  key={data._id}
                  heading={data.name}
                  text={data.description}
                  imageKey={data.image}
                  vendorLink={
                    !isDashboard
                      ? `/services/${data._id}/vendors`
                      : `/dashboard/services/${data._id}/explore-vendors`
                  }
                />
              );
            })
          ) : (
            <div className="center">
              <img src={notFound} alt="not found" />
            </div>
          )}
        </Box>
        <LoadMore loadMoreRef={loadMoreRef} hasNextPage={hasNextPage} />
      </Container>
      {!isDashboard && (
        <Box>
          <Footer />
        </Box>
      )}
    </Box>
  );
};

export default AllServices;
