import { Box, Button, Rating, Skeleton, Typography } from "@mui/material";
import React, { useRef } from "react";
import banner from "assets/dashboard/myEvent/banner.png";
import { Link } from "react-router-dom";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import PortraitOutlinedIcon from "@mui/icons-material/PortraitOutlined";
import AddPhotoAlternateOutlinedIcon from "@mui/icons-material/AddPhotoAlternateOutlined";
import axios from "axios";

function ProfileBanner({
  profileImage,
  coverImage,
  businessName,
  rating,
  isEdit = false,
  isCoverImageLoaded,
  mutatePhoto = null,
  isPhotoSubmitting = null,
  getUploadSignedUrlData = null,
  refetchCoverPhoto = null,
  refetchProfilePhoto = null,
  isProfileImageLoaded,
}) {
  isCoverImageLoaded = coverImage ? isCoverImageLoaded : true;

  const disableInputWhileOnChange = useRef({ cover: false, profile: false });
  const editImageStyle = isEdit
    ? null
    : {
        transition: "0.5s ease",
        "&:hover": {
          filter: "blur(2px)",
        },
      };

  const onChangeImage = async (e, key, objKey) => {
    if (key === "cover") {
      disableInputWhileOnChange.current.cover = true;
    } else {
      disableInputWhileOnChange.current.profile = true;
    }
    const file = e.target.files[0];
    const refetchImage =
      key === "cover" ? refetchCoverPhoto : refetchProfilePhoto;
    const uploadSignedUrl = await getUploadSignedUrlData({
      key,
    });

    await axios.put(uploadSignedUrl?.data?.url, file, {
      headers: {
        "Content-Type": file.type,
      },
    });
    const body = {
      [objKey]: uploadSignedUrl?.data?.key,
    };
    mutatePhoto(
      { body },
      {
        onSuccess: () => {
          refetchImage();
          if (key === "cover") {
            disableInputWhileOnChange.current.cover = false;
          } else {
            disableInputWhileOnChange.current.profile = false;
          }
        },

        onError: () => {
          if (key === "cover") {
            disableInputWhileOnChange.current.cover = true;
          } else {
            disableInputWhileOnChange.current.profile = true;
          }
        },
      }
    );
  };
  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      {isEdit ? (
        <Box
          sx={{
            top: "20px",
            position: "absolute",
            right: "0",
            zIndex: 1,
            px: 3,
          }}
        >
          <Button
            component={Link}
            to="/dashboard/profile/edit"
            variant="primary"
          >
            Edit
          </Button>
        </Box>
      ) : null}
      <Box
        sx={{
          position: "relative",
          width: "100%",
          height: "380px",
        }}
      >
        {!disableInputWhileOnChange.current.cover && isCoverImageLoaded ? (
          <>
            {coverImage ? (
              <Box
                component="img"
                src={coverImage}
                sx={{
                  width: "100%",
                  height: "100%",
                  background: "gainsboro",
                  objectFit: "cover",
                  objectPosition: "center",
                  position: "absolute",
                  ...editImageStyle,
                }}
              />
            ) : (
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flex: 1,
                  background: "gainsboro",
                  position: "absolute",
                  ...editImageStyle,
                }}
              />
            )}
            {isEdit ? null : (
              <label htmlFor="coverPhoto">
                <Box
                  sx={{
                    transition: "0.5s ease",
                    background: "rgba(0, 0, 0, 0.6)",
                    width: "100%",
                    height: "100%",
                    position: "absolute",
                    opacity: "0",
                    color: "#fff",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    "&:hover": {
                      opacity: "1",
                    },
                    "> div": {
                      transform: "translateY(20px)",
                      transition: "transform 0.5s",

                      "&:hover": {
                        transform: "translateY(0)",
                      },
                    },
                  }}
                >
                  <input
                    type="file"
                    id="coverPhoto"
                    accept="image/png, image/gif, image/jpeg"
                    style={{ display: "none" }}
                    disabled={
                      disableInputWhileOnChange.current.cover ||
                      isPhotoSubmitting
                    }
                    onChange={(e) => onChangeImage(e, "cover", "coverPhoto")}
                  />

                  <Box
                    sx={{
                      position: "relative",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Typography
                      variant="h4"
                      color="#fff"
                      sx={{ fontFamily: "Poppins", textAlign: "center" }}
                    >
                      Cover Photo
                    </Typography>
                    <Typography
                      variant="p"
                      color="#fff"
                      sx={{ fontFamily: "Poppins" }}
                    >
                      Change/Add your cover photo
                    </Typography>
                  </Box>
                </Box>{" "}
              </label>
            )}
          </>
        ) : (
          <Skeleton
            variant="rectangular"
            sx={{ width: "100%", height: "100%" }}
          />
        )}
      </Box>
      <Box sx={{ px: 3 }}>
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              position: "relative",
              width: "100%",
              flex: 1,
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "-85px",
                display: "flex",
                gap: "20px",
                width: "100%",
                zIndex: "2",
                width: "min-content",
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: { xxs: "170px", sm: "211px" },
                  height: { xxs: "170px", sm: "206px" },
                  borderRadius: "50%",
                }}
              >
                {!disableInputWhileOnChange.current.profile &&
                isProfileImageLoaded ? (
                  <>
                    <Box
                      component="img"
                      src={profileImage ? profileImage : banner}
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                        borderRadius: "50%",
                        position: "absolute",
                        ...editImageStyle,
                      }}
                    />
                    {isEdit ? null : (
                      <label htmlFor="profilePhoto">
                        <Box
                          sx={{
                            transition: "0.5s ease",
                            background: "rgba(0, 0, 0, 0.6)",
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            opacity: "0",
                            color: "#fff",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "50%",
                            cursor: "pointer",
                            "&:hover": {
                              opacity: "1",
                            },
                            "> div": {
                              transform: "translateY(20px)",
                              transition: "transform 0.5s",

                              "&:hover": {
                                transform: "translateY(0)",
                              },
                            },
                          }}
                        >
                          <input
                            type="file"
                            id="profilePhoto"
                            accept="image/png, image/gif, image/jpeg"
                            style={{ display: "none" }}
                            onChange={(e) =>
                              onChangeImage(e, "profile", "profilePhoto")
                            }
                            disabled={
                              disableInputWhileOnChange.current.profile ||
                              isPhotoSubmitting
                            }
                          />
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "100%",
                              height: "100%",
                            }}
                          >
                            <AddPhotoAlternateOutlinedIcon
                              sx={{ fontSize: "30px" }}
                            />

                            <Typography
                              variant="p"
                              color="#fff"
                              sx={{ fontFamily: "Poppins" }}
                            >
                              Profile Photo
                            </Typography>
                          </Box>
                        </Box>
                      </label>
                    )}
                  </>
                ) : (
                  <Skeleton
                    variant="circular"
                    sx={{ width: "100%", height: "100%", position: "absolute" }}
                    animation="wave"
                  />
                )}
              </Box>
              {isEdit ? (
                <Box
                  sx={{
                    position: "relative",
                    width: "max-content",
                    top: "110px",
                    display: "flex",
                    flexWrap: "wrap",
                    height: "max-content",
                  }}
                >
                  {" "}
                  <Box>
                    <Typography variant="h4">
                      {businessName && businessName}
                    </Typography>
                    <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                      <Rating readOnly value={rating ? rating : "0"} />
                    </Box>
                  </Box>
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default ProfileBanner;
