import { Box, Button, Container, Typography, useTheme } from "@mui/material";
import * as React from "react";
import classes from "./CancelationPolicy.module.css";

const CancelationPolicy = ({ policy }) => {
  const theme = useTheme();
  return (
    <Container
      sx={{
        maxWidth: "1420px !important",
        margin: "auto",
      }}
    >
      <Box sx={{ width: "100%", height: "100%", minHeight: "450px" }}>
        <Typography
          component="h2"
          variant="h3"
          className={classes.heading}
          color={theme.palette.primary.main}
        >
          Cancelation Policy
        </Typography>
        <Typography
          component="p"
          variant="p"
          color={theme.palette.primary.black}
          className={classes.paragraph}
        >
          {policy ?? "No Cancellation Policy"}
        </Typography>
      </Box>
    </Container>
  );
};

export default CancelationPolicy;
