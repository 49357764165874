export const servicesData = [
  {
    name: "Florist",
    id: 1,
    show: true,
    subServices: [
      {
        type: "checkbox",
        name: "Categories",
        categories: [
          {
            name: "table-decor",
            text: "Table Decor",
          },
          {
            name: "aisle-decorations",
            text: "Aisle decorations",
          },
          {
            name: "gazebo-decorations",
            text: "Gazebo decorations",
          },
          {
            name: "altar-arrangements",
            text: "Altar arrangements",
          },
          {
            name: "cocktail-hour-decor",
            text: "Cocktail hour decor",
          },
          {
            name: "sweetheart-table-flowers",
            text: "Sweetheart table flowers",
          },
          {
            name: "sign-in-table-arrangements",
            text: "Sign-in table arrangements",
          },
          {
            name: "buffet-arrangements",
            text: "Buffet arrangements",
          },
          {
            name: "cake-table-decor",
            text: "Cake table decor",
          },
        ],
      },
    ],
  },
  {
    name: "Pastry Chef/Cake Decorator",
    id: 1,
    show: true,
    subServices: [
      {
        type: "radio",
        name: "How many people do you want to serve?",
        categories: [
          {
            name: "1-5-pastry",
            text: "1-5 person",
          },
          {
            name: "5-10-pastry",
            text: "5-10 people",
          },
          {
            name: "10-20-pastry",
            text: "10-20 people",
          },
          {
            name: "20-30-pastry",
            text: "20-30 people",
          },
          {
            name: "30-pastry",
            text: "30+ people",
          },
        ],
      },
      {
        type: "checkbox",
        name: "What type of pastries would you like to serve?",
        categories: [
          {
            name: "cupckae",
            text: "Cupcakes",
          },
          {
            name: "cakes",
            text: "Cakes",
          },
          {
            name: "cake-pops",
            text: "Cake pops",
          },
          {
            name: "pies",
            text: "Pies",
          },
          {
            name: "marshmallow-treats",
            text: "Marshmallow Treats",
          },

          {
            name: "brownies",
            text: "Brownies",
          },
          {
            name: "doughnuts",
            text: "Doughnuts",
          },
          {
            name: "cookies",
            text: "Cookies",
          },
          {
            name: "chocolate-covered-fruit",
            text: "Chocolate-covered-fruit",
          },
          {
            name: "macaron",
            text: "Macaron",
          },
          {
            name: "other-type-of-pastries",
            text: "Other",
          },
        ],
      },
    ],
  },
  {
    name: "Caterer",
    id: 1,
    show: true,
    subServices: [
      {
        type: "checkbox",
        name: "Cuisine(s)",
        categories: [
          {
            name: "american-casual",
            text: "American - Casual",
          },
          {
            name: "american-formal",
            text: "American - Formal",
          },
          {
            name: "italian",
            text: "Italian",
          },
          {
            name: "barbecue",
            text: "Barbecue",
          },
          {
            name: "maxican-or-latin-american",
            text: "Mexican or Latin American",
          },
          {
            name: "soul-food",
            text: "Soul Food",
          },
          {
            name: "asian",
            text: "Asian",
          },
          {
            name: "mediterranean-or-middle-eastern",
            text: "Mediterranean or Middle Eastern",
          },
          {
            name: "french",
            text: "French",
          },
          {
            name: "vegan",
            text: "Vegan",
          },
          {
            name: "indian",
            text: "Indian",
          },
          {
            name: "other-cuisine",
            text: "Other",
          },
        ],
      },
      {
        type: "radio",
        name: "Serving style",
        categories: [
          {
            name: "plated-meal",
            text: "Plated Meal",
          },
          {
            name: "buffet",
            text: "Buffet",
          },
          {
            name: "food-truck",
            text: "Food Truck",
          },
          {
            name: "food-cart",
            text: "Food Cart",
          },
          {
            name: "food-station",
            text: "Food stations",
          },
          {
            name: "family-style",
            text: "Family style",
          },
          {
            name: "small-bites",
            text: "Small Bites",
          },
        ],
      },
      {
        type: "checkbox",
        name: "Drinks and Dessert",
        categories: [
          {
            name: "dessert",
            text: "Dessert",
          },
          {
            name: "non-alcoholic-drinks",
            text: "Non-alcoholic drinks",
          },
          {
            name: "alcoholic-drinks",
            text: "Alcoholic drinks",
          },
          {
            name: "coffee-or-tea",
            text: "Coffee or tea",
          },
          {
            name: "other-drinks",
            text: "Other",
          },
        ],
      },
      {
        type: "checkbox",
        name: "Miscellaneous",
        categories: [
          {
            name: "plates-utensils-and-glasses",
            text: "Plates, utensils and glasses",
          },
          {
            name: "disposable-plates-utensils-and-cups",
            text: "Disposable plates, utensils, and cups",
          },
          {
            name: "paper-napkins",
            text: "Paper napkins",
          },
          {
            name: "cloth-napkins",
            text: "Cloth napkins",
          },
          {
            name: "serving-and-warming-equipment",
            text: "Serving and warming equipment",
          },
        ],
      },
      {
        type: "radio",
        name: "Level of Service",
        categories: [
          {
            name: "full-service-with-staff",
            text: "Full service with staff",
          },
          {
            name: "food-drop-off-only",
            text: "Food drop off only",
          },
        ],
      },
    ],
  },
  {
    name: "Entertainment and Rentals",
    id: 1,
    show: true,
    subServices: [
      {
        type: "checkbox",
        name: "Disney",
        categories: [
          {
            name: "disney-mickey-mouse",
            text: "Disney - Mickey Mouse",
          },
          {
            name: "disney-minnie-mouse",
            text: "Disney - Minnie Mouse",
          },
          {
            name: "disney-princess",
            text: "Disney Princess",
          },
        ],
      },
      {
        type: "radio",
        name: "Holiday Type",
        categories: [
          {
            name: "holiday-type",
            text: "Santa (Holiday)",
          },
          {
            name: "holiday-type",
            text: "Grinch (Holiday)",
          },
          {
            name: "holiday-type",
            text: "Easter Bunny (Holiday)",
          },
        ],
      },
      {
        type: "checkbox",
        name: "Celebrity Impersonation",
        categories: [
          {
            name: "clown",
            text: "Clown",
          },
          {
            name: "superhero",
            text: "Superhero",
          },
          {
            name: "paw-petrol",
            text: "Paw Petrol",
          },
          {
            name: "sesame-street",
            text: "Sesame Street",
          },
          {
            name: "ninja-turtle",
            text: "Ninja Turtle",
          },
          {
            name: "star-wars",
            text: "Star Wars",
          },
          {
            name: "human-statue",
            text: "Human Statue",
          },
          {
            name: "mermaid",
            text: "Mermaid",
          },
        ],
      },
      {
        type: "radio",
        name: "Duration",
        categories: [
          {
            name: "1-hour",
            text: "1 hour",
          },
          {
            name: "2-hour",
            text: "2 hours",
          },
          {
            name: "3-hour",
            text: "3 hours",
          },
          {
            name: "4-hour",
            text: "4 hours",
          },
          {
            name: "5-hour",
            text: "5 hours",
          },
        ],
      },
    ],
  },
  {
    name: "Venue",
    id: 1,
    show: true,
    subServices: [
      {
        type: "checkbox",
        name: "Venue",
        categories: [
          {
            name: "museum",
            text: "Museum",
          },
          {
            name: "art-gallery",
            text: "Art Gallery",
          },
          {
            name: "event-hall",
            text: "Event Hall",
          },
          {
            name: "house-rental",
            text: "House Rental",
          },
          {
            name: "barn",
            text: "Barn",
          },
        ],
      },
      {
        type: "radio",
        name: "Duration",
        categories: [
          {
            name: "duration",
            text: "Half Day",
          },
          {
            name: "duration",
            text: "Full Day",
          },
        ],
      },
    ],
  },
  {
    name: "Security",
    id: 1,
    show: true,
    subServices: [
      {
        type: "radio",
        name: "Number of Security Guards",
        categories: [
          {
            name: "1-2",
            text: "1-2 Security Guards",
          },
          {
            name: "3-5",
            text: "3-5 Security Guards",
          },
          {
            name: "5-7",
            text: "5-7 Security Guards",
          },
          {
            name: "8-10",
            text: "8-10 Security Guards",
          },
        ],
      },
      {
        type: "radio",
        name: "Event Security",
        categories: [
          {
            name: "loss-prevention",
            text: "Loss prevention",
          },
          {
            name: "roving patrol",
            text: "Roving patrol",
          },
          {
            name: "event-security",
            text: "24/7 response",
          },
          {
            name: "bodyguard services",
            text: "Bodyguard services",
          },
          {
            name: "armed",
            text: "Armed",
          },
          {
            name: "unarmed",
            text: "Unarmed",
          },
        ],
      },
    ],
  },
  {
    name: "Glam Services",
    id: 1,
    show: true,
    subServices: [
      {
        id: "florist1",
        type: "checkbox",
        name: "How Many People",
        categories: [
          {
            name: "1-person",
            text: "1 person",
          },
          {
            name: "2-people",
            text: "2 people",
          },
          {
            name: "3-people",
            text: "3 people",
          },
          {
            name: "4-people",
            text: "4 people",
          },
        ],
      },
      {
        id: "florist1",
        type: "radio",
        name: "Event Type",
        categories: [
          {
            name: "event-type",
            text: "Holiday",
          },
          {
            name: "event-type",
            text: "Wedding",
          },
          {
            name: "event-type",
            text: "Prom/formal",
          },
          {
            name: "event-type",
            text: "Birthday Party",
          },
          {
            name: "event-type",
            text: "Photoshoot",
          },
        ],
      },
    ],
  },
  {
    name: "Officiant/Speaker",
    id: 1,
    show: true,
    subServices: [
      {
        type: "radio",
        name: "Number of Guests",
        categories: [
          {
            name: "1-15",
            text: "1-15",
          },
          {
            name: "16-30",
            text: "16-30",
          },
          {
            name: "31-50",
            text: "31-50",
          },
          {
            name: "51-75",
            text: "51-75",
          },
          {
            name: "76-100",
            text: "76-100",
          },
          {
            name: "100-150",
            text: "100-150",
          },
          {
            name: "151-200",
            text: "151-200",
          },
          {
            name: "201-300",
            text: "201-300",
          },
        ],
      },
      {
        type: "radio",
        name: "Type of Ceremony",
        categories: [
          {
            name: "elopement",
            text: "Elopement",
          },
          {
            name: "commitment-ceremony",
            text: "Commitment ceremony",
          },
          {
            name: "wedding",
            text: "Wedding",
          },
          {
            name: "vow-renewal",
            text: "Vow renewal",
          },
        ],
      },
      {
        type: "checkbox",
        name: "Laungages",
        categories: [
          {
            name: "english",
            text: "English",
          },
          {
            name: "spanish",
            text: "Spanish",
          },
          {
            name: "mandarin",
            text: "Mandarin",
          },
          {
            name: "tagalog",
            text: "Tagalog",
          },
          {
            name: "french",
            text: "French",
          },
          {
            name: "vietnamese",
            text: "Vietnamese",
          },
          {
            name: "german",
            text: "German",
          },
          {
            name: "korean",
            text: "Korean",
          },
          {
            name: "russian",
            text: "Russian",
          },
        ],
      },
      {
        type: "radio",
        name: "Religion",
        categories: [
          {
            name: "christian",
            text: "Christian",
          },
          {
            name: "jewish",
            text: "Jewish",
          },
          {
            name: "muslim",
            text: "Muslim",
          },
          {
            name: "buddhist",
            text: "Buddhist",
          },
          {
            name: "catholic",
            text: "Catholic",
          },
          {
            name: "interfaith",
            text: "Interfaith",
          },
          {
            name: "religion",
            text: "Non-denominational (mentions God)",
          },
          {
            name: "religion",
            text: "Non-religious (does not mention God)",
          },
        ],
      },
    ],
  },
  {
    name: "Transportation",
    id: 1,
    show: true,
    subServices: [
      {
        type: "radio",
        name: "Bus Service Type",
        categories: [
          {
            name: "party-bus-rental",
            text: "Party Bus Rental",
          },
          {
            name: "charter-bus-rental",
            text: "Charter Bus Rental",
          },
          {
            name: "limo",
            text: "Limo",
          },
        ],
      },
      {
        type: "radio",
        name: "Duration",
        categories: [
          {
            name: "1-hour",
            text: "1 hour",
          },
          {
            name: "2-hour",
            text: "2 hours",
          },
          {
            name: "3-hour",
            text: "3 hours",
          },
          {
            name: "4-hour",
            text: "4 hours",
          },
          {
            name: "5-hour",
            text: "5 hours",
          },
          {
            name: "other-bus-duration",
            text: "Other",
          },
        ],
      },
      {
        type: "checkbox",
        name: "Additional features",
        categories: [
          {
            name: "dvd-player-and-tv",
            text: "DVD player and TV",
          },
          {
            name: "leather-seats",
            text: "Leather seats",
          },
          {
            name: "restrooms",
            text: "Restrooms",
          },
          {
            name: "wifi",
            text: "WiFi",
          },
        ],
      },
      {
        type: "radio",
        name: "Purpose of transportation",
        categories: [
          {
            name: "purpose-of-transportation",
            text: "Wedding",
          },
          {
            name: "purpose-of-transportation",
            text: "Birthday",
          },
          {
            name: "purpose-of-transportation",
            text: "Bachelor(ette) party",
          },
          {
            name: "purpose-of-transportation",
            text: "Corporate",
          },
        ],
      },
      {
        type: "radio",
        name: "Number of passengers",
        categories: [
          {
            name: "1-8-passengers",
            text: "1-8 people",
          },
          {
            name: "9-30-passengers",
            text: "9 - 30 people",
          },
          {
            name: "31-40-passengers",
            text: "31 - 40 people",
          },
          {
            name: "41-80-passengers",
            text: "41 - 80 people",
          },
          {
            name: "81-120-passengers",
            text: "81 - 120 people",
          },
          {
            name: "121-160-passengers",
            text: "121 - 160 people",
          },
        ],
      },
    ],
  },
  {
    name: "Set-Up, Tear Down & Clean Up",
    id: 1,
    show: true,
    subServices: [
      {
        type: "checkbox",
        name: "Cleanup",
        categories: [
          {
            name: "light-cleaning-(ex,-sudting,-vaccuming,-wiping,-counters)",
            text: "Light Cleaning (ex. dusting, vacuuming, wiping, counters)",
          },
          {
            name: "medium-cleaning(ex.-wiping-down-walls-ets)",
            text: "Medium Cleaning (ex. Wiping down walls etc)",
          },
          {
            name: "deep-cleaning-(ex,-cleaning-under-furniture.-wiping-down-ceiling-fans-ets.",
            text: "Deep Cleaning (ex. Cleaning under furniture. Wiping down Ceiling fans etc.)",
          },
          {
            name: "before-event",
            text: "Before Event",
          },
          {
            name: "after-event",
            text: "After Event",
          },
        ],
      },
      {
        type: "checkbox",
        name: "Details",
        categories: [
          {
            name: "wedding-ceremony/reception",
            text: "Wedding ceremony/reception",
          },
          {
            name: "birthday",
            text: "Birthday",
          },
          {
            name: "corporate",
            text: "Corporate",
          },
          {
            name: "decor-setup",
            text: "Decor Setup",
          },
          {
            name: "decor-teardown-and-disposal",
            text: "Decor teardown and disposal",
          },
          {
            name: "design-consultation",
            text: "Design Consultation",
          },
        ],
      },
      {
        type: "radio",
        name: "Number of Guests",
        categories: [
          {
            name: "1-15-of-guests",
            text: "1-15",
          },
          {
            name: "6-30-of-guests",
            text: "6-30",
          },
          {
            name: "31-50-of-guests",
            text: "31-50",
          },
          {
            name: "51-75-of-guests",
            text: "51-75",
          },
          {
            name: "76-100-of-guests",
            text: "76-100",
          },
          {
            name: "100-150-of-guests",
            text: "100-150",
          },
          {
            name: "151-200-of-guests",
            text: "151-200",
          },
          {
            name: "201-300-of-guests",
            text: "201-300",
          },
        ],
      },
    ],
  },
  {
    name: "Photography/Videography",
    id: 1,
    show: true,
    subServices: [
      {
        type: "radio",
        name: "Photoshoot location",
        categories: [
          {
            name: "outdoors-location",
            text: "Outdoors",
          },
          {
            name: "venue-location",
            text: "Venue",
          },
          {
            name: "corporate-location",
            text: "Corporate",
          },
        ],
      },
      {
        type: "radio",
        name: "Budget",
        categories: [
          {
            name: "budget-200",
            text: "Under $200",
          },
          {
            name: "budget-300",
            text: "$201 - $300",
          },
          {
            name: "budget-400",
            text: "$301 - $400",
          },
          {
            name: "budget-500",
            text: "$401 - $500",
          },
        ],
      },
      {
        type: "checkbox",
        name: "Photo formats",
        categories: [
          {
            name: "digital-or-online-download",
            text: "Digital or online download",
          },
          {
            name: "cd/dvd",
            text: "CD / DVD",
          },
          {
            name: "flash-drive",
            text: "Flash drive",
          },
          {
            name: "physical-proofs-and-prints",
            text: "Physical proofs and prints",
          },
          {
            name: "album-or-photo-book",
            text: "Album or photo book",
          },
        ],
      },
      {
        type: "checkbox",
        name: "Additional Services:",
        categories: [
          {
            name: "videography",
            text: "Videography",
          },
          {
            name: "photo-booth",
            text: "Photo Booth",
          },
          {
            name: "photo-booth-360",
            text: "Photo Booth 360",
          },
        ],
      },
      {
        type: "radio",
        name: "Duration",
        categories: [
          {
            name: "1-3-duration-photo",
            text: "1-3 hours",
          },
          {
            name: "4-6-duration-photo",
            text: "4-6 Hours",
          },
          {
            name: "8-10-duration-photo",
            text: "8-10 Hours",
          },
        ],
      },
    ],
  },
  {
    name: "Decorator",
    id: 1,
    show: true,
    subServices: [
      {
        type: "checkbox",
        name: "Services needed",
        categories: [
          {
            name: "balloons",
            text: "Balloons",
          },
          {
            name: "flowers",
            text: "Flowers",
          },
          {
            name: "picnic",
            text: "Picnic Decor and Supplies",
          },
          {
            name: "chair",
            text: "Chair Rental",
          },
          {
            name: "table",
            text: "Table Rental",
          },
        ],
      },
      {
        type: "radio",
        name: "Number of Guests",
        categories: [
          {
            name: "number-of-guests-1-15",
            text: "1-15",
          },
          {
            name: "number-of-guests-6-30",
            text: "6-30",
          },
          {
            name: "number-of-guests-31-50",
            text: "31-50",
          },
          {
            name: "number-of-guests-51-75",
            text: "51-75",
          },
          {
            name: "number-of-guests-76-100",
            text: "76-100",
          },
          {
            name: "number-of-guests-100-150",
            text: "100-150",
          },
          {
            name: "number-of-guests-151-200",
            text: "151-200",
          },
          {
            name: "number-of-guests-201-300",
            text: "201-300",
          },
        ],
      },
    ],
  },
  {
    name: "Stationery, Invitations, Favors, Gifts",
    id: 1,
    show: true,
    subServices: [
      {
        type: "checkbox",
        name: "What type of stationery services do you need?",
        categories: [
          {
            name: "magazine",
            text: "Magazine",
          },
          {
            name: "flyer",
            text: "Flyer",
          },
          {
            name: "postcards",
            text: "Postcards",
          },
          {
            name: "business-cards",
            text: "Business Cards",
          },
          {
            name: "logo",
            text: "Logo",
          },
          {
            name: "invitations",
            text: "Invitations",
          },
          {
            name: "digital-art",
            text: "Digital Art",
          },
          {
            name: "poster",
            text: "Poster",
          },
          {
            name: "banner",
            text: "Banner",
          },
          {
            name: "sign/stickers",
            text: "Sign/Stickers",
          },
          {
            name: "t-shirt",
            text: "T-shirt",
          },
        ],
      },
      {
        type: "checkbox",
        name: "What type of invitation services do you need? ",
        categories: [
          {
            name: "invitations-design",
            text: "Invitation Design",
          },
          {
            name: "printing-invitations",
            text: "Printing Invitations",
          },
          {
            name: "preparing-invitations-to-mail",
            text: "Preparing Invitations to Mail",
          },
          {
            name: "mailing-invitations",
            text: "Mailing Invitations",
          },
          {
            name: "reviewing-invitatinos-for-rsvp-confirmations",
            text: "Reviewing Invitaions for RSVP Confirmations",
          },
        ],
      },
      {
        type: "radio",
        name: "What type of favors are needed?",
        categories: [
          {
            name: "what-type-of-favors-you-need-wedding",
            text: "Wedding",
          },
          {
            name: "what-type-of-favors-you-need-baby",
            text: "Baby Shower",
          },
          {
            name: "what-type-of-favors-you-need-engagement",
            text: "Engagement Party",
          },
          {
            name: "what-type-of-favors-you-need-birthday",
            text: "Birthday Party",
          },
        ],
      },
      {
        type: "checkbox",
        name: "What type of gifts are needed?",
        categories: [
          {
            name: "what-anniversary-of-gifts-are-needed",
            text: "Anniversary",
          },
          {
            name: "what-new-of-gifts-are-needed",
            text: "New Baby",
          },
          {
            name: "what-birthday-of-gifts-are-needed",
            text: "Birthday",
          },
          {
            name: "what-housewarming-of-gifts-are-needed",
            text: "Housewarming ",
          },
          {
            name: "what-because-of-gifts-are-needed",
            text: "Just Because ",
          },
          {
            name: "what-religious-of-gifts-are-needed",
            text: "Religious ",
          },
          {
            name: "what-sympathy-of-gifts-are-needed",
            text: "Sympathy ",
          },
          {
            name: "what-romantic-of-gifts-are-needed",
            text: "Romantic ",
          },
          {
            name: "what-type-of-wedding-are-needed",
            text: "Wedding ",
          },
          {
            name: "what-corporate-of-gifts-are-needed",
            text: "Corporate",
          },
          {
            name: "what-graduation-of-gifts-are-needed",
            text: "Graduation ",
          },
        ],
      },
      {
        type: "checkbox",
        name: "What customization would you like to include",
        categories: [
          {
            name: "engraving",
            text: "Engraving",
          },
          {
            name: "personalized",
            text: "Personalized",
          },
          {
            name: "keepsake",
            text: "Keepsake",
          },
          {
            name: "monogram",
            text: "Monogram ",
          },
          {
            name: "portrait",
            text: "Portrait",
          },
        ],
      },
    ],
  },
  {
    name: "Assistant/Coordinator/Planner",
    id: 1,
    show: true,
    subServices: [
      {
        type: "checkbox",
        name: "Assistance Type",
        categories: [
          {
            name: "valet",
            text: "Valet",
          },
          {
            name: "day of coordinator",
            text: "Day of Coordinator",
          },
          {
            name: "event planner",
            text: "Event Planner",
          },
          {
            name: "personal assistant",
            text: "Personal Assistant",
          },
          {
            name: "errand runner",
            text: "Errand Runner",
          },
          {
            name: "wait staff",
            text: "Wait Staff",
          },
          {
            name: "bartender",
            text: "Bartender",
          },
        ],
      },
      {
        type: "radio",
        name: "Number of Guests",
        categories: [
          {
            name: "1-number-of-guests",
            text: "1-15",
          },
          {
            name: "6-number-of-guests",
            text: "6-30",
          },
          {
            name: "31-number-of-guests",
            text: "31-50",
          },
          {
            name: "51-number-of-guests",
            text: "51-75",
          },
          {
            name: "76-number-of-guests",
            text: "76-100",
          },
          {
            name: "100-number-of-guests",
            text: "100-150",
          },
          {
            name: "151-number-of-guests",
            text: "151-200",
          },
          {
            name: "201-number-of-guests",
            text: "201-300",
          },
          {
            name: "other-number-of-guests",
            text: "Other",
          },
        ],
      },
      {
        type: "radio",
        name: "How long do you need assistance",
        categories: [
          {
            name: "1-3-duration",
            text: "1-3 hours",
          },
          {
            name: "4-6-duration",
            text: "4-6 hours",
          },
          {
            name: "8-10-duration",
            text: "8-10 hours",
          },
          {
            name: "other-assitant",
            text: "Other",
          },
        ],
      },
    ],
  },
  {
    name: "DJ/Band",
    id: 1,
    show: true,
    subServices: [
      {
        type: "radio",
        name: "Number Of Guests",
        categories: [
          {
            name: "15-of-guests",
            text: "1-15",
          },
          {
            name: "30-of-guests",
            text: "6-30",
          },
          {
            name: "50-of-guests",
            text: "31-50",
          },
          {
            name: "75-of-guests",
            text: "51-75",
          },
          {
            name: "100-of-guests",
            text: "76-100",
          },
          {
            name: "150-of-guests",
            text: "100-150",
          },
          {
            name: "200-of-guests",
            text: "151-200",
          },
          {
            name: "300-of-guests",
            text: "201-300",
          },
        ],
      },
      {
        type: "radio",
        name: "How long will you need the musician",
        categories: [
          {
            name: "how-long-will-you-need-the-1",
            text: "1 hour",
          },
          {
            name: "how-long-will-you-need-the-2",
            text: "2 hours",
          },
          {
            name: "how-long-will-you-need-the-3 ",
            text: "3 hours",
          },
          {
            name: "how-long-will-you-need-the-4",
            text: "4 hours",
          },
          {
            name: "how-long-will-you-need-the-5",
            text: "5 hours",
          },
        ],
      },
      {
        type: "checkbox",
        name: "What type of musician would you like",
        categories: [
          {
            name: "dj",
            text: "DJ",
          },
          {
            name: "string-quarter",
            text: "String Quartet",
          },
          {
            name: "band",
            text: "Band",
          },
          {
            name: "vocal",
            text: "Vocals/Singer",
          },
          {
            name: "pianist",
            text: "Pianist",
          },
          {
            name: "guitarist",
            text: "Guitarist",
          },
          {
            name: "harpist",
            text: "Harpist",
          },
        ],
      },
      {
        type: "checkbox",
        name: "What type of music would you like",
        categories: [
          {
            name: "top-40/pop",
            text: "Top 40/Pop",
          },
          {
            name: "rock/classic-rock",
            text: "Rock/Classic Rock",
          },
          {
            name: "jazz/blues",
            text: "Jazz/Blues",
          },
          {
            name: "r&b/soul",
            text: "R&B/Soul",
          },
          {
            name: "hip-hop/rap",
            text: "Hip Hop/Rap",
          },
          {
            name: "mariachi",
            text: "Mariachi",
          },
          {
            name: "country/bluegrass",
            text: "Country/Bluegrass",
          },
          {
            name: "dance",
            text: "Dance",
          },
          {
            name: "covers",
            text: "Covers",
          },
          {
            name: "variety",
            text: "Variety",
          },
        ],
      },
    ],
  },
];
