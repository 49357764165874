import ThemeProvider from "../src/theme";
import React from "react";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import { UserProvider } from "context/UserContext";
import { SocketProvider } from "context/SocketContext";
import { LogoutProvider } from "context/LogoutContext";
import { EventProvider } from "context/EventContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoginProvider } from "context/LoginContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { NavStyleProvider } from "context/NavStyleContext";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ThemeProvider>
          <UserProvider>
            <SocketProvider>
              <EventProvider>
                <NavStyleProvider>
                  <LoginProvider>
                    <LogoutProvider>
                      <Elements stripe={stripePromise}>
                        <App />
                      </Elements>
                    </LogoutProvider>
                  </LoginProvider>
                </NavStyleProvider>
              </EventProvider>
            </SocketProvider>
          </UserProvider>
        </ThemeProvider>
      </BrowserRouter>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
    <ToastContainer position="top-right" autoClose={3000} />
  </React.StrictMode>
);
