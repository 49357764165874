import React from "react";
import NavBar from "components/navigation/AppBar/NavBar";
import { Box, Button, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
const NotFound = () => {
  const navigate = useNavigate();

  return (
    <Box sx={{ width: "100%", height: "100%", backgroundColor: "#000" }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "100vh",
        }}
      >
        <Typography
          component="h1"
          variant="h2"
          sx={{ fontFamily: "Poppins", color: "#fff" }}
        >
          404 Page Not Found
        </Typography>
      </Box>
    </Box>
  );
};

export default NotFound;
