import * as React from "react";
import { useInfiniteQuery } from "react-query";
import useIntersectionObserver from "./useIntersectionObserver";

export default function useInfiniteListQuery({
  queryKey,
  queryFn,
  queryConfig,
  queryParams = {},
  otherParams = {},
}) {
  const { fetchNextPage, hasNextPage, ...other } = useInfiniteQuery(
    queryKey,
    async ({ pageParam = 1 }) => {
      const res = await queryFn({
        ...otherParams,
        queryParams: {
          ...queryParams,
          currentPage: pageParam,
        },
      });
      return res.data;
    },
    {
      ...queryConfig,
      getNextPageParam: (lastPage, allPages) => {
        return allPages.length
          ? allPages?.[allPages?.length - 1]?.length ||
            Object.keys(allPages?.[allPages?.length - 1])?.length
            ? allPages?.length + 1
            : undefined // To stop it from calling the api again
          : 1;
      },
    }
  );

  const loadMoreRef = React.useRef();

  useIntersectionObserver({
    target: loadMoreRef,
    onIntersect: () => {
      fetchNextPage();
    },
    enabled: hasNextPage,
  });
  return {
    ...other,
    hasNextPage,
    loadMoreRef,
  };
}
