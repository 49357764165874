import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useLoginContext } from "context/LoginContext";
import { USER_TYPE } from "constants";
import ROUTES from "routes";
import * as React from "react";
import { useNavigate } from "react-router-dom";
const NotLogInModal = () => {
  const { loginModal, setLoginModal } = useLoginContext();
  const navigate = useNavigate();
  const handleClose = () => setLoginModal(false);
  function logout() {
    navigate(ROUTES.LOGIN + `?loginAs=${USER_TYPE.CUSTOMER}`);
    setLoginModal(false);
  }
  return (
    <Dialog maxWidth open={loginModal} onClose={handleClose}>
      <DialogTitle textAlign="center">
        <Typography variant="h4" component="h4">
          Login
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" component="h6">
          Let's keep the party going! login/sign up so you can receive quotes
          from vendors.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>cancel</Button>
        <Button onClick={logout} autoFocus>
          ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default NotLogInModal;
