import client from "../axiosConfig";

export const getQuotes = async ({ queryParams = {} }) => {
  return client.get(`/api/v1/quotes`, {
    params: queryParams,
  });
};
export const getQuotesById = async (id) => {
  return client.get(`/api/v1/quotes/${id}`);
};
export const getQuotesOfEvents = async (id) => {
  return client.get(`/api/v1/events/${id}/quotes`);
};
export const declineQuote = async (id) => {
  return client.patch(`/api/v1/quotes/${id}/decline`);
};

export const submitQuote = async (eventId, body) => {
  return client.post(`/api/v1/events/${eventId}/quotes`, body);
};

export const updateQuote = async (id, body) => {
  return client.patch(`/api/v1/quotes/${id}`, body);
};
