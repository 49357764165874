import { Grid, Box, Typography } from "@mui/material";
import * as React from "react";
import TabSection from "components/TabSection";
import ChatSection from "components/ChatSection";
import { useChat } from "Hooks/useChat";
import { useParams } from "react-router-dom";
import { useUserContext } from "context/UserContext";
import classes from "./index.module.css";
import { useSocketContext } from "context/SocketContext";
import chatMessage from "assets/placeholder/chatMessage.svg";
import noMessageImage from "assets/not-found/no-message.png";

const Chat = () => {
  const { id } = useParams();
  const { activeChatIdRef } = useSocketContext();
  const { user: userMetaData, isVendor } = useUserContext();

  const messageInputRef = React.useRef("");
  const [activeChatId, setActiveChatId] = React.useState(id); // for data-fetching -- Why? so that the API retriggers when id change
  const [activeChat, setActiveChat] = React.useState(null);

  const [active, setActive] = React.useState("all");
  const {
    threads,
    areThreadsLoading,
    threadHasNextPage,
    threadLoadMoreButtonRef,
    messages,
    areMessagesLoading,
    messageHasNextPage,
    messageLoadMoreButtonRef,
    sendAttachments,
    sendMessage,
  } = useChat({
    messageInputRef,
    activeChat,
    activeChatId,
  });

  React.useEffect(() => {
    activeChatIdRef.current = id;
    if (!activeChat) {
      const thread = threads?.pages?.flat().find((thread) => thread._id === id);
      setActiveChat(thread);
    }
    setActiveChatId(id);
  }, [id, threads, activeChat]);
  React.useEffect(() => {
    // Clear activeChatIdRef on Unmount
    return () => (activeChatIdRef.current = null);
  }, []);
  return (
    <Box className={classes.root}>
      <Box
        className={classes.tabSection}
        sx={{
          display: { xxs: id && activeChat ? "none" : "flex", md: "flex" },
          width: "100%",
          maxWidth: { xxs: "100%", md: "400px" },
        }}
      >
        <TabSection
          setActiveChat={setActiveChat}
          active={active}
          activeChat={activeChat}
          areThreadsLoading={areThreadsLoading}
          threadHasNextPage={threadHasNextPage}
          threadLoadMoreButtonRef={threadLoadMoreButtonRef}
          setActive={setActive}
          allThreads={threads}
          threads={
            active === "all"
              ? threads
              : threads?.pages
                  ?.flat()
                  .filter((thread) => thread.metaData.unreadCount)
          }
          isVendor={isVendor}
        />
      </Box>
      <Box
        className={classes.chatSection}
        sx={{
          display: { xxs: id ? "flex" : "none", md: "flex" },
          width: "100%",
          flex: 1,
        }}
      >
        {id && activeChat ? (
          <ChatSection
            profile={activeChat.users.find(
              (user) => user._id !== userMetaData._id
            )}
            activeChat={activeChat}
            messages={messages}
            areMessagesLoading={areMessagesLoading}
            user={userMetaData}
            sendMessage={sendMessage}
            messageLoadMoreButtonRef={messageLoadMoreButtonRef}
            messageInputRef={messageInputRef}
            sendAttachments={sendAttachments}
            messageHasNextPage={messageHasNextPage}
          />
        ) : (
          <>
            {" "}
            {isVendor ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5" textAlign="center">
                  {threads && threads?.pages?.[0]?.length > 0
                    ? "Select a customer from drawer to view messages."
                    : "There are no customers in your chat drawer."}
                </Typography>
                <img
                  src={chatMessage}
                  alt="no-message"
                  style={{
                    width: "100%",
                    height: "100%",
                    maxWidth: "450px",
                    maxHeight: "450px",
                  }}
                />
              </Box>
            ) : (
              <div className="noMessage">
                <img src={noMessageImage} alt="no message" />
              </div>
            )}
          </>
        )}
      </Box>
    </Box>
  );
};
export default Chat;
