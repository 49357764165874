import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import header from "assets/dashboard/header.webp";
import mobileHeader from "assets/dashboard/mobile-header.webp";
import tabletHeader from "assets/dashboard/tablet-header.webp";
import { Link } from "react-router-dom";
function TopSection() {
  return (
    <Box
      sx={{
        width: "100%",
        height: { xxs: "100%", sm: "250px" },
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
    >
      <Box sx={{ width: "100%", height: "100%", position: "absolute" }}>
        <picture>
          <source media="(max-width: 600px)" srcset={mobileHeader} />
          <source media="(max-width: 800px)" srcset={tabletHeader} />
          <source media="(min-width: 801px)" srcset={header} />
          <img
            src={header}
            alt="header"
            style={{ height: "100%", width: "100%" }}
          />
        </picture>
      </Box>

      <Box
        sx={{
          pl: { xxs: "10px", sm: "20px", md: "120px" },
          py: "2rem",
          position: "relative",
        }}
      >
        <Link to="/dashboard/events/plan">
          <Box sx={{ pb: "0.5rem" }}>
            <Typography
              variant="h3"
              sx={{
                color: "#fff",
                fontSize: { xxs: "1.6rem", sm: "2rem", md: "2.5rem" },
              }}
            >
              Plan your event
            </Typography>
          </Box>
        </Link>
        <Box sx={{ width: "50%", pb: "0.5rem" }}>
          <Typography
            variant="p"
            sx={{
              color: "#fff",
              fontSize: { xxs: "0.7rem", sm: "0.8rem", md: "0.875rem" },
            }}
          >
            ThePartyStarter helps you organize, create, and manage important
            events and preserve your happiness. It's easy to get started.
          </Typography>
        </Box>
        <Box>
          <Link to="/dashboard/events/plan">
            <Button
              variant="primary"
              sx={{
                color: "text.primary",
                backgroundColor: "#fff",
                fontSize: {
                  xxs: "0.7rem",
                  sm: "0.8rem",
                  md: "1rem",
                  "&:hover": { backgroundColor: "#fff" },
                },
              }}
            >
              Plan your event
            </Button>
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default TopSection;
