import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import ROUTES from "routes";
import SignUpConfirmation from "./SignUpConfirmation";

export default function SignUpConfirmationPage() {
  const { state } = useLocation();

  return (
    <>
      {state ? (
        <>
          {state === ROUTES.SIGNUP.VENDOR || state === ROUTES.SIGNUP.USER ? (
            <SignUpConfirmation />
          ) : (
            <Navigate to={"/"} replace />
          )}
        </>
      ) : (
        <Navigate to={"/"} replace />
      )}
    </>
  );
}
