import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as LocationIcon } from "assets/dashboard/location.svg";
import { ReactComponent as TimeIcon } from "assets/dashboard/time.svg";
import TimeShowStyled from "components/styled/TimeShow";
import { DATE_FORMAT_TWO } from "constants";
import moment from "moment";
import React from "react";

function LeftMiddleSubSection2({ data }) {
  const [timeDate, setTimeDate] = React.useState([]);
  let [time, A] = moment(data?.startTime).format("hh:mm A").split(" ");
  time = time.split(":");

  React.useEffect(() => {
    const timeDate = time.map((value, index) => ({
      value,
      text: index === 0 ? "Hour" : "Minutes",
    }));
    timeDate.push({
      value: A,
      text: "AM/PM",
    });
    setTimeDate(timeDate);
  }, []);
  return (
    <Grid container rowSpacing={1}>
      <Grid
        item
        xxs={12}
        sx={{
          borderBottom: "0.702318px solid rgba(101, 101, 101, 0.2)",
        }}
      >
        <Box sx={{ py: "1rem" }}>
          <Box>
            <Typography variant="h5">{data?.title}</Typography>
          </Box>
          <Box>
            <Typography variant="p" sx={{ fontSize: { xxs: "0.68rem" } }}>
              {data?.description}
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xxs={12}
        sx={{
          borderBottom: "0.702318px solid rgba(101, 101, 101, 0.2)",
        }}
      >
        <Box sx={{ py: "1rem" }}>
          <Box sx={{ pb: "0.6rem" }}>
            <Typography
              variant="pBold"
              sx={{
                fontSize: { xxs: "0.75rem" },
                color: "primary.main",
              }}
            >
              Event Time
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-start",
            }}
          >
            {timeDate?.map((x, i) => (
              <Box
                key={x.text}
                sx={{
                  textAlign: "center",
                  maxWidth: "75.71px",
                  width: "100%",
                  mr: "0.8rem",
                }}
              >
                <TimeShowStyled sx={{ cursor: "default" }} value={x.value}>
                  {x.value}
                </TimeShowStyled>
                <Typography variant="p600" sx={{ fontSize: { xxs: "0.8rem" } }}>
                  {x.text}
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      </Grid>
      <Grid item xxs={12}>
        <Box sx={{ display: "flex", alignItems: "center", pt: "1rem" }}>
          <TimeIcon />
          <Typography variant="p" sx={{ pl: "0.6rem", fontWeight: "500" }}>
            {moment(data?.startTime).format(DATE_FORMAT_TWO)}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", py: "1rem" }}>
          <LocationIcon />
          <Typography variant="p" sx={{ pl: "0.6rem", fontWeight: "500" }}>
            {data?.location?.address}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default LeftMiddleSubSection2;
