import { TextField, InputAdornment, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import { Controller } from "react-hook-form";
import InputWrapper from "../Wrapper/InputWrapper";

function FormInputTextSecondary({
  name,
  label,
  type = "text",
  control,
  errors,
  customHeight,
  icon: Icon,
  iconToggle: IconToggle,
  toggle,
  handleClick,
  adornment = "endAdornment",
  position = "start",
  disabled = false,
  positionEdge = "start",
  color = "",
  iconBtn = true,
  ...rest
}) {
  const TextField = (field) =>
    Icon ? (
      <StyledTextField
        disabled={disabled}
        InputProps={{
          [adornment]: (
            <InputAdornment position={position}>
              {iconBtn ? (
                <IconButton
                  color="primary"
                  aria-label="toggle password visibility"
                  edge={positionEdge}
                  onClick={handleClick}
                >
                  {toggle ? <Icon /> : <IconToggle />}
                </IconButton>
              ) : (
                <Icon />
              )}
            </InputAdornment>
          ),
        }}
        type={toggle ? type : "password"}
        {...field}
        {...rest}
      />
    ) : (
      <StyledTextField disabled={disabled} type={type} {...field} {...rest} />
    );

  return (
    <InputWrapper
      label={label}
      errors={errors}
      color={color ? color : "#01214D"}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => TextField(field)}
      />
    </InputWrapper>
  );
}

export default FormInputTextSecondary;

export const StyledTextField = styled(TextField)(({ theme, customHeight }) => ({
  "& .MuiOutlinedInput-root": {
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    maxHeight: customHeight || "45px",
  },
  borderRadius: "123px",

  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "primary.light",
  },
}));
