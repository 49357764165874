import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import React from "react";
import { useController } from "react-hook-form";
import InputWrapper from "../Wrapper/InputWrapper";

function FormCheckBox({
  control,
  name,
  options,
  label,
  errors,
  handleSwitch = null,
  checkBoxProps = {},
  ...rest
}) {
  const { field } = useController({
    control,
    name,
  });
  const [value, setValue] = React.useState(field.value || []);

  const handleChange = (event, index) => {
    const valueCopy = [...value];

    // update checkbox value
    valueCopy[index] = event.target.checked ? event.target.value : "";

    // send data to react hook form
    field.onChange(options.length > 1 ? valueCopy : valueCopy[0]);
    // update local state
    setValue(valueCopy);
    if (handleSwitch) {
      if (event.target.value === "other") handleSwitch(event.target.checked);
    }
  };

  return (
    <InputWrapper label={label} errors={errors}>
      <FormGroup aria-label="position" row>
        {options.map((x, i) => {
          return (
            <FormControlLabel
              key={x.value + i}
              value={x.value}
              control={
                <Checkbox
                  checked={value.includes(x.value)}
                  onChange={(e) => handleChange(e, i)}
                  {...checkBoxProps}
                />
              }
              label={x.label}
              {...rest}
            />
          );
        })}
      </FormGroup>
    </InputWrapper>
  );
}

export default FormCheckBox;
