export const USER_TYPE = {
  CUSTOMER: "customers",
  VENDOR: "vendors",
};

export const DAY = "86400";
export const MONTH = "2592000";
export const YEAR = "31104000";

export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT_TWO = "dddd, MMMM Do, YYYY";
export const DATE_FORMAT_THREE = "MMMM DD, YYYY";
export const DATE_FORMAT_FOUR = "hh:mm A dddd, MMMM D, YYYY";

export const SOCKET_EVENT = {
  MESSAGE: "message",
  NEW_THREAD: "new-thread",
  INCREMENT_UNREAD_COUNT: "incrementUnreadCount",
};
export const minortyOptions = [
  { label: "Black-Owned Business", value: "blackOwned" },
  { label: "Latin-Owned Business", value: "latinOwned" },
  { label: "Asian-American Owned Business", value: "asianOwned" },
  { label: "Indigenous-Owned Business", value: "indigenousOwned" },
  { label: "Woman-Owned Business", value: "womenOwned" },
  { label: "LGBTQ-Owned Business", value: "lgbtqOwned" },
  { label: "NMSDC Certified", value: "nsmdcOwned" },
  { label: "MBE Certified", value: "mbeOwned" },
  { label: "Active Duty Military/Veteran", value: "militaryOwned" },
];

export const businessTypeOptions = [
  { label: "Licensed", value: "licensed" },
  { label: "Bonded", value: "bonded" },
  { label: "Insured", value: "insured" },
];

export const gender = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Non-Binary", value: "non-binary" },
  { label: "Prefer not to answer", value: "no-answer" },
];

export const language = [
  { label: "English", value: "english" },
  { label: "Spanish", value: "spanish" },
  { label: "French", value: "french" },
  { label: "Chinese", value: "chinese" },
  { label: "Other", value: "other" },
];

export const listMessages = {
  booking: {
    customer: "Book your vendor and turn your dream event into a reality!",
    vendor: "No customer has booked you yet.",
  },
  quote: {
    customer: "We are finding the best vendors for your celebration!",
    vendor: "You don't have any quotes.",
  },
  meeting: {
    customer: "Schedule a meeting with your vendor for your upcoming event.",
    vendor: "View your scheduled meetings with your cutomers.",
  },
};
