import client from "api/axiosConfig";
import formatUrl from "utils/formatUrl";

export function createMeeting({ body }) {
  return client.post(`/api/v1/meetings`, body);
}
export function getMeeting({ id }) {
  return client.get(`/api/v1/meetings/${id}`);
}
export function getMeetings({ queryParams = {} } = {}) {
  return client.get(formatUrl(`/api/v1/meetings`, queryParams));
}

export function updateMeeting({ body, id }) {
  return client.patch(`/api/v1/meetings/${id}`, body);
}
