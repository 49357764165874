import DialogContent from "@mui/material/DialogContent";
import { styled } from "@mui/system";

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: "10px 24px",
}));

export default function CustomDialogContent({ children }) {
  return <StyledDialogContent>{children}</StyledDialogContent>;
}
