import {
  Box,
  Button,
  Container,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  useTheme,
} from "@mui/material";
import logo from "assets/logo/logo.png";
import { USER_TYPE } from "constants";
import React from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import ROUTES from "routes";
import { useUserContext } from "context/UserContext";
import useMenu from "Hooks/useMenu";
import { useNavStyleContext } from "context/NavStyleContext";

export default function NavBar({ isDashboard }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { textColorPrimary } = useNavStyleContext();
  const theme = useTheme();
  const { isAuthenticated } = useUserContext();
  const link = [
    {
      title: "All Services",
      url: ROUTES.SERVICIES.MAIN,
    },
    {
      title: "Become a Vendor",
      url: ROUTES.SIGNUP.VENDOR,
    },
  ];
  const onLogoClick = () =>
    (window.location.href = process.env.REACT_APP_LANDING_URL);
  return (
    <Container maxWidth="xl">
      <Toolbar
        sx={{
          padding: "10px 0 0 0",
          margin: "0",
          display: "inline-block",
          positon: "relative",
          width: "100%",
          zIndex: "100",
        }}
      >
        <Box
          sx={{
            display: { xxs: "inline-block", lg: "none" },
            float: "left",
            paddingTop: "40px",
          }}
        >
          <ResponsiveHamburgerMenu textColorPrimary={textColorPrimary} />
        </Box>
        <Box
          sx={{
            display: { xxs: "none", lg: "inline-block" },
            float: "left",
            paddingTop: "50px",
          }}
        >
          {!isAuthenticated &&
            link.map((x) => (
              <Link
                noWrap
                key={x.title}
                variant="p"
                to={x.url}
                component={RouterLink}
                sx={{
                  m: 1,
                  textDecoration: "none",
                  color:
                    textColorPrimary === true
                      ? theme.palette.primary.black
                      : "#fff",

                  "&:hover": {
                    borderBottomWidth: "2px",
                    borderBottomStyle: "solid",
                    borderBottomColor: textColorPrimary
                      ? "primary.black"
                      : "#ffffff",
                    paddingBottom: "4px !important",
                  },
                }}
              >
                {x.title}
              </Link>
            ))}
        </Box>
        <Box sx={{ display: "inline-block" }}>
          <Box
            sx={{
              position: "absolute",
              left: "50%",
              marginLeft: "-50px",
              top: { xxs: pathname === "/" ? "96px" : "auto", sm: "auto" },
              zIndex: "4",
              cursor: "pointer",
            }}
          >
            <img src={logo} alt="logo" onClick={onLogoClick} />
          </Box>
        </Box>
        <Box
          sx={{
            display: { xxs: "none", lg: "inline-block" },
            float: "right",
            paddingTop: "36px",
          }}
        >
          {!isAuthenticated && (
            <>
              <Button
                variant="primary"
                onClick={() => navigate(`/?loginAs=${USER_TYPE.CUSTOMER}`)}
              >
                User Login
              </Button>
              <Button
                sx={{
                  marginLeft: "10px",
                  backgroundColor: textColorPrimary
                    ? "primary.black"
                    : "#ffffff",
                  color: textColorPrimary ? "#ffffff" : "primary.main",
                  "&:hover": {
                    backgroundColor: textColorPrimary ? "#114a99" : "#f0f0f0",
                  },
                }}
                variant="primary"
                onClick={() => {
                  navigate(`/?loginAs=${USER_TYPE.VENDOR}`);
                }}
              >
                Vendor Login
              </Button>
            </>
          )}
          <Button
            sx={{
              marginLeft: "10px",
              color: textColorPrimary ? "#01214D" : "#fff",
              borderColor: textColorPrimary ? "#01214D" : "#fff",
              "&:hover": {
                backgroundColor: textColorPrimary ? "none" : "#5f615f",
                color: textColorPrimary ? "#114a99" : "#fff",
                borderColor: textColorPrimary ? "#114a99" : "#fff",
              },
            }}
            variant="secondary"
            onClick={() =>
              isDashboard
                ? navigate("/dashboard/events/plan")
                : navigate("/event/plan")
            }
          >
            Plan your events
          </Button>
        </Box>
        {!isAuthenticated && pathname === "/" && (
          <Box
            sx={{
              display: { xxs: "inline-block", lg: "none" },
              float: "right",
              paddingTop: "40px",
            }}
          >
            <Button
              variant="primary"
              onClick={() => navigate(ROUTES.SIGNUP.VENDOR)}
            >
              Become a Vendor
            </Button>
          </Box>
        )}
      </Toolbar>
    </Container>
  );
}

function ResponsiveHamburgerMenu({ textColorPrimary }) {
  const responsiveLink = [
    {
      title: "All Services",
      url: ROUTES.SERVICIES.MAIN,
    },
    {
      title: "Become a Vendor",
      url: ROUTES.SIGNUP.VENDOR,
    },
    {
      title: "User Login",
      url: `/?loginAs=${USER_TYPE.CUSTOMER}`,
    },
    {
      title: "Vendor Login",
      url: `/?loginAs=${USER_TYPE.VENDOR}`,
    },
    {
      title: "Plan your event",
      url: ROUTES.EVENT.PLAN,
    },
  ];

  const { open, anchorEl, handleClick, handleClose } = useMenu();

  return (
    <div>
      <Button
        id="menu-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        variant="text"
        sx={{
          textTransform: "none",
          textDecoration: "underline",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        {/* <MenuIcon
          sx={textColorPrimary ? { color: "primary.black" } : { color: "#fff" }}
        /> */}
        Menu
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "menu-button",
        }}
        PaperProps={{
          sx: {
            bgcolor: "rgba(0, 0, 0, 0.6)",
            "& .MuiMenuItem-root": {
              "&:hover": {
                bgcolor: "rgba(0, 0, 0, 0.7)",
              },
            },
          },
        }}
      >
        {responsiveLink.map((x) => (
          <MenuItem dense key={x.title} onClick={handleClose}>
            <Link
              noWrap
              variant="p"
              to={{ pathname: x.url }}
              component={RouterLink}
              sx={{
                p: 1,
                textDecoration: "none",
                color: textColorPrimary ? "#ffffff" : "primary.main",
              }}
            >
              {x.title}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
