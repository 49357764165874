import { Box, Container, Typography } from "@mui/material";
import { getQuotes } from "api/v1/quotes";
import { ReactComponent as SearchIcon } from "assets/dashboard/quotes/search.svg";
import CustomInput from "components/InputField/CustomInput";
import CustomSelect from "components/InputField/CustomSelect";
import { minortyOptions } from "constants";
import { useUserContext } from "context/UserContext";
import useInfiniteListQuery from "Hooks/useInfiniteListQuery";
import React from "react";
import { useParams } from "react-router-dom";
import BottomSectionTable from "./comp/BottomSectionTable";

function QuotesList() {
  const { id } = useParams();
  const { isVendor } = useUserContext();
  const [search, setSearch] = React.useState("");
  const [status, setStatus] = React.useState("");
  const [price, setPrice] = React.useState("");
  const [minorityOwned, setMinorityOwned] = React.useState("");

  let sortParams = price
    ? {
        sortBy: "price",
        sortDirection: price,
      }
    : {};

  const { data, isLoading, loadMoreRef, refetch, hasNextPage } =
    useInfiniteListQuery({
      queryKey: "quotes",
      queryFn: getQuotes,
      queryParams: {
        ...(id ? { eventId: id } : null),
        search,
        status,
        minorityOwned,
        ...sortParams,
      },
    });

  const isData = data?.pages[0]?.length > 0;

  React.useEffect(() => {
    refetch();
  }, [status, price, minorityOwned]);

  return (
    <Container maxWidth="xl" sx={{ py: "3rem" }}>
      <Box sx={{ mb: "2rem" }}>
        <TopSection
          status={status}
          setStatus={setStatus}
          setSearch={setSearch}
          setPrice={setPrice}
          price={price}
          refetch={refetch}
          isVendor={isVendor}
          minorityOwned={minorityOwned}
          setMinorityOwned={setMinorityOwned}
        />
      </Box>
      <Box sx={{ overflowX: "auto" }}>
        <Box
          sx={{
            display: "block",
            borderRadius: "8px",
            background: "#FFFFFF",
            boxShadow: "0px -3px 27px 10px #0000000d",
            minWidth: isData ? "900px" : "0",
            py: "1rem",
          }}
        >
          <BottomSectionTable
            hasNextPage={hasNextPage}
            loadMoreRef={loadMoreRef}
            data={data}
            isLoading={isLoading}
            isVendor={isVendor}
            isData={isData}
          />
        </Box>
      </Box>
    </Container>
  );
}

export default QuotesList;

function TopSection({
  price,
  setPrice,
  status,
  setStatus,
  setSearch,
  refetch,
  isVendor = false,
  minorityOwned,
  setMinorityOwned,
}) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box>
        <Typography
          variant="h4"
          style={{ fontFamily: "Playfair Display", paddingBottom: "1rem" }}
        >
          {isVendor ? "My Quotes" : "Vendors Quotes"}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xxs: "column", md: "row" },
          width: "100%",
          gap: "10px",
        }}
      >
        <Box sx={{ flexGrow: 1, pb: { xxs: "0.5rem", md: "0" } }}>
          <CustomInput
            height="45px"
            adornment="endAdornment"
            position="end"
            Icon={SearchIcon}
            onChange={(e) => setSearch(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                refetch();
              } else {
                return null;
              }
            }}
            placeholder="Search here"
            textInputStyle={{ maxWidth: "700px" }}
          />
        </Box>
        <Box sx={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
          <Box>
            <CustomSelect
              label="Status"
              value={status}
              setValue={setStatus}
              data={[
                { value: "", label: "Select Status" },
                { value: "pending", label: "Pending" },
                { value: "declined", label: "Declined" },
              ]}
              height="45px"
            />
          </Box>

          {isVendor ? null : (
            <Box>
              <CustomSelect
                label="Minority-owned
            Business"
                data={[
                  { label: "Select Minority-owned Business", value: "" },
                  ...minortyOptions,
                ]}
                height="45px"
                value={minorityOwned}
                setValue={setMinorityOwned}
              />
            </Box>
          )}
          <Box>
            <CustomSelect
              label="Price"
              value={price}
              setValue={setPrice}
              data={[
                { value: "", label: "Select Price" },
                { value: "1", label: "Low to High" },
                { value: "-1", label: "High to Low" },
              ]}
              height="45px"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
