import { getUser } from "api/v1/auth";
import { getPaymentServicesStatus } from "api/v1/vendor";
import jwtDecode from "jwt-decode";
import * as React from "react";
import {
  getToken,
  removeToken,
  setToken as setTokenInLocalStorage,
} from "utils/token";

const UserContext = React.createContext();

export function UserProvider({ children }) {
  const [token, setToken] = React.useState(getToken());
  const vendor = React.useRef();
  let defaultUser = null;
  if (token) {
    defaultUser = jwtDecode(token);
    vendor.current = defaultUser?.model === "Vendors";
  }
  const [refetch, setRefetch] = React.useState(false);
  function userRefetch() {
    setRefetch(!refetch);
  }

  const [user, setUser] = React.useState(defaultUser);
  const [paymentServicesStatus, setPaymentServicesStatus] =
    React.useState(null);
  const [paymentStatusRefetch, setPaymentStatusRefetch] = React.useState(false);

  async function fetchData(decoded) {
    const res = await getUser();
    setUser((prev) => ({ ...prev, ...decoded, ...res.data }));
  }

  React.useEffect(() => {
    if (token) {
      try {
        let decoded = jwtDecode(token);
        if (Date.now() >= decoded.exp * 1000) {
          throw Error("token expired");
        }
        // To get token data
        vendor.current = decoded.model === "Vendors";
        setTokenInLocalStorage(token);
        fetchData(decoded);
      } catch (error) {
        removeToken();
        window.location.reload();
      }
    }
  }, [token, refetch]);

  React.useEffect(() => {
    if (token) {
      async function fetchPaymentServicesStatus() {
        try {
          const res = await getPaymentServicesStatus();
          setPaymentServicesStatus(res.data);
        } catch (err) {}
      }
      fetchPaymentServicesStatus();
    }
  }, [token, paymentStatusRefetch]);

  return (
    <UserContext.Provider
      value={{
        isAuthenticated: Boolean(Object.values(user ?? {}).length),
        user,
        setToken,
        setUser,
        userRefetch,
        isVendor: vendor.current,
        isPaypalConnected: paymentServicesStatus?.paypal,
        isStripeConnected: paymentServicesStatus?.stripe,
        setPaymentStatusRefetch,
        paymentStatusRefetch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUserContext can only be used inside UserProvider");
  }
  return context;
}
