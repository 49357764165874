import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import FormInputTextSecondary from "components/forms/FormInputTextSecondary";
import FormRadioButton from "components/forms/FormRadioButton";
import { ReactComponent as MeetingIcon } from "assets/dashboard/meeting/meeting.svg";

function TopSection({ control, errors, watch }) {
  const watchMeetingType = watch("meetingType");
  return (
    <Grid container rowSpacing={3}>
      <Grid item xxs={12}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box>
            <MeetingIcon />
          </Box>
          <Box sx={{ pl: "0.5rem" }}>
            <Typography variant="h5">Meeting Details</Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xxs={12}>
        <FormInputTextSecondary
          name="title"
          control={control}
          label="Meeting Title"
          errors={errors?.title?.message}
          placeholder="Enter meeting title here"
          sx={{ width: { xxs: "100%", sm: "80%", md: "60%" } }}
        />
      </Grid>
      <Grid item xxs={12}>
        <FormInputTextSecondary
          name="description"
          control={control}
          label="Meeting Description"
          errors={errors?.description?.message}
          placeholder="Enter meeting description here"
          sx={{ width: { xxs: "100%", sm: "80%", md: "60%" } }}
        />
      </Grid>
      <Grid item xxs={12}>
        <FormInputTextSecondary
          name="people"
          control={control}
          label="Add People"
          errors={errors?.people?.message}
          placeholder="Enter comma seperated emails e.g. test123@test.com, myfirst@gmail.com"
          sx={{ width: { xxs: "100%", sm: "80%", md: "60%" } }}
        />
      </Grid>
      <Grid item xxs={12}>
        <FormRadioButton
          name="meetingType"
          row
          control={control}
          label="Which meeting type do you preferred?"
          options={[
            { label: "Virtual Meeting", value: "virtual" },
            { label: "In-person Meeting", value: "inPerson" },
          ]}
        />
      </Grid>
      {watchMeetingType === "inPerson" ? (
        <Grid item xxs={12}>
          <FormInputTextSecondary
            name="venue"
            control={control}
            label="Meeting Venue"
            errors={errors?.venue?.message}
            placeholder="Enter venue location here"
            sx={{ width: { xxs: "100%", sm: "80%", md: "60%" } }}
          />
        </Grid>
      ) : watchMeetingType === "virtual" ? (
        <Grid item xxs={12}>
          <FormInputTextSecondary
            name="link"
            control={control}
            label="Meeting Link"
            errors={errors?.link?.message}
            placeholder="Enter venue link here"
            sx={{ width: { xxs: "100%", sm: "80%", md: "60%" } }}
          />
        </Grid>
      ) : null}
    </Grid>
  );
}

export default TopSection;
