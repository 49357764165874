import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import * as React from "react";
import { Controller } from "react-hook-form";
import InputWrapper from "../Wrapper/InputWrapper";

function CustomDatePicker({
  onChange,
  format = "YYYY-MM-DD",
  defaultValue = new Date(),
  ...rest
}) {
  const [value, setValue] = React.useState(defaultValue ?? null);
  function onChangeHandler(value) {
    setValue(value);
    onChange(value.format(format));
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDatePicker
        {...rest}
        displayStaticWrapperAs="desktop"
        orientation="landscape"
        openTo="day"
        value={value}
        onChange={onChangeHandler}
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
}

export default function FormInputStaticDatePicker({
  name,
  control,
  label,
  errors,
  defaultValue,
  ...rest
}) {
  return (
    <InputWrapper label={label} errors={errors}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) =>
          CustomDatePicker({ onChange, defaultValue, ...rest })
        }
      />
    </InputWrapper>
  );
}
