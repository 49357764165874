import { Grid, Typography } from "@mui/material";
import React from "react";

function ListHeader({ headers, spacing, spacingValue = 1 }) {
  return (
    <Grid
      container
      justifyContent="center"
      sx={{ pl: "2rem", py: "0.7rem" }}
      columnSpacing={spacing ? spacingValue : 0}
    >
      {headers.map((x) => (
        <Grid key={x.id} item xxs={x.col ?? 2}>
          <Typography variant="p2Bold" sx={{ color: "#1260CC" }}>
            {x.label}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
}

export default ListHeader;
