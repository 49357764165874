export const businessTypeOptions = [
  { label: "Licensed", value: "licensed" },
  { label: "Bonded", value: "bonded" },
  { label: "Insured", value: "insured" },
];

export const gender = [
  { label: "Male", value: "male" },
  { label: "Female", value: "female" },
  { label: "Non-Binary", value: "non-binary" },
  { label: "Prefer not to answer", value: "no-answer" },
];

export const language = [
  { label: "English", value: "english" },
  { label: "Spanish", value: "spanish" },
  { label: "French", value: "french" },
  { label: "Chinese", value: "chinese" },
  { label: "Other", value: "other" },
];
