import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Container, Typography } from "@mui/material";
import { signup } from "api/v1/auth";
import { getUploadSignedUrl } from "api/v1/image";
import { emailAvailability } from "api/v1/vendor";
import backgroundImage from "assets/login/vendor-signup.png";
import axios from "axios";
import CustomCard from "components/forms/CustomCard";
import FormApiResponse from "components/forms/FormApiResponse";
import CustomStepper from "components/navigation/Stepper/CustomStepper";
import { USER_TYPE } from "constants";
import {
  FB_URL,
  FB_URL_REGEX,
  INSTA_URL_REGEX,
  PASSWORD_MIN_LENGTH,
  PASSWORD_REGEX,
  TWITTER_URL_REGEX,
  URL_REGEX,
} from "constraints";
import messages from "messages";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ROUTES from "routes";
import {
  filterNullArrayData,
  filterObjectEmptyValues,
  validLocation,
  YesNoToBoolean,
} from "utilities";
import * as yup from "yup";
import { steps } from "./data/steps";
import AddressInformation from "./forms/AddressInformation";
import BusinessInformation from "./forms/BusinessInformation";
import PersonalInformation from "./forms/PersonalInformation";
import dayjs from "dayjs";
import { DATE_FORMAT } from "constants";

const date = new Date();

function SignUpPage() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);

  const { mutateAsync, isLoading, isError, isSuccess, error } = useMutation(
    async (payload) => await signup(payload)
  );
  const { mutateAsync: getUploadSignedUrlData } =
    useMutation(getUploadSignedUrl);
  const Forms = [PersonalInformation, BusinessInformation, AddressInformation];
  const CurrentForm = Forms[activeStep];
  const {
    control,
    handleSubmit,
    trigger,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      location: null,
      businessCriteria: [],
      dateOfRegisteration: null,
      fullName: "",
      birthDate: null,
      businessName: "",
      email: "",
      country: "United States",
      password: "",
      confirmPassword: "",
      websiteUrl: "",
      gender: "",
      minorityEligibility: [],
      skills: [],
      languages: [],
      otherLanguages: "",
      accept: "",
      convictedFelony: "",
      felonyDescription: "",
      virtualEvent: "",
    },
    resolver: yupResolver(schema),
  });

  async function onSubmit(data) {
    try {
      const { otherLanguages, languages, convictedFelony } = data;
      let selectedLanguages = [
        ...new Set([...languages, ...otherLanguages.split(",")]),
      ];

      selectedLanguages = filterNullArrayData(selectedLanguages);
      if (convictedFelony === "no" && data?.felonyDescription) {
        data.felonyDescription = "";
      }

      data.dateOfRegisteration = dayjs(data.dateOfRegisteration).format(
        DATE_FORMAT
      );
      data.birthDate = dayjs(data.birthDate).format(DATE_FORMAT);
      data.minorityEligibility = filterNullArrayData(data.minorityEligibility);
      data.businessCriteria = filterNullArrayData(data.businessCriteria);

      const filterData = filterObjectEmptyValues(data);
      const body = {
        ...filterData,
        languages: selectedLanguages,
        virtualEvent: YesNoToBoolean(data.virtualEvent),
        convictedFelony: YesNoToBoolean(data.convictedFelony),
      };

      if (data?.image) {
        var uploadSignedUrl = await getUploadSignedUrlData({ key: "profile" });
        axios.put(uploadSignedUrl?.data?.url, data.image, {
          headers: {
            "Content-Type": data.image.type,
          },
        });
        body.profilePhoto = uploadSignedUrl?.data?.key;
      }

      await mutateAsync({
        userType: USER_TYPE.VENDOR,
        body,
      });

      navigate(ROUTES.SIGNUP_CONFIRMATION.VENDOR, {
        state: ROUTES.SIGNUP.VENDOR,
        replace: true,
      });
    } catch (err) {}
  }

  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: "1",
        position: "relative",
      }}
    >
      <img
        src={backgroundImage}
        alt="sign-up-page"
        style={{
          objectFit: "cover",
          objectPosition: "center",
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: "-2",
        }}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#000",
          opacity: "0.3",
          position: "absolute",
          zIndex: "-1",
        }}
      />

      <Box sx={{ pt: "7rem" }}>
        <Container
          sx={{
            marginTop: "30px",
            marginBottom: "20px",
          }}
          maxWidth="md"
        >
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              mt: 2,
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#fff",
                  fontSize: "3.5rem",
                  lineHeight: "70px",
                  fontWeight: "800",
                  pb: 2,
                  pt: 2,
                }}
                variant="h1"
              >
                Let's Make Dream Events Come True
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <CustomCard sx={{ p: "2rem" }}>
                <Box sx={{ mb: "2rem" }}>
                  <Typography variant="h4">Vendor Registration Form</Typography>
                </Box>
                <Box sx={{ mb: "3rem" }}>
                  <CustomStepper steps={steps} activeStep={activeStep} />
                </Box>
                <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                  <CurrentForm
                    control={control}
                    getValues={getValues}
                    setValue={setValue}
                    setActiveStep={setActiveStep}
                    trigger={trigger}
                    errors={errors}
                    isLoading={isLoading}
                    isError={isError}
                    isSuccess={isSuccess}
                    error={error}
                  />
                </Box>
                <Box sx={{ mt: "1rem" }}>
                  <FormApiResponse
                    isError={isError}
                    isSuccess={isSuccess}
                    error={error}
                    successMessage={messages.signedUp}
                  />
                </Box>
              </CustomCard>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default SignUpPage;

const schema = yup
  .object({
    fullName: yup.string().required(messages.requiredField),
    email: yup
      .string()
      .email()
      .required(messages.requiredField)
      .test(
        "username_async_validation",
        messages.emailNotAvailable,
        async function (email) {
          const res = await emailAvailability({ email });
          if (res.data) {
            return true;
          } else {
            return false;
          }
        }
      ),
    image: yup.mixed().required(messages.requiredField),
    location: yup
      .object()
      .shape({
        type: yup.string().required(),
        address: yup.string().required(),
        state: yup.string().required(),
        country: yup.string().required(),
        coordinates: yup.array().of(yup.number()).required(),
      })
      .nullable()
      .required(messages.requiredField),
    businessCriteria: yup.array(yup.string()).notRequired(),
    telephoneNumber: yup.string().required(messages.requiredField),
    dateOfRegisteration: yup
      .date()
      .typeError(messages.invalidDate)
      .min(dayjs(new Date(1900, 0, 1)), messages.invalidDate)
      .max(dayjs(date), messages.invalidDate)
      .nullable()
      .required(messages.requiredField),
    birthDate: yup
      .date()
      .typeError(messages.invalidDate)
      .min(dayjs(new Date(1900, 0, 1)), messages.invalidDate)
      .max(
        dayjs(
          new Date(date.getFullYear() - 18, date.getMonth(), date.getDate())
        ),
        messages.invalidDate
      )
      .nullable()
      .required(messages.requiredField),
    businessName: yup.string().required(messages.requiredField),
    websiteUrl: yup.string().required(messages.requiredField),
    password: yup
      .string()
      .matches(PASSWORD_REGEX, messages.invalidPassword)
      .min(PASSWORD_MIN_LENGTH, messages.passwordLength)
      .required(messages.requiredField),
    confirmPassword: yup
      .string()
      .required(messages.requiredField)
      .test("pass check", messages.passwordNotMatch, (value, context) => {
        if (value === context.parent.password) return true;
        else return false;
      }),
    virtualEvent: yup.string().required(messages.requiredField),
    convictedFelony: yup.string().required(messages.requiredField),
    felonyDescription: yup
      .string()
      .when("convictedFelony", (value) => {
        if (value === "yes") {
          return yup.string().required(messages.requiredField);
        } else {
          return yup.string().notRequired();
        }
      })
      .notRequired(),
    serviceDescription: yup.string().required(messages.requiredField),
    gender: yup.string().required(messages.requiredField),
    languages: yup
      .array(yup.string())
      .min(1)
      .ensure()
      .required(messages.requiredField),
    skills: yup
      .array()
      .min(1, messages.requiredField)
      .required(messages.requiredField),
    accept: yup
      .string()
      .required(messages.acceptAllInformationIsTrue)
      .oneOf(["accepted"], messages.acceptAllInformationIsTrue),
    facebookUrl: yup
      .string()
      .test("if value fb", messages.invalidFBUrl, (value) => {
        if (value) {
          return FB_URL.test(value);
        } else {
          return true;
        }
      })
      .nullable()
      .notRequired(),
    instagramUrl: yup
      .string()
      .test("if value", messages.invalidInstaUrl, (value) => {
        if (value) {
          return INSTA_URL_REGEX.test(value);
        } else {
          return true;
        }
      })
      .nullable()
      .notRequired(),

    twitterUrl: yup
      .string()
      .test("if value", messages.invalidTwitterUrl, (value) => {
        if (value) {
          return TWITTER_URL_REGEX.test(value);
        } else {
          return true;
        }
      })
      .nullable()
      .notRequired(),
  })
  .shape({
    image: yup.mixed().required(messages.requiredField),
  })
  .required();
