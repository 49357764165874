import { getStatsInterval } from "api/v1/stats";
import { useQuery } from "react-query";

export default function useTimeStats(timePeriod, isVendor = false) {
  const queryParams = {
    params: {
      timePeriod,
    },
  };
  return useQuery(
    ["stats", timePeriod],
    async () => {
      const { data } = await getStatsInterval(queryParams);
      return data;
    },
    { enabled: isVendor }
  );
}
