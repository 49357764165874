import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/material";
import { updateVendor } from "api/v1/vendor";
import FormInputTextSecondary from "components/forms/FormInputTextSecondary";
import React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Wrapper from "../../comp/Wrapper";

function PolicyEdit({ cancellationPolicy, userRefetch }) {
  const navigate = useNavigate();
  const { mutate, isLoading, isError, isSuccess, error } = useMutation(
    updateVendor,
    {
      onSuccess: (data) => {
        userRefetch();
        navigate("/dashboard/profile", { state: 2 });
      },
      onError: (error) => {
        toast.error(error?.errMsg);
      },
    }
  );

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      cancellationPolicy: cancellationPolicy,
    },
    resolver: yupResolver(schema),
  });
  const onSubmit = (data) => {
    mutate({ body: data });
  };

  return (
    <Wrapper label="Cancellation Policy">
      <Box>
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "2rem",
            justifyContent: "center",
          }}
        >
          <Box>
            <FormInputTextSecondary
              name="cancellationPolicy"
              errors={errors?.cancellationPolicy?.message}
              control={control}
              label="Cancellation Policy"
              placeholder="Enter your company/organizations policy here"
              multiline
              minRows={14}
              sx={{
                "& .MuiOutlinedInput-root": {
                  borderRadius: "12px",
                  maxHeight: "none",
                },
              }}
            />
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <LoadingButton variant="primary" type="submit" loading={isLoading}>
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </Wrapper>
  );
}

export default PolicyEdit;

const schema = yup.object({ cancellationPolicy: yup.string() }).required();
