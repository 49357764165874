import { yupResolver } from "@hookform/resolvers/yup";
import { Box } from "@mui/material";
import messages from "messages";
import React from "react";
import { useForm, useFormState } from "react-hook-form";
import AddressForm from "./forms/AddressForm";
import BusinessForm from "./forms/BusinessForm";
import PersonalForm from "./forms/PersonalForm";
import * as yup from "yup";
import { updateVendor } from "api/v1/vendor";
import {
  FB_URL,
  FB_URL_REGEX,
  INSTA_URL_REGEX,
  PASSWORD_MIN_LENGTH,
  PASSWORD_REGEX,
  TWITTER_URL_REGEX,
} from "constraints";
import { getServices } from "api/v1/service";
import { useMutation, useQuery } from "react-query";
import dayjs from "dayjs";
import { DATE_FORMAT } from "constants";
import { toast } from "react-toastify";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";
import {
  businessCriteriaObject,
  languagesObject,
  minorityEligibilityObject,
} from "../data/profileData";
import { dirtyValues, filterNullArrayData } from "utilities";

const date = new Date();

function ProfileEdit({ user, userRefetch, services, servicesLoading }) {
  const navigate = useNavigate();

  const { mutate, isLoading, isError, isSuccess, error } = useMutation(
    updateVendor,
    {
      onSuccess: (data) => {
        userRefetch();
        navigate("/dashboard/profile");
      },
      onError: (error) => {
        toast.error(error?.errMsg);
      },
    }
  );

  const formatedLanguages = () => {
    const temp = [];
    let otherLanguage = null;
    const lang = Object.keys(languagesObject);
    user?.languages?.forEach((x) => {
      if (lang.includes(x)) {
        temp[languagesObject[x]] = x;
      } else {
        if (temp[4] === "other") {
          otherLanguage.push(x);
        } else {
          temp[4] = "other";
          otherLanguage = [x];
        }
      }
    });
    otherLanguage = otherLanguage?.join(",");
    return { temp, otherLanguage };
  };

  const formatedCheckBoxData = (userValues, allValues) => {
    const temp = [];
    const minority = Object.keys(allValues);
    userValues?.forEach((x) => {
      if (minority.includes(x)) {
        temp[allValues[x]] = x;
      }
    });

    return temp;
  };

  const formatedBirthDate = user?.birthDate
    ? dayjs(new Date(user?.birthDate)).format(DATE_FORMAT)
    : null;
  const formatedDateOfRegisteration = user?.dateOfRegisteration
    ? dayjs(new Date(user?.dateOfRegisteration)).format(DATE_FORMAT)
    : null;

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      businessCriteria: formatedCheckBoxData(
        user?.businessCriteria,
        businessCriteriaObject
      ),
      dateOfRegisteration: formatedDateOfRegisteration,
      fullName: user?.fullName ?? "",
      birthDate: formatedBirthDate,
      businessName: user?.businessName ?? "",
      password: "",
      existingPassword: "",
      websiteUrl: user?.websiteUrl ?? "",
      gender: user?.gender ?? "",
      skills: user?.skills ?? [],
      languages: formatedLanguages().temp,
      virtualEvents: user?.virtualEvents ? "yes" : "no",
      minorityEligibility: formatedCheckBoxData(
        user?.minorityEligibility,
        minorityEligibilityObject
      ),
      otherLanguages: formatedLanguages().otherLanguage,
      telephoneNumber: user?.telephoneNumber ?? "",
      location: user?.location ?? null,
      serviceDescription: user?.serviceDescription ?? "",
      facebookUrl: user?.facebookUrl ?? "",
      instagramUrl: user?.instagramUrl ?? "",
      twitterUrl: user?.twitterUrl ?? "",
    },
    resolver: yupResolver(schema),
  });

  const { dirtyFields } = useFormState({
    control,
  });

  const onSubmit = (data) => {
    const mutatedFields = dirtyValues(dirtyFields, data);

    if (Object.keys(mutatedFields).length > 0) {
      let { otherLanguages, languages, minorityEligibility, businessCriteria } =
        data;

      if (mutatedFields?.languages || mutatedFields?.otherLanguages) {
        if (!languages.includes("other")) otherLanguages = "";
        let selectedLanguages = [
          ...new Set([...languages, ...otherLanguages.split(",")]),
        ];
        mutatedFields.languages = filterNullArrayData(selectedLanguages);
      }
      if (mutatedFields?.minorityEligibility) {
        mutatedFields.minorityEligibility =
          filterNullArrayData(minorityEligibility);
      }
      if (mutatedFields?.businessCriteria) {
        mutatedFields.businessCriteria = filterNullArrayData(businessCriteria);
      }

      mutate({ body: mutatedFields });
    } else navigate("/dashboard/profile");
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        display: "flex",
        flexDirection: "column",
        rowGap: "2rem",
        justifyContent: "center",
      }}
    >
      <Box>
        <PersonalForm
          control={control}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
          date={date}
        />
      </Box>
      <Box>
        <BusinessForm
          control={control}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
          services={services}
          servicesLoading={servicesLoading}
          date={date}
        />
      </Box>
      <Box>
        <AddressForm
          control={control}
          getValues={getValues}
          setValue={setValue}
          errors={errors}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <LoadingButton variant="primary" type="submit" loading={isLoading}>
          Submit
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default ProfileEdit;

const schema = yup
  .object({
    fullName: yup
      .string()
      .matches(/^[aA-zZ\s]+$/, messages.invalidName)
      .required(messages.requiredField),
    location: yup
      .object()
      .shape({
        type: yup.string().required(),
        address: yup.string().required(),
        state: yup.string().required(),
        country: yup.string().required(),
        coordinates: yup.array().of(yup.number()).required(),
      })
      .nullable()
      .required(messages.requiredField),
    businessCriteria: yup.array(yup.string()).notRequired(),
    telephoneNumber: yup.string().required(messages.requiredField),
    dateOfRegisteration: yup.string().required(messages.requiredField),
    birthDate: yup.string().required(messages.requiredField),
    businessName: yup.string().required(messages.requiredField),
    websiteUrl: yup.string().optional(),
    existingPassword: yup.string().optional(),
    password: yup
      .string()
      .optional()
      .when("existingPassword", (val, schema) => {
        if (val) {
          if (val.length > 0) {
            //if value exist then apply min max else not
            return yup
              .string()
              .matches(PASSWORD_REGEX, messages.invalidPassword)
              .min(PASSWORD_MIN_LENGTH, messages.passwordLength)
              .required("Must enter email address");
          } else {
            return yup.string().notRequired();
          }
        } else {
          return yup.string().notRequired();
        }
      }),
    felonyDescription: yup.string().optional(),
    serviceDescription: yup.string().required(messages.requiredField),
    gender: yup.string().required(messages.requiredField),
    languages: yup
      .array(yup.string())
      .min(1)
      .ensure()
      .required(messages.requiredField),
    otherLanguages: yup
      .string()
      .nullable()
      .when("languages", (val, schemaa) => {
        if (val.includes("other")) {
          return yup
            .string()
            .nullable()
            .matches(
              /^[a-zA-Z]+(,[a-zA-Z]+)*$/,
              messages.validateLanguageString
            )
            .transform((value) => {
              if (!value) return null;
              return value
                .split(",")
                .map((x) => x.toLowerCase())
                .join();
            })

            .required(messages.requiredField);
        } else {
          return yup.string().notRequired();
        }
      }),
    skills: yup
      .array()
      .min(1, messages.requiredField)
      .required(messages.requiredField),
    facebookUrl: yup
      .string()
      .test("if value", messages.invalidFBUrl, (value) => {
        if (value) {
          return FB_URL.test(value);
        } else {
          return true;
        }
      })
      .nullable()
      .notRequired(),
    instagramUrl: yup
      .string()
      .test("if value", messages.invalidInstaUrl, (value) => {
        if (value) {
          return INSTA_URL_REGEX.test(value);
        } else {
          return true;
        }
      })
      .nullable()
      .notRequired(),

    twitterUrl: yup
      .string()
      .test("if value", messages.invalidTwitterUrl, (value) => {
        if (value) {
          return TWITTER_URL_REGEX.test(value);
        } else {
          return true;
        }
      })
      .nullable()
      .notRequired(),
  })
  .required();
