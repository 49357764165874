import client from "../axiosConfig";

export const getVendors = async ({ sortBy, limit }) => {
  return client.get(`/api/v1/vendors`, {
    params: { sortBy, limit },
  });
};
export const emailAvailability = async ({ email }) => {
  return client.get(`/api/v1/vendors/auth/emailAvailability/${email}`);
};
export const getFavVendorsByServiceId = async ({ id, queryParams = {} }) => {
  return client.get(`/api/v1/services/${id}/vendors/favourited`, queryParams);
};

export const updateVendor = ({ body }) => {
  return client.patch(`/api/v1/vendors`, body);
};

export const getPaymentServicesStatus = ({ id = "" } = {}) => {
  return client.get(`/api/v1/vendors/payment-services/status/${id}`);
};

export const getVendorReviews = (id, queryParams) => {
  return client.get(`/api/v1/vendors/${id}/reviews`, {
    params: queryParams,
  });
};
