import formatUrl from "utils/formatUrl";
import client from "../axiosConfig";

export function createEvent({ body }) {
  return client.post(`/api/v1/events`, body);
}
export function getAllEvents({ queryParams = {} }) {
  return client.get(formatUrl(`/api/v1/events`, queryParams));
}
export function getOneEvents(id) {
  return client.get(`/api/v1/events/${id}`);
}
export function getUpcomingEvents({ queryParams = {} }) {
  return client.get(formatUrl(`/api/v1/events/upcomingEvent`, queryParams));
}
export function getEventType() {
  return client.get(`/api/v1/events/types`);
}
export function getCompletedEventsByVendorId({ id }) {
  return client.get(`/api/v1/events/vendors/${id}/completed`);
}
export function update({ id, body }) {
  return client.patch(`/api/v1/events/${id}`, body);
}

export function closeEvent(id) {
  return client.patch(`/api/v1/events/${id}/close`);
}
