import React from "react";
import { useUserContext } from "context/UserContext";

import { Box, CircularProgress } from "@mui/material";
import { Outlet } from "react-router-dom";
import useImage from "Hooks/useImage";
import useImages from "Hooks/useImages";
import useLoaded from "Hooks/useLoaded";
import { getServices } from "api/v1/service";
import { useQuery } from "react-query";

export default function VendorProfilePage() {
  const { user, userRefetch } = useUserContext();
  const { data: coverImage, refetch: refetchCoverPhoto } = useImage({
    key: user?.coverPhoto,
  });
  const { data: profileImage, refetch: refetchProfilePhoto } = useImage({
    key: user?.profilePhoto,
  });

  const { data: services, isLoading: servicesLoading } = useQuery(
    "services",
    async () => {
      const data = await getServices({ queryParams: { limit: 100000 } });
      return data.data;
    }
  );

  const isCoverImageLoaded = useLoaded({
    src: coverImage,
  });
  const isProfileImageLoaded = useLoaded({
    src: profileImage,
  });
  const galleryImages = useImages({
    model: user?.model,
    keys: user?.gallery ?? [],
  });

  if (!user || !user?.birthDate)
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          minHeigh: "100%",
          minWidth: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Outlet
      context={{
        user,
        userRefetch,
        coverImage,
        refetchCoverPhoto,
        isCoverImageLoaded,
        profileImage,
        refetchProfilePhoto,
        isProfileImageLoaded,
        galleryImages,
        services,
        servicesLoading,
      }}
    />
  );
}
