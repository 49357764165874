import * as React from "react";
import Tab from "components/Tab";
import classes from "./index.module.css";
import { Box } from "@mui/system";
import { Typography, Button, CircularProgress } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "context/UserContext";
import useMarkAsReadMutation from "Hooks/useMarkAsReadMutation";
import { useQueryClient } from "react-query";
import LoadMore from "components/LoadMore/LoadMore";
const TabSection = ({
  threads,
  active,
  allThreads,
  setActive,
  setActiveChat,
  areThreadsLoading,
  activeChat,
  threadHasNextPage,
  threadLoadMoreButtonRef,
  isVendor,
}) => {
  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const { user: userMetaData } = useUserContext();
  const { markAsReadMutation } = useMarkAsReadMutation(queryClient);
  const onTabClick = (tab) => {
    setActive(tab);
  };
  const onBlockClick = async ({ thread }) => {
    if (thread.metaData.unreadCount) markAsReadMutation(thread._id);
    setActiveChat(thread);
    navigate(`/dashboard/messages/${thread._id}`);
  };
  return (
    <Box className={classes.root}>
      {allThreads?.pages?.flat().length > 0 && (
        <Box className={classes.tabSection}>
          <Typography
            onClick={() => onTabClick("all")}
            className={`${active === "all" && classes.activeTab}`}
          >
            All
          </Typography>
          <Typography
            onClick={() => onTabClick("unread")}
            className={`${active === "unread" && classes.activeTab}`}
          >
            Unread
          </Typography>
        </Box>
      )}
      {areThreadsLoading ? (
        <div className="center">
          <CircularProgress size="3rem" />
        </div>
      ) : Boolean(threads?.pages?.[0]?.length) ? (
        threads.pages.flat().map((thread) => (
          <Tab
            thread={thread}
            activeChat={activeChat}
            profile={thread.users.find((user) => user._id !== userMetaData._id)}
            date={moment(thread.lastMessage.createdAt).fromNow(true)}
            onBlockClick={() => {
              onBlockClick({ thread });
            }}
            lastMessage={
              thread?.lastMessage?.content
                ? thread?.lastMessage?.content.substring(0, 18).trim() +
                  (thread?.lastMessage?.content.length > 18 ? "..." : "")
                : ""
            }
          />
        ))
      ) : active === "unread" ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Typography variant="h5">No unread messages</Typography>
        </Box>
      ) : (
        <Box className={classes.emptyButton}>
          <Button
            onClick={() =>
              navigate(isVendor ? "/dashboard/events" : "/dashboard/quotes")
            }
            variant="primary"
          >
            {" "}
            {isVendor
              ? "See your events to send quotes"
              : "See Your Quotes to Initiate Messages"}
          </Button>
        </Box>
      )}
      <LoadMore
        loadMoreRef={threadLoadMoreButtonRef}
        hasNextPage={threadHasNextPage}
      />
    </Box>
  );
};
export default TabSection;
