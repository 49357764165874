import CreateIcon from "@mui/icons-material/Create";
import { Box, Button, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { getUploadSignedUrl } from "api/v1/image";
import { updateUser } from "api/v1/user";
import profile from "assets/dashboard/myEvent/profile.webp";
import axios from "axios";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PersonalInformation from "../Login/SignUpPage/Vendor/forms/PersonalInformation";
import classes from "./EditProfile.module.css";
import useImage from "Hooks/useImage";
import { useUserContext } from "context/UserContext";
import dayjs from "dayjs";
import { DATE_FORMAT } from "constants";
const EditProfile = () => {
  const { user, userRefetch } = useUserContext();
  const { mutateAsync: getUploadSignedUrlData } =
    useMutation(getUploadSignedUrl);
  const { mutate, isLoading } = useMutation(updateUser, {
    onSuccess() {
      toast.success("Profile Updated Successfully");
      userRefetch();
    },
    onError(error) {
      toast.error(error.errMsg);
    },
  });
  const [activeStep, setActiveStep] = React.useState(0);
  const [file, setFile] = React.useState(null);
  const navigate = useNavigate();

  const formatedBirthDate = user?.birthDate
    ? dayjs(new Date(user?.birthDate)).format(DATE_FORMAT)
    : null;
  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    defaultValues: {
      firstName: user?.firstName ?? "",
      lastName: user?.lastName ?? "",
      birthDate: formatedBirthDate,
      email: user?.email ?? "",
      password: "",
      confirmPassword: "",
      gender: user?.gender ?? "",
      language: [],
    },
  });
  React.useEffect(() => {
    if (user && Object.keys(user).length > 0) {
      reset({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        gender: user.gender,
        birthDate: formatedBirthDate,
      });
    }
  }, [user]);

  const onSubmit = async (data) => {
    const {
      firstName,
      lastName,
      birthDate,
      email,
      password,
      gender,
      profilePicture,
      confirmPassword,
    } = data;

    const body = {
      email,
      firstName,
      lastName,
      birthDate: dayjs(birthDate).format("YYYY-MM-DD"),
      password: confirmPassword,
      existingPassword: password,
      gender,
      profilePicture,
    };
    if (data?.profilePicture) {
      const uploadSignedUrl = await getUploadSignedUrlData({
        key: "profile",
      });
      axios.put(uploadSignedUrl?.data?.url, data.profilePicture, {
        headers: {
          "Content-Type": data.profilePicture.type,
        },
      });
      body["profilePhoto"] = uploadSignedUrl?.data?.key;
    }
    await mutate({ body });
  };

  const onChangePicture = (e, onChange) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
      onChange(file);
    }
  };

  const handleBack = () => {
    navigate("/");
  };
  const { data: profileImage } = useImage({
    key: user?.profilePhoto,
  });

  if (!user) return <div></div>;

  return (
    <>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          backgroundColor: "white",
        }}
      >
        <Box className={classes.root}>
          <Button
            style={{
              zIndex: 999,
            }}
            variant="primary"
            onClick={handleBack}
          >
            Back
          </Button>
          <Typography variant="body6" component="h3" mt={3}>
            My Profile
          </Typography>
          <Box className={classes.center}>
            <img
              src={
                file
                  ? URL.createObjectURL(file)
                  : profileImage
                  ? profileImage
                  : profile
              }
              for="photo-upload"
              alt="profile"
              className={classes.image}
              accept="image/*"
            />
            <Box className={classes.eventDetail}>
              <Controller
                control={control}
                name={`profilePicture`}
                render={({ field: { onChange } }) => (
                  <input
                    type="file"
                    name="profilePicture"
                    id="img"
                    accept="image/*"
                    onChange={(e) => {
                      onChangePicture(e, onChange);
                    }}
                    style={{ display: "none" }}
                  />
                )}
              />
              <label htmlFor="img">
                <div className={classes.label}>
                  <CreateIcon fontSize="large" />
                </div>
              </label>
            </Box>
          </Box>
          <Box component="form" onSubmit={handleSubmit(onSubmit)}>
            <PersonalInformation
              control={control}
              getValues={getValues}
              setActiveStep={setActiveStep}
              forProfile={false}
              user={user}
            />
            <LoadingButton
              loading={isLoading}
              disabled={!isDirty || errors.length > 0}
              variant="primary"
              type="submit"
            >
              Submit
            </LoadingButton>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default EditProfile;
