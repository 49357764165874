import StarIcon from "@mui/icons-material/Star";
import { Box, Container, Typography, useTheme } from "@mui/material";
import notFound from "assets/not-found/notFound.jpg";
import Image from "components/Image";
import * as React from "react";
import classes from "./AboutVendor.module.css";
const AboutVendor = ({ data }) => {
  const theme = useTheme();
  return (
    <Container
      sx={{
        maxWidth: "1420px !important",
        margin: "auto",
      }}
    >
      <Typography
        component="h2"
        variant="h3"
        className={classes.intro}
        color={theme.palette.primary.main}
      >
        Introduction:
      </Typography>
      <Typography
        component="p"
        variant="p"
        color={theme.palette.primary.black}
        className={classes.paragraph}
      >
        {data?.serviceDescription}
      </Typography>
      <br />
      <Typography variant="body3" className={classes.skills} color="#01214D">
        Skills and Expertise
      </Typography>
      <Box className={classes.tags}>
        {data?.skills ? (
          data?.skills.map(({ name }, index) => {
            return <Tag text={name} key={index} />;
          })
        ) : (
          <Typography>No skills Given</Typography>
        )}
      </Box>
      <Box>
        <Gallery gallery={data?.gallery} />
      </Box>
    </Container>
  );
};

export default AboutVendor;

function Tag({ text }) {
  const theme = useTheme();
  return (
    <Box className={classes.tagContainer}>
      <StarIcon className={classes.icon} />
      <Typography
        variant="body3"
        className={classes.skills}
        color={theme.palette.primary.black}
      >
        {text}
      </Typography>
    </Box>
  );
}

function Gallery({ gallery }) {
  const theme = useTheme();
  return (
    <Box className={classes.root}>
      <Typography
        component="h2"
        variant="h3"
        className={classes.intro}
        color={theme.palette.primary.main}
      >
        Gallery:
      </Typography>
      <Box className={classes.gallery}>
        <Box className={classes.images}>
          {gallery?.length > 0 ? (
            gallery?.map((image) => (
              <Image
                style={{
                  maxWidth: "20rem",
                }}
                src={image}
              />
            ))
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
            >
              <img src={notFound} />
            </div>
          )}
        </Box>
      </Box>
    </Box>
  );
}
