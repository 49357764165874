import LoginPage from "views/pages/Login/LoginPage/LoginPage";
import ForgotPassword from "views/pages/ForgotPassword";
import UserSignup from "views/pages/Login/SignUpPage/User";
import VendorSignup from "views/pages/Login/SignUpPage/Vendor";
import { Box } from "@mui/material";
import NavBar from "components/navigation/AppBar/NavBar";
import Event from "views/pages/Event/EventPage";
import PlanPage from "views/pages/Event/EventPage/PlanPage";
import AllServices from "views/pages/Services/AllServices/AllServices";
import Vendors from "views/pages/Services/Vendors/Vendors";
import ServiceDetail from "views/pages/Services/ServiceDetail/ServiceDetail";
import CheckListPage from "views/pages/Event/EventPage/CheckListPage";
import { Route, Routes } from "react-router-dom";
import EditProfile from "views/pages/EditProfile/EditProfile";
import NotFound from "views/pages/404/NotFound";
import ROUTES from "routes";
import VerificationCode from "views/pages/Login/VerificationCode/VerificationCode";
import SignUpConfirmationPage from "views/pages/Login/SignUpPage/SignUpConfirmationPage";
function MainLayoutRoutes() {
  return (
    <>
      <Box
        sx={{
          position: "absolute",
          display: "flex",
          zIndex: "2",
          flexGrow: "1",
          width: "100%",
        }}
      >
        <NavBar />
      </Box>
      <Routes>
        <Route path={ROUTES.LOGIN} element={<LoginPage />} />
        <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword />} />
        <Route
          path={ROUTES.RESET_PASSWORD}
          element={<ForgotPassword isResetPage={true} />}
        />
        <Route path={ROUTES.SIGNUP.USER} element={<UserSignup />} />
        <Route
          path={ROUTES.SIGNUP_CONFIRMATION.USER}
          element={<SignUpConfirmationPage />}
        />
        <Route
          path={ROUTES.VERIFICATIONCODE.USER}
          element={<VerificationCode />}
        />
        <Route path={ROUTES.SIGNUP.VENDOR} element={<VendorSignup />} />
        <Route
          path={ROUTES.SIGNUP_CONFIRMATION.VENDOR}
          element={<SignUpConfirmationPage />}
        />
        <Route path="/event" element={<Event />}>
          <Route path="plan" element={<PlanPage />} />
          <Route path="checklist" element={<CheckListPage />} />
        </Route>
        <Route path={ROUTES.SERVICIES.MAIN} element={<AllServices />} />
        <Route path={ROUTES.SERVICIES.VENDOR} element={<Vendors />} />
        {/*Vendor Detail Page*/}
        <Route path={ROUTES.SERVICIES.DETAIL} element={<ServiceDetail />} />
        <Route path={ROUTES.PROFILE} element={<EditProfile />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </>
  );
}

export default MainLayoutRoutes;
