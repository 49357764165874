import * as React from "react";

// To temporarily hold event data if the vendor is not logged in
const LogoutContext = React.createContext();

export function LogoutProvider({ children }) {
  const [logoutModal, setLogoutModal] = React.useState(false);

  return (
    <LogoutContext.Provider
      value={{
        logoutModal,
        setLogoutModal,
      }}
    >
      {children}
    </LogoutContext.Provider>
  );
}

export function useLogoutContext() {
  const context = React.useContext(LogoutContext);
  if (context === undefined) {
    throw new Error("useLogoutContext can only be used inside LogoutProvider");
  }
  return context;
}
