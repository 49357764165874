import { Box, Rating, Typography } from "@mui/material";
import React from "react";
import placeholderProfileImage from "assets/dashboard/myEvent/profile.webp";
import classes from "./index.module.css";
import useLoaded from "Hooks/useLoaded";

function DetailItem({
  profileImage,
  value,
  rating,
  isVendor = false,
  imgStyles = null,
}) {
  const isImage = useLoaded({ src: profileImage });

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ pr: "0.5rem" }}>
        <img
          src={
            isImage
              ? isImage != "error"
                ? profileImage
                : placeholderProfileImage
              : placeholderProfileImage
          }
          alt="profile"
          className={classes.profile}
          sx={imgStyles}
        />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="p600" sx={{ color: "text.primary" }}>
          {value}
        </Typography>
        {isVendor ? null : (
          <Rating readOnly value={rating ? rating : 0} size="small" />
        )}
      </Box>
    </Box>
  );
}

export default DetailItem;
