import React from "react";
import {
  Box,
  Button,
  Chip,
  Grid,
  Rating,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import classes from "./MeetingDetails.module.css";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import large from "assets/icon/large.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import profileImage from "assets/dashboard/myEvent/profile.webp";
import { getMeeting } from "api/v1/meeting";
import { useQuery } from "react-query";
import { DATE_FORMAT_TWO } from "constants";
import FaceIcon from "@mui/icons-material/Face";
import moment from "moment/moment";
import dayjs from "dayjs";

function MeetingDetails() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { meetingId } = useParams();
  const { data } = useQuery(["meetings", meetingId], async () => {
    const res = await getMeeting({ id: meetingId });
    return res.data;
  });
  return (
    <Box>
      <Typography variant="h2" p={2}>
        Meeting Details
      </Typography>
      <Box className={classes.root}>
        <Box className={classes.heading}>
          <Typography variant="body6" mr={1} fontWeight={600}>
            {data?.title}
          </Typography>
        </Box>
        <Typography
          component="p"
          mb={3}
          mt={1}
          variant="mediumText"
          fontWeight={400}
        >
          {data?.description}
        </Typography>
        <hr />
        <Grid container rowSpacing={2} mt={3}>
          <Grid item xl={6} lg={6} md={6} sm={12} xxs={12}>
            <Box className={classes.iconContainer}>
              <Box sx={{ color: theme.palette.primary.main }}>
                <CalendarMonthOutlinedIcon fontSize="large" />
              </Box>
              <Typography variant="body4" fontWeight="400">
                {moment(data?.startTime).format(DATE_FORMAT_TWO)}
              </Typography>
            </Box>
            <Box className={classes.iconContainer}>
              <Box sx={{ color: theme.palette.primary.main }}>
                <WatchLaterIcon fontSize="large" />
              </Box>
              <Typography variant="h5" fontWeight="400">
                {`${dayjs(data?.startTime).format("LT")} (${data?.timeZone})`}
              </Typography>
            </Box>
            <Box className={classes.iconContainer}>
              <Box sx={{ color: theme.palette.primary.main }}>
                <LocationOnIcon fontSize="large" />
              </Box>
              <Typography variant="body4" fontWeight="400">
                {data?.link || data?.venue}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Typography variant="body6" component="h3" mt={3}>
          Vendor Details
        </Typography>
        <Grid container rowSpacing={2} mt={1} sx={{ marginBottom: "40px" }}>
          <Grid item xl={6} lg={12} md={12} sm={12} xxs={12}>
            <Box className={classes.profileDetail}>
              <Box>
                <img
                  src={profileImage}
                  alt="profile"
                  className={classes.profile}
                />
              </Box>
              <Box>
                <Typography variant="body6" mr={1} fontWeight={600}>
                  {data?.vendorId?.fullName}
                </Typography>
                <Link to={`/dashboard/vendors/${data?.vendorId?._id}`}>
                  <img src={large} alt="large" style={{ cursor: "pointer" }} />
                </Link>
                <Box className={classes.rating}>
                  <Rating
                    name="half-rating-read"
                    defaultValue={data?.vendorId?.rating}
                    precision={0.5}
                    readOnly
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xl={6} lg={12} md={12} sm={12} xxs={12}>
            <Box className={classes.btn}>
              <Link to="/dashboard/quotes">
                <Button variant="secondary">See All Quote</Button>
              </Link>
            </Box>
          </Grid>
        </Grid>
        <hr />
        <Typography variant="body6" component="h3" mt={3} pb={1.6}>
          Members
        </Typography>
        <Stack direction="row" spacing={1}>
          {data?.people.map((item, index) => {
            return <Chip icon={<FaceIcon />} label={item} variant="outlined" />;
          })}
        </Stack>
        <Box className={classes.btnContainer}>
          <Button
            component="button"
            variant="secondary"
            onClick={() => navigate(-1)}
          >
            Back
          </Button>
          <Button
            component="button"
            variant="primary"
            onClick={() => navigate(`/dashboard/meetings/${meetingId}/edit`)}
          >
            Edit Meeting
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default MeetingDetails;
