import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { styled } from "@mui/system";
import { Controller } from "react-hook-form";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
} from "@mui/material";

function FormRadioButton({
  name,
  control,
  options,
  row,
  label,
  errors,
  radioClasses = "",
  ...rest
}) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FormControl sx={{ display: "flex", maxWidth: "100%" }}>
          {label && (
            <FormLabel
              focused={false}
              sx={{
                fontWeight: "600",
                fontSize: "18px",
                lineHeight: "26px",
                color: "#01214D",
                textAlign: "left",
                marginBottom: "5px",
              }}
            >
              {label}
            </FormLabel>
          )}
          <RadioGroup {...rest} row={row} {...field}>
            {options.map((x) => (
              <FormControlLabel
                className={radioClasses}
                key={x.value}
                value={x.value}
                control={<Radio />}
                label={x.label}
                {...rest}
              />
            ))}
          </RadioGroup>
          {errors && <FormHelperText error>{errors}</FormHelperText>}
        </FormControl>
      )}
    />
  );
}

export default FormRadioButton;
