import { updateCache } from "utilities";

export function addMessageInCachedThread({ queryKey, queryClient, message }) {
  updateCache(queryKey, queryClient, function update(updatedThread) {
    updatedThread.pages[0] = [message, ...updatedThread.pages[0]];
    queryClient.setQueryData(queryKey, updatedThread);
  });
}
export function updateLastMessage(
  queryKey,
  queryClient,
  { thread, content, sender },
  activeChatIdRef = null,
  currentUserId
) {
  updateCache(queryKey, queryClient, function update(threads) {
    let threadIndex;
    let pageIndex;
    if (!threads?.pages?.length) return;
    threads.pages.forEach((i, index) => {
      threadIndex = threads.pages[index].findIndex(
        (item) => item._id === thread
      );
      if (threadIndex > -1) {
        pageIndex = index;
        return true;
      }
      return false;
    });
    if (threadIndex > -1) {
      threads.pages[pageIndex][threadIndex].lastMessage.content = content;
      threads.pages[pageIndex][threadIndex].lastMessage.sender = sender;
      threads.pages[pageIndex][threadIndex].lastMessage.createdAt = new Date();
      if (thread !== activeChatIdRef && sender !== currentUserId)
        threads.pages[pageIndex][threadIndex].metaData.unreadCount += 1;
    }
    queryClient.setQueryData(queryKey, threads);
  });
}
export function addNewThread(thread, queryClient) {
  updateCache("threads", queryClient, function update(updatedThreads) {
    updatedThreads.pages[0] = [thread, ...updatedThreads.pages[0]];
    queryClient.setQueryData("threads", updatedThreads);
  });
}
