import React from "react";
import { styled } from "@mui/system";
const StyledDiv = styled("div")(({ theme }) => ({
  border: "1.2619px solid #504E4E",
  borderRadius: "5.04762px",
  maxWidth: "75.71px",
  maxHeight: "64.36px",
  backgroundColor: "#fff",
  textAlign: "center",
  padding: "1rem",
  fontWeight: "500",
  fontSize: "20px",
  lineHeight: "28px",
  cursor: "pointer",
}));
function TimeShowStyled(props) {
  return <StyledDiv {...props}>{props.children}</StyledDiv>;
}

export default TimeShowStyled;
