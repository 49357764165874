import { CssBaseline } from "@mui/material";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import {
  palette,
  breakpoints,
  Typography,
  Button,
  LoadingButton,
  Input,
  StaticDatePicker,
  PickersDay,
} from "./themeObjects/index";

export default function ThemeConfig({ children }) {
  let theme = createTheme({
    palette: palette,
    breakpoints: breakpoints,
    typography: {
      fontFamily: [
        "Be Vietnam Pro",
        "Playfair Display",
        "Poppins",
        "sans-serif",
      ].join(","),
    },
  });
  theme = createTheme(theme, {
    components: {
      ...Button(theme),
      ...LoadingButton(theme),
      ...Typography(theme),
      ...Input(theme),
      ...StaticDatePicker(theme),
      ...PickersDay(theme),
    },
  });
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
