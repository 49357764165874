import React from "react";
import { Box, Stack, Typography, Rating } from "@mui/material";
import useImage from "Hooks/useImage";
import placeholderProfileImage from "assets/dashboard/myEvent/profile.webp";
import useLoaded from "Hooks/useLoaded";
import { useNavigate } from "react-router-dom";

function RightMiddleSubSection({
  businessName,
  location,
  rating,
  profilePhoto,
  _id
}) {
  const { data: profileImage } = useImage({
    key: profilePhoto,
  });
  const navigate = useNavigate();
  const isImage = useLoaded({ src: profileImage });
  return (
    <Box
      sx={{
        border: "0.877898px solid #b5b3b3",
        boxShadow: "0px 3.51159px 17.558px rgba(0, 0, 0, 0.15)",
        borderRadius: "3.51159px",
        minHeight: "96.57px",
        display: "flex",
        alignItems: "center",
        width: "100%",
        cursor:"pointer"
      }}
      onClick={()=> navigate(`/dashboard/vendors/${_id}`)}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ width: "100%", height: "100%", maxWidth: "100%", p: 2 }}
        spacing={1}
      >
        <Box sx={{ width: "65.84px", height: "65.84px" }}>
          <img
            src={
              isImage
                ? isImage !== "error"
                  ? profileImage
                  : placeholderProfileImage
                : placeholderProfileImage
            }
            alt="avatar"
            style={{
              width: "100%",
              height: "100%",
              borderRadius: "3.51159px",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            flex: 1,
          }}
        >
          <Typography
            variant="pBold"
            sx={{
              color: "text.primary",
              fontSize: {
                xxs: "0.875rem",
                sm: "0.9rem",
                md: "1rem",
              },
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
              width: "100%",
            }}
          >
            {businessName}
          </Typography>
          <Typography
            variant="p"
            sx={{
              color: "text.primary",
              fontSize: { xxs: "0.75rem", sm: "0.8rem", md: "0.875rem" },
            }}
          >
            {location.city && `${location.city},`}
            {location?.state}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Rating name="read-only" value={rating} readOnly size="small" />
          <Typography
            component="legend"
            variant="p"
            sx={{ fontSize: { xxs: "0.75rem", textAlign: "center" } }}
          >
            {rating}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
}

export default RightMiddleSubSection;
