import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import logo from "assets/logo/logo.png";
import { Box } from "@mui/system";
import { customerNavList, vendorNavList } from "./listItems";

import { useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from "context/UserContext";

export default function DrawerList() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isVendor } = useUserContext();

  const navList = isVendor ? vendorNavList : customerNavList;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Toolbar sx={{ justifyContent: "center", py: "2rem" }}>
        <Box
          sx={{ cursor: "pointer" }}
          onClick={() =>
            (window.location.href = process.env.REACT_APP_LANDING_URL)
          }
        >
          <img src={logo} alt="logo" />
        </Box>
      </Toolbar>

      <List>
        {navList?.map(({ id, item, url, icon }, index) => (
          <ListItem
            key={item}
            disablePadding
            sx={{
              px: "1rem",
              ...pathSelector(id, url, pathname),
            }}
          >
            <Box sx={{ width: "100%" }}>
              <ListItemButton
                onClick={() => navigate(url)}
                sx={{
                  mx: "auto",
                  "&:hover": {
                    backgroundColor: "#5f615f",
                  },
                }}
              >
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={item} />
              </ListItemButton>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

function pathSelector(id, url, pathname) {
  if (id === "dashboard" && url === pathname) {
    if (url === pathname) {
      return {
        px: "0.8rem",
        borderLeft: "4px solid #E801BE",
      };
    } else {
      return {
        px: "1rem",
        borderLeft: "none",
      };
    }
  }
  if (id !== "dashboard") {
    if (pathname.includes(url)) {
      return {
        px: "0.8rem",
        borderLeft: "4px solid #E801BE",
      };
    } else {
      return {
        px: "1rem",
        borderLeft: "none",
      };
    }
  }
}
