import { pxToRem } from "../util/util";

function responsiveFontSizes({ xs, sm, md, lg }) {
  return {
    "@media (min-width: 359px)": {
      fontSize: pxToRem(xs),
    },
    "@media (min-width:600px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:960px)": {
      fontSize: pxToRem(md),
    },
    "@media (min-width:1280px)": {
      fontSize: pxToRem(lg),
    },
  };
}
const FONT_PRIMARY = "Be Vietnam Pro";
const FONT_SECONDARY = "Playfair Display";
const FONT_TERTIARY = "Poppins";
export default function Typography(theme) {
  return {
    MuiTypography: {
      variants: [
        {
          props: { variant: "h1" },
          style: {
            fontSize: "3.5rem",
            fontFamily: FONT_SECONDARY,
            fontWeight: "700",
            color: theme.palette.text.primary,
            ...responsiveFontSizes({ xs: 35, sm: 40, md: 45, lg: 50 }),
          },
        },
        {
          props: { variant: "h1bold" },
          style: {
            fontSize: "3.5rem",
            fontFamily: FONT_SECONDARY,
            fontWeight: "700",
            color: theme.palette.text.primary,
            ...responsiveFontSizes({ xs: 35, sm: 40, md: 45, lg: 50 }),
          },
        },
        {
          props: { variant: "h2" },
          style: {
            fontSize: "2.5rem",
            fontFamily: FONT_SECONDARY,
            fontWeight: "700",
            color: theme.palette.text.primary,
            ...responsiveFontSizes({ xs: 30, sm: 35, md: 40, lg: 45 }),
          },
        },
        {
          props: { variant: "h2bold" },
          style: {
            fontSize: "2.5rem",
            fontFamily: FONT_SECONDARY,
            fontWeight: "700",
            color: theme.palette.text.primary,
            ...responsiveFontSizes({ xs: 30, sm: 35, md: 40, lg: 45 }),
          },
        },
        {
          props: { variant: "h3" },
          style: {
            fontSize: "2rem",
            fontFamily: FONT_SECONDARY,
            fontWeight: "700",
            color: theme.palette.text.primary,
            ...responsiveFontSizes({ xs: 25, sm: 30, md: 35, lg: 40 }),
          },
        },
        {
          props: { variant: "h3bold" },
          style: {
            fontSize: "2rem",
            fontFamily: FONT_SECONDARY,
            fontWeight: "700",
            color: theme.palette.text.primary,
            ...responsiveFontSizes({ xs: 25, sm: 30, md: 35, lg: 40 }),
          },
        },
        {
          props: { variant: "h4" },
          style: {
            fontSize: "1rem",
            fontFamily: FONT_PRIMARY,
            fontWeight: "700",
            color: theme.palette.text.primary,
            ...responsiveFontSizes({ xs: 25, sm: 27, md: 30, lg: 32 }),
          },
        },
        {
          props: { variant: "h5" },
          style: {
            fontSize: "1rem",
            fontFamily: FONT_PRIMARY,
            fontWeight: "700",
            color: theme.palette.text.primary,
            ...responsiveFontSizes({ xs: 18, sm: 20, md: 22, lg: 24 }),
          },
        },
        {
          props: { variant: "p" },
          style: {
            fontSize: "0.9rem",
            fontFamily: FONT_PRIMARY,
            fontWeight: "400",
            color: theme.palette.text.secondary,
            ...responsiveFontSizes({ xs: 14, sm: 14, md: 15, lg: 16 }),
          },
        },
        {
          props: { variant: "pBold" },
          style: {
            fontSize: "1.1rem",
            fontFamily: FONT_PRIMARY,
            fontWeight: "700",
            color: theme.palette.text.secondary,
            ...responsiveFontSizes({ xs: 14, sm: 14, md: 15, lg: 16 }),
          },
        },
        {
          props: { variant: "p600" },
          style: {
            fontSize: "1.1rem",
            fontFamily: FONT_PRIMARY,
            fontWeight: "600",
            color: theme.palette.text.secondary,
            ...responsiveFontSizes({ xs: 14, sm: 14, md: 16, lg: 18 }),
          },
        },
        {
          props: { variant: "p2" },
          style: {
            fontSize: "0.9rem",
            fontFamily: FONT_TERTIARY,
            fontWeight: "400",
            color: theme.palette.text.secondary,
            ...responsiveFontSizes({ xs: 14, sm: 14, md: 15, lg: 16 }),
          },
        },
        {
          props: { variant: "p2Bold" },
          style: {
            fontSize: "1.1rem",
            fontFamily: FONT_TERTIARY,
            fontWeight: "600",
            color: theme.palette.text.secondary,
            ...responsiveFontSizes({ xs: 14, sm: 14, md: 15, lg: 16 }),
          },
        },
        {
          props: { variant: "p3" },
          style: {
            fontSize: "1.1rem",
            fontFamily: FONT_PRIMARY,
            fontWeight: "400",
            color: theme.palette.text.secondary,
            ...responsiveFontSizes({ xs: 14, sm: 16, md: 18, lg: 20 }),
          },
        },
        {
          props: { variant: "body3" },
          style: {
            fontSize: "20px",
            fontFamily: FONT_PRIMARY,
            fontWeight: "600",
            color: theme.palette.text.primary,
            ...responsiveFontSizes({ xs: 15, sm: 16, md: 18, lg: 20 }),
          },
        },
        {
          props: { variant: "body4" },
          style: {
            fontSize: "24px",
            fontFamily: FONT_PRIMARY,
            fontWeight: "700",
            color: theme.palette.text.primary,
            ...responsiveFontSizes({ xs: 16, sm: 17, md: 20, lg: 24 }),
          },
        },
        {
          props: { variant: "body5" },
          style: {
            fontSize: "12px",
            fontFamily: FONT_PRIMARY,
            fontWeight: "400",
            color: theme.palette.text.secondary,
            ...responsiveFontSizes({ xs: 12, sm: 12, md: 12, lg: 12 }),
          },
        },
        {
          props: { variant: "body6" },
          style: {
            fontSize: "32px",
            fontFamily: FONT_TERTIARY,
            fontWeight: "700",
            color: theme.palette.primary.black,
            ...responsiveFontSizes({ xs: 18, sm: 22, md: 25, lg: 32 }),
          },
        },
        {
          props: { variant: "smallP" },
          style: {
            fontSize: "16px",
            fontFamily: FONT_TERTIARY,
            fontWeight: "600",
            color: theme.palette.primary.black,
            ...responsiveFontSizes({ xs: 12, sm: 12, md: 15, lg: 16 }),
          },
        },
        {
          props: { variant: "mediumText" },
          style: {
            fontSize: "18px",
            fontFamily: FONT_TERTIARY,
            fontWeight: "600",
            color: theme.palette.primary.black,
            ...responsiveFontSizes({ xs: 14, sm: 16, md: 17, lg: 18 }),
          },
        },
        {
          props: { variant: "pSmall" },
          style: {
            fontSize: "14px",
            fontFamily: FONT_TERTIARY,
            display: "block",
            fontWeight: "500",
            color: theme.palette.primary.black,
            ...responsiveFontSizes({ xs: 12, sm: 13, md: 14, lg: 14 }),
          },
        },
      ],
    },
  };
}
