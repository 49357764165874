import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Divider, FormControl, Grid, Typography } from "@mui/material";
import FormInputTextSecondary from "components/forms/FormInputTextSecondary";
import FormRadioButton from "components/forms/FormRadioButton";
import classes from "./detailForm.module.css";
import { guestIcon } from "./data/icon/themeIcons";
import GooglePlacesAutoComplete from "components/GooglePlacesAutoComplete";
import PlanActionButton from "components/PlanEventActionButtons/PlanActionButton";

function DetailForm({
  control,
  setFormStep,
  errors,
  trigger,
  setValue,
  getValues,
}) {
  const location = getValues("location");

  const handleClickBack = async () => {
    setFormStep((prev) => prev - 1);
  };
  const handleClickContinue = async () => {
    const result = await trigger([
      "venueName",
      "venueType",
      "location",
      "numberOfPeople",
    ]);
    result && setFormStep((prev) => prev + 1);
    document.querySelector("#stepper").scrollIntoView({
      behavior: "smooth",
    });
  };

  return (
    <Grid
      container
      rowSpacing={4}
      sx={{ padding: { xxs: "0.7rem", xs: "1.5rem", sm: "2rem" } }}
    >
      <Grid item xxs={12}>
        <FirstSection />
      </Grid>
      <Grid item xxs={12}>
        <Divider />
      </Grid>
      <Grid item xxs={12}>
        <SecondSection
          control={control}
          errors={errors}
          setFormValue={setValue}
          location={location}
        />
      </Grid>
      <Grid item xxs={12}>
        <ThirdSection control={control} errors={errors} />
      </Grid>
      <Grid
        item
        container
        xxs={12}
        columnSpacing={2}
        rowSpacing={2}
        justifyContent="center"
        style={{ textAlign: "center", paddingTop: "4rem" }}
      >
        <PlanActionButton
          primaryTextValue="Save and Continue"
          handlePrimaryClick={handleClickContinue}
          secondaryTextValue="Back"
          handleSecondaryClick={handleClickBack}
        />
      </Grid>
    </Grid>
  );
}

export default DetailForm;

function FirstSection() {
  return (
    <Grid
      container
      rowSpacing={2}
      justifyContent="center"
      sx={{ textAlign: "center", pb: "1rem" }}
    >
      <Grid item xxs={12}>
        <Typography variant="h4">Event Details</Typography>
      </Grid>
      <Grid item xxs={12}>
        <Typography variant="p3">
          Please provide more information about your event
        </Typography>
      </Grid>
    </Grid>
  );
}

function SecondSection({ control, errors, setFormValue, location }) {
  return (
    <Grid
      container
      rowSpacing={2}
      justifyContent="center"
      sx={{ textAlign: "center", pb: "1rem" }}
    >
      <Grid item xxs={12}>
        <FormControl sx={{ display: "flex", flexDirection: "column" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xxs: "column", md: "row" },
              justifyContent: { xxs: "center", md: "space-between" },
              alignItems: { xxs: "flex-start", md: "center" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                color: "#E801BE",
                fontSize: { xxs: "30px", sm: "35px", md: "43px" },
              }}
            >
              <LocationOnIcon color="inherit" fontSize="inherit" />
              <Typography variant="h5" sx={{ pl: { xxs: "0", md: "0" } }}>
                Location
              </Typography>
            </Box>
            <Box>
              <FormRadioButton
                control={control}
                radioClasses={classes.radioClass}
                name="venueType"
                options={[
                  { label: "Indoor", value: "indoor" },
                  { label: "Outdoor", value: "outdoor" },
                  { label: "Virtual", value: "virtual" },
                ]}
                errors={errors?.venueType?.message}
                row={true}
              />
            </Box>
          </Box>
        </FormControl>
      </Grid>

      <Grid item container columnSpacing={2} xxs={12}>
        {" "}
        <Grid item xxs={12} sm={6}>
          <FormInputTextSecondary
            name="venueName"
            control={control}
            label="Venue Name"
            errors={errors?.venueName?.message}
            placeholder="Enter venue name"
          />
        </Grid>
        <Grid item xxs={12} sm={6}>
          <GooglePlacesAutoComplete
            isSecondarySelect={true}
            control={control}
            defaultValue={location}
            errors={errors}
            setFormValue={setFormValue}
            name="location"
            label="Venue Address"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

function ThirdSection({ control, errors }) {
  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      <Grid item xxs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: { xxs: "38px", sm: "44px", md: "48px" },
              height: { xxs: "auto", sm: "auto", md: "auto" },
            }}
          >
            {guestIcon}
          </Box>
          <Box>
            <Typography
              variant="h5"
              sx={{ pl: { xxs: "0.3rem", md: "0.35rem" } }}
            >
              Number of Guests
            </Typography>
          </Box>
        </Box>
      </Grid>
      <Grid item xxs={12}>
        <FormInputTextSecondary
          type="number"
          InputProps={{ inputProps: { min: 1 } }}
          name="numberOfPeople"
          label="Number of People"
          control={control}
          errors={errors?.numberOfPeople?.message}
          placeholder="Enter the number of people attending your event"
        />
      </Grid>
    </Grid>
  );
}
