import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";
import { getUploadSignedUrl } from "api/v1/image";
import { updateVendor } from "api/v1/vendor";
import React from "react";
import { useMutation } from "react-query";
import { useOutletContext } from "react-router-dom";
import { toast } from "react-toastify";
import ActiveTab from "../comp/ActiveTab";
import Gallery from "../comp/Gallery";
import ProfileBanner from "../comp/ProfileBanner";
import ProfileBody from "../comp/ProfileBody";
import {
  profileIcon,
  descriptionIcon,
  galleryIcon,
} from "../icon/profilePageIcons";
import PolicyEdit from "./comp/PolicyEdit";
import ProfileEdit from "./comp/ProfileEdit";

const tabs = [
  { type: 0, label: "Profile", icon: profileIcon },
  { type: 1, label: "Gallery", icon: galleryIcon },
  { type: 2, label: "Cancellation Policy", icon: descriptionIcon },
];

function VendorProfileEdit() {
  const {
    user,
    userRefetch,
    coverImage,
    refetchCoverPhoto,
    isCoverImageLoaded,
    profileImage,
    refetchProfilePhoto,
    isProfileImageLoaded,
    galleryImages,
    services,
    servicesLoading,
  } = useOutletContext();
  const [currentTab, setCurrentTab] = React.useState(0);

  const { mutateAsync: getUploadSignedUrlData } =
    useMutation(getUploadSignedUrl);

  const { mutate: mutatePhoto, isLoading: isPhotoSubmitting } = useMutation(
    updateVendor,
    {
      onSuccess: (data) => {
        userRefetch();
      },
      onError: (error) => {
        toast.error(error?.errMsg);
      },
    }
  );

  if (!user || !user?.birthDate)
    return (
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "100%",
          minHeigh: "100%",
          minWidth: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CircularProgress />
      </Box>
    );

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        backgroundColor: "#fafafa",

        boxShadow: "0px -3px 27px rgba(0, 0, 0, 0.05)",
      }}
    >
      <Box>
        <ProfileBanner
          profileImage={profileImage}
          coverImage={coverImage}
          businessName={user?.businessName}
          rating={user?.rating}
          mutatePhoto={mutatePhoto}
          isPhotoSubmitting={isPhotoSubmitting}
          getUploadSignedUrlData={getUploadSignedUrlData}
          refetchCoverPhoto={refetchCoverPhoto}
          isCoverImageLoaded={isCoverImageLoaded}
          refetchProfilePhoto={refetchProfilePhoto}
          isProfileImageLoaded={isProfileImageLoaded}
        />
      </Box>
      <Box
        sx={{
          width: "100%",
          maxWidth: "100%",
          mt: { xxs: 15, sm: 18 },
          py: 2,
        }}
      >
        <ProfileBody
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          tabs={tabs}
        >
          <ActiveTab currentTab={currentTab}>
            <ProfileEdit
              value={0}
              user={user}
              userRefetch={userRefetch}
              services={services}
              servicesLoading={servicesLoading}
            />
            <Gallery
              value={1}
              isEdit={true}
              galleryImages={galleryImages}
              gallery={user?.gallery}
              mutatePhoto={mutatePhoto}
              isPhotoSubmitting={isPhotoSubmitting}
              getUploadSignedUrlData={getUploadSignedUrlData}
            />
            <PolicyEdit
              value={2}
              cancellationPolicy={user?.cancellationPolicy}
              userRefetch={userRefetch}
            />
          </ActiveTab>
        </ProfileBody>
      </Box>
    </Box>
  );
}

export default VendorProfileEdit;
