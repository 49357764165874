import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import IconWrapper from "components/Wrapper/IconWrapper";
import * as React from "react";
export default function CustomTabs({
  step,
  values,
  onSelect,
  iconPosition,
  mobileTabFontSize = "0.6rem",
}) {
  const handleClick = (index, type) => {
    onSelect(index, type);
  };

  return (
    <Box
      sx={{
        bgcolor: "transparent",
        maxWidth: { sm: "100%" },
      }}
    >
      <Tabs
        value={step}
        variant="fullWidth"
        aria-label="tabs"
        sx={{
          "& .Mui-selected": {
            color: "#000000 !important",
          },
          "& .MuiTabs-indicator": {
            borderBottom: "4px solid #E801BE",
          },
        }}
      >
        {values.map((x, index) => (
          <Tab
            key={x.label}
            label={x.label}
            icon={
              x.icon && (
                <IconWrapper
                  sx={{ width: "28px", height: "28px" }}
                  svgIcon={x.icon}
                />
              )
            }
            iconPosition={iconPosition}
            sx={{
              textTransform: "none",
              fontSize: { xxs: mobileTabFontSize, sm: "0.9rem", md: "1.25rem" },
              borderBottom: "4px solid #E7E4E4",
            }}
            onClick={() => {
              handleClick(index, x.type);
            }}
          />
        ))}
      </Tabs>
    </Box>
  );
}
