import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import {
  Box,
  Divider,
  IconButton,
  Paper,
  Typography,
  useTheme,
} from "@mui/material";
import { useUserContext } from "context/UserContext";
import useImage from "Hooks/useImage";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import formatDate from "utils/formatDate";
import Status from "../Status/Status";
import classes from "./MyEventCard.module.css";
import eventPlaceHolderImage from "assets/placeholder/image-placeholder.svg";
import LoadingImage from "components/LoadingImage/LoadingImage";
import useLoaded from "Hooks/useLoaded";

const MyEventCard = ({
  title,
  description,
  startTime,
  location,
  type,
  _id,
  quotes,
  quotesCount,
  status = "completed",
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { isVendor } = useUserContext();

  const { data: eventImage, isLoading: isImageLoading } = useImage({
    key: type?.eventTypeId?.images?.[0],
  });

  const isEventImage = useLoaded({ src: eventImage });

  return (
    <WrapperBasedOnUser isVendor={isVendor} eventId={_id} navigate={navigate}>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "175px",
          overflow: "hidden",
          borderRadius: "10px 10px 0px 0px",
        }}
      >
        <LoadingImage
          isImageLinkLoading={isImageLoading}
          isImageLoaded={isEventImage}
          image={eventImage}
          placeholderImage={eventPlaceHolderImage}
          alt="eventCardImage"
          imageStyles={{ borderRadius: "10px 10px 0px 0px" }}
        />
      </Box>
      <Box className={classes.status}>
        {status === "open" ? (
          <Status status="open" />
        ) : status === "completed" ? (
          <Status status="completed" />
        ) : status === "cancelled" ? (
          <Status status="cancelled" />
        ) : null}
      </Box>
      <Box className={classes.content} sx={{ px: 2 }}>
        <Box
          sx={{
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
          }}
        >
          <Typography
            sx={{
              cursor: "pointer",
              fontFamily: "Be Vietnam pro",
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "20px",
              lineHeight: "22px",
              color: "#01214D",
            }}
            variant="p"
          >
            {title}
          </Typography>
          <Typography
            className={classes.paragraph}
            component="p"
            variant="body5"
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            {description}
          </Typography>

          <Divider />
          <Box className={classes.clock}>
            <AccessTimeFilledIcon />
            <Typography variant="body5">{formatDate(startTime)}</Typography>
          </Box>
          <Box
            className={classes.clock}
            sx={{
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
          >
            <LocationOnIcon />
            <Typography
              variant="body5"
              sx={{
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {location?.address}
            </Typography>
          </Box>

          {quotes && quotes.length > 0 ? (
            <Box className={classes.clock}>
              <RequestQuoteIcon />
              <Typography variant="body5">Quote Submitted</Typography>
            </Box>
          ) : null}
        </Box>
      </Box>
      {isVendor ? null : (
        <Box
          sx={{
            flex: 1,
            display: "flex",
            alignItems: "flex-end",
            px: 2,
            py: 1,
          }}
        >
          {status === "open" ? (
            <Box className={classes.received} sx={{ flex: 1 }}>
              <Box className={classes.vendor}>
                <Box className={classes.circle}>
                  <Typography variant="body5" color="#fff">
                    {quotesCount}
                  </Typography>
                </Box>

                <Typography
                  variant="p"
                  fontWeight="600"
                  color={theme.palette.primary.black}
                  sx={{ pl: 0 }}
                >
                  Vendor Quotes Recieved
                </Typography>
              </Box>
              <Box className={classes.arrow}>
                {isVendor ? null : (
                  <IconButton
                    size="small"
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(`/dashboard/event/${_id}/quotes`);
                    }}
                    color="primary"
                  >
                    {" "}
                    <ArrowForwardRoundedIcon sx={{ fontSize: "28px" }} />
                  </IconButton>
                )}
              </Box>
            </Box>
          ) : null}
        </Box>
      )}
    </WrapperBasedOnUser>
  );
};

export default MyEventCard;

function WrapperBasedOnUser({ children, isVendor, navigate, eventId }) {
  return isVendor ? (
    <Paper
      sx={{
        minWidth: "320px",
        width: "340px",
        minHeight: "435px",
        maxHeight: "435px",
        cursor: "pointer",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        "&:hover": {
          boxShadow:
            " 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)",
        },
      }}
      elevation={2}
      onClick={() => navigate(`/dashboard/events/${eventId}`)}
    >
      {children}
    </Paper>
  ) : (
    <Box
      sx={{
        minWidth: "320px",
        width: "340px",
        display: "flex",
        flexDirection: "column",
        cursor: "pointer",
        minHeight: "435px",
        maxHeight: "435px",
      }}
      onClick={() => navigate(`/dashboard/events/${eventId}`)}
      className={classes.root}
    >
      {children}
    </Box>
  );
}
