import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Box, Container, Divider, Typography } from "@mui/material";
import { forgotPassword } from "api/v1/auth";
import backgroundImage from "assets/login/sign-up.svg";
import CustomCard from "components/forms/CustomCard";
import FormInputText from "components/forms/FormInputText";
import { useUserContext } from "context/UserContext";
import messages from "messages";
import React from "react";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { PASSWORD_MIN_LENGTH, PASSWORD_REGEX } from "constraints";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
function ForgotPassword({ isResetPage = false }) {
  const { setToken, isAuthenticated } = useUserContext();
  const [showPassConfirm, setShowPassConfirm] = React.useState(false);
  const [showPass, setShowPass] = React.useState(false);
  const { mutateAsync, isLoading, isError, isSuccess, error } =
    useMutation(forgotPassword);
  const [address, setAddress] = React.useState(null);
  const navigate = useNavigate();
  const schema = yup
    .object({
      ...(isResetPage
        ? {
            password: yup
              .string()
              .matches(PASSWORD_REGEX, messages.invalidPassword)
              .min(PASSWORD_MIN_LENGTH, messages.passwordLength)
              .required(messages.requiredField),
            passwordConfirm: yup
              .string()
              .required(messages.requiredField)
              .oneOf([yup.ref("password")], "Your passwords do not match."),
          }
        : {
            email: yup.string().email().required(messages.requiredField),
          }),
    })
    .required();
  const {
    control,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...(isResetPage
        ? {
            password: "",
            passwordConfirm: "",
          }
        : {
            email: "",
          }),
    },
    resolver: yupResolver(schema),
  });
  const handleClickShowPass = () => {
    setShowPass(!showPass);
  };
  const handleClickShowPassConfirm = () => {
    setShowPassConfirm(!showPassConfirm);
  };

  async function onSubmit(data) {
    try {
      await mutateAsync({
        payload: data,
      });
      toast.success("Confirmation email sent successfully");
      navigate("/");
    } catch (err) {
      toast.error(err.errMsg);
    }
  }

  return (
    <Box
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: "1",
        position: "relative",
      }}
    >
      <img
        src={backgroundImage}
        alt="sign-up-page"
        style={{
          objectFit: "cover",
          objectPosition: "center",
          position: "absolute",
          width: "100%",
          height: "100%",
          zIndex: "-2",
        }}
      />
      <div
        style={{
          width: "100%",
          height: "100%",
          backgroundColor: "#000",
          opacity: "0.3",
          position: "absolute",
          zIndex: "-1",
        }}
      />
      <Box sx={{ pt: "7rem" }}>
        <Container
          sx={{
            marginTop: "30px",
            marginBottom: "20px",
          }}
          maxWidth="md"
        >
          <Box
            sx={{
              textAlign: "center",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              mt: 2,
            }}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Box component="form" onSubmit={handleSubmit(onSubmit)}>
                <Box sx={{ width: "600px", height: "500px" }}>
                  <CustomCard style={{ height: "100%" }}>
                    <Box
                      sx={{
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        p: 5,
                      }}
                    >
                      <Box sx={{ pb: "5px" }}>
                        <Typography
                          variant="pbold"
                          style={{ fontSize: "2rem", lineHeight: "47px" }}
                        >
                          Forgot Password
                        </Typography>
                      </Box>
                      <Divider />
                      <Box
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            flexWrap: "wrap",
                          }}
                        >
                          <Box
                            sx={{
                              width: "90%",
                            }}
                          >
                            {isResetPage ? (
                              <>
                                <Box
                                  sx={{
                                    mb: "30px",
                                  }}
                                >
                                  <FormInputText
                                    label="Add Password"
                                    name="password"
                                    icon={VisibilityOff}
                                    iconToggle={Visibility}
                                    toggle={showPassConfirm}
                                    handleClick={handleClickShowPassConfirm}
                                    position="start"
                                    positionEdge="start"
                                    adornment="endAdornment"
                                    control={control}
                                    errors={errors?.password?.message}
                                  />
                                </Box>
                                <Box
                                  sx={{
                                    mb: "30px",
                                  }}
                                >
                                  <FormInputText
                                    label="Retype Password"
                                    name="passwordConfirm"
                                    icon={VisibilityOff}
                                    iconToggle={Visibility}
                                    toggle={showPass}
                                    handleClick={handleClickShowPass}
                                    position="start"
                                    positionEdge="start"
                                    adornment="endAdornment"
                                    control={control}
                                    errors={errors?.passwordConfirm?.message}
                                  />
                                </Box>
                              </>
                            ) : (
                              <Box
                                sx={{
                                  mb: "30px",
                                  mt: "30px",
                                }}
                              >
                                <FormInputText
                                  label="Email"
                                  name="email"
                                  placeholder="Enter your email"
                                  control={control}
                                  errors={errors?.email?.message}
                                />
                              </Box>
                            )}

                            <Box sx={{ mb: "10px", mt: "50px" }}>
                              <LoadingButton
                                type="submit"
                                loading={isLoading}
                                variant="primary"
                                fullWidth
                                sx={{
                                  height: "50px",
                                  fontSize: "1.25rem",
                                  lineHeight: "29px",
                                }}
                              >
                                Submit
                              </LoadingButton>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </CustomCard>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

export default ForgotPassword;
