import formatUrl from "utils/formatUrl";
import client from "../axiosConfig";

export const getServices = async ({ queryParams = {} }) => {
  return client.get(formatUrl(`/api/v1/services`, queryParams));
};
export const getSubServices = async () => {
  return client.get(`/api/v1/subServices`);
};
export const getServicesType = async () => {
  return client.get(`/api/v1/services`);
};
