import { pxToRem } from "../util/util";

function responsiveButtonSizes({ xs, sm, md, lg }) {
  return {
    "@media (min-width: 359px)": {
      fontSize: pxToRem(xs),
    },
    "@media (min-width:600px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:960px)": {
      fontSize: pxToRem(md),
    },
    "@media (min-width:1280px)": {
      fontSize: pxToRem(lg),
    },
  };
}
// function responsiveButtonPadding({ xs, sm, md, lg }) {
//   return {
//     "@media (min-width: 359px)": {
//       padding: xs,
//     },
//     "@media (min-width:600px)": {
//       padding: sm,
//     },
//     "@media (min-width:960px)": {
//       padding: md,
//     },
//     "@media (min-width:1280px)": {
//       padding: lg,
//     },
//   };
// }
export default function Button(theme) {
  return {
    MuiButton: {
      variants: [
        {
          props: { variant: "primary" },
          style: {
            background: theme.palette.primary.main,
            color: "#ffffff",
            textTransform: "none",
            fontFamily: "Be Vietnam Pro",
            fontWeight: "700",
            fontSize: "1rem",
            cursor: "pointer",
            whiteSpace: "nowrap",
            borderRadius: "34px",
            minWidth: pxToRem(140),
            ...responsiveButtonSizes({ xs: 12, sm: 12, md: 14, lg: 14 }),
            // ...responsiveButtonPadding({
            //   xs: "0.5rem 1rem",
            //   sm: "0.5rem 1rem",
            //   md: "0.5rem 1.5rem",
            //   lg: "0.5rem 2rem",
            // }),
            "&:hover": {
              background: "0.5",
              backgroundColor: theme.palette.primary.dark,
            },
          },
        },
        {
          props: { variant: "secondary" },
          style: {
            color: "#000",
            fontFamily: "Be Vietnam Pro",
            fontWeight: "700",
            cursor: "pointer",
            fontSize: "1rem",
            textTransform: "none",
            borderStyle: "solid",
            borderWidth: "2px",
            borderColor: "#000",
            borderRadius: "34px",
            minWidth: pxToRem(140),
            ...responsiveButtonSizes({ xs: 12, sm: 12, md: 13, lg: 13 }),
            // ...responsiveButtonPadding({
            //   xs: "0.5rem 1rem",
            //   sm: "0.5rem 1rem",
            //   md: "0.5rem 1.5rem",
            //   lg: "0.5rem 2rem",
            // }),
            "&:hover": {
              background: "0.4",
              borderStyle: "solid",
              borderWidth: "2px",
            },
          },
        },
        {
          props: { variant: "small" },
          style: {
            color: theme.palette.primary.dark,
            fontFamily: "Poppins",
            fontWeight: "600",
            cursor: "pointer",
            fontSize: "1.2rem",
            padding: "0.3rem 1rem",
            textTransform: "none",
            borderStyle: "solid",
            borderRadius: "5rem",
            borderWidth: "2px",
            "&:hover": {
              transform: "scale(0.98)",
            },
          },
        },
        {
          props: { variant: "btnMedium" },
          style: {
            backgroundColor: theme.palette.primary.green,
            color: "#fff",
            fontFamily: "Poppins",
            fontWeight: "600",
            cursor: "pointer",
            fontSize: "1.2rem",
            padding: "6px 30px",
            textTransform: "none",
            borderStyle: "solid",
            borderRadius: "6.64px",
            "&:hover": {
              backgroundColor: theme.palette.primary.green,
            },
          },
        },
      ],
    },
  };
}
