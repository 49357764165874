export default function PickersDay(theme) {
  return {
    MuiPickersDay: {
      styleOverrides: {
        root: {
          borderRadius: "7px",
        },
      },
    },
  };
}
