import { USER_TYPE } from "constants";
import client from "../axiosConfig";

export function signup({ userType, body }) {
  return client.post(`/api/v1/${userType}/auth/signUp`, body);
}

export function login({ userType, body }) {
  return client.post(`/api/v1/${userType}/auth/login`, body);
}
export function forgotPassword({ payload }) {
  return client.patch(`/api/v1/customers/auth/forgot-password`, payload);
}

export function getUser() {
  return client.get(`/api/v1/user`);
}

export function checkUsernameAvailability({ username }) {
  return client.get(
    `/api/v1/${USER_TYPE.CUSTOMER}/auth/usernameAvailability/${username}`
  );
}

export function updateUser({ body }) {
  return client.patch(`/api/v1/user`, body);
}
