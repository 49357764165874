import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useUserContext } from "context/UserContext";

function ProtectedRoute({ allowedUsers }) {
  const { isAuthenticated } = useUserContext();

  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
}

export default ProtectedRoute;
