import TextField from "@mui/material/TextField";
import { styled } from "@mui/system";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import IconWrapper from "components/Wrapper/IconWrapper";
import * as React from "react";
import { Controller } from "react-hook-form";
import { calendarIcon } from "views/pages/Event/EventPage/PlanPage/forms/data/icon/themeIcons";
import InputWrapper from "../Wrapper/InputWrapper";

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    color: theme.palette.text.secondary,
    fontSize: "0.875rem",
    maxHeight: "45px",
  },
  borderRadius: "123px",

  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "primary.light",
  },
}));

function CalendarIcon() {
  return (
    <IconWrapper
      sx={{ width: "20px", height: "20px" }}
      svgIcon={calendarIcon}
    />
  );
}

function InputDatePicker({
  onChange,
  defaultValue = null,
  format = "YYYY-MM-DD",
  placeholder,
  ...rest
}) {
  const [value, setValue] = React.useState(defaultValue);

  function onChangeHandler(value) {
    setValue(value);
    onChange(value ? value.format(format) : "");
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        {...rest}
        value={value}
        onChange={onChangeHandler}
        components={{ OpenPickerIcon: CalendarIcon }}
        classes={{
          root: {
            height: "45px",
          },
        }}
        OpenPickerButtonProps={{}}
        renderInput={(params) => (
          <StyledTextField
            variant="outlined"
            placeholder={placeholder}
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  );
}

export default function FormInputDatePicker({
  name,
  control,
  label,
  errors,
  defaultValue,
  placeholder,
  ...rest
}) {
  return (
    <InputWrapper label={label} errors={errors}>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange } }) =>
          InputDatePicker({ onChange, defaultValue, placeholder, ...rest })
        }
      />
    </InputWrapper>
  );
}
