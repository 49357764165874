import client from "api/axiosConfig";
import formatUrl from "utils/formatUrl";

export function createBooking({ body }) {
  return client.post(`/api/v1/bookings`, body);
}

export function createOrderPaypal({ id, body }) {
  return client.post(`/api/v1/bookings/${id}/order/paypal`, body);
}

export function createOrderStripe({ id, body }) {
  return client.post(`/api/v1/bookings/${id}/order/stripe`, body);
}

export function getBooking({ id }) {
  return client.get(`/api/v1/bookings/${id}`);
}
export function getEventBookings({ id }) {
  return client.get(`/api/v1/events/${id}/bookings`);
}
export function getBookings({ queryParams = {} } = {}) {
  return client.get(formatUrl(`/api/v1/bookings`, queryParams));
}
export function disputeBookings(id) {
  return client.patch(`/api/v1/bookings/${id}/dispute`);
}
export function concludeBookings(id) {
  return client.patch(`/api/v1/bookings/${id}/conclude`);
}
export function addReviews({ id, payload } = {}) {
  return client.post(`/api/v1/bookings/${id}/review`, payload);
}
