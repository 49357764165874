import { useQueries } from "react-query";
import { getImage } from "api/v1/image";

export default function useImages({
  model,
  keys,
  enabled = Boolean(keys.length),
  ...queryConfig
}) {
  return useQueries(
    keys?.map((key) => ({
      queryKey: ["image", key],
      queryFn: async () => {
        const res = await getImage({
          key,
        });
        return res.data;
      },
      enabled,
      staleTime: Infinity,
      refetchInterval: 840000,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      ...queryConfig,
    }))
  );
}
