import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { getMeetings } from "api/v1/meeting";
import LoadMore from "components/LoadMore/LoadMore";
import MeetingCard from "components/MeetingCard/MeetingCard";
import DashboardCard from "components/styled/DashboardCard";
import useInfiniteListQuery from "Hooks/useInfiniteListQuery";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import MeetingFilters from "./MeetingFilters";
import useSearch from "Hooks/useSearch";

import Lottie from "lottie-react";
import meetingLottie from "assets/lottie/meetinglottie.json";
import { listMessages } from "constants";
import { useUserContext } from "context/UserContext";

function MeetingsList() {
  const { isVendor } = useUserContext();
  const [sortBy, setSortBy] = React.useState("");
  const [status, setStatus] = React.useState("");
  const { onSearchChange, search } = useSearch();
  const { data, isLoading, isRefetching, loadMoreRef, refetch, hasNextPage } =
    useInfiniteListQuery({
      queryKey: "meetings",
      queryFn: getMeetings,
      queryParams: {
        text: search,
        sortDirection: sortBy,
      },
      queryConfig: {
        refetchOnWindowFocus: false,
      },
    });
  const navigate = useNavigate();

  React.useEffect(() => {
    refetch();
  }, [refetch, sortBy, search, status]);

  return (
    <Container maxWidth="xl" sx={{ py: "3rem" }}>
      <Box sx={{ mb: "2rem" }}>
        <MeetingFilters
          sortBy={sortBy}
          setSortBy={setSortBy}
          status={status}
          setStatus={setStatus}
          onSearchChange={onSearchChange}
          isRefetching={isRefetching}
        />
      </Box>
      <Box>
        <DashboardCard
          sx={{
            p: "1rem",
            width: "100%",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Grid
            container
            columnSpacing={2}
            rowSpacing={2}
            justifyContent="center"
          >
            {isLoading ? (
              <div className="center">
                <CircularProgress size="3rem" />
              </div>
            ) : data?.pages?.flat()?.length > 0 ? (
              data?.pages?.flat()?.map((item) => (
                <Grid item xxs={12} sm key={item._id}>
                  <Box>
                    <MeetingCard
                      data={item}
                      onClick={() => {
                        navigate(`/dashboard/meetings/${item?._id}`);
                      }}
                    />
                  </Box>
                </Grid>
              ))
            ) : (
              <Box
                sx={{
                  marginTop: "60px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography variant="h4" sx={{ textAlign: "center" }}>
                    {isVendor
                      ? listMessages.meeting.vendor
                      : listMessages.meeting.customer}
                  </Typography>
                </Box>
                <Box style={{ width: "50%" }}>
                  <Lottie animationData={meetingLottie} loop={true} />
                </Box>
              </Box>
            )}
          </Grid>
          <LoadMore loadMoreRef={loadMoreRef} hasNextPage={hasNextPage} />
        </DashboardCard>
      </Box>
    </Container>
  );
}

export default MeetingsList;
