import { LoadingButton } from "@mui/lab";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import FormCheckBox from "components/forms/FormCheckBox";
import FormInputTextSecondary from "components/forms/FormInputTextSecondary";
import FormRadioButton from "components/forms/FormRadioButton";
import GooglePlacesAutoComplete from "components/GooglePlacesAutoComplete";
import React from "react";
import { useWatch } from "react-hook-form";

function AddressInformation({
  control,
  setActiveStep,
  errors,
  isLoading,
  getValues,
  setValue,
}) {
  const convicted = useWatch({ control, name: "convictedFelony" });
  const { location } = getValues();

  return (
    <Grid container rowSpacing={6}>
      <Grid item xxs={12}>
        <SectionOne control={control} errors={errors} convicted={convicted} />
      </Grid>
      <Grid item xxs={12}>
        <Typography variant="h4" sx={{ pb: "1rem" }}>
          Address Information
        </Typography>
        <Divider />
      </Grid>
      <Grid item xxs={12}>
        <SectionTwo
          setFormValue={setValue}
          control={control}
          errors={errors}
          location={location}
        />
      </Grid>
      <Grid
        item
        container
        xxs={12}
        justifyContent="center"
        columnSpacing={2}
        rowSpacing={2}
      >
        <Grid item xxs={8} sm={4} md={3}>
          <Button
            variant="secondary"
            fullWidth
            sx={{ borderColor: "#000", color: "#000" }}
            onClick={() => {
              setActiveStep(1);
            }}
          >
            Back
          </Button>
        </Grid>
        <Grid item xxs={8} sm={6} md={4}>
          <LoadingButton
            type="submit"
            variant="primary"
            fullWidth
            loading={isLoading}
          >
            Submit
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AddressInformation;

function SectionOne({ control, errors, convicted }) {
  return (
    <Grid container rowSpacing={3}>
      <Grid item container xxs={12}>
        <Grid item xxs={12}>
          <FormRadioButton
            control={control}
            name="convictedFelony"
            errors={errors?.convictedFelony?.message}
            label="Have you ever been convicted of a felony?"
            options={[
              { label: "Yes", value: "yes" },
              { label: "No", value: "no" },
            ]}
            row={true}
            sx={{ mr: "4.5rem" }}
          />
        </Grid>
        {convicted === "yes" && (
          <Grid item xxs={12}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                pb: "0.8rem",
              }}
            >
              <Typography
                variant="pBold"
                sx={{
                  textAlign: "left",
                  fontSize: "1.1rem",
                  color: "text.primary",
                }}
              >
                If yes, please explain
              </Typography>
            </Box>
            <Box>
              <FormInputTextSecondary
                name="felonyDescription"
                errors={errors?.felonyDescription?.message}
                control={control}
                placeholder="Enter description here"
                multiline
                minRows={4}
                maxRows={7}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    maxHeight: "none",
                  },
                }}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

function SectionTwo({ setFormValue, control, errors, location }) {
  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      <Grid item container xxs={12} rowSpacing={2} columnSpacing={2}>
        <Grid item xxs={12} sm>
          <GooglePlacesAutoComplete
            isSecondarySelect={true}
            setFormValue={setFormValue}
            defaultValue={location ?? null}
            control={control}
            errors={errors}
            name="location"
            label="Address"
          />
          <FormInputTextSecondary
            control={control}
            label=""
            name="country"
            disabled={true}
            sx={{
              marginTop: "1rem",
            }}
          />
        </Grid>
      </Grid>

      <Grid item xxs={12}>
        <FormCheckBox
          name="accept"
          errors={errors?.accept?.message}
          control={control}
          options={[
            {
              label:
                "I hereby affirm that all information supplied is true and accurate to the best of my knowledge and belief. Notice must be given of any change in status impacting the information provided within ten (10) days of said change.",
              value: "accepted",
            },
          ]}
          componentsProps={{
            typography: {
              fontSize: "0.85rem",
              paddingTop: "0.7rem",
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
