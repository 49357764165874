export default function input(theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "123px",
          background: "#FFFFFF",
          boxSizing: "border-box",
          // "& .MuiOutlinedInput-notchedOutline": {
          //   border: "1px solid #01214D",
          // },
          // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          //   borderColor: "primary.light",
          // },
          "&::placeholder": {
            color: theme.palette.text.secondary,
          },
        },
      },
    },
  };
}
