export const palette = {
  primary: {
    light: "#e934c9",
    main: "#E801BE",
    green: "#02C232",
    dark: "#9f0183",
    contrastText: "#fff",
    black: "#01214D",
    grey: "#84818A",
  },
  secondary: {
    light: "#976be7",
    main: "#7E47E2",
    dark: "#58319e",
    contrastText: "#000",
  },
  text: {
    primary: "#01214D",
    secondary: "#504E4E",
  },
};
