import { Button, Divider, Grid, Typography } from "@mui/material";
import FormIconInputText from "components/forms/FormIconInputText";
import FormInputSelect from "components/forms/FormInputSelect";
import FormInputTextSecondary from "components/forms/FormInputTextSecondary";
import FormRadioButton from "components/forms/FormRadioButton";
import { minortyOptions } from "../data/form/businessInfoData";
import React from "react";
import {
  facebookIcon,
  instagramIcon,
  twitterIcon,
} from "../data/icons/businessIcon";
import { useQuery } from "react-query";
import { getServices } from "api/v1/service";
import FormCheckBox from "components/forms/FormCheckBox";
function BusinessInformation({
  control,
  getValues,
  setActiveStep,
  errors,
  trigger,
}) {
  const handleClickContinue = async () => {
    const result = await trigger([
      "facebookUrl",
      "instagramUrl",
      "twitterUrl",
      "businessName",
      "websiteUrl",
      "contactPersonName",
      "contactPersonTitle",
      "contactEmail",
      "contactPhoneNumber",
      "serviceDescription",
      "businessType",
      "virtualEvent",
      "skills",
    ]);
    if (result) setActiveStep(2);
  };

  const { data, isLoading } = useQuery("services", async () => {
    const data = await getServices({ queryParams: { limit: 100000 } });
    return data.data;
  });

  return (
    <Grid container rowSpacing={6}>
      <Grid item xxs={12}>
        <SectionOne control={control} errors={errors} />
      </Grid>
      <Grid item xxs={12}>
        <Typography variant="h4" sx={{ pb: "1rem" }}>
          Business Information
        </Typography>
        <Divider />
      </Grid>
      <Grid item xxs={12}>
        <SectionTwo
          getValues={getValues}
          data={data}
          isLoading={isLoading}
          control={control}
          errors={errors}
        />
      </Grid>
      <Grid
        item
        container
        xxs={12}
        justifyContent="center"
        columnSpacing={2}
        rowSpacing={2}
      >
        <Grid item xxs={8} sm={4} md={3}>
          <Button
            variant="secondary"
            fullWidth
            sx={{ borderColor: "#000", color: "#000" }}
            onClick={() => setActiveStep(0)}
          >
            Back
          </Button>
        </Grid>
        <Grid item xxs={8} sm={6} md={4}>
          <Button variant="primary" fullWidth onClick={handleClickContinue}>
            Save and Continue
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BusinessInformation;

function SectionOne({ control, errors }) {
  return (
    <Grid container rowSpacing={3}>
      <Grid>
        <Typography variant="h5">Social Media URLs</Typography>
      </Grid>
      <Grid item xxs={12}>
        <FormIconInputText
          control={control}
          name="facebookUrl"
          icon={facebookIcon}
          placeholder="Enter your facebook profile link"
          errors={errors?.facebookUrl?.message}
        />
      </Grid>
      <Grid item xxs={12}>
        <FormIconInputText
          control={control}
          name="instagramUrl"
          icon={instagramIcon}
          placeholder="Enter your Instagram profile link"
          errors={errors?.instagramUrl?.message}
        />
      </Grid>
      <Grid item xxs={12}>
        <FormIconInputText
          control={control}
          name="twitterUrl"
          icon={twitterIcon}
          placeholder="Enter your Twitter profile link"
          errors={errors?.twitterUrl?.message}
        />
      </Grid>
    </Grid>
  );
}

function SectionTwo({ data, isLoading, control, errors }) {
  return (
    <Grid container rowSpacing={2} columnSpacing={2}>
      <Grid item xxs={12} sm={6}>
        <FormInputTextSecondary
          control={control}
          label="Business Name"
          name="businessName"
          placeholder="Enter your Business name"
          errors={errors?.businessName?.message}
        />
      </Grid>
      <Grid item xxs={12} sm={6}>
        <FormInputTextSecondary
          control={control}
          label="Website URL"
          name="websiteUrl"
          placeholder="Enter your website"
          errors={errors?.websiteUrl?.message}
        />
      </Grid>
      <Grid item xxs={12}>
        <FormCheckBox
          control={control}
          name="minorityEligibility"
          errors={errors?.minority?.message}
          label="Minority-Owned Business Eligibility:"
          options={minortyOptions}
          row={true}
        />
      </Grid>
      <Grid item xxs={12}>
        <FormInputTextSecondary
          name="serviceDescription"
          errors={errors?.serviceDescription?.message}
          control={control}
          label="Description of Talent or Service"
          placeholder="Enter description here"
          multiline
          minRows={4}
          maxRows={7}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "12px",
              maxHeight: "none",
            },
          }}
        />
      </Grid>
      <Grid item container rowSpacing={2} columnSpacing={2}>
        <Grid item xxs={12} sm={6}>
          <FormRadioButton
            name="virtualEvent"
            control={control}
            label="Does your business offer virtual events?"
            options={[
              { label: "Yes", value: "yes" },
              { label: "No", value: "no" },
            ]}
            row={true}
            sx={{ mr: "3.5rem" }}
            errors={errors?.virtualEvent?.message}
          />
        </Grid>
      </Grid>
      <Grid item container rowSpacing={2} columnSpacing={2}>
        <Grid item xxs={12} sm={6}>
          <FormInputSelect
            name="skills"
            label="Services"
            multiple={true}
            control={control}
            options={
              isLoading || data?.length <= 0 || !data
                ? []
                : data?.map((service) => ({
                    label: service.name,
                    value: service._id,
                  }))
            }
            placeholder="Enter your skills"
            errors={errors?.skills?.message}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
