import { Box } from "@mui/material";
import React from "react";
import { styled } from "@mui/system";

const StyledBox = styled(Box)(({ theme }) => ({
  background: " #FFFFFF",
  boxShadow: " 0px -3px 27px 10px rgba(0, 0, 0, 0.05)",
  borderRadius: " 8px",
  display: "flex",
}));

function DashboardCard({ children, ...rest }) {
  return <StyledBox {...rest}>{children}</StyledBox>;
}

export default DashboardCard;
