import { Box, Grid, Typography } from "@mui/material";
import BasicCard from "components/Cards/BasicCard";

export default function SubscribedServices({ services }) {
  return (
    <Grid container rowSpacing={1} columnSpacing={1} mt={2} mb={2}>
      {services.map(({ info }, i) => {
        // eslint-disable-next-line prefer-object-spread
        const clone = Object.assign({}, info);
        delete clone.name;
        return (
          <Grid item xxs={12} key={i}>
            <Box sx={{ justifyContent: "center", display: "flex" }}>
              <BasicCard title={info.name} serivceObject={clone} />
            </Box>
          </Grid>
        );
      })}
    </Grid>
  );
}
