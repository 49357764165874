export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
export const VALID_LOCATION_KEYS = [
  "coordinates",
  "address",
  "state",
  "country",
  // "postalCode",
];
export const EMAIL_REGEX =
  /^\w+([.-]?\w+)*(\+[0-9]+)?@\w+([.-]?\w+)*(\.\w{2,3})+$/;
export const URL_REGEX =
  /(?:https?:\/\/)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gm;

export const FB_URL_REGEX =
  /(?:https?:\/\/)?(?:www\.)?(mbasic.facebook|m\.facebook|facebook|fb)\.(com|me)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-\.]*\/)*([\w\-\.]*)/g;
export const FB_URL =
  /(?:https?:\/\/)?(?:www\.)(facebook.com)\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+)/;
export const INSTA_URL_REGEX =
  /(?:https?:\/\/)?(?:www\.)(instagram.com)\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+)/;

export const TWITTER_URL_REGEX =
  /(?:https?:\/\/)?(?:www\.)?(mobile.twitter|m\.twitter|twitter)\.(com)\/(?![a-zA-Z0-9_]+\/)([a-zA-Z0-9_]+)/;
