import { CircularProgress } from "@mui/material";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import { createOrderPaypal } from "api/v1/booking";
import * as React from "react";

export default function PaypalButton({
  currency = "USD",
  showSpinner,
  amount,
  bookingId,
  setIsCompleted,
  percentage,
}) {
  // usePayPalScriptReducer can be use only inside children of PayPalScriptProviders
  // This is the main reason to wrap the PayPalButtons in a new component

  const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

  React.useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        currency: currency,
      },
    });
  }, [currency, showSpinner]);

  return (
    <>
      {showSpinner && isPending && (
        <CircularProgress size="3rem" sx={{ margin: "auto" }} />
      )}
      <PayPalButtons
        style={{ layout: "vertical" }}
        disabled={false}
        forceReRender={[amount, currency, { layout: "vertical" }]}
        fundingSource={undefined}
        createOrder={async (data, actions) => {
          const order = await createOrderPaypal({
            id: bookingId,
            body: {
              percentage,
            },
          });
          return order?.data?.id;
        }}
        onApprove={function (data, actions) {
          return actions.order.capture().then(function () {
            setIsCompleted(true);
          });
        }}
      />
    </>
  );
}
