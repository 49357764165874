import BasicSkeleton from "components/Skeleton/BasicSkeleton";
import React from "react";

function LoadingImage({
  isImageLinkLoading,
  isImageLoaded,
  image = null,
  placeholderImage = null,
  imageStyles = {},
  alt = null,
  skeletonStyles = {},
}) {
  return (
    <>
      {" "}
      {image ? (
        !isImageLinkLoading && isImageLoaded ? (
          <img
            style={{
              width: "100%",
              height: "100%",
              background: "gainsboro",
              objectFit: "cover",
              objectPosition: "center",
              display: "flex",
              ...imageStyles,
            }}
            src={isImageLoaded !== "error" ? image : placeholderImage}
            alt={alt ?? "imageLoaded"}
          />
        ) : (
          <BasicSkeleton variant="rectangle" skeletonStyles={skeletonStyles} />
        )
      ) : (
        <img
          style={{
            width: "100%",
            height: "100%",
            background: "gainsboro",
            objectFit: "cover",
            objectPosition: "center",
            display: "flex",
            ...imageStyles,
          }}
          src={placeholderImage}
          alt={alt ?? "imageLoaded"}
        />
      )}
    </>
  );
}

export default LoadingImage;
