export default function StaticDatePicker(theme) {
  return {
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          backgroundColor: "#F5F5F5",
          boxShadow: "0px 1.25352px 2.50704px rgba(0, 14, 51, 0.25)",
          borderRadius: "20",
        },
      },
    },
  };
}
