import { getStats } from "api/v1/stats";
import { useQuery } from "react-query";

export default function useStats(isVendor = false) {
  return useQuery(
    "stats",
    async () => {
      const { data } = await getStats();
      return data;
    },
    { enabled: isVendor }
  );
}
