import StarIcon from "@mui/icons-material/Star";
import { Box, Typography, useTheme } from "@mui/material";
import classes from "./index.module.css";
const Tags = ({ text, isShowIcon = true }) => {
  const theme = useTheme();
  return (
    <Box className={classes.tagContainer}>
      {isShowIcon && <StarIcon className={classes.icon} />}
      <Typography
        variant="body3"
        className={classes.skills}
        color={theme.palette.primary.black}
      >
        {text}
      </Typography>
    </Box>
  );
};
export default Tags;
