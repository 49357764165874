import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

import { Controller } from "react-hook-form";

function FormInputCheckBox({ name, control, label }) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange } }) => (
        <FormControlLabel
          control={<Checkbox checked={value} onChange={onChange} />}
          label={label}
        />
      )}
    />
  );
}

export default FormInputCheckBox;
