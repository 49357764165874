import { Box, CircularProgress, Typography } from "@mui/material";
import { ReactComponent as SearchIcon } from "assets/dashboard/quotes/search.svg";
import CustomInput from "components/InputField/CustomInput";
import CustomSelect from "components/InputField/CustomSelect";

export default function MeetingFilters({
  sortBy,
  setSortBy,
  status,
  setStatus,
  onSearchChange,
  isRefetching,
}) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box>
        <Typography
          variant="h4"
          style={{ fontFamily: "Playfair Display", paddingBottom: "1rem" }}
        >
          Meetings
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: { xxs: "column", sm: "row" },
          width: "100%",
        }}
      >
        <Box sx={{ flexGrow: 1, pb: { xxs: "0.5rem", md: "0" } }}>
          <Box
            sx={{ width: { xxs: "100%", sm: "90%", md: "80%", lg: "60% " } }}
          >
            <CustomInput
              height="45px"
              adornment="endAdornment"
              position="end"
              Icon={SearchIcon}
              placeholder="Search here"
              onChange={onSearchChange}
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ pl: "0.5rem" }}>
            <CustomSelect
              label="Sort By"
              data={[
                { value: "1", label: "Ascending" },
                { value: "-1", label: " Descending" },
              ]}
              height="45px"
              value={sortBy}
              setValue={setSortBy}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
