import { Box, Divider, Paper } from "@mui/material";
import React from "react";
import BasicSkeleton from "./BasicSkeleton";

function EventCardSkeleton({ isVendor }) {
  const customerStyles = isVendor
    ? {}
    : {
        boxShadow: "0 3px 10px rgba(0, 0, 0, 0.1)",
        borderRadius: " 10px 10px 0px 0px",
      };

  return (
    <Paper
      sx={{
        width: "340px",
        height: "435px",
        borderRadius: isVendor ? "8px" : "10px 10px 0px 0px",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
        ...customerStyles,
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "175px",
          overflow: "hidden",
          borderRadius: "10px 10px 0px 0px",
        }}
      >
        <BasicSkeleton variant="rectangle" />
      </Box>
      <Box
        sx={{
          px: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          flex: 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Box>
            <BasicSkeleton
              variant="text"
              skeletonStyles={{
                width: "80px",
                height: "30px",
                mt: 3,
              }}
            />
          </Box>
          <Box>
            <BasicSkeleton variant="text" />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Divider />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 1,
            }}
          >
            <BasicSkeleton
              variant="circular"
              skeletonStyles={{ width: "20px", height: "20px" }}
            />
            <BasicSkeleton variant="text" />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: 1,
            }}
          >
            <BasicSkeleton
              variant="circular"
              skeletonStyles={{ width: "20px", height: "20px" }}
            />
            <BasicSkeleton variant="text" />
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

export default EventCardSkeleton;
