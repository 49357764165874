import { Box } from "@mui/material";
import React from "react";

function ImageWrapper({ svgIcon, alt, imgStyle, ...rest }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }} {...rest}>
      <img
        src={svgIcon}
        alt={alt ?? "svg"}
        style={{ width: "21px", height: "auto", ...imgStyle }}
      />
    </Box>
  );
}

export default ImageWrapper;
