export default function LoadingButton(theme) {
  return {
    MuiLoadingButton: {
      styleOverrides: {
        loadingIndicator: {
          color: theme.palette.primary.contrastText,
        },
      },
    },
  };
}
