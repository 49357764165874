import { onboardingLink } from "api/v1/stripe";
import { useQuery } from "react-query";

export default function useStripeOnboardingLink({ enabled = false } = {}) {
  const { refetch: getStripeOnboardingLink, ...rest } = useQuery(
    "onboarding-link",
    onboardingLink,
    {
      onSuccess(data) {
        window.location.href = data.data.url;
      },
      enabled,
    }
  );
  return { getStripeOnboardingLink, ...rest };
}
