import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Box, Button, Divider, Typography } from "@mui/material";
import CustomCard from "components/forms/CustomCard";
import FormInputText from "components/forms/FormInputText";
import * as React from "react";

function FormOne({ control, errors, handleClick }) {
  const [showPass, setShowPass] = React.useState(false);
  const [showPassConfirm, setShowPassConfirm] = React.useState(false);
  return (
    <Box sx={{ maxWidth: "600px" }}>
      <CustomCard>
        <Box sx={{ display: "flex", flexDirection: "column", p: 5 }}>
          <Box>
            <FormHead />
          </Box>
          <Divider />
          <Box>
            <FormBody
              showPass={showPass}
              setShowPass={setShowPass}
              showPassConfirm={showPassConfirm}
              setShowPassConfirm={setShowPassConfirm}
              control={control}
              errors={errors}
              handleClick={handleClick}
            />
          </Box>
        </Box>
      </CustomCard>
    </Box>
  );
}

export default FormOne;

function FormHead() {
  return (
    <>
      <Box sx={{ pb: "5px" }}>
        <Typography
          variant="pbold"
          style={{ fontSize: "2rem", lineHeight: "47px" }}
        >
          Sign Up
        </Typography>
      </Box>
      <Box sx={{ pb: "7px" }}>
        <Typography
          variant="p"
          style={{ lineHeight: "34px", fontSize: "1.25rem" }}
        >
          If you are already a user you can login with your email address and
          password.
        </Typography>
      </Box>
    </>
  );
}

function FormBody({
  showPass,
  setShowPass,
  showPassConfirm,
  setShowPassConfirm,
  control,
  errors,
  handleClick,
}) {
  const handleClickShowPass = () => {
    setShowPass(!showPass);
  };
  const handleClickShowPassConfirm = () => {
    setShowPassConfirm(!showPassConfirm);
  };

  return (
    <Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Box
          sx={{
            width: "90%",
          }}
        >
          <Box
            sx={{
              mb: "30px",
              mt: "30px",
            }}
          >
            <FormInputText
              label="Username"
              name="username"
              placeholder="John Doe"
              control={control}
              errors={errors?.username?.message}
            />
          </Box>
          <Box
            sx={{
              mb: "30px",
            }}
          >
            <FormInputText
              label="Add Password"
              name="password"
              icon={VisibilityOff}
              iconToggle={Visibility}
              toggle={showPassConfirm}
              handleClick={handleClickShowPassConfirm}
              position="start"
              positionEdge="start"
              adornment="endAdornment"
              control={control}
              errors={errors?.password?.message}
            />
          </Box>
          <Box
            sx={{
              mb: "30px",
            }}
          >
            <FormInputText
              label="Retype Password"
              name="passwordConfirm"
              icon={VisibilityOff}
              iconToggle={Visibility}
              toggle={showPass}
              handleClick={handleClickShowPass}
              position="start"
              positionEdge="start"
              adornment="endAdornment"
              control={control}
              errors={errors?.passwordConfirm?.message}
            />
          </Box>

          <Box sx={{ mb: "10px", mt: "50px" }}>
            <Button
              variant="primary"
              fullWidth
              onClick={handleClick}
              sx={{ height: "50px", fontSize: "1.25rem", lineHeight: "29px" }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
