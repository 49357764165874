import { v4 as uuidv4 } from "uuid";
import { SOCKET_EVENT } from "../constants";
import {
  addMessageInCachedThread,
  addNewThread,
  updateLastMessage,
} from "./chat";
import { updateCache } from "../utilities";

export function subscribeToInboxEvents({
  socketRef,
  activeChatIdRef,
  queryClient,
  userMetaData,
}) {
  socketRef.current?.on(SOCKET_EVENT.MESSAGE, (data) => {
    if (data.thread === activeChatIdRef.current) {
      addMessageInCachedThread({
        queryKey: ["threads", activeChatIdRef.current],
        queryClient,
        message: {
          _id: uuidv4(),
          ...data,
        },
      });
    } else {
      updateCache(
        "newMessagesCount",
        queryClient,
        function update(newMessagesCount) {
          newMessagesCount += 1;
          queryClient.setQueryData("newMessagesCount", newMessagesCount);
        }
      );
      socketRef.current?.emit(SOCKET_EVENT.INCREMENT_UNREAD_COUNT, {
        thread: data.thread,
        receiver: userMetaData._id,
      });
    }
    // if the message belongs to some thread which we haven't fetched yet (due to pagination) then fetch that thread and set that too.
    updateLastMessage(
      "threads",
      queryClient,
      data,
      activeChatIdRef.current,
      userMetaData._id
    );
  });
  socketRef.current?.on(SOCKET_EVENT.NEW_THREAD, (data) => {
    addNewThread(data, queryClient);
  });

  function removeListeners() {
    socketRef.current?.removeListener(SOCKET_EVENT.MESSAGE);
    socketRef.current?.removeListener(SOCKET_EVENT.NEW_THREAD);
  }
  return removeListeners;
}
