import { Box, FormHelperText, Typography } from "@mui/material";
import messages from "messages";
import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useController } from "react-hook-form";
import { ImageUploadIcon } from "views/pages/Login/SignUpPage/Vendor/data/icons/personalIcon";

export default function CustomFileInput({ error, control, name }) {
  const { field } = useController({ control, name });
  const [errorMessage, setErrorMessage] = useState("");
  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (acceptedFiles.length > 0) {
      setErrorMessage("");
      field.onChange(acceptedFiles[0]);
    }
    if (rejectedFiles.length > 0) {
      field.onChange(null);

      let expr = rejectedFiles[0].errors[0];
      switch (expr.code) {
        case "file-too-large":
          setErrorMessage(messages.invalidImageSize);
          break;
        case "file-invalid-type":
          setErrorMessage(messages.invalidImageType);
          break;
        default:
          setErrorMessage(expr.message);
      }
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 3145728,
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
    },
    excludeAcceptAllOption: true,
    useFsAccessApi: false,
  });

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "1rem",
      }}
    >
      <Box>
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "26px",
            color: "#01214D",
            textAlign: "left",
          }}
        >
          Profile Image
        </Typography>
      </Box>
      <Box
        {...getRootProps()}
        sx={{
          border: "2px dashed #504E4E",
          borderRadius: "6px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          minWidth: "270px",
          minHeight: "230px",
          maxWidth: "290px",
          maxHeight: "250px",
          pt: 0.5,
          cursor: "pointer",
        }}
      >
        <input {...getInputProps()} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          {!field.value ? (
            <Box
              sx={{
                width: { xxs: "98px", sm: "88px", md: "120px" },
                height: { xxs: "98px", sm: "88px", md: "120px" },
                display: "flex",
                flexGrow: "1",
              }}
            >
              {ImageUploadIcon}
            </Box>
          ) : (
            <>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  height: "inherit",
                  flex: 1,
                  justifyContent: "center",
                  py: 0.5,
                }}
              >
                <img
                  src={URL.createObjectURL(field.value)}
                  alt="img"
                  style={{
                    objectFit: "cover",
                    objectPosition: "50% 40%",
                    display: "flex",
                    width: "180px",
                    height: "180px",
                    borderRadius: "50%",
                  }}
                />
              </Box>
            </>
          )}
        </Box>
        <Box>
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <Typography
              variant="pbold"
              sx={{ fontSize: "0.875rem", px: 1, py: 1 }}
            >
              DRAG & DROP FILE OR
              <Box component="span" sx={{ color: "primary.main" }}>
                {` UPLOAD FROM YOUR DEVICE`}
              </Box>
            </Typography>
          )}
        </Box>
      </Box>
      <Box>
        <FormHelperText error>{errorMessage || error}</FormHelperText>
      </Box>
    </Box>
  );
}
