import { FormHelperText } from "@mui/material";

export default function FormApiResponse({
  isError,
  isSuccess,
  error,
  absolute,
  successMessage,
}) {
  return (
    (isError || isSuccess) && (
      <FormHelperText
        component="span"
        sx={{
          color: isError ? "error.main" : isSuccess ? "success.main" : "",
          position: absolute ? "absolute" : "static",
          top: "10px",
          fontSize: "14px",
          left: 0,
          right: 0,
          margin: "0 auto",
          width: "fit-content",
        }}
      >
        {isError ? error?.errMsg : successMessage}
      </FormHelperText>
    )
  );
}
