import * as React from "react";
import classes from "./EventBanner.module.css";
import { Box, Button, Skeleton } from "@mui/material";
import Status from "components/Accordian/dashboard/Status/Status";
import { Link } from "react-router-dom";
import CreateIcon from "@mui/icons-material/Create";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import messages from "messages";
import { useMutation } from "react-query";
import { closeEvent } from "api/v1/event";
import { LoadingButton } from "@mui/lab";
import AlertDialog from "components/Dialog/AlertDialog";
import BasicSkeleton from "components/Skeleton/BasicSkeleton";

const statusWhenNotToRenderClose = ["cancelled", "completed"];

const EventBanner = ({
  bannerImage,
  status,
  btnLink = "#",
  isVendor = false,
  isQuote = false,
  setOpenAddQuoteDialog,
  isPaypalConnected,
  isStripeConnected,
  eventId = null,
  refetchEvent = () => {},
  isBooking,
  isBookingPaid,
  isImageLoading,
  isEventLoading,
}) => {
  const { isLoading, isError, mutate } = useMutation(
    async (eventId) => await closeEvent(eventId)
  );

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = async () => {
    mutate(eventId, {
      onSuccess: (data, variables, context) => {
        refetchEvent();
        setOpenDialog(false);
      },
    });
  };
  return (
    <Box sx={{ position: "relative" }}>
      <Box>
        {isEventLoading || isImageLoading ? (
          <Box className={classes.img}>
            <BasicSkeleton variant="rectangle" />
          </Box>
        ) : (
          <img src={bannerImage} alt="banner" className={classes.img} />
        )}
      </Box>
      <Box className={classes.status}>{<Status status={status} />}</Box>
      {isVendor ? null : (
        <Box
          sx={{
            position: "absolute",
            display: "block",
            top: "35px",
            right: "20px",
            fontSize: { xxs: "10px", sm: "12px", lg: "14px" },
          }}
        >
          {" "}
          {statusWhenNotToRenderClose.includes(status) ? null : (
            <LoadingButton
              variant="contained"
              sx={{ fontSize: "inherit" }}
              onClick={handleClickOpenDialog}
              loading={isLoading}
            >
              Close Event
            </LoadingButton>
          )}
        </Box>
      )}
      {isVendor ? (
        isQuote || isBooking ? null : (
          <Box
            sx={{
              position: "relative",
              display: " block",
              marginLeft: "20px",
              bottom: "80px",
              float: "right",
              right: "20px",
              fontSize: { xxs: "10px", sm: "12px", lg: "14px" },
            }}
          >
            <Button
              variant="contained"
              startIcon={<AddIcon fontSize="inherit" />}
              sx={{ fontSize: "inherit" }}
              onClick={() =>
                isPaypalConnected || isStripeConnected
                  ? setOpenAddQuoteDialog(true)
                  : toast.error(messages.paymentGatewayNotConnected)
              }
            >
              Add Quote
            </Button>
          </Box>
        )
      ) : (
        <Box className={classes.eventDetail}>
          <Link to={btnLink}>
            {status === "open" && !isBooking && (
              <Button className={classes.button}>
                <CreateIcon className={classes.icon} />
              </Button>
            )}
          </Link>
        </Box>
      )}
      {isVendor ? null : (
        <AlertDialog
          open={openDialog}
          handleSubmit={handleSubmit}
          handleClose={handleCloseDialog}
          description={`Are you sure you want to ${
            isBookingPaid() ? "complete" : "cancel"
          } this event? This will be a permanent change if you ${
            isBookingPaid() ? "complete" : "cancel"
          } this event.`}
          title="Close Event"
          isLoading={isLoading}
        />
      )}
    </Box>
  );
};

export default EventBanner;
