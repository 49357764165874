import React from "react";
import { Container, Grid, Typography, Box } from "@mui/material";

import logo from "assets/logo/logo.png";
import badge from "assets/logo/atlanta-badge.png";

import FooterList from "./FooterList";

import termsOfServicePdf from "assets/pdfs/terms-services.pdf";
import termsOfConditionPdf from "assets/pdfs/terms-conditions.pdf";
import privacyPolicyPdf from "assets/pdfs/privacy-policy.pdf";
import cookiesPolicyPdf from "assets/pdfs/cookies-policy.pdf";

export default function Footer() {
  return (
    <Box
      component="footer"
      sx={{ backgroundColor: "#202020", paddingTop: "50px" }}
    >
      <Container maxWidth="xl">
        <Grid container>
          <FooterContent />
        </Grid>
      </Container>
    </Box>
  );
}

function FooterContent() {
  return (
    <>
      <LeftFooterContent />
      <RightFooterContent />
      <BottomFooterContent />
    </>
  );
}

function LeftFooterContent() {
  return (
    <>
      <Grid item container xxs={12} sm={3} md={3} rowSpacing={2}>
        <Grid item xxs={12}>
          <img alt="logo" src={logo} />
        </Grid>
        <Grid item xxs={12}>
          <Typography variant="p2" style={{ color: "#fff", opacity: "0.6" }}>
            Inclusive and diverse event vendor marketplace.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ width: "150px", position: "relative" }}>
            <img
              alt="made in atlanta"
              src={badge}
              style={{
                transform: "scale(1.3)",
                marginTop: "10px",
                marginBottom: "-15px",
                marginLeft: "-14px",
                zIndex: "10",
                position: "relative",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

function RightFooterContent() {
  return (
    <>
      <Grid
        item
        container
        xxs={12}
        sm={9}
        md={9}
        justifyContent="flex-end"
        sx={{
          marginTop: { xxs: "20px", sm: "none" },
        }}
      >
        <FooterList />
      </Grid>
    </>
  );
}

function BottomFooterContent() {
  return (
    <>
      <Grid
        container
        item
        xxs={12}
        justifyContent="space-between"
        alignItems="center"
        sx={{ padding: "30px 0" }}
      >
        <Grid item xxs={12} md={6}>
          <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                gap: "1.5rem",
              }}
            >
              <Typography variant="p2" style={{ color: "#fff" }}>
                Copyright © 2022
              </Typography>

              <a href={termsOfServicePdf} target="_blank">
                <Typography variant="p2" style={{ color: "#fff" }}>
                  Terms of Service
                </Typography>
              </a>
              <a href={privacyPolicyPdf} target="_blank">
                <Typography variant="p2" style={{ color: "#fff" }}>
                  Privacy Policy
                </Typography>
              </a>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap",
                gap: "1.8rem",
              }}
            >
              <Typography variant="p2" style={{ color: "#fff" }}>
                hello@thepartystarter.com
              </Typography>
              <a href={termsOfConditionPdf} target="_blank">
                <Typography variant="p2" style={{ color: "#fff" }}>
                  Terms & Conditions
                </Typography>
              </a>
              <a href={cookiesPolicyPdf} target="_blank">
                <Typography variant="p2" style={{ color: "#fff" }}>
                  Cookies Policy
                </Typography>
              </a>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
