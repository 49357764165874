import React from "react";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/system";

import { Controller } from "react-hook-form";

let StyledTextField = styled(TextField)(({ theme }) => ({
  color: theme.palette.text.primary,
  borderRadius: "123px",
  boxShadow: "0px 4px 10px 3px rgba(0, 0, 0, 0.11)",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "1px solid #01214D",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "primary.light",
  },
}));

function FormInputText({
  label,
  name,
  icon,
  iconToggle,
  control,
  toggle,
  handleClick,
  adornment,
  position,
  positionEdge,
  errors,
  observe,
  ...rest
}) {
  const MyIcon = icon ? icon : null;
  let MyToggleIcon = iconToggle ? iconToggle : null;
  const checkToggleIcon = () => (toggle ? <MyIcon /> : <MyToggleIcon />);
  const Observe = observe != undefined ? Boolean(observe) : true;

  const MyField = (field) =>
    icon ? (
      <StyledTextField
        InputProps={{
          [adornment]: (
            <InputAdornment position={position}>
              {Observe && (
                <IconButton
                  color="primary"
                  aria-label="toggle password visibility"
                  edge={positionEdge}
                  onClick={handleClick}
                >
                  {checkToggleIcon()}
                </IconButton>
              )}
            </InputAdornment>
          ),
        }}
        type={toggle ? "text" : "password"}
        {...field}
        {...rest}
      />
    ) : (
      <StyledTextField {...field} {...rest} />
    );

  return (
    <FormControl sx={{ display: "flex", maxWidth: "100%" }}>
      {label && (
        <FormLabel
          id="input-label"
          sx={{
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "26px",
            color: "#01214D",
            textAlign: "left",
            marginBottom: "5px",
          }}
        >
          {label}
        </FormLabel>
      )}
      <Controller
        name={name}
        control={control}
        render={({ field }) => MyField(field)}
      />
      <FormHelperText error>{errors ? errors : null}</FormHelperText>
    </FormControl>
  );
}

export default FormInputText;
