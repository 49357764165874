import client from "api/axiosConfig";
import formatUrl from "utils/formatUrl";

export const getAllVendors = async ({ queryParams = {}, id }) => {
  return client.get(
    formatUrl(`/api/v1/subCategories/${id}/vendors`, queryParams)
  );
};
export const getVendorByServiceId = async ({ id, queryParams = {} }) => {
  return client.get(formatUrl(`/api/v1/services/${id}/vendors`, queryParams));
};

export const getVendorDetails = async (id) => {
  return client.get(`/api/v1/vendors/${id}`);
};
