import * as React from "react";
import { useQueryClient } from "react-query";
import { useSocketContext } from "context/SocketContext";
import { useUserContext } from "context/UserContext";
import { subscribeToInboxEvents } from "Helpers/socket";

export function useSocket() {
  const queryClient = useQueryClient();
  const { user: userMetaData } = useUserContext();
  const { socketRef, activeChatIdRef } = useSocketContext();
  React.useEffect(() => {
    const removeListeners = subscribeToInboxEvents({
      socketRef,
      activeChatIdRef,
      queryClient,
      userMetaData,
    });
    return removeListeners;
  }, [queryClient, activeChatIdRef, socketRef, userMetaData]);

  return null;
}
