import { Box, Divider } from "@mui/material";
import React from "react";
import CustomListWrapper from "./CustomListWrapper";

function Profile({ personalList, businessList, addressList }) {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", rowGap: "20px" }}>
      <CustomListWrapper list={personalList} label="Personal Details" />
      <Divider />
      <CustomListWrapper list={businessList} label="Business Details" />
      <Divider />
      <CustomListWrapper list={addressList} label="Address Details" />
    </Box>
  );
}

export default Profile;
