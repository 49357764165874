export const languagesObject = {
  english: 0,
  spanish: 1,
  french: 2,
  chinese: 3,
  other: 4,
};

export const minorityEligibilityObject = {
  blackOwned: 0,
  latinOwned: 1,
  asianOwned: 2,
  indigenousOwned: 3,
  womenOwned: 4,
  lgbtqOwned: 5,
  nsmdcOwned: 6,
  mbeOwned: 7,
  militaryOwned: 8,
};

export const businessCriteriaObject = {
  licensed: 0,
  bonded: 1,
  insured: 2,
};
