import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { useLogoutContext } from "context/LogoutContext";
import * as React from "react";
const LogoutModal = () => {
  const { logoutModal, setLogoutModal } = useLogoutContext();
  const handleClose = () => setLogoutModal(false);
  function logout() {
    localStorage.removeItem("token");
    window.location.reload();
  }
  return (
    <Dialog maxWidth open={logoutModal} onClose={handleClose}>
      <DialogTitle textAlign="center">
        <Typography variant="h4" component="h4">
          Logout
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h6" component="h6">
          Are you sure you want to logout?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>cancel</Button>
        <Button onClick={logout} autoFocus>
          ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default LogoutModal;
