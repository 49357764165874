import { Select, MenuItem } from "@mui/material";
import React from "react";
import InputWrapper from "../Wrapper/InputWrapper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useController } from "react-hook-form";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function FormInputSelect({
  label,
  options,
  control,
  name,
  errors,
  ifSave = false,
  width,
  multiple = false,
  placeholder,
  forModal = false,
  color = "",
  ...rest
}) {
  const { field } = useController({
    name,
    control,
  });
  const [open, setOpen] = React.useState(false);

  const handleOnChange = (event) => {
    if (multiple) {
      !event.target.value.includes(null) && field.onChange(event);
    } else {
      if (event.target.value == null) {
      } else {
        field.onChange(event);
      }
    }
  };

  return (
    <InputWrapper
      label={label}
      errors={errors}
      color={color ? color : "#01214D"}
    >
      <Select
        multiple={multiple}
        variant="outlined"
        value={field.value}
        onChange={handleOnChange}
        SelectDisplayProps={{
          onClick: (e) => setOpen(true),
        }}
        open={open}
        IconComponent={KeyboardArrowDownIcon}
        MenuProps={MenuProps}
        sx={{
          textAlign: "left",
          maxHeight: "45px",
          width: width,
          position: "relative",
        }}
        {...rest}
      >
        {options.map(({ value, label }, index) => (
          <MenuItem key={index} value={value}>
            {label}
          </MenuItem>
        ))}
        <MenuItem
          onClick={() => setOpen(false)}
          value={null}
          sx={{
            fontWeight: "500",
            color: "primary.main",
            position: "sticky",
            bottom: 0,
            zIndex: "6",
            background: "#ffffff",
            boxShadow: "0px -3.50209px 31.5188px 11.6736px rgba(0, 0, 0, 0.05)",
            "&:hover": {
              background: "#ffffff",
              boxShadow:
                "0px -3.50209px 31.5188px 11.6736px rgba(0, 0, 0, 0.05)",
            },
          }}
        >
          Save
        </MenuItem>
      </Select>
    </InputWrapper>
  );
}

export default FormInputSelect;
