import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import messages from "messages";
import { yupResolver } from "@hookform/resolvers/yup";
import FormInputTextSecondary from "components/forms/FormInputTextSecondary";
import { useMutation } from "react-query";
import { submitQuote, updateQuote } from "api/v1/quotes";
import FormCheckBox from "components/forms/FormCheckBox";
import CustomDialogContent from "components/styled/CustomDialogContent";
import { filterNullArrayData, formatCurrency } from "utilities";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { toast } from "react-toastify";

function CreateQuote({
  open,
  setOpen,
  id,
  filteredServices,
  refetchEvent,
  isEdit = false,
  eventData = {},
}) {
  const { isLoading, isError, mutateAsync } = useMutation(
    async ({ id, payload }) => {
      if (isEdit) {
        await updateQuote(id, payload);
      } else {
        await submitQuote(id, payload);
      }
    },
    {
      onSuccess: (data, variables, context) => {
        refetchEvent();
      },
    }
  );

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      quote: eventData.quote ?? "",
      price: eventData.price ?? "",
      services: eventData.services ?? [],
    },
    resolver: yupResolver(schema),
  });

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const onSubmit = async (data) => {
    const formValues = data;

    const services = filterNullArrayData(formValues.services);

    if (services.length <= 0 || !services) {
      toast.error(messages.serviceRequired);
      return;
    }

    try {
      await mutateAsync({
        id,
        payload: {
          price: formValues.price,
          description: formValues.quote,
          servicesId: services,
        },
      });

      reset();

      setOpen(false);
    } catch (err) {
      toast.error(err.errMsg);
    }
  };

  return (
    <div style={{ margin: "auto" }}>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        PaperProps={{
          sx: {
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "auto",
            ml: {
              xs: "none",
              md: "250px",
              lg: "none",
            },
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            py: 1,
          }}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <DialogTitle
            sx={{ textAlign: "center", fontSize: "1.5rem", fontWeight: "400" }}
          >
            Add Quote
          </DialogTitle>
          <Box sx={{ px: 0.7 }}>
            <CustomDialogContent>
              <FormInputTextSecondary
                name="quote"
                control={control}
                errors={errors?.quote?.message}
                label="Description"
                placeholder="Type your quote right here."
                multiline
                minRows={4}
                maxRows={7}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    borderRadius: "12px",
                    maxHeight: "none",
                  },
                }}
              />
            </CustomDialogContent>
            <CustomDialogContent>
              <FormInputTextSecondary
                name="price"
                control={control}
                label="Price"
                type="number"
                placeholder="Enter your asking price."
                errors={errors?.price?.message}
                customHeight="49px"
                adornment="startAdornment"
                positionEdge="start"
                icon={AttachMoneyIcon}
                toggle={true}
                iconBtn={false}
              />
            </CustomDialogContent>
            <CustomDialogContent>
              <FormCheckBox
                control={control}
                name="services"
                label="Choose Services"
                options={filteredServices()}
                row={true}
                handleSwitch={false}
                sx={{ mr: "3.5rem" }}
                errors={errors?.services?.message}
              />
            </CustomDialogContent>
          </Box>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit">Submit</Button>
          </DialogActions>
        </Box>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </div>
  );
}

export default CreateQuote;

const schema = yup
  .object({
    price: yup
      .string()
      .required(messages.requiredField)
      .matches(/^[0-9\s]+$/, messages.onlyNumber),
    services: yup
      .array(yup.string())
      .min(1, messages.requiredField)
      .ensure()
      .required(messages.requiredField),
    quote: yup.string().required(messages.requiredField),
  })
  .required();
