import { Box, Typography, useTheme } from "@mui/material";
import * as React from "react";
import classes from "./servicesCard.module.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Link, useNavigate } from "react-router-dom";
import useImage from "Hooks/useImage";
import profile from "assets/dashboard/myEvent/profile.webp";
import useLoaded from "Hooks/useLoaded";

const ServicesCard = ({
  heading,
  text,
  imageKey,
  vendorLink = "#",
  vendor = true,
  vendorDetailUrl = "",
}) => {
  const { data: image } = useImage({
    key: imageKey,
  });
  const isImage = useLoaded({ src: image });
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <Box
      className={classes.root}
      sx={{ cursor: vendorDetailUrl ? "pointer" : "default" }}
      onClick={() => vendorDetailUrl && navigate(vendorDetailUrl)}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <img
          src={isImage ? (isImage != "error" ? image : profile) : profile}
          alt="simple"
          className={classes.img}
        />
        <Box className={classes.text}>
          {vendor ? (
            <Typography
              component="h2"
              variant="p2Bold"
              color={theme.palette.primary.black}
            >
              {heading}
            </Typography>
          ) : (
            <Typography
              component="h2"
              variant="p2Bold"
              color={theme.palette.primary.black}
              sx={{ cursor: "pointer" }}
            >
              {heading}
            </Typography>
          )}
          <Typography
            sx={{
              display: "-webkit-box",
              "-webkit-line-clamp": "4",
              "-webkit-box-orient": "vertical",
              overflow: "hidden",
            }}
            variant="p"
            component="p"
          >
            {text ? text : "No Description Available."}
          </Typography>
        </Box>
        {vendor && (
          <Box
            sx={{
              bottom: "0",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <hr style={{ margin: "0px 20px" }} />
            <Box className={classes.container}>
              <Typography
                component="h3"
                variant="p2Bold"
                className={classes.vendor}
                color={theme.palette.primary?.black}
              >
                Vendors
              </Typography>
              <Link to={vendorLink}>
                <Box className={classes.icon}>
                  <ArrowForwardIcon />
                </Box>
              </Link>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default ServicesCard;
