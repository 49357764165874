import React from "react";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
export default function InputWrapper(props) {
  return (
    <FormControl sx={{ display: "flex", maxWidth: "100%" }}>
      {props.label && (
        <FormLabel
          id="input-label"
          sx={{
            fontWeight: "600",
            fontSize: "18px",
            lineHeight: "26px",
            color: props.color ? props.color : "#01214D",
            textAlign: "left",
            marginBottom: "5px",
          }}
          focused={false}
        >
          {props.label}
        </FormLabel>
      )}
      {props.children}
      {props.errors && <FormHelperText error>{props.errors}</FormHelperText>}
    </FormControl>
  );
}
