import { ReactComponent as DashboardIcon } from "assets/drawer/dashboard.svg";
import { ReactComponent as EventIcon } from "assets/drawer/event.svg";
import { ReactComponent as MeetingIcon } from "assets/drawer/meetings.svg";
import { ReactComponent as MessageIcon } from "assets/drawer/message.svg";
import { ReactComponent as QuoteIcon } from "assets/drawer/quotes.svg";
import { ReactComponent as VendorIcon } from "assets/drawer/vendor.svg";

export const customerNavList = [
  {
    id: "dashboard",
    item: "Dashboard",
    url: "/dashboard",
    icon: <DashboardIcon />,
  },
  {
    id: "events",
    item: "My Events",
    url: "/dashboard/events",
    icon: <EventIcon />,
  },
  {
    id: "quotes",
    item: "Quotes",
    url: "/dashboard/quotes",
    icon: <QuoteIcon />,
  },
  {
    id: "messages",
    item: "Messages",
    url: "/dashboard/messages",
    icon: <MessageIcon />,
  },
  {
    id: "bookings",
    item: "Bookings",
    url: "/dashboard/bookings",
    icon: <MeetingIcon />,
  },
  {
    id: "meetings",
    item: "Meetings",
    url: "/dashboard/meetings",
    icon: <MeetingIcon />,
  },
  {
    id: "explore-vendors",
    item: "Explore Vendors",
    url: "/dashboard/explore/vendors",
    icon: <VendorIcon />,
  },
];

export const vendorNavList = [
  {
    id: "dashboard",
    item: "Dashboard",
    url: "/dashboard",
    icon: <DashboardIcon />,
  },
  {
    id: "events",
    item: "My Events",
    url: "/dashboard/events",
    icon: <EventIcon />,
  },
  {
    id: "quotes",
    item: "Quotes",
    url: "/dashboard/quotes",
    icon: <QuoteIcon />,
  },
  {
    id: "messages",
    item: "Messages",
    url: "/dashboard/messages",
    icon: <MessageIcon />,
  },
  {
    id: "bookings",
    item: "Bookings",
    url: "/dashboard/bookings",
    icon: <MeetingIcon />,
  },
  {
    id: "meetings",
    item: "Meetings",
    url: "/dashboard/meetings",
    icon: <MeetingIcon />,
  },
];
